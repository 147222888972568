/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:08653e54-1008-4047-8ed0-bcd4e60159dc",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_KPqrWJaSC",
    "aws_user_pools_web_client_id": "7ja5m7mnr60m9lq7k9qup3s8mk",
    "oauth": {
        "domain": "prod-partner.auth.eu-central-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "GIVEN_NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "hansefit-prod-pimcore-dam-20210915084850615100000006",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "deepl",
            "endpoint": "https://ayki86k727.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
