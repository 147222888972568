import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const useAppointmentsFormStyles = makeStyles()(theme => ({
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  switchFieldAndLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  backBtn: {
    marginBottom: 15,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.83,
    letterSpacing: 0.34,
    color: theme.palette.primary.dark,
    padding: '4px 2px',
  },
  courseName: {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 14,
    color: FigmaDesignTokens.Grey[950],
    textTransform: 'uppercase',
    marginBottom: '15px',
  },
  managerLabel: {
    fontWeight: 600,
  },
}));

export const useAppointmentsTimeStyles = makeStyles()(() => ({
  errorContainer: {
    height: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
  },
  helperText: {
    position: 'absolute',
    fontSize: '0.70rem',
  },
}));

export const useSingleEventFormStyles = makeStyles()(() => ({
  datepicker: {
    '& .MuiIconButton-root': {
      position: 'relative',
    },
  },
}));
