import { CustomizedChip } from '@/App/Shared/Form/Components/Customized/CustomizedChip';
import { HansefitLogo } from '@/Components';

import FigmaDesignTokens from '../../design/design-tokens.json';

const COLOR_BEST = FigmaDesignTokens.Purple[950];
const COLOR_PRO = FigmaDesignTokens.Blue[800];
const COLOR_BASIC = FigmaDesignTokens.Blue[400];

const ServicesBadge = ({ service }: { service: string }) => {
  let color;

  switch (service.toLowerCase()) {
    case '(best)':
      color = COLOR_BEST;
      break;
    case '(pro)':
      color = COLOR_PRO;
      break;
    case '(basic)':
      color = COLOR_BASIC;
      break;
  }

  return (
    <CustomizedChip
      variant='outlined'
      label={service}
      key={service}
      icon={
        color ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <HansefitLogo
              style={{
                color,
                width: 24,
                height: 24,
                borderRadius: '50%',
              }}
            />
          </div>
        ) : undefined
      }
    />
  );
};

export default ServicesBadge;
