import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  container: {
    pointerEvents: 'none',
    position: 'fixed',
    top: 0,
    zIndex: 9999,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-end',

    paddingLeft: '2rem',
    paddingRight: '2rem',
    paddingTop: '2rem',
  },
}));

export default useStyles;
