import { Auth } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { useCurrentPartnerIdQuery } from '@/Queries';
import { CheckInCheckoutEventType } from '@/Types';

import CheckinCheckoutToast from '../App/Shared/CheckinCheckoutToast';
import { useCurrentCheckinsMutation } from './useCurrentCheckins';

const useLiveCheckins = () => {
  const [token, setToken] = useState('');
  const [seenToken, setSeenToken] = useState<string[]>([]);
  const { mutate: addLiveCheckinMutation } = useCurrentCheckinsMutation();
  const { data: casPublicId } = useCurrentPartnerIdQuery();

  const { lastJsonMessage, readyState } = useWebSocket<CheckInCheckoutEventType>(
    process.env.REACT_APP_LIVE_CHECKINS_WEBSOCKET_URL && token
      ? `wss://${process.env.REACT_APP_LIVE_CHECKINS_WEBSOCKET_URL}`
      : null,
    {
      shouldReconnect: () => true,
      reconnectAttempts: 10,
      //attemptNumber will be 0 the first time it attempts to reconnect, so this equation results in a reconnect pattern of 1 second, 2 seconds, 4 seconds, 8 seconds, and then caps at 10 seconds until the maximum number of attempts is reached
      reconnectInterval: attemptNumber => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
      queryParams: {
        token,
      },
    },
  );

  const getSession = useCallback(async () => {
    const session = await Auth.currentSession();
    setToken(session.getIdToken().getJwtToken());
  }, []);

  useEffect(() => {
    if (!token) {
      getSession();
    }
  }, [token, getSession]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  useEffect(() => {
    // Do nothing if there is no message or if the message is not for the current partner
    if (!lastJsonMessage || lastJsonMessage?.data?.partnerPublicId !== casPublicId) return;

    const uniqueCheckinId = `${lastJsonMessage.data.checkInId}-${lastJsonMessage.data.checkInTimestamp}-${lastJsonMessage.data.checkOutTimestamp}-${lastJsonMessage.data.checkInSource}-${lastJsonMessage.data.checkOutSource}-${lastJsonMessage.data.errors.length}-${lastJsonMessage.data.offline}`;

    if (!seenToken.includes(uniqueCheckinId)) {
      setSeenToken(prev => [...prev, uniqueCheckinId]);

      // Use the mutation to add the new item to the list
      addLiveCheckinMutation(lastJsonMessage);

      toast.custom(
        <CheckinCheckoutToast
          data={lastJsonMessage.data}
          type={lastJsonMessage.name}
          variant='small'
        />,
        {
          duration: 30000,
          style: {
            width: '50%',
            maxWidth: 400,
          },
        },
      );
    }
  }, [addLiveCheckinMutation, lastJsonMessage, casPublicId, seenToken]);

  return { connectionStatus };
};

export default useLiveCheckins;
