import { Auth } from 'aws-amplify';
import { Box, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';

import { AuthActions } from '@/Store/Auth';
import { Button, SubHeadlineDisplay } from '@/Components';
import { ChangePasswordSchema, getPasswordErrors } from '@/Utils/Validators';
import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { useChangePasswordStyles } from '@/Views/Authentication/Components/ForgotPassword/ForgotPassword.styles';
import { useAppDispatch } from '@/Hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import { ChangePasswordFormProps } from '@/Types';

const initialState = {
  oldPassword: '',
  password: '',
  passwordConfirmation: '',
};

const ChangePasswordForm = () => {
  const { formatMessage } = useIntl();
  const { classes } = useChangePasswordStyles();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (
    values: ChangePasswordFormProps,
    formikHelpers: FormikHelpers<ChangePasswordFormProps>,
  ) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();

      await Auth.changePassword(cognitoUser, values.oldPassword, values.password);
      navigate('/login');
      dispatch(AuthActions.Logout());
    } catch (err: unknown) {
      const error = err as { name: string; code: string };

      formikHelpers.setFieldError('oldPassword', getPasswordErrors(error.code, formatMessage));
    }
    formikHelpers.setSubmitting(false);
  };

  return (
    <Formik
      // innerRef={formikRef}
      initialValues={initialState}
      validationSchema={() => ChangePasswordSchema(formatMessage)}
      isInitialValid={false}
      validateOnChange
      onSubmit={(values, helpers) => handleSubmit(values, helpers)}>
      {props => (
        <Box>
          <ContentContainer>
            <Form>
              <Grid container spacing={4}>
                <Grid item container spacing={4}>
                  <Grid item xs={12}>
                    <SubHeadlineDisplay
                      textContent={formatMessage({
                        id: 'form.current_user.cta.change_password',
                        defaultMessage: 'Change Password',
                      })}
                      textAlign='center'
                    />
                  </Grid>
                  <Grid xs={8} item container>
                    <Grid container spacing={4}>
                      <Grid item xs={8}>
                        <Field
                          className={classes.textField}
                          component={FormikTextField}
                          variant='outlined'
                          name='oldPassword'
                          type='password'
                          label={formatMessage({
                            id: 'form.change_password.field.old_password.label',
                            defaultMessage: 'Old password',
                          })}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <Field
                          className={classes.textField}
                          component={FormikTextField}
                          variant='outlined'
                          name='password'
                          type='password'
                          label={formatMessage({
                            id: 'form.forgot_password.stepper.step.change_password.field.password.label',
                            defaultMessage: 'Password',
                          })}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <Field
                          className={classes.textField}
                          component={FormikTextField}
                          variant='outlined'
                          name='passwordConfirmation'
                          type='password'
                          label={formatMessage({
                            id: 'form.forgot_password.stepper.step.change_password.field.password_confirmation.label',
                            defaultMessage: 'Repeat password',
                          })}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant='contained' disabled={!props.isValid} type='submit'>
                      {formatMessage({
                        id: 'form.current_user.cta.change_password',
                        defaultMessage: 'Change password',
                      })}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </ContentContainer>
        </Box>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
