import { DatePicker } from '@mui/x-date-pickers-pro';
import { get } from 'lodash';
import { FieldProps, useFormikContext } from 'formik';
import { Event } from '@/Types';

const KeyboardDatePicker = (props: FieldProps<Date | null>) => {
  const { errors, touched } = useFormikContext<Event>();

  const hasError = !!get(errors, props.field.name) && !!get(touched, props.field.name);

  return (
    <DatePicker<Date | null>
      {...props}
      slotProps={{
        textField: {
          error: hasError,
        },
      }}
      views={['year', 'month', 'day']}
    />
  );
};

export default KeyboardDatePicker;
