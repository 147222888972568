import { Box, ClickAwayListener } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Flag } from '@/Components';
import useLanguagesList from '@/Hooks/useLanguagesList';
import { LanguageType } from '@/Types/Language';

import { useLanguageDropdownStyles } from './Styles/Header.styles';

export const LanguageDropdown = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { language } = useParams();
  const { classes } = useLanguageDropdownStyles();

  const [isOpen, setIsOpen] = useState(false);

  const fullLanguagesList = useLanguagesList();

  const languagesList = useMemo(() => {
    if (isOpen) {
      //sorting to show current selected language on top of the list
      return fullLanguagesList.sort((a, b) => (a.languageKey === language ? -1 : 1));
    }
    return fullLanguagesList.filter(item => item.languageKey === language);
  }, [isOpen, language, fullLanguagesList]);

  const handleChangeLanguage = useCallback(
    (newLanguage?: LanguageType) => {
      if (isOpen && newLanguage && newLanguage !== language) {
        navigate(`${pathname.replace(`/${language}/`, `/${newLanguage}/`)}${search}`);
      }
      setIsOpen(prev => !prev);
    },
    [isOpen, language, navigate, pathname, search],
  );

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOpen(false);
      }}>
      <Box role='presentation' className={classes.languageContainer}>
        {languagesList.map(item => (
          <Box
            key={item.languageKey}
            className={clsx(classes.languageRow, { [classes.active]: isOpen })}
            onClick={() => handleChangeLanguage(item.languageKey)}>
            <Flag src={item.languageFlagPath} width={35} height={35} />
          </Box>
        ))}
      </Box>
    </ClickAwayListener>
  );
};

export default LanguageDropdown;
