import { Box } from '@mui/material';
import clsx from 'clsx';
import { Field } from 'formik';
import { ReactNode, useCallback, useEffect, useState } from 'react';

import { FormikTextFieldPhoto } from '@/App/Shared/Form/Components/Formik/FormikTextFieldPhoto';
import { AlterGalleryImageModal, CropGalleryImageModal } from '@/Components';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import { useGetPartnerQuery } from '@/Queries';
import { AssetService } from '@/Services';
import New from '@/Static/Icons/atoms-symbols-alert-neu.svg';
import { GalleryAsset, Partner } from '@/Types';

import { GalleryAssetPreview } from '../Assets/GalleryAssetPreview';
import { useGalleryImageTileStyles } from './Gallery.styles';

type GalleryImageTileProps = {
  resourceData: GalleryAsset;
  handleRemoveImage: (value: number) => void;
  dragComponent: ReactNode;
  casPublicId: Partner['casPublicId'];
  idx: number;
  assetService: AssetService;
  latestUploads: GalleryAsset[];
  handleReplaceImage: (
    idx: number,
    uri: string,
    assetId: number,
    newOriginalFilename: string | null,
  ) => void;
  handlePrimaryCtaDisabled: (value: boolean) => void;
};

export const GalleryImageTile = ({
  handleRemoveImage,
  dragComponent,
  resourceData,
  casPublicId,
  idx,
  assetService,
  latestUploads,
  handleReplaceImage,
  handlePrimaryCtaDisabled,
}: GalleryImageTileProps) => {
  const currentLanguage = useCurrentLanguage();
  const { classes } = useGalleryImageTileStyles();
  const [isNew, setIsNew] = useState(false);
  const { data: partner } = useGetPartnerQuery();

  const [newFileKey, setNewFileKey] = useState('');
  const [newOriginalFilename, setNewOriginalFilename] = useState<string | null>(null);
  const [newFileAssetId, setNewFileAssetId] = useState<number>();

  const handleNewFileKey = useCallback((value: string) => {
    setNewFileKey(value);
  }, []);

  const handleNewOriginalFilename = useCallback((value: string | null) => {
    setNewOriginalFilename(value);
  }, []);

  const handleNewFileAssetId = useCallback((value: number | undefined) => {
    setNewFileAssetId(value);
  }, []);

  useEffect(() => {
    const isNew = latestUploads.find(upload => upload.assetId === resourceData.assetId);

    setIsNew(!!isNew);
  }, [latestUploads, resourceData]);

  return (
    <Box
      className={clsx(classes.tileWrapper, {
        [classes.tileWrapperDisabled]: !!partner?.readonly,
        [classes.activeBorder]: idx === 0,
      })}>
      {isNew && idx > 0 && <img className={classes.isNew} alt='' src={New} />}
      {!resourceData.assetId && idx === 0 && <Box className={classes.galleryPlaceholder} />}
      {resourceData.assetId && (
        <>
          <Box className={classes.topWrapper}>
            {resourceData.uri && (
              <CropGalleryImageModal
                handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
                originalFilename={resourceData.originalFilename}
                resource={resourceData.uri}
                assetId={resourceData.assetId}
                casPublicId={casPublicId}
                assetService={assetService}
              />
            )}
            {dragComponent}
          </Box>
          <GalleryAssetPreview
            resourceData={resourceData}
            casPublicId={casPublicId}
            assetService={assetService}
            handleRemoveImage={handleRemoveImage}
            isBinIcon
          />
          <Box className={classes.tileInputWrapper}>
            <Field
              className={classes.input}
              component={FormikTextFieldPhoto}
              variant='outlined'
              name={`gallery[${idx}].title.${currentLanguage}`}
              type='text'
            />
          </Box>
          <Box className={classes.alterModalBtn}>
            <AlterGalleryImageModal
              assetService={assetService}
              casPublicId={casPublicId}
              resourceData={resourceData}
              handleNewFileKey={handleNewFileKey}
              newOriginalFilename={newOriginalFilename}
              handleNewOriginalFilename={handleNewOriginalFilename}
              newFileKey={newFileKey}
              handleReplaceImage={handleReplaceImage}
              idx={idx}
              newFileAssetId={newFileAssetId}
              handleNewFileAssetId={handleNewFileAssetId}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
