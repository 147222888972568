import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../../design/design-tokens.json';

export const useStartCodeStyles = makeStyles()(theme => ({
  textField: {
    '& input.MuiOutlinedInput-input::placeholder': {
      color: `${theme.palette.info.dark}!important`,
    },
    '& label, input': {
      fontSize: 14,
      height: '20.63px',
    },
    '& label.Mui-error': {
      fontSize: 14,
    },
  },
  startCodeField: {
    width: 80,
  },
  mainWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  multiInputsWrapper: {
    margin: '0 55px',
    display: 'flex',
    alignItems: 'center',
  },
  partKeyDash: {
    margin: '0 10px',
    color: FigmaDesignTokens.Grey[950],
    lineHeight: 1.43,
    letterSpacing: 0.18,
  },
  inputBoxWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 4,
    justifyContent: 'space-evenly',
  },
}));
