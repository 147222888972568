import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const useTableStyles = makeStyles()(theme => ({
  toolbarButton: {
    color: FigmaDesignTokens.Grey[500],
    fontWeight: 500,
    fontFamily: 'Roboto',
    fontSize: 16,
    '& .MuiButton-root': {
      color: FigmaDesignTokens.Grey[500],
      fontWeight: 500,
      fontFamily: 'Roboto',
      fontSize: 16,
      textTransform: 'initial',
      height: 40,
    },
    '& p': {
      color: FigmaDesignTokens.Grey[500],
      fontWeight: 500,
      fontFamily: 'Roboto',
      fontSize: 16,
      textTransform: 'initial',
    },
    '& .MuiButton-startIcon': {
      marginRight: 4,
      '& .material-icons': {
        width: 24,
        height: 24,
      },
    },
  },
  switchToolbar: {
    '& p': {
      fontSize: 16,
      fontWeight: 400,
    },
  },
  filtersApplyBtn: {
    padding: '7px 16px',
    fontFamily: 'Roboto-Medium',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.main,
    borderRadius: '4px',
    boxShadow: 'none',
    minWidth: 'auto',
    height: '40px',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.38,
    textTransform: 'unset',

    '&.MuiButton-outlined': {
      fontFamily: 'Roboto-Bold',
      borderColor: 'currentColor',
    },
    '&.MuiButton-info': {
      fontFamily: 'Roboto-Bold',
      borderColor: 'currentColor',
    },

    '&:disabled': {
      opacity: 0.5,
    },
    '&:hover': {
      backgroundColor: FigmaDesignTokens.Grey[200],
      color: theme.palette.primary.dark,
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: FigmaDesignTokens.Grey[300],
      color: FigmaDesignTokens.Blue[950],
      boxShadow: 'none',
    },
  },
}));
