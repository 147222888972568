import { CustomTooltip } from '@/App/Shared/Tooltip/CustomTooltip';
import { User } from '@/Types';

import { BlockedName, Container, Name } from './EmailCell.styles';

type EmailCellProps = {
  data: User;
};

export const EmailCell = ({ data: row }: EmailCellProps) => (
  <Container>
    {row.status === 'Blocked' && (
      <CustomTooltip title={row.email}>
        <BlockedName>{row.email}</BlockedName>
      </CustomTooltip>
    )}
    {row.status !== 'Blocked' && (
      <CustomTooltip title={row.email}>
        <Name>{row.email}</Name>
      </CustomTooltip>
    )}
  </Container>
);
