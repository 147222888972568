import { Grid } from '@mui/material';
import { useIntl } from 'react-intl';

import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { CustomTable } from '@/App/Shared/Table/CustomTable';
import { EquipmentAndServiceTableHead } from './EquipmentAndServiceTableHead';
import { EquipmentAndServiceTableBody } from './TableBody/EquipmentAndServiceTableBody';

import { Partner } from '@/Types';

import { BodyTextSmall } from '@/Components';

interface EquipmentAndServiceTableBoxProps {
  partner: Partner;
  handleChange: (property: string, value: string, idx: number) => void;
}

export const EquipmentAndServiceTableBox = (
  props: EquipmentAndServiceTableBoxProps,
): JSX.Element => {
  const { formatMessage } = useIntl();
  const { partner, handleChange } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <FormHeader
              title={formatMessage({
                id: 'form.equipment_and_services.header.equipment',
                defaultMessage: 'Equipment ',
              })}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid container item xs={8}>
            <Grid item xs={12}>
              <BodyTextSmall
                textContent={formatMessage({
                  id: 'form.equipment_and_services.description.equipment',
                  defaultMessage:
                    'Please select which services and benefits Hansefit members can use free of charge or for an additional charge.',
                })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          tableHead={<EquipmentAndServiceTableHead partner={partner} />}
          tableBody={<EquipmentAndServiceTableBody partner={partner} handleChange={handleChange} />}
        />
      </Grid>
    </Grid>
  );
};
