import { useParams } from 'react-router-dom';
import { LanguageType } from '../Types';

// export here for compatibility with old code
export const useCurrentLanguage = () => {
  const { language = 'de' } = useParams<{ language: LanguageType }>();

  return language;
};

export default useCurrentLanguage;
