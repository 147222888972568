import { useMemo } from 'react';

import { LanguageType } from '@/Types';

import useMemoedIntl from './useMemoedIntl';

// export here for compatibility with old code
export const useLanguagesList = () => {
  const { formatMessage } = useMemoedIntl();

  const list: { languageKey: LanguageType; languageTitle: string; languageFlagPath: string }[] =
    useMemo(
      () => [
        {
          languageKey: 'de',
          languageTitle: formatMessage({
            id: 'language.dropdown.title.de',
            defaultMessage: 'Deutsch',
          }),
          languageFlagPath: '/assets/flags/de.svg',
        },
        {
          languageKey: 'en',
          languageTitle: formatMessage({
            id: 'language.dropdown.title.en',
            defaultMessage: 'English',
          }),
          languageFlagPath: '/assets/flags/en.svg',
        },
      ],
      [formatMessage],
    );
  return list;
};

export default useLanguagesList;
