import { CSSObject } from '@emotion/react';
import { Button, ButtonTypeMap, FormHelperText } from '@mui/material';
import { ReactNode } from 'react';

type HookFormSingleUploadProps = {
  name: string;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  children?: ReactNode;
  css?: CSSObject;
  variant?: ButtonTypeMap['props']['variant'];
  className?: string;
  formHelperText?: ReactNode;
  validFileExtensions?: string[];
  setFieldValue: (field: string, value: FileList, shouldValidate?: boolean) => void;
};

const FormikSingleUpload = ({
  name,
  error,
  children,
  formHelperText,
  validFileExtensions,
  setFieldValue,
  ...props
}: HookFormSingleUploadProps) => (
  <>
    <input
      type='file'
      id={name}
      style={{
        position: 'absolute',
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: 'hidden',
        clip: 'rect(0,0,0,0)',
        border: 0,
        appearance: 'none',
      }}
      accept={validFileExtensions?.map(ext => `.${ext}`).join(',')}
      onChange={event => {
        event.currentTarget.files && setFieldValue(name, event.currentTarget.files);
      }}
    />
    {error && error.length > 0 && <FormHelperText error>{error}</FormHelperText>}

    <Button component='label' htmlFor={name} {...props}>
      {children}
    </Button>

    {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
  </>
);

export default FormikSingleUpload;
