import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { EnableCourseManagerDialogStepIcon } from './EnableCourseManagerDialogStepIcon';
import { EnableCourseManagerDialogStepConnector } from './EnableCourseManagerDialogStepConnector';

export interface RegisterStepperProps {
  activeStep: number;
}

const useStyles = makeStyles()(_theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 2,
  },
  stepper: {
    backgroundColor: 'transparent',
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingTop: 0,
    paddingBottom: 20,
  },
  step: {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiStepLabel-iconContainer': {
      display: 'initial',
      width: '100%',
      paddingRight: 5,
      paddingLeft: 5,
      zIndex: 2,
    },
  },
}));

export const EnableCourseManagerDialogStepper = (props: RegisterStepperProps): JSX.Element => {
  const { activeStep } = props;
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Stepper
        alternativeLabel
        connector={<EnableCourseManagerDialogStepConnector />}
        activeStep={activeStep}
        className={classes.stepper}>
        <Step className={classes.step}>
          <StepLabel
            StepIconComponent={props => (
              <EnableCourseManagerDialogStepIcon {...props} stepNumber={1} />
            )}
          />
        </Step>
        <Step className={classes.step}>
          <StepLabel
            StepIconComponent={props => (
              <EnableCourseManagerDialogStepIcon {...props} stepNumber={2} />
            )}
          />
        </Step>
        <Step className={classes.step}>
          <StepLabel
            StepIconComponent={props => (
              <EnableCourseManagerDialogStepIcon {...props} stepNumber={3} />
            )}
          />
        </Step>
        <Step className={classes.step}>
          <StepLabel
            StepIconComponent={props => (
              <EnableCourseManagerDialogStepIcon {...props} stepNumber={4} />
            )}
          />
        </Step>
      </Stepper>
    </Box>
  );
};
