import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppContext } from '@/Context';
import { PartnerServiceFactory } from '@/Services';
import { CurrentUserInfo } from '@/Types';

const useCurrentUserInfoQuery = () => {
  const { dispatch } = useAppContext();

  const partnerServiceInstance = useMemo(
    () => new PartnerServiceFactory().getInstance(dispatch),
    [dispatch],
  );

  const queryResult = useQuery(
    ['getUserAttributes'],
    async () =>
      (await partnerServiceInstance.httpService
        .getAuthClass()
        .currentUserInfo()) as CurrentUserInfo,
    {
      cacheTime: 0,
    },
  );

  return queryResult;
};

export default useCurrentUserInfoQuery;
