import { Tab } from '@/Types';

export const getPhotoAndVideoTabIdx = (tab: string) => {
  switch (tab) {
    case 'logo':
      return 0;
    case 'foto-upload':
      return 1;
    case 'video-upload':
      return 2;
    default:
      return 0;
  }
};

export const getPhotoAndVideoTabName = (tab: number) => {
  switch (tab) {
    case 0:
      return 'logo';
    case 1:
      return 'foto-upload';
    case 2:
      return 'video-upload';
    default:
      return 'logo';
  }
};

export const getDashboardPathname = (language: string) => `/${language}/dashboard`;

export const getLogoPathname = (language: string) => `/${language}/foto-und-video/logo`;

export const getCommonInfoTabIdx = (tab: string) => {
  switch (tab) {
    case 'adresse-und-kontakt':
      return 0;
    case 'check-in-optionen':
      return 1;
    case 'kategorien':
      return 2;
    default:
      return 0;
  }
};

export const getCommonInfoTabName = (tab: number) => {
  switch (tab) {
    case 0:
      return 'adresse-und-kontakt';
    case 1:
      return 'check-in-optionen';
    case 2:
      return 'kategorien';
    default:
      return 'adresse-und-kontakt';
  }
};

export const getCourseOverviewTabName = (tab: number) => {
  switch (tab) {
    case 0:
      return 'laufende-kurse';
    case 1:
      return 'abgelaufene-kurse';
    default:
      return 'laufende-kurse';
  }
};

export const getCourseOverviewTabIdx = (tab: string) => {
  switch (tab) {
    case 'laufende-kurse':
      return 0;
    case 'abgelaufene-kurse':
      return 1;
    default:
      return 0;
  }
};

export const getDescAndOpenHoursTabIdx = (tab: string) => {
  switch (tab) {
    case 'beschreibung':
      return 0;
    case 'oeffnungszeiten':
      return 1;
    default:
      return 0;
  }
};

export const getDescAndOpenHoursTabName = (tab: number) => {
  switch (tab) {
    case 0:
      return 'beschreibung';
    case 1:
      return 'oeffnungszeiten';
    default:
      return 'beschreibung';
  }
};

export const getUsersTabIdx = (tab: string) => {
  switch (tab) {
    case 'benutzerverwaltung':
      return 0;
    case 'benutzer-einladen':
      return 1;
    default:
      return 0;
  }
};

export const getUsersTabName = (tab: number) => {
  switch (tab) {
    case 0:
      return 'benutzerverwaltung';
    case 1:
      return 'benutzer-einladen';
    default:
      return 'benutzerverwaltung';
  }
};

export const getCourseAndEventsTabNumber = (
  routeParams: {
    courseId?: number;
    action?: string;
    eventId?: number;
  },
  pathname: string,
  language: string,
) => {
  const { courseId, eventId } = routeParams;

  switch (pathname) {
    case getNewCoursePathname(language):
      return 0;
    case getCoursePathname(language, courseId):
      return 0;
    case getEventsPathname(language, courseId):
      return 1;
    case getAddEventPathname(language, courseId):
      return 1;
    case getPastEventsPathname(language, courseId):
      return 2;
    case getPastEventPathname(language, courseId, eventId):
      return 2;
    case getEventPathname(language, courseId, eventId):
      return 1;
    default:
      return -1;
  }
};

export const getPhotoUploadPathname = (language: string) =>
  `/${language}/foto-und-video/foto-upload`;

export const getVideoUploadPathname = (language: string) =>
  `/${language}/foto-und-video/video-upload`;

export const getEquipmentPathname = (language: string) => `/${language}/ausstattung-und-leistungen`;

export const getDescriptionPathname = (language: string) =>
  `/${language}/beschreibung-und-oeffnungszeiten/beschreibung`;

export const getOpeningHoursPathname = (language: string) =>
  `/${language}/beschreibung-und-oeffnungszeiten/oeffnungszeiten`;

export const getCommonInformationPathname = (language: string) =>
  `/${language}/allgemeine-informationen/adresse-und-kontakt`;

export const getCheckInOptionsPathname = (language: string) =>
  `/${language}/allgemeine-informationen/check-in-optionen`;

export const getCategoriesPathname = (language: string) =>
  `/${language}/allgemeine-informationen/kategorien`;

export const getNewCoursePathname = (language: string) => `/${language}/neuen-kurs-anlegen`;

export const getNewCoursePathnameWithTemplate = (language: string, courseId: number) =>
  `/${language}/neuen-kurs-anlegen?template=${courseId}`;

export const getCoursePathname = (language: string, courseId?: number) =>
  `/${language}/kurse/${courseId}`;

export const getEventsPathname = (language: string, courseId?: number) =>
  `/${language}/kurse/${courseId}/termine`;

export const getAddEventPathname = (language: string, courseId?: number) =>
  `/${language}/kurse/${courseId}/termin-hinzufuegen`;

export const getPastEventsPathname = (language: string, courseId?: number) =>
  `/${language}/kurse/${courseId}/vergangene-termine`;

export const getPastEventPathname = (language: string, courseId?: number, eventId?: number) =>
  `/${language}/kurse/${courseId}/vergangene-termine/${eventId}`;

export const getEventPathname = (language: string, courseId?: number, eventId?: number) =>
  `/${language}/kurse/${courseId}/termine/${eventId}`;

export const getCoursesPathname = (language: string, params?: string) =>
  params
    ? `/${language}/kursuebersicht/laufende-kurse${params}`
    : `/${language}/kursuebersicht/laufende-kurse`;

export const getSchedulePathname = (language: string) => `/${language}/terminplan`;

export const getUsersManagementPathname = (language: string) =>
  `/${language}/benutzer/benutzerverwaltung`;

export const getInviteUsersPathname = (language: string) =>
  `/${language}/benutzer/benutzer-einladen`;

export const getCurrentUserPathname = (language: string) => `/${language}/mein-benutzerkonto`;

export const getAllCheckInsPathname = (language: string) => `/${language}/check-in-ubersicht/alle`;

export const getCompanyCheckInsPathname = (language: string) =>
  `/${language}/check-in-ubersicht/firma`;

export const getCurrentCoursesPathname = (language: string) =>
  `/${language}/kursuebersicht/laufende-kurse`;

export const getExpiredCoursesPathname = (language: string) =>
  `/${language}/kursuebersicht/abgelaufene-kurse`;

export const getScheduledEventsPathname = (language: string) => `/${language}/terminplan`;

export const getCreateEventPathname = (language: string, courseId: number) =>
  `/${language}/kurse/${courseId}/termin-hinzufuegen`;

export const getEditEventPathname = (language: string, courseId: number) =>
  `/${language}/kurse/${courseId}`;

export const getInitialPasswordPathname = (email?: string) =>
  email ? `/initial-password?email=${email}` : `/initial-password`;

export const getInitialTabChangeCourseAndEventsPathname = (
  routeParams: {
    courseId?: number;
    action?: string;
    eventId?: number;
  },
  tab: number,
  language: string,
) => {
  const { courseId } = routeParams;

  switch (tab) {
    case 0:
      return courseId ? `/${language}/kurse/${courseId}` : `/${language}/neuen-kurs-anlegen`;
    case 1:
      return `/${language}/kurse/${courseId}/termine`;
    case 2:
      return `/${language}/kurse/${courseId}/vergangene-termine`;
    case 3:
      return `/${language}/kurse/${courseId}/laufende-kurse`;
    case 4:
      return `/${language}/kurse/${courseId}/abgelaufene-kurse`;
    default:
      return '';
  }
};

export const getCoursesEventsPathname = (language: string, courseId?: string) =>
  `/${language}/kurse/${courseId}/abgelaufene-kurse`;

export const getCourseAndEventTabs = (params: { currentTab: number; courseId?: string }): Tab[] => {
  if (params.courseId) {
    return [
      { label: 'view.courses_and_events.edit_course', disabled: false },
      { label: 'view.courses_and_events.events', disabled: false },
      { label: 'view.courses_and_events.past_events', disabled: false },
    ];
  } else {
    return [
      { label: 'view.courses_and_events.add_new_course', disabled: false },
      { label: 'view.courses_and_events.add_events', disabled: true },
    ];
  }
};

export const getCoursesOverviewTabs = () => [
  { label: 'view.courses_and_events.courses', disabled: false },
  { label: 'view.courses_and_events.past_courses', disabled: false },
];

export const getStaticPathname = (language: string, tab: string) => `/${language}/statistik/${tab}`;

export const isNewCourseView = (pathname: string) =>
  pathname.split('/').indexOf('neuen-kurs-anlegen') !== -1;

export const isEditCourseView = (courseId: number, action: string) => !!courseId && !action;

export const isEventsView = (courseId: number, action: string) =>
  !!courseId && action === 'termine';

export const isNewEventView = (courseId: number, action: string) =>
  !!courseId && action === 'termin-hinzufuegen';

export const isCoursesListView = (courseId: number, action: string) =>
  !!courseId && action === 'laufende-kurse';

export const isPastEventListView = (courseId: number, action: string) =>
  !!courseId && action === 'vergangene-termine';

export const getCommonInformationTabs = () => [
  {
    label: 'view.common_information.address_and_contact',
    disabled: false,
  },
  {
    label: 'view.common_information.check_in_options',
    disabled: false,
  },
  {
    label: 'view.common_information.categories',
    disabled: false,
  },
];

export const getSettingsPathname = (language: string) =>
  `/${language}/allgemeine-informationen/einstellungen`;
