import { Dispatch } from 'react';
import { AnyStateAction } from '@/Context';
import { SupportService } from '../Support/Support.service';
import { Auth } from 'aws-amplify';
import { HttpService } from '@/Services';

export class SupportServiceFactory {
  public getInstance(dispatch: Dispatch<AnyStateAction>): SupportService {
    return new SupportService(new HttpService(Auth, dispatch));
  }
}
