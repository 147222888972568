import { useIntl } from 'react-intl';

import TimerIcon from '@/Static/Icons/atoms-symbols-icons-list-timer.svg';
import { OpenEventsCellTooltip } from './OpenEventsCell.styles';

export const OpenEventsCell = ({ value }: { value?: number }) => {
  const intl = useIntl();

  return (
    <>
      {value}{' '}
      {intl.formatMessage({
        id: 'view.courses.current_courses.table.events',
        defaultMessage: 'Dates',
      })}
      {value && value <= 1 && (
        <OpenEventsCellTooltip
          title={intl.formatMessage({
            id: 'view.courses.current_courses.table.events.tooltip.last_event',
            defaultMessage: 'Your last appointment is coming soon. Add new dates now.',
          })}>
          <img alt='' src={TimerIcon} />
        </OpenEventsCellTooltip>
      )}
    </>
  );
};
