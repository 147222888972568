import { Box, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { Button } from '@/Components';
import { useAppDispatch } from '@/Hooks/useAppDispatch';
import { UsersMap, UserStatus } from '@/Mappers';
import { useGetPartnerQuery } from '@/Queries';
import CloseIcon from '@/Static/Icons/atoms-symbols-category-close-big.svg';
import { AuthActions } from '@/Store/Auth';
import { CurrentUserFormik, Role, UserAttr } from '@/Types';
import handleCatchError from '@/Utils/handleCatchError';

import { CurrentUserDeleteDialogStyles } from './CurrentUserDeleteDialog.styles';

interface DeleteCurrentUserDialogProps {
  handleClose: () => void;
  isOpen: boolean;
  usersService: UsersMap;
  userAttr: UserAttr;
}

const DeleteCurrentUserDialog = ({
  isOpen,
  handleClose,
  usersService,
  userAttr,
}: DeleteCurrentUserDialogProps) => {
  const dispatch = useAppDispatch();

  const { classes } = CurrentUserDeleteDialogStyles();
  const { data: partner } = useGetPartnerQuery();

  const { formatMessage } = useIntl();
  const { values } = useFormikContext<CurrentUserFormik>();
  const header = formatMessage({
    id: 'form.current_user.modal.delete_account.header',
    defaultMessage: 'Delete user account',
  });
  const description = formatMessage({
    id: 'form.current_user.modal.delete_account.description',
    defaultMessage: `After deleting your personal user account, you can no longer independently change the information about your facility.`,
  });

  const handleDeleteUser = useCallback(async () => {
    if (partner) {
      const role = values.isAdmin ? Role.ADMIN : Role.User;

      const currentUpdatedUser = {
        publicId: partner.publicId,
        role,
        email: userAttr.email,
        name: userAttr.name,
        givenName: userAttr.given_name,
        cognitoId: userAttr.sub,
        status: UserStatus.DELETED,
      };

      try {
        await usersService.updateUser(currentUpdatedUser);
        Sentry.setUser(null);
        dispatch(AuthActions.Logout());
      } catch (error: unknown) {
        handleCatchError(error);
      }
      handleClose();
    }
  }, [
    dispatch,
    handleClose,
    partner,
    userAttr.email,
    userAttr.given_name,
    userAttr.name,
    userAttr.sub,
    usersService,
    values.isAdmin,
  ]);

  return (
    <Dialog open={isOpen} onClose={handleClose} className={classes.dialog}>
      <Box className={classes.headerWrapper}>
        <FormHeader title={header} />
        <IconButton aria-label='close' onClick={handleClose} className={classes.closeButton}>
          <img alt='' src={CloseIcon} />
        </IconButton>
      </Box>

      <DialogContent className={classes.dialogContent}>{description}</DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button className={classes.confirmButton} onClick={handleDeleteUser}>
          {formatMessage({
            id: 'form.current_user.modal.delete_account.cta',
            defaultMessage: 'delete user account now',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCurrentUserDialog;
