import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';

import { HttpService } from '@/Services';
import {
  Booking,
  BulkSaveFeedback,
  CourseTypes,
  EditAddressDataCourseType,
  EditEvent,
  Event,
  EventList,
  EventListItem,
  EventsData,
  FilterCheckboxParam,
  FilterCheckboxQueriesString,
  FilterParam,
  FilterPickersQueriesString,
  FilterPickersRangeParam,
  FilterQueryStringParam,
  Partner,
  StreamSettings,
  UpdateEventsResponse,
  UpdateEventTypes,
} from '@/Types';

export enum EVENT_ACTIONS {
  SAVE = 'SAVE',
  DRAFT = 'DRAFT',
}

export class EventsService {
  public httpService: HttpService;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  public async createEvent(
    id: string,
    courseId: number,
    data: Omit<Event, 'eventType'>,
  ): Promise<AxiosResponse<Event> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.post(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}/courses/${courseId}/events`,
      data,
    );
  }

  public async createOnsiteEvent(
    id: string,
    courseId: number,
    data: Omit<Event, 'eventType'>,
  ): Promise<AxiosResponse<Event> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.post(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}/courses/${courseId}/onsite/events`,
      data,
    );
  }

  public async updateOnsiteEvent(
    id: string,
    data: EditAddressDataCourseType[],
  ): Promise<AxiosResponse<{ data: { events: EventListItem[] } }> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.patch(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}/courses/onsite/events`,
      data,
      {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
    );
  }

  public async updateEvents(
    id: string,
    data: EventListItem[],
  ): Promise<AxiosResponse<UpdateEventsResponse> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxiosForPatchRequest();

    return await axiosInstance.patch(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}/courses/events`,
      data,
    );
  }

  public getFiltersParams(values: EventList, courseId?: number) {
    const filtersParams: FilterCheckboxParam[] & FilterPickersRangeParam[] & FilterParam[] = [];
    const courseIdParam = { id: 'courseId', value: courseId || values.courseName.join() };
    const courseTypeParam = { id: 'courseType', value: values.courseType.join() };
    const pageParam = { id: 'page', value: values.page };
    const limitParam = { id: 'limit', value: values.limit };
    const publishedParam = { id: 'published', value: values.published };
    const canceledParam = { id: 'canceled', value: values.canceled };
    const bookingsParam = { id: 'bookings', value: values.bookings };
    const streamParam = { id: 'stream', value: values.stream };
    const dateRangeParam = { id: 'dateRange', value: values.dateRange };
    const timeRangeParam = { id: 'timeRange', value: values.timeRange };
    const durationRangeParam = { id: 'durationRange', value: values.durationRange };

    // @ts-ignore // typescript doesnt react condition
    courseIdParam.value && filtersParams.push(courseIdParam);
    // @ts-ignore // typescript doesnt react condition
    limitParam.value !== '0' && filtersParams.push(limitParam);
    // @ts-ignore
    courseTypeParam.value && filtersParams.push(courseTypeParam);
    // @ts-ignore
    publishedParam.value.length && filtersParams.push(publishedParam);
    // @ts-ignore
    canceledParam.value.length && filtersParams.push(canceledParam);
    // @ts-ignore
    bookingsParam.value.length && filtersParams.push(bookingsParam);
    // @ts-ignore
    streamParam.value.length && filtersParams.push(streamParam);
    filtersParams.push(pageParam);
    values.dateRange.from &&
      values.dateRange.to &&
      filtersParams.push(dateRangeParam as FilterPickersRangeParam);
    values.timeRange.from &&
      values.timeRange.to &&
      filtersParams.push(timeRangeParam as FilterPickersRangeParam);
    values.durationRange.from &&
      values.durationRange.to &&
      filtersParams.push(durationRangeParam as FilterPickersRangeParam);

    return filtersParams;
  }

  public getQueryString(kindOfParam: string, param: FilterQueryStringParam) {
    return kindOfParam + '%5B' + param.id + '%5D=' + param.value + '&';
  }

  public getCheckboxQueriesString(filterParam: FilterCheckboxQueriesString) {
    const filterQueriesString = { checkboxYes: '', checkboxNo: '' };
    filterQueriesString.checkboxYes =
      filterParam.checkboxYes !== undefined
        ? this.getQueryString('filter', filterParam.checkboxYes)
        : '';
    filterQueriesString.checkboxNo = filterParam.checkboxNo
      ? this.getQueryString('filter', filterParam.checkboxNo)
      : '';
    return filterQueriesString;
  }

  public getPickersQueriesString(filterParam: FilterPickersQueriesString) {
    const filterQueriesString = { from: '', to: '' };
    filterQueriesString.from = this.getQueryString('filter', filterParam.pickersRangeFrom);
    filterQueriesString.to = this.getQueryString('filter', filterParam.pickersRangeTo);
    return filterQueriesString;
  }

  public getPickersRange(param: FilterPickersRangeParam) {
    let pickersRangeFrom!: { id: string; value: string };
    let pickersRangeTo!: { id: string; value: string };
    if (param.id === 'dateRange') {
      pickersRangeFrom = {
        id: 'date_from',
        value: param.value.from ? param.value.from : '',
      };

      pickersRangeTo = {
        id: 'date_to',
        value: param.value.to ? param.value.to : '',
      };
    }

    if (param.id === 'timeRange') {
      pickersRangeFrom = {
        id: 'time_from',
        value: param.value.from ? param.value.from : '',
      };

      pickersRangeTo = {
        id: 'time_to',
        value: param.value.to ? param.value.to : '',
      };
    }

    if (param.id === 'durationRange') {
      pickersRangeFrom = {
        id: 'duration_from',
        value: param.value.from ? param.value.from : '',
      };

      pickersRangeTo = {
        id: 'duration_to',
        value: param.value.to ? param.value.to : '',
      };
    }

    const filterParam = { pickersRangeFrom, pickersRangeTo };

    return this.getPickersQueriesString(filterParam);
  }

  public getCheckboxFilterParam(param: FilterCheckboxParam) {
    let checkboxYes!: { id: string; value: boolean };
    let checkboxNo!: { id: string; value: boolean };

    if (param.id === 'published') {
      if (param.value.length === 1) {
        if (param.value[0]) {
          checkboxYes = {
            id: 'not_published',
            value: true,
          };
        } else {
          checkboxYes = {
            id: 'published',
            value: true,
          };
        }
      }
    }

    if (param.id === 'canceled') {
      param.value.includes(true) &&
        (checkboxYes = {
          id: 'canceled',
          value: true,
        });

      param.value.includes(false) &&
        (checkboxNo = {
          id: 'canceled',
          value: false,
        });
    }

    if (param.id === 'bookings') {
      param.value.includes(true) &&
        (checkboxYes = {
          id: 'bookings',
          value: true,
        });

      param.value.includes(false) &&
        (checkboxNo = {
          id: 'no_bookings',
          value: true,
        });
    }

    if (param.id === 'stream') {
      param.value.includes(true) &&
        (checkboxYes = {
          id: 'stream',
          value: true,
        });

      param.value.includes(false) &&
        (checkboxNo = {
          id: 'no_stream',
          value: true,
        });
    }

    const filterParam = { checkboxYes, checkboxNo };

    return this.getCheckboxQueriesString(filterParam);
  }

  public async getEvents(
    partnerId: string,
    resource: 'ACTIVE' | 'ARCHIVED',
    queryParams?: FilterCheckboxParam[] | FilterPickersRangeParam[] | FilterParam[],
  ): Promise<AxiosResponse<EventsData> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    let page = '';
    let limit = '';
    let courseId = '';
    let courseType = '';
    let published = { checkboxYes: '', checkboxNo: '' };
    let canceled = { checkboxYes: '', checkboxNo: '' };
    let bookings = { checkboxYes: '', checkboxNo: '' };
    let stream = { checkboxYes: '', checkboxNo: '' };
    let dateRange = { from: '', to: '' };
    let timeRange = { from: '', to: '' };
    let durationRange = { from: '', to: '' };

    queryParams?.forEach((param: FilterCheckboxParam | FilterPickersRangeParam | FilterParam) => {
      switch (param.id) {
        case 'page':
          if (param.value) {
            page = `&page=${param.value}`;
          }
          break;
        case 'limit':
          if (parseInt(param.value as string, 10) > 0) {
            limit = `&limit=${param.value}`;
          }
          break;
        case 'published':
          published = this.getCheckboxFilterParam(param as FilterCheckboxParam);
          canceled = this.getCheckboxFilterParam({ id: 'canceled', value: [false] });
          break;
        case 'canceled':
          canceled = this.getCheckboxFilterParam(param as FilterCheckboxParam);
          break;
        case 'bookings':
          bookings = this.getCheckboxFilterParam(param as FilterCheckboxParam);
          break;
        case 'stream':
          stream = this.getCheckboxFilterParam(param as FilterCheckboxParam);
          break;
        case 'dateRange':
          dateRange = this.getPickersRange(param as FilterPickersRangeParam);
          break;
        case 'timeRange':
          timeRange = this.getPickersRange(param as FilterPickersRangeParam);
          break;
        case 'durationRange':
          durationRange = this.getPickersRange(param as FilterPickersRangeParam);
          break;
        case 'courseId':
          courseId = param.value ? `course_id=${param.value}&` : '';
          break;
        case 'courseType':
          courseType = param.value ? this.getQueryString('filter', param as FilterParam) : '';
          break;
      }
    });

    const order = resource === 'ACTIVE' ? 'asc' : 'desc';
    const baseUrl =
      resource === 'ACTIVE'
        ? `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${partnerId}/courses/events?`
        : this.getEventsArchiveUrl(partnerId);
    const url =
      baseUrl +
      `${courseId}` +
      `${courseType}` +
      `${durationRange.from}${durationRange.to}${timeRange.from}${timeRange.to}` +
      `${dateRange.from}${dateRange.to}${published.checkboxYes}${published.checkboxNo}` +
      `${bookings.checkboxYes}${bookings.checkboxNo}${canceled.checkboxYes}${canceled.checkboxNo}` +
      `${stream.checkboxYes}${stream.checkboxNo}${page}&order[date]=${order}${limit}`;

    return await axiosInstance.get(url);
  }

  public getEventsArchiveUrl(partnerId: string): string {
    return `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${partnerId}/courses/events-archive?`;
  }

  public async getNumberOfPublishedEvents(
    partnerId: string,
    courseId: string,
  ): Promise<AxiosResponse<EventsData> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.get(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${partnerId}/courses/events?course_id=${courseId}&filter[published]=true&limit=10&page=1`,
    );
  }

  public cancelEvents(
    id: string,
    eventIds: string[],
    events: EventListItem[],
  ): Promise<AxiosResponse<{}> & AxiosError>[] {
    return eventIds.map(eventId => {
      const event = events.find(currentEvent => currentEvent.eventId.toString() === eventId);
      let courseId: number = 0;
      if (event) {
        courseId = event.courseId;
      }
      return this.cancelEvent(id, eventId, courseId);
    });
  }

  public async cancelEvent(
    id: string,
    eventId: string,
    courseId: number,
  ): Promise<AxiosResponse<{}> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.patch(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}/courses/${courseId}/events/${eventId}/cancel`,
      { cancel: true },
    );
  }

  public async getNumberOfPublishedEventsInFuture(
    partnerId: string,
    courseId: string,
  ): Promise<AxiosResponse<EventsData> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();
    const dateFrom = moment().add(1, 'days').format('YYYY-MM-DD');
    return await axiosInstance.get(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${partnerId}/courses/events?course_id=${courseId}&filter[published]=true&filter[canceled]=false&filter[date_from]=${dateFrom}&limit=10&page=1`,
    );
  }

  public getNewUpdatedEvents(
    eventListFormikVal: EventList,
    updatedFields: UpdateEventTypes,
    editStreamIds: string[] | undefined,
  ): EventListItem[] {
    const newUpdatedEvents: EventListItem[] = [];
    const mutatedUpdatedEvents = [...eventListFormikVal.updatedEvents];

    (editStreamIds ? editStreamIds : eventListFormikVal.checkedEvents).forEach(eventId => {
      const idx = mutatedUpdatedEvents.findIndex(ev => ev.eventId.toString(10) === eventId);
      if (idx !== -1) {
        mutatedUpdatedEvents[idx] = {
          ...mutatedUpdatedEvents[idx],
          ...updatedFields,
        };
      } else {
        const idx = eventListFormikVal.eventsData.result.findIndex(
          event => event.eventId.toString(10) === eventId,
        );

        if (idx !== -1) {
          newUpdatedEvents.push({ ...eventListFormikVal.eventsData.result[idx], ...updatedFields });
        }
      }
    });

    return [...mutatedUpdatedEvents, ...newUpdatedEvents];
  }

  public async getBookings(
    id: string,
    courseId: number,
    eventId: string,
  ): Promise<AxiosResponse<Booking[]> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.get(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}/courses/${courseId}/events/${eventId}/bookings`,
    );
  }

  public getInitialEventDataType(params: {
    duration?: number;
    capacity?: number;
    courseType?: CourseTypes;
    partner?: Partner;
  }): Event {
    const { duration, capacity, courseType, partner } = params;

    const generalEvent = {
      isSeries: false,
      appointments: {
        startDate: null,
        time: '',
        hour: '',
        minute: '',
        timeZone: 'Europe/Berlin',
        duration: duration ? duration : '',
        capacity: capacity ? capacity : '',
        seriesSettings: {
          endDate: null,
          weekdays: [],
          repetitions: '',
        },
      },
      published: false,
    };

    if (courseType === CourseTypes.ONLINE) {
      return {
        ...generalEvent,
        eventType: CourseTypes.ONLINE,
        streamSettings: {
          streamingHost: '',
          streamLink: '',
          streamPassword: '',
          meetingId: '',
          additionalInformation: '',
        },
      };
    } else {
      return {
        ...generalEvent,
        eventType: CourseTypes.ONSITE,
        address: {
          isPartnerAddress: true,
          street: partner?.street || '',
          streetNumber: partner?.streetNumber || '',
          city: partner?.city || '',
          postcode: partner?.zip || '',
        },
        additionalInformation: '',
      };
    }
  }

  public getUpdatableSeriesEventValues(values: EditEvent): EditEvent {
    const valuesForUpdate: EditEvent = {
      capacity: parseInt(values.capacity as string),
      date: values.date,
      duration: parseInt(values.duration as string),
      time: values.time,
    };

    return valuesForUpdate;
  }

  public getUpdatableStreamingEventValues(values: StreamSettings): StreamSettings {
    return values;
  }

  public static isCanceled(eventListItem: EventListItem): boolean {
    return eventListItem.cancelled;
  }

  public static isDraft(eventListItem: EventListItem): boolean {
    return !eventListItem.cancelled && !eventListItem.published;
  }

  public static isPublished(eventListItem: EventListItem): boolean {
    return !eventListItem.cancelled && eventListItem.published;
  }

  public getBulkSaveFeedback(params: UpdateEventsResponse): BulkSaveFeedback {
    const bulkSaveFeedback: BulkSaveFeedback = {};

    params.events.forEach((event: EventListItem) => {
      bulkSaveFeedback[event.eventId] = { type: 'SUCCESS' };
    });

    Object.keys(params.errors).forEach(eventId => {
      bulkSaveFeedback[parseInt(eventId)] = { type: 'ERROR', message: params.errors[eventId] };
    });

    return bulkSaveFeedback;
  }

  public getBulkSaveErrorHeader(params: UpdateEventsResponse, events: EventListItem[]): string {
    const errors: string[] = [];
    Object.keys(params.errors).forEach(eventId => {
      const match = events.find(event => event.eventId === parseInt(eventId));

      if (match) {
        errors.push(moment(match.date).format('DD.MM.YYYY') as string);
      }
    });
    return errors.join(', ');
  }

  public getHours(): { displayValue: string; value: string }[] {
    const hours = [];
    for (let i = 0; i <= 24; i++) {
      hours.push({
        value: i.toString().padStart(2, '0'),
        displayValue: i.toString().padStart(2, '0'),
      });
    }
    return hours;
  }

  public getMinutes(): { displayValue: string; value: string }[] {
    const minutes = [];
    const step = 5;
    const totalMinutes = 60;
    const minutesByStep = Math.ceil(totalMinutes / step);

    for (let i = 0; i < minutesByStep; i++) {
      const minute = i * step;
      minutes.push({
        value: minute.toString(),
        displayValue: minute.toString(),
      });
    }
    return minutes;
  }
}
