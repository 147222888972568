import { Box, Link } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export type FooterItemProps = {
  title: string;
  href: string;
};

const useStyles = makeStyles()(theme => ({
  navigationListItemContainer: {
    '&:not(:first-of-type)': {
      marginLeft: 30,
    },
  },
  navigationListItem: {
    fontSize: 12,
    lineHeight: 2,
    letterSpacing: 0.11,
    color: FigmaDesignTokens.Grey[700],
    textDecoration: 'initial',
    '&:hover': {
      color: theme.palette.primary.dark,
      textDecoration: 'underline',
    },
  },
  navigationListItemActive: {
    color: theme.palette.primary.dark,
  },
}));

export const FooterItem = ({ title, href }: FooterItemProps): JSX.Element => {
  const { classes } = useStyles();

  return (
    <Box className={classes.navigationListItemContainer}>
      <Link href={href} target='_blank' rel='noopener' className={classes.navigationListItem}>
        {title}
      </Link>
    </Box>
  );
};
