import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { DynamicBreadcrumbs } from '@/Components';
import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { useQuery } from '@/Hooks/useQueryParams';
import { CategoryFAQ } from '@/Types';

import { SupportDetailWrapper } from './Faq.styles';
import FaqCleanArticle from './FaqCleanArticle';

type FaqSectionPageProps = {
  data: CategoryFAQ | null;
};
export const FaqSectionPage: React.FC<FaqSectionPageProps> = ({ data }) => {
  const { formatMessage } = useMemoedIntl();
  const currentLanguage = useCurrentLanguage();
  const query = useQuery();
  const article = query.get('article');
  const categoryFaq = query.get('faq');

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article]);

  const articleDetail = useMemo(() => {
    if (!article) {
      return null;
    }
    return data?.faqs?.find(item => item.id.toString() === article);
  }, [data, article]);

  const breadcrumbs = useMemo(() => {
    const result = [
      {
        title: formatMessage({ id: 'support.faq.title', defaultMessage: 'FAQ' }),
        href: `/${currentLanguage}/unterstutzung`,
      },
    ];
    if (data) {
      if (articleDetail) {
        result.push({
          title: data.categoryName,
          href: `/${currentLanguage}/unterstutzung?faq=${data.id}`,
        });
        result.push({ title: articleDetail.question, href: '' });
      } else {
        result.push({ title: data.categoryName, href: '' });
      }
    }

    return result;
  }, [data, articleDetail, currentLanguage, formatMessage]);

  const renderCleanAnswer = useCallback((answer: string) => {
    if (!answer || answer.length === 0) {
      return <></>;
    }
    return answer.split('\n').map((answer, idx) => <FaqCleanArticle key={idx} answer={answer} />);
  }, []);

  return (
    <SupportDetailWrapper>
      <DynamicBreadcrumbs items={breadcrumbs} />
      {!articleDetail && !article && data && (
        <Box className='content'>
          <Typography className='title' variant='h1'>
            {data.categoryName}
          </Typography>
          <Typography className='content__text' variant='body1'>
            {data.categoryDescription}
          </Typography>
          <Box>
            {data.faqs.map(item => (
              <Box key={item.id} className='content__item'>
                <Typography className='content__item_title' variant='body1'>
                  {item.question}
                </Typography>
                <Box className='content__item_info'>
                  <Typography className='content__item_text' variant='body1'>
                    {renderCleanAnswer(item.answer)}
                  </Typography>
                  <Link
                    to={`/${currentLanguage}/unterstutzung?faq=${categoryFaq}&article=${item.id}`}
                    className='content__item_link'>
                    {formatMessage({
                      id: 'support.links.view_article',
                      defaultMessage: 'View article',
                    })}
                  </Link>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {articleDetail && (
        <Box className='content'>
          <Typography className='title' variant='h1'>
            {articleDetail.question}
          </Typography>
          <Typography className='answer' variant='body1'>
            {renderCleanAnswer(articleDetail.answer)}
          </Typography>
        </Box>
      )}
    </SupportDetailWrapper>
  );
};
