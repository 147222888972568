import { createStyles, Tab } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const TabNavigation: typeof Tab = withStyles(
  (props: any) => <Tab {...props} />,
  theme =>
    createStyles({
      root: {
        fontSize: 14,
        fontWeight: 'normal',
        paddingLeft: 0,
        paddingRight: 0,
        textTransform: 'none',
        lineHeight: 1,
        padding: 0,
        maxHeight: 26,
        minHeight: 'initial',
        color: FigmaDesignTokens.Grey[950],
        opacity: 1,
        minWidth: 'unset',
        marginRight: 28,
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 'unset',
        '&:last-child': {
          marginRight: 0,
        },
      },
      wrapper: {
        display: 'inline',
        whiteSpace: 'nowrap',
      },
      selected: {
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
    }),
);
