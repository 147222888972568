import { makeStyles } from 'tss-react/mui';

export const useTagsStyles = makeStyles()(theme => ({
  container: {
    minHeight: 32,
  },

  root: {
    padding: ' 5px 0px 5px 0',

    '& .MuiChip-label': {
      color: theme.palette.text.primary,
      fontSize: 13,
    },
    '& .MuiChip-deleteIconColorSecondary': {
      color: theme.palette.background.paper,
      background: theme.palette.info.dark,
      height: '21px',
      borderRadius: '14px',
      padding: '1px',

      '&:hover': {
        backgroundColor: theme.palette.info.dark,
      },
    },
  },
}));
