import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../design/design-tokens.json';

export const UserProfileIconStyles = makeStyles()(theme => ({
  userProfileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  userProfileCircle: {
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.background.paper,
  },
  small: {
    width: 20,
    height: 20,
    backgroundColor: FigmaDesignTokens.Blue[950],
  },
  large: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.primary.main,
  },
  userProfileShortcutName: {
    fontSize: 9,
    textAlign: 'center',
    margin: 0,
    color: theme.palette.background.paper,
  },
  useProfileInitials: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
    margin: 0,
    color: theme.palette.background.paper,
  },
}));
