import { Box, Grid, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { ConnectedFocusError } from 'focus-formik-error';
import { Form, Formik, FormikProps } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { Button, InfoMessage } from '@/Components';
import { useAppContext } from '@/Context';
import { useConfirmDialog } from '@/Hooks/useConfirmDialog';
import { ErrorMap } from '@/Mappers';
import { UsersServiceFactory } from '@/Services';
import { ReactComponent as AddIcon } from '@/Static/Icons/bluePlusIcon.svg';
import { InvitationFormik, InvitationsFormik, Partner, Role } from '@/Types';
import { InvitationsValidation } from '@/Utils';
import handleCatchError from '@/Utils/handleCatchError';

import { Invitation } from './Invitation';
import { useInvitationsFormStyles } from './Invitation.styles';

type InvitationsFormProps = {
  casPublicId: Partner['casPublicId'];
  refCallback: (
    ref: React.RefObject<FormikProps<InvitationsFormik> | undefined>,
    tabNumber: number,
  ) => void;
  handleToastMessage: (value: boolean, customMessage?: string) => void;
  handlePrimaryCtaDisabled: (value: boolean) => void;
  handlePrimaryCtaTitle: (value: string) => void;
};

export const InvitationsForm = ({
  refCallback,
  casPublicId,
  handleToastMessage,
  handlePrimaryCtaDisabled,
  handlePrimaryCtaTitle,
}: InvitationsFormProps) => {
  const { classes } = useInvitationsFormStyles();
  const intl = useIntl();
  const { dispatch } = useAppContext();
  const { formatMessage } = intl;
  const formikRef = useRef<FormikProps<InvitationsFormik>>(null);
  const handleConfirmDialog = useConfirmDialog();
  const [initialState] = useState<InvitationsFormik>({
    invitations: [{ email: '', role: Role.User, name: '', surname: '' }],
  });

  const usersService = useMemo(() => new UsersServiceFactory().getInstance(dispatch), [dispatch]);

  useEffect(() => {
    refCallback(formikRef, 1);
  }, [refCallback, formikRef]);

  const addInvitationForm = useCallback(
    (
      invitationsParam: InvitationFormik[],
      setFieldValueParam: FormikProps<InvitationsFormik>['setFieldValue'],
    ) => {
      const newInvitationsParam = [
        ...invitationsParam,
        { email: '', role: Role.User, name: '', surname: '' },
      ];

      setFieldValueParam('invitations', newInvitationsParam);
    },
    [],
  );

  const triggerCtaStateChange = () => handlePrimaryCtaDisabled(false);

  return (
    <Formik
      innerRef={formikRef}
      validationSchema={() => InvitationsValidation(intl)}
      validateOnChange
      isInitialValid={false}
      initialValues={initialState}
      validate={values => {
        if (formikRef?.current?.isValid) {
          handleConfirmDialog(true);
          handlePrimaryCtaDisabled(false);
        } else {
          handleConfirmDialog(false);
          handlePrimaryCtaDisabled(true);
        }

        if (values.invitations.length > 1) {
          handlePrimaryCtaTitle(
            formatMessage({
              id: 'view.invite_user.cta.multiple',
              defaultMessage: 'Send invitations',
            }),
          );
        } else {
          handlePrimaryCtaTitle(
            formatMessage({
              id: 'view.invite_user.cta.single',
              defaultMessage: 'Send invitation',
            }),
          );
        }
      }}
      onSubmit={async (values, formik) => {
        try {
          const invitedUsers = values.invitations.map(invitedUserParam =>
            usersService.getInvitedUser(invitedUserParam, casPublicId),
          );

          const responses = await Promise.allSettled(usersService.sendInvitedUsers(invitedUsers));
          const notSuccessfulInvitations: InvitationFormik[] = [];
          const notSuccessfulInvitationsErrorMessages: string[] = [];

          responses.forEach(response => {
            if (response.status === 'fulfilled' && response.value instanceof AxiosError) {
              const item = values.invitations.find(
                invitation =>
                  invitation.email ===
                  (JSON.parse(response.value.config.data) as InvitationFormik).email,
              );

              if (item && response.value.response) {
                notSuccessfulInvitations.push(item);
                notSuccessfulInvitationsErrorMessages.push(
                  usersService.getInviteUserTranslateMessageKey(
                    response.value.response.data.message,
                  ),
                );
              }
            }
          });

          if (notSuccessfulInvitations.length > 0) {
            formik.setValues({ invitations: notSuccessfulInvitations });

            setTimeout(() => {
              notSuccessfulInvitationsErrorMessages.forEach((message, idx) => {
                formik.setFieldError(`invitations[${idx}].email`, formatMessage({ id: message }));
              });
              new ErrorMap().scrollToErrorField('invitations[0].email');
            });

            if (responses.length > notSuccessfulInvitations.length) {
              const diff = responses.length - notSuccessfulInvitations.length;
              if (responses.length - notSuccessfulInvitations.length === 1) {
                handleToastMessage(
                  true,
                  `${diff} ${formatMessage({
                    id: 'view.invite_user.message_add_user_success',
                    defaultMessage: 'User was invited successfully',
                  })}`,
                );
              } else {
                handleToastMessage(
                  true,
                  `${diff} ${formatMessage({
                    id: 'view.invite_user.message_add_users_success',
                    defaultMessage: 'Users were successfully invited ',
                  })}`,
                );
              }
            }

            handlePrimaryCtaDisabled(true);
            handleConfirmDialog(false);
          } else {
            formik.resetForm();
            handlePrimaryCtaDisabled(true);
            handleConfirmDialog(false);
            handleToastMessage(
              true,
              `${responses.length} ${formatMessage({
                id: 'view.invite_user.message_add_users_success',
                defaultMessage: 'Users were successfully invited ',
              })}`,
            );
          }
        } catch (error: unknown) {
          handleCatchError(error);
        }
      }}>
      {({ values, setFieldValue }) => (
        <Box className={classes.contentContainerWrapper}>
          <ConnectedFocusError />

          <ContentContainer>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} container>
                  <FormHeader
                    title={formatMessage({
                      id: 'view.invite_user.header',
                      defaultMessage: 'Invite new user',
                    })}
                  />
                </Grid>

                <Grid xs={8} item container>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Typography variant='body2'>
                        {formatMessage({
                          id: 'view.invite_user.hint.description',
                          defaultMessage:
                            'Invite other employees to manage this composite facility.',
                        })}
                      </Typography>
                    </Grid>

                    {values.invitations.map((invitation, idx) => (
                      <Invitation
                        key={idx}
                        idx={idx}
                        invitation={invitation}
                        changeCtaState={triggerCtaStateChange}
                      />
                    ))}

                    <Grid xs={8} item>
                      <Button
                        variant='text'
                        onClick={() => addInvitationForm(values.invitations, setFieldValue)}
                        icon={<AddIcon />}
                        className={classes.addNewUserBtn}>
                        {formatMessage({
                          id: 'view.invite_user.cta.add_additional_users',
                          defaultMessage: 'add more users',
                        })}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid xs={4} item>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <InfoMessage
                        title={formatMessage({
                          id: 'form.invite_user.hint.info_user.header',
                          defaultMessage: 'Invite new user',
                        })}
                        description={formatMessage({
                          id: 'form.invite_user.hint.info_user.description',
                          defaultMessage:
                            'The invited user will receive an invitation by e-mail to register for the composite partner portal.',
                        })}
                        type='information'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </ContentContainer>
        </Box>
      )}
    </Formik>
  );
};
