import { LanguageType } from '@/Types';

export const getFormattedPhoneNumber = (phoneNumber: string): string => {
  const phoneNumberWithoutSpaces = phoneNumber.replaceAll(' ', '');

  if (phoneNumberWithoutSpaces.startsWith('+')) {
    if (phoneNumberWithoutSpaces.startsWith('+352')) {
      return phoneNumberWithoutSpaces.substring(4);
    } else {
      return phoneNumberWithoutSpaces.substring(3);
    }
  } else if (phoneNumberWithoutSpaces.startsWith('00352')) {
    return phoneNumberWithoutSpaces.substring(5);
  } else if (
    phoneNumberWithoutSpaces.startsWith('0049') ||
    phoneNumberWithoutSpaces.startsWith('0043') ||
    phoneNumberWithoutSpaces.startsWith('0041')
  ) {
    return phoneNumberWithoutSpaces.substring(4);
  } else if (phoneNumberWithoutSpaces.startsWith('0')) {
    return phoneNumberWithoutSpaces.substring(1);
  } else {
    return phoneNumberWithoutSpaces;
  }
};

export const getFormattedCountryPhoneNumberPrefix = (phoneNumber: string): string => {
  const phoneNumberWithoutSpaces = phoneNumber.replaceAll(' ', '');

  if (phoneNumberWithoutSpaces.startsWith('0049') || phoneNumberWithoutSpaces.startsWith('+49')) {
    return '+49';
  }

  if (phoneNumberWithoutSpaces.startsWith('0043') || phoneNumberWithoutSpaces.startsWith('+43')) {
    return '+43';
  }

  if (phoneNumberWithoutSpaces.startsWith('0041') || phoneNumberWithoutSpaces.startsWith('+41')) {
    return '+41';
  }

  if (phoneNumberWithoutSpaces.startsWith('00352') || phoneNumberWithoutSpaces.startsWith('+352')) {
    return '+352';
  }

  return '+49';
};

export const getFormattedWebsite = (website: string): string =>
  website.replace('http://', '').replace('https://', '');

export const getTargetLanguage = (pageLanguage: LanguageType) =>
  pageLanguage === 'de' ? 'en' : 'de';
