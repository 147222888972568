import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../design/design-tokens.json';

export const useSwitchPartnerStyles = makeStyles()(() => ({
  background: {
    height: '100vh',
    width: '100vw',
    backgroundImage: `linear-gradient(170deg, ${FigmaDesignTokens.Blue[200]} 29%, ${FigmaDesignTokens.Blue[800]} 109%)`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  partnerNameWrapper: {
    borderRadius: 33.5,
    boxShadow: '0 15px 10px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: FigmaDesignTokens.White,
    padding: '20px 44px',
    marginTop: 27,
  },
  partnerName: {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 20,
    fontWeight: 900,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.4,
    color: FigmaDesignTokens.Blue[950],
    textTransform: 'uppercase',
  },
}));
