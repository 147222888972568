import React, { useCallback } from 'react';
import { CourseIcon, CourseIconWrapper } from './MainCategoryCell.styles';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { Course, Category } from '@/Types';

type MainCategoryCellProps = {
  isPastCourse: boolean;
  gridRenderCellParams: GridRenderCellParams<Course, string>;
  categories: Category[];
};

export const MainCategoryCell = ({
  isPastCourse,
  gridRenderCellParams,
  categories,
}: MainCategoryCellProps) => {
  const getCategoryUrl = useCallback(
    (categoryId: number) => {
      const categoryDetails = categories.find((category: Category) => +category.id === categoryId);
      return categoryDetails && categoryDetails.iconUrlContour
        ? categoryDetails.iconUrlContour
        : '';
    },
    [categories],
  );

  return (
    <CourseIconWrapper $isPastCourse={isPastCourse}>
      {gridRenderCellParams.row.mainCategory &&
        getCategoryUrl(gridRenderCellParams.row.mainCategory) && (
          <CourseIcon alt='' src={getCategoryUrl(gridRenderCellParams.row.mainCategory)} />
        )}
    </CourseIconWrapper>
  );
};
