import { Box, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { Button } from '@/Components';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import { getSchedulePathname } from '@/Mappers';
import CloseIcon from '@/Static/Icons/atoms-symbols-category-close-big.svg';

import { CurrentUserDeleteDialogStyles } from './CurrentUserDeleteDialog.styles';

type OpenedEventsDialogProps = {
  handleClose: () => void;
  isOpen: boolean;
};

// keep this export to keep compatibility with old code
export const OpenedEventsDialog = ({ isOpen, handleClose }: OpenedEventsDialogProps) => {
  const navigate = useNavigate();
  const { classes } = CurrentUserDeleteDialogStyles();
  const currentLanguage = useCurrentLanguage();
  const { formatMessage } = useIntl();

  const header = formatMessage({
    id: 'form.current_user.modal.redirect_schedule.header',
    defaultMessage: 'published dates\ncancel',
  });
  const description = formatMessage({
    id: 'form.current_user.modal.redirect_schedule.description',
    defaultMessage: `You still have published dates. Before you can delete your user account, you must cancel these appointments.`,
  });
  const ctaLabel = formatMessage({
    id: 'form.current_user.modal.redirect_schedule.cta',
    defaultMessage: 'to the schedule',
  });

  const handleRedirect = useCallback(() => {
    navigate(getSchedulePathname(currentLanguage || 'de'));
  }, [navigate, currentLanguage]);

  return (
    <Dialog open={isOpen} onClose={handleClose} className={classes.dialog}>
      <Box className={classes.headerWrapper}>
        <FormHeader title={header} />
        <IconButton aria-label='close' onClick={handleClose} className={classes.closeButton}>
          <img alt='' src={CloseIcon} />
        </IconButton>
      </Box>

      <DialogContent className={classes.dialogContent}>{description}</DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button className={classes.confirmButton} onClick={handleRedirect}>
          {ctaLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OpenedEventsDialog;
