import { Box, styled } from '@mui/material';

export const Container = styled(Box)`
  width: 100%;
  max-width: 1090px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const EventsTableContainer = styled(Box)`
  padding: 30px 30px 20px 30px;
  background-color: white;
  border-radius: 4px;
`;

export const FirstRowContainer = styled(Box)`
  display: flex;
  flexdirection: row;
  alignitems: stretch;
  gap: 10px;
`;
