import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@/Context';
import { AssetServiceFactory } from '@/Services';

import useCurrentPartnerIdQuery from './useCurrentPartnerIdQuery';

const useGetPartnerAsset = () => {
  const { dispatch } = useAppContext();
  const { data: casPublicId = '' } = useCurrentPartnerIdQuery();

  const queryResult = useQuery(
    ['useGetPartnerAsset', casPublicId],
    async () => {
      let signedResource = null;

      const assetService = new AssetServiceFactory().getInstance(dispatch);
      const assetsResponse = await assetService.getAssetsByPartnerId(casPublicId);

      const uri = assetsResponse?.data?.logo?.uri || null;

      if (uri) {
        signedResource = await assetService.S3GetSignedResource(casPublicId, uri, 'Logo');
      }

      return signedResource;
    },
    {
      staleTime: 60 * 1000 * 3, // we should use cache and invalidate it, when the partner changes the logo
      enabled: !!casPublicId,
    },
  );

  return queryResult;
};

export default useGetPartnerAsset;
