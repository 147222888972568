import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import { FooterItem } from '@/Components';
import useLiveCheckins from '@/Hooks/useLiveCheckins';
import Logo from '@/Static/Icons/atoms-symbols-hanssefit-logo.svg';

const useStyles = makeStyles()(() => ({
  footerWrapper: {
    marginTop: 50,
    marginBottom: 30,
    display: 'flex',
  },
  appLogoWrapper: {
    display: 'flex',
    marginRight: 50,
  },
  logoIcon: {
    marginRight: 9,
    height: '100%',
  },
  leftPartLinks: {
    marginRight: 310,
    display: 'flex',
    flex: 1,
  },
  rightPartLinks: {
    display: 'flex',
  },
}));

const Footer = () => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  useLiveCheckins();

  return (
    <>
      <Box className={classes.footerWrapper}>
        <Box className={classes.appLogoWrapper}>
          <img src={Logo} alt='' className={classes.logoIcon} />
        </Box>
        <Box className={classes.leftPartLinks}>
          <FooterItem
            href={formatMessage({
              id: 'footer.links.homepage.href',
              defaultMessage: 'https://www.hansefit.de',
            })}
            title={formatMessage({
              id: 'footer.links.homepage.text',
              defaultMessage: 'www.hansefit.de',
            })}
          />
        </Box>

        <Box className={classes.rightPartLinks}>
          <FooterItem
            href={formatMessage({
              id: 'footer.links.imprint.href',
              defaultMessage: 'https://hansefit.de/impressum/',
            })}
            title={formatMessage({ id: 'footer.links.imprint.text', defaultMessage: 'Imprint' })}
          />
          <FooterItem
            href={formatMessage({
              id: 'footer.links.gdpr.href',
              defaultMessage: 'https://hansefit.de/datenschutz/',
            })}
            title={formatMessage({ id: 'footer.links.gdpr.text', defaultMessage: 'Privacy' })}
          />
          <FooterItem
            href={formatMessage({
              id: 'footer.links.legal.href',
              defaultMessage: 'https://static.hansefit.de/partner_nutzungsbedingungen.html',
            })}
            title={formatMessage({ id: 'footer.links.legal.text', defaultMessage: 'Terms of use' })}
          />
        </Box>
      </Box>
    </>
  );
};

export default Footer;
