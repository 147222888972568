import { useMemo } from 'react';

import {
  getCurrentCoursesPathname,
  getDashboardPathname,
  getExpiredCoursesPathname,
} from '@/Mappers';
import { BreadcrumbProps } from '@/Types';

import useCurrentLanguage from '../useCurrentLanguage';
import useMemoedIntl from '../useMemoedIntl';

const useCoursesOverviewBreadcrumbs = (currentTab: number): BreadcrumbProps[] => {
  const { formatMessage } = useMemoedIntl();
  const currentLanguage = useCurrentLanguage();

  const dynamicBreadcrumbs = useMemo(() => {
    const staticBreadcrumbs = [
      {
        title: formatMessage({ id: 'view.dashboard', defaultMessage: 'Dashboard' }),
        href: getDashboardPathname(currentLanguage),
      },
      {
        title: formatMessage({
          id: 'view.courses.courses_overview',
          defaultMessage: 'Course overview',
        }),
        href: getCurrentCoursesPathname(currentLanguage),
      },
    ];

    if (currentTab === 1) {
      staticBreadcrumbs.push({
        title: formatMessage({
          id: 'view.courses_and_events.past_courses',
          defaultMessage: 'Inactive courses & drafts',
        }),
        href: getExpiredCoursesPathname(currentLanguage),
      });
    } else {
      staticBreadcrumbs.push({
        title: formatMessage({
          id: 'view.courses_and_events.courses',
          defaultMessage: 'Active courses',
        }),
        href: getCurrentCoursesPathname(currentLanguage),
      });
    }
    return staticBreadcrumbs;
  }, [formatMessage, currentTab, currentLanguage]);

  return dynamicBreadcrumbs;
};

export default useCoursesOverviewBreadcrumbs;
