import { styled, Typography } from '@mui/material';

export const Information = styled(Typography)`
  font-weight: 500;
  margin-bottom: 15px;
`;

export const HintContainer = styled('div')`
  margin-bottom: 28px;
`;
