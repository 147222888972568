import { Dispatch, AnyAction } from 'redux';
import { uiConstants } from './UI.constants';

export const uiActions = {
  showNotification,
  hideNotification,
  showSpinnerOverlay,
  hideSpinnerOverlay,
  showModal,
  hideModal,
};

function showNotification({ title, variant }: { title: string; variant: string }) {
  return {
    type: uiConstants.SHOW_ALERT,
    title,
    variant,
  };
}

function hideNotification(title: string) {
  return {
    type: uiConstants.HIDE_ALERT,
    title,
  };
}

function showErrorNotification({
  title,
  delay = 3000,
}: {
  title: string;
  delay?: number;
}): (dispatch: Dispatch<AnyAction>) => void {
  return (dispatch: Dispatch<AnyAction>) => {
    const parsedTitle = typeof title === 'string' ? title : 'Problem encountered. Please try again';

    dispatch(showNotification({ title: parsedTitle, variant: 'failure' }));
    setTimeout(() => dispatch(hideNotification(parsedTitle)), delay);
  };
}

function showSpinnerOverlay(isOverlayTransparent = true, spinnerOverlayAdditionalTitle = '') {
  return {
    type: uiConstants.SHOW_SPINNER_OVERLAY,
    isOverlayTransparent,
    spinnerOverlayAdditionalTitle,
  };
}

function hideSpinnerOverlay() {
  return {
    type: uiConstants.HIDE_SPINNER_OVERLAY,
  };
}

export function showModal<T>(modalType: string, modalState?: T) {
  return {
    type: uiConstants.PUSH_MODAL,
    modal: {
      ...modalState,
      show: true,
      modalType,
    },
  };
}

export function hideModal(modalType: string) {
  return {
    type: uiConstants.POP_MODAL,
    modalType,
  };
}

export type ShowErrorNotification = typeof showErrorNotification;
export type ShowSpinnerOverlay = typeof showSpinnerOverlay;
export type HideSpinnerOverlay = typeof hideSpinnerOverlay;
export type ShowModalType = typeof showModal;
