import { GridRowModel, GridRowsProp } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';

export interface UseTableRowsProps {
  uniqueIdKey: string;
  data: { [key: string]: any }[];
  composedKeys?: string[][];
}

export const useTableRows = ({
  uniqueIdKey,
  data,
  composedKeys,
}: UseTableRowsProps): GridRowsProp =>
  useMemo(() => {
    const rows: { [key: string]: any }[] = [];

    data.forEach((entry, idx) => {
      const keys = Object.keys(entry);
      const rowEntry: GridRowModel = {};

      keys.forEach(key => {
        if (key === uniqueIdKey) {
          rowEntry['id'] = entry[key] as number;
          rowEntry[key] = entry[key] as string | number;
        } else {
          rowEntry[key] = entry[key] as string | number;
        }
      });

      composedKeys?.forEach(keys => {
        const composedKey = keys.join('.');

        data.forEach(() => (rowEntry[composedKey] = keys.map(key => data[idx][key]).join(' ')));
      });

      rows.push(rowEntry);
    });

    return rows as GridRowsProp;
  }, [uniqueIdKey, data, composedKeys]);
