import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@/Context';
import { languageName } from '@/Mappers';
import { DeeplServiceFactory } from '@/Services/Factories/DeeplServiceFactory';
import deTranslations from '@/translations/de.json';
import enTranslations from '@/translations/en.json';
import { LanguageType } from '@/Types';

type useTranslateQueryProps = {
  language: LanguageType;
  isRemoteEnv: boolean;
};

const useTranslateQuery = ({ language, isRemoteEnv }: useTranslateQueryProps) => {
  const { dispatch } = useAppContext();
  const deeplService = new DeeplServiceFactory().getInstance(dispatch);

  const getCurrentLanguage = () => {
    if (language === languageName.GERMANY) {
      return deTranslations;
    }
    if (language === languageName.ENGLISH) {
      return enTranslations;
    }
  };

  const queryResult = useQuery(
    [language],
    async () => {
      if (isRemoteEnv) {
        const response = await deeplService.getTerms(language);
        return response.data;
      } else {
        return getCurrentLanguage();
      }
    },
    {
      onError(error) {
        console.error('Error while fetching terms', error);
      },
      enabled: !!language,
    },
  );

  return queryResult;
};

export default useTranslateQuery;
