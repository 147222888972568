import clsx from 'clsx';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { useIntl } from 'react-intl';

import { useTableHeadStyles } from './PastEvents.styles';
import { DisplayTextAccent } from '@/Components';

export const PastEventListTableHead = (): JSX.Element => {
  const { classes } = useTableHeadStyles();

  const { formatMessage } = useIntl();

  return (
    <TableHead className={classes.root}>
      <TableRow>
        <TableCell className={clsx(classes.tableCell)} width={60}>
          <DisplayTextAccent
            className={classes.tableCellContainerFirst}
            textContent={formatMessage({
              id: 'view.courses_and_events.past_events.table.header.date',
              defaultMessage: 'Date',
            })}
          />
        </TableCell>
        <TableCell className={clsx(classes.tableCell)} width={60}>
          <DisplayTextAccent
            textContent={formatMessage({
              id: 'view.courses_and_events.past_events.table.header.time',
              defaultMessage: 'Time',
            })}
          />
        </TableCell>
        <TableCell className={clsx(classes.tableCell)} width={60}>
          <DisplayTextAccent
            textContent={formatMessage({
              id: 'view.courses_and_events.past_events.table.header.duration',
              defaultMessage: 'Duration',
            })}
          />
        </TableCell>
        <TableCell className={clsx(classes.tableCell)} width={60}>
          <DisplayTextAccent
            textContent={formatMessage({
              id: 'view.courses_and_events.past_events.table.header.bookings',
              defaultMessage: 'Participant',
            })}
          />
        </TableCell>
        <TableCell className={clsx(classes.tableCell)} width={60}>
          <DisplayTextAccent
            textContent={formatMessage({
              id: 'view.courses_and_events.past_events.table.header.status',
              defaultMessage: 'Status',
            })}
          />
        </TableCell>
        <TableCell className={clsx(classes.tableCell)} width={60}>
          <DisplayTextAccent
            className={classes.tableCellContainerLast}
            textContent={formatMessage({
              id: 'view.courses_and_events.past_events.table.header.check_ins',
              defaultMessage: 'List of participants',
            })}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
