import moment from 'moment';

export const formatRowValue = (value: string, formatTo: 'DAY' | 'TIME') => {
  if (!value) return '';
  if (formatTo === 'DAY') {
    return `${value.substring(6)}.${value.substring(4, 6)}.${value.substring(0, 4)}`;
  }
  return `${value.substring(0, value.length - 2)}:${value.slice(-2)}`;
};

export const formatDate = (date: Date) => moment(date).format('DD.MM.YYYY');
