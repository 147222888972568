import { fieldToTextField } from 'formik-mui';
import { Autocomplete } from '@mui/material';
import { CustomizedTextField } from '../Customized/CustomizedTextField';

export const FormikAutocomplete = ({ textFieldProps, ...props }: any) => {
  const {
    form: { setFieldTouched, setFieldValue },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);

  return (
    <Autocomplete
      {...props}
      {...field}
      onChange={(_, value) => setFieldValue(textFieldProps.name, value)}
      onInputChange={(_, value) => setFieldValue(textFieldProps.name, value)}
      onBlur={() => setFieldTouched(textFieldProps.name, true, true)}
      renderInput={props => (
        <CustomizedTextField
          {...props}
          {...textFieldProps}
          inputProps={{ ...props.inputProps, ...textFieldProps.inputProps }}
        />
      )}
    />
  );
};
