import { useIntl } from 'react-intl';

export const PerformedEventsCell = ({ value }: { value?: number }) => {
  const intl = useIntl();

  if (!value) {
    return <></>;
  }

  return (
    <>
      {value}{' '}
      {value === 1
        ? intl.formatMessage({ id: 'course.event', defaultMessage: 'Event' })
        : intl.formatMessage({ id: 'course.events', defaultMessage: 'Events' })}
    </>
  );
};
