import { Box, Button } from '@mui/material';
import { useRef } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useGetPartnerQuery } from '@/Queries';

import FigmaDesignTokens from '../../../../../design/design-tokens.json';
import { CustomizedTextField } from './CustomizedTextField';

type CustomizedAutocompleteProps = {
  handleEnterTag: (enteredTag: string) => void;
  ctaTitle: string;
};

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  textField: {
    width: '100%',
  },
  cta: {
    borderRadius: 18,
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: FigmaDesignTokens.Grey[50],
    height: 36,
    padding: '7px 21px',
    marginLeft: 24,
    marginTop: 1,
  },
}));

export const CustomizedAutocomplete = ({
  handleEnterTag,
  ctaTitle,
}: CustomizedAutocompleteProps) => {
  const { data: partner } = useGetPartnerQuery();

  const { classes } = useStyles();
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  const handleCLick = () => {
    if (textFieldRef.current) {
      const el: NodeListOf<HTMLInputElement> = textFieldRef.current.querySelectorAll(
        '.MuiInputBase-input.MuiOutlinedInput-input',
      );

      if (el.length > 0) {
        handleEnterTag(el[0].value);
        el[0].value = '';
      }
    }
  };

  return (
    <Box className={classes.container}>
      <CustomizedTextField
        disabled={partner?.readonly}
        id='tagsInp'
        className={classes.textField}
        ref={textFieldRef}
        variant='outlined'
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          const elTarget = e.target as HTMLInputElement;
          if (e.code === 'Enter') {
            handleEnterTag(elTarget.value);

            elTarget.value = '';

            elTarget.blur();
            e.preventDefault();
          }
        }}
      />

      <Button
        disabled={partner?.readonly}
        className={classes.cta}
        variant='contained'
        color='primary'
        onClick={handleCLick}>
        {ctaTitle}
      </Button>
    </Box>
  );
};
