import { ReactNode } from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import clsx from 'clsx';
import Theme from '@/App/Theme/Theme';
import { BodyTextRegular } from '@/Components/Typography';
import { useMenuNavStyles } from './MenuNavigation.styles';
import { Link } from 'react-router-dom';

interface MenuNavItemHeaderProps {
  name: string;
  defaultMessage?: string;
  className?: string;
  isActive: boolean;
  isExpandable?: boolean;
  menuIcon: ReactNode;
  href: string;
}

const MenuNavItemHeader = ({
  name,
  menuIcon,
  isActive,
  href,
  isExpandable = true,
  defaultMessage,
  className,
}: MenuNavItemHeaderProps) => {
  const { classes } = useMenuNavStyles();
  const setActiveNavStyle = (isItemSelected: boolean) => (isItemSelected ? classes.opaque : '');
  const { formatMessage } = useIntl();

  return (
    <Link
      className={clsx(classes.navigationHeaderWrapper, setActiveNavStyle(isActive), className)}
      to={href}>
      <Box
        className={clsx(classes.navigationListItemRectangle, {
          [classes.navigationListItemActiveRectangle]: isActive,
        })}
      />
      <Box className={classes.navigationHeaderTitle}>
        {menuIcon}
        <BodyTextRegular
          textContent={formatMessage({ id: name, defaultMessage })}
          fontWeight={500}
          customColor={Theme.palette.secondary.light}
        />
      </Box>
      {isExpandable && <ArrowDropDownIcon className={isActive ? classes.dropdownArrow : ''} />}
    </Link>
  );
};

export default MenuNavItemHeader;
