import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppContext } from '@/Context';
import { EventsServiceFactory } from '@/Services';

import useCurrentPartnerIdQuery from '../useCurrentPartnerIdQuery';

type usePublishedEventsInFutureQueryProps = {
  courseId: string;
};

const useGetNumberOfPublishedEventsInFutureQuery = ({
  courseId,
}: usePublishedEventsInFutureQueryProps) => {
  const { dispatch } = useAppContext();
  const partnerServiceInstance = useMemo(
    () => new EventsServiceFactory().getInstance(dispatch),
    [dispatch],
  );
  const { data: casPublicId = '' } = useCurrentPartnerIdQuery();

  const queryResult = useQuery(
    ['getNumberOfPublishedEventsInFuture', casPublicId, courseId],
    async () => {
      const publishedEventsInFuture =
        await partnerServiceInstance.getNumberOfPublishedEventsInFuture(casPublicId, courseId);

      return publishedEventsInFuture?.data?.pagination?.totalCount || 0;
    },
    {
      enabled: !!casPublicId && !!courseId,
      cacheTime: 0,
    },
  );

  return queryResult;
};

export default useGetNumberOfPublishedEventsInFutureQuery;
