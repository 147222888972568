import { Box, Button, styled } from '@mui/material';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const Container = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 620px;
  background-color: ${FigmaDesignTokens.White};
  padding: 30px 50px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export const Content = styled('div')`
  margin-top: 30px;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;

export const CloseButton = styled(Button)`
  width: 36px;
  height: 36px;
  background: none;
  min-width: 36px;
  min-height: 36px;
  border-radius: 20px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 28px;
  top: 18px;
`;
