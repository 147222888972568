import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { useAppContext } from '@/Context';
import { AssetServiceFactory } from '@/Services';

import useCurrentPartnerIdQuery from './useCurrentPartnerIdQuery';

const useGetHubspotDataQuery = () => {
  const { dispatch } = useAppContext();
  const { data: casPublicId = '' } = useCurrentPartnerIdQuery();
  const { language } = useParams();

  const queryResult = useQuery(
    ['useGetHubspotDataQuery', casPublicId, language],
    async () => {
      let fileName = 'vpp-hubspot-support-form';
      if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
        fileName += `-${process.env.REACT_APP_ENVIRONMENT}`;
      }
      if (language === 'en') {
        fileName += `-${language}`;
      }

      const assetService = new AssetServiceFactory().getInstance(dispatch);

      const file = await assetService.S3GetObject(
        casPublicId,
        `${fileName}.json`,
        'hubspot-forms',
        'application/json',
      );

      const decoder = new TextDecoder('utf-8');
      const str = decoder.decode(file.Body);

      return JSON.parse(str);
    },
    {
      staleTime: 1000 * 3600 * 6, // cache for 6 hours
      enabled: !!casPublicId,
    },
  );

  return queryResult;
};

export default useGetHubspotDataQuery;
