import { HttpService } from '@/Services';
import { MarketingMaterialsData, FilterParam } from '@/Types';

export class MarketingMaterialService {
  public httpService: HttpService;
  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  public async getMarketingMaterialList(partnerId: string, queryParams?: FilterParam[]) {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();
    let page = '1';
    let language = 'de';
    queryParams?.forEach(param => {
      switch (param.id) {
        case 'page': {
          page = param.value;
          break;
        }
        case 'language': {
          language = param.value;
          break;
        }
        default: {
          return;
        }
      }
    });
    const baseUrl = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${partnerId}/marketing-material?`;

    return axiosInstance.get<MarketingMaterialsData>(baseUrl + `language=${language}&page=${page}`);
  }
}
