import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppContext } from '@/Context';
import { UsersServiceFactory } from '@/Services';
import { User } from '@/Types';

type useUpdateUserDataMutationProps = {
  updatedUser: User;
};

const useUpdateUserDataMutation = () => {
  const { dispatch } = useAppContext();
  const usersService = useMemo(() => new UsersServiceFactory().getInstance(dispatch), [dispatch]);

  return useMutation(({ updatedUser }: useUpdateUserDataMutationProps) =>
    usersService.updateUser(updatedUser),
  );
};

export default useUpdateUserDataMutation;
