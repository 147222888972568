import { Box, Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import Warning from '@/Static/Icons/atoms-symbols-alert-error-orange.svg';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export interface EnableCourseManagerDialogNoAccessProps {
  header: string;
  description: string;
}

const useStyles = makeStyles()(theme => ({
  mainContent: {
    width: 624,
    borderRadius: 10,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: FigmaDesignTokens.White,
    margin: '0 auto',
    padding: '20px 60px',
    paddingTop: 37,
    position: 'relative',
    height: '100%',
  },
  header: {
    textAlign: 'center',
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 25,
    fontWeight: 900,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: 0.5,
    color: FigmaDesignTokens.Blue[950],
  },
  content: {
    marginTop: 20,
    backgroundColor: FigmaDesignTokens.Orange[50],
    borderRadius: 9,
    paddingTop: 30,
    paddingBottom: 54,
    paddingLeft: 30,
    paddingRight: 30,
    display: 'flex',
    flexDirection: 'row',
  },
  contentHeader: {
    fontSize: 14,
    letterSpacing: 0.13,
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    fontStretch: 'normal',
    textTransform: 'uppercase',
  },
  contentDescription: {
    fontSize: 12,
    letterSpacing: 0.11,
    color: FigmaDesignTokens.Grey[700],
    fontWeight: 'normal',
    fontStretch: 'normal',
    marginTop: 4,
  },
  contentIcon: {
    marginRight: 16,
  },
}));
export const EnableCourseManagerDialogNoAccess = ({
  header,
}: EnableCourseManagerDialogNoAccessProps) => {
  const { classes } = useStyles();
  const intl = useIntl();

  return (
    <Box className={classes.mainContent}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid>
            <Typography variant='body1' className={classes.header}>
              {header.toUpperCase()}
            </Typography>
          </Grid>
          <Grid>
            <Box className={classes.content}>
              <Box>
                <img alt='' src={Warning} className={classes.contentIcon} />
              </Box>
              <Box>
                <Typography variant='body1' className={classes.contentHeader}>
                  {intl
                    .formatMessage({
                      id: 'course_manager.dialog.no_access.header',
                      defaultMessage: 'Course manager is not unlocked',
                    })
                    .toUpperCase()}
                </Typography>
                <Typography variant='body1' className={classes.contentDescription}>
                  {intl.formatMessage({
                    id: 'course_manager.dialog.no_access.description',
                    defaultMessage: `The course manager is not yet unlocked for this partner.
                                    Please contact your administrator to unlock this area.`,
                  })}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
