import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '@/Store';
import { getModals } from '@/Store/UI/UI.reducer';
import MODAL_LOOKUP from './Lookup';

import { PopModalProps } from '@/Types';
import { modalNameToConstant } from '@/Utils';

type ModalComponent = React.FC<{
  onHide: () => void;
  show?: boolean;
}>;

export const ModalManager = () => {
  const dispatch = useDispatch();
  const modals: PopModalProps[] | [] = (useSelector(getModals) as PopModalProps[]) || [];

  const filteredModals = modals?.filter(modal => modal.show) || [];

  return (
    <>
      {filteredModals.map((modal: PopModalProps, i: number) => {
        const { modalType, show, ...modalState } = modal;
        const Modal = MODAL_LOOKUP[modalType] as ModalComponent | undefined;

        const onHide = () => {
          dispatch(uiActions.hideModal(modalType));
        };

        if (!Modal) {
          console.error(
            `Modal ${modalType} with Name ${modalNameToConstant(modalType)} not found in file ./Modals/index.ts and file system.`,
            Modal,
            MODAL_LOOKUP,
          );
          return <React.Fragment key={i} />;
        }

        return <Modal {...modalState} key={modalType + i} onHide={onHide} show={show} />;
      })}
    </>
  );
};
