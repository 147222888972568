import { actions as AuthSyncActions } from './authSlice';
import { Authenticate } from './Authenticate';
import { Logout } from './Logout';
import { Login } from './Login';

export const AuthActions = {
  Authenticate,
  Logout,
  Login,
  ...AuthSyncActions,
};

export { default as AuthSelectors } from './Selectors';
