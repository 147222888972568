import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const ListTableFooterStyles = makeStyles()(() => ({
  root: {
    '& .MuiTableCell-root': {
      border: 'none',
      padding: 0,
      paddingTop: 46,
    },
    '& .MuiPagination-root': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      fontWeight: 'bold',
    },
    '& .MuiPaginationItem-page.Mui-selected:hover, .MuiPaginationItem-page.Mui-selected.Mui-focusVisible':
      {
        backgroundColor: FigmaDesignTokens.Grey[50],
      },
    '& .MuiPaginationItem-page': {
      fontSize: 14,
      color: FigmaDesignTokens.Blue[950],
      margin: 0,
    },
    '& .MuiSvgIcon-root': {
      height: 24,
      width: 24,
    },
    '& li': {
      marginRight: 6,
    },
    '& li:first-of-type': {
      marginRight: 10,
    },
    '& li:last-child': {
      marginLeft: 4,
      marginRight: 0,
    },
  },
}));
