import { styled, Typography } from '@mui/material';

export const InternalCourseName = styled(Typography)`
  font-weight: bold;
  display: flex;
  font-family: Roboto-Bold;
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
`;
