import { useFormikContext } from 'formik';

import { UsersMap } from '@/Mappers';
import { CurrentUserFormik, User, UserAttr } from '@/Types';

import DeleteCurrentUserDialog from './Dialog/DeleteCurrentUserDialog';
import { OpenedEventsDialog } from './Dialog/OpenedEventsDialog';

type DeleteCurrentUserModalProps = {
  usersService: UsersMap;
  userAttr: UserAttr;
  adminUsers: User[];
  handleClose: () => void;
  isOpen: boolean;
};

export const DeleteCurrentUserModal = ({
  usersService,
  userAttr,
  adminUsers,
  handleClose,
  isOpen,
}: DeleteCurrentUserModalProps) => {
  const { values } = useFormikContext<CurrentUserFormik>();

  return (
    <>
      {!values.eventsTotalCount || adminUsers.length > 0 ? (
        <DeleteCurrentUserDialog
          handleClose={handleClose}
          isOpen={isOpen}
          usersService={usersService}
          userAttr={userAttr}
        />
      ) : (
        <OpenedEventsDialog handleClose={handleClose} isOpen={isOpen} />
      )}
    </>
  );
};
