import { ErrorMessage } from 'formik';
import { ErrorMessageStyles } from '../Styles/ErrorMessageStyles';
import { ErrorMessageProps } from 'formik/dist/ErrorMessage';
import { Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export const FormikErrorMessage = (props: ErrorMessageProps) => {
  const { classes } = ErrorMessageStyles();
  return (
    <ErrorMessage {...props}>
      {errorMessage => (
        <Box className={classes.root}>
          <FormattedMessage id={errorMessage} />
        </Box>
      )}
    </ErrorMessage>
  );
};
