import { Box, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { getSchedulePathname } from '@/Mappers';
import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';
import { BodyTextSmall, Button } from '@/Components';

import { useEventListHintStyles } from './EventListHint.styles';

export const EventListHint = (): JSX.Element => {
  const { classes } = useEventListHintStyles();
  const currentLanguage = useCurrentLanguage();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const handleScheduleForm = useCallback(() => {
    navigate(getSchedulePathname(currentLanguage));
  }, [navigate, currentLanguage]);

  return (
    <Grid container justifyContent='flex-end'>
      <Grid item xs={6}>
        <Box className={classes.root}>
          <BodyTextSmall
            textContent={formatMessage({
              id: 'view.courses_and_events.events.table.dashboard.hint.header',
              defaultMessage: 'In the schedule you can view and edit all dates for your courses.',
            })}
          />
          <Button variant='outlined' className={classes.scheduleBtn} onClick={handleScheduleForm}>
            {formatMessage({
              id: 'view.courses_and_events.events.table.dashboard.hint.cta.go_to_schedule',
              defaultMessage: 'to the schedule',
            })}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
