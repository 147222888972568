export const TIME_AND_DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
};

export const CountryDialCodes = [
  { dialCode: '+49' },
  { dialCode: '+43' },
  { dialCode: '+41' },
  { dialCode: '+352' },
];
