import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { MarketingMaterialItem } from '@/Types';
import { CellContainer, StyleDescription, StyleName } from './TableCell.styles';

type BookingsCellProps = {
  gridRenderCellParams: GridRenderCellParams<MarketingMaterialItem, number>;
};

export const FileInfoCell = ({ gridRenderCellParams }: BookingsCellProps) => {
  const { description, originalFilename } = gridRenderCellParams.row;
  return (
    <CellContainer>
      <StyleName>{originalFilename}</StyleName>
      <StyleDescription>
        {description.length > 70 ? `${description.slice(0, 67)}...` : description}
      </StyleDescription>
    </CellContainer>
  );
};
