import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { getCoursePathname } from '@/Mappers';
import { EventListItem } from '@/Types';

import PremiumIcon from '@/Static/Icons/modules-list-exclusive.svg';
import { Cancelled, Container, CourseName, PremiumImage } from './CourseNameCell.styles';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';

type CourseNameCellProps = {
  gridRenderCellParams: GridRenderCellParams<EventListItem, number>;
};

export const CourseNameCell = ({ gridRenderCellParams }: CourseNameCellProps) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const currentLanguage = useCurrentLanguage();

  const handleRedirectToCourse = useCallback(() => {
    navigate(getCoursePathname(currentLanguage, gridRenderCellParams.row.courseId));
  }, [navigate, currentLanguage, gridRenderCellParams]);

  return (
    <Container>
      {gridRenderCellParams.row.exclusiveCourse && !gridRenderCellParams.row.cancelled ? (
        <PremiumImage alt='' src={PremiumIcon} />
      ) : null}

      {gridRenderCellParams.row.cancelled ? (
        <Cancelled>
          {intl.formatMessage({
            id: 'view.courses_and_events.events.table.state.canceled',
            defaultMessage: 'Course Cancelled',
          })}
        </Cancelled>
      ) : null}
      <CourseName $cancelled={gridRenderCellParams.row.cancelled} onClick={handleRedirectToCourse}>
        {gridRenderCellParams.value}
      </CourseName>
    </Container>
  );
};
