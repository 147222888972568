import { DataGridProProps } from '@mui/x-data-grid-pro';
import { useIntl } from 'react-intl';

import { CustomGridProps } from '@/Types/CustomGrid';

import LabelDisplayedRows from '../Footer/LabelDisplayRows';
import { useDataGridLocaleText } from '../hooks/useDataGridLocalText';
import { DataGridTable } from './Table.styles';

export const CustomDataGrid = (props: DataGridProProps & CustomGridProps) => {
  const dataGridLocalText = useDataGridLocaleText();
  const { formatMessage } = useIntl();

  return (
    <DataGridTable
      localeText={dataGridLocalText}
      pageSizeOptions={[10, 30, 60, 100]}
      {...props}
      slotProps={{
        filterPanel: {
          sx: {
            padding: '8px',

            '.MuiDataGrid-filterFormDeleteIcon': {
              alignSelf: 'center',
            },
          },
        },
        baseFormControl: {
          sx: {
            marginLeft: '8px',
          },
        },
        baseInputLabel: {
          sx: {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
        },

        baseTextField: {
          variant: 'outlined',
        },
        baseSelect: {
          variant: 'outlined',
          notched: true,
        },
        // basePopper: {
        //   popperOptions: {
        //     placement: 'top-start',
        //   },
        // },

        pagination: {
          labelRowsPerPage: formatMessage({
            id: 'table.footer.row_per_page',
            defaultMessage: 'Rows per page',
          }),
          labelDisplayedRows: LabelDisplayedRows,
        },

        ...(props.slotProps || {}),
      }}
    />
  );
};
