import { makeStyles } from 'tss-react/mui';
import Theme from '@/App/Theme/Theme';

export const useMenuNavStyles = makeStyles()(() => ({
  navigationHeaderWrapper: {
    paddingRight: 24,
    display: 'flex',
    opacity: 0.5,
    color: Theme.palette.secondary.light,
    alignContent: 'space-between',
    cursor: 'pointer',
    alignItems: 'center',
    minHeight: 48,
  },
  navigationHeaderTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    flex: 1,
  },
  navigationListItemRectangle: {
    width: 4,
    height: 46,
    marginRight: 24,
  },
  navigationListItemActiveRectangle: {
    backgroundColor: Theme.palette.secondary.light,
  },
  dropdownArrow: {
    transform: 'rotate(-180deg)',
  },
  opaque: {
    opacity: 1,
  },
  hidden: {
    display: 'none',
  },
}));
