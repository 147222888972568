import * as Yup from 'yup';
import { IntlShape } from 'react-intl/src/types';

export const CheckInOptionsValidation = (intl: IntlShape) =>
  Yup.object().shape({
    checkInApp: Yup.boolean().when('checkInCard', {
      is: false,
      then: Yup.boolean().oneOf(
        [true],
        intl.formatMessage({
          id: 'form.check_in_options.error.check_in_app',
          defaultMessage: 'Please select an option. ',
        }),
      ),
    }),
  });
