import { Box } from '@mui/material';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { useCurrentPartnerIdQuery } from '@/Queries';

import { ProfileDropdownItemStyles } from './Styles/ProfilePartnerItem.styles';

type ProfilePartnerItemProps = {
  title: string;
  href: string;
  translate: boolean;
  publicId?: string;
  isActive?: boolean;
  isRectangle?: boolean;
};

export const ProfilePartnerItem = ({
  title,
  href,
  translate,
  publicId,
  isActive,
  isRectangle = true,
}: ProfilePartnerItemProps) => {
  const { classes } = ProfileDropdownItemStyles();
  const { data: currentPartnerId } = useCurrentPartnerIdQuery();

  return (
    <Box className={classes.navigationListItemContainer}>
      {isRectangle && (
        <Box
          className={clsx(classes.navigationListItemRectangle, {
            [classes.navigationListItemActiveRectangle]: publicId === currentPartnerId,
          })}
        />
      )}
      <Link
        to={href}
        className={clsx(classes.navigationListItem, {
          [classes.navigationListItemActive]: isActive || publicId === currentPartnerId,
        })}>
        {!translate && title}
        {translate && <FormattedMessage id={title} />}
      </Link>
    </Box>
  );
};
