import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';

import { FormSnackbar } from '@/App/Shared/Notification/Components/FormSnackbar';
import { Button, Dropzone, UploadIndicator } from '@/Components';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { useUploadCancel } from '@/Hooks/useUploadCancel';
import { getDefaultGalleryAsset } from '@/Mappers';
import { AssetService } from '@/Services';
import ErrorIcon from '@/Static/Icons/atoms-symbols-alert-error.svg';
import CloseIcon from '@/Static/Icons/atoms-symbols-category-close-big.svg';
import ChangeIcon from '@/Static/Icons/atoms-symbols-icons-media-change.svg';
import { GalleryAsset, Partner } from '@/Types';
import { handleFileUpload } from '@/Utils/fileUpload';

import { GalleryAssetPreview } from '../Forms/Assets/GalleryAssetPreview';
import { useAlterGalleryImageStyles } from './Styles/AlterGalleryImage.styles';

interface AlterGalleryImageModalProps {
  resourceData: GalleryAsset;
  assetService: AssetService;
  casPublicId: Partner['casPublicId'];
  handleNewFileKey: (value: string) => void;
  newFileKey: string;
  handleReplaceImage: (
    idx: number,
    uri: string,
    assetId: number,
    newOriginalFilename: string | null,
  ) => void;
  idx: number;
  handleNewFileAssetId: (value: number | undefined) => void;
  handleNewOriginalFilename: (value: string | null) => void;
  newFileAssetId: number | undefined;
  newOriginalFilename: string | null;
}

export const AlterGalleryImageModal = (props: AlterGalleryImageModalProps) => {
  const {
    assetService,
    casPublicId,
    resourceData,
    handleNewFileKey,
    newFileKey,
    idx,
    handleReplaceImage,
    handleNewFileAssetId,
    newFileAssetId,
    newOriginalFilename,
    handleNewOriginalFilename,
  } = props;

  const [open, setOpen] = useState(false);
  const { classes } = useAlterGalleryImageStyles();
  const { formatMessage } = useMemoedIntl();
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  const [isWrongFormat, setIsWorngFormat] = useState(false);

  const { uploads, handleCancel, setUploads } = useUploadCancel();
  const handleDrop = useCallback(
    async (files: File[]) => {
      if (files.length > 0) {
        const file = files[0];

        await handleFileUpload(
          assetService,
          casPublicId,
          file,
          'Gallery',
          setUploads,
          async (filename: string, file: File) =>
            assetService.assignGalleryImage(casPublicId, {
              filename,
              originalFilename: file.name,
            }),
          formatMessage({
            id: 'upload.failed',
            defaultMessage: 'Upload failed',
          }),
        );
      }
    },
    [assetService, casPublicId, formatMessage, setUploads],
  );

  const handleApplyChanges = useCallback(() => {
    newFileAssetId && handleReplaceImage(idx, newFileKey, newFileAssetId, newOriginalFilename);
    handleNewFileKey('');
    handleNewFileAssetId(undefined);
    handleNewOriginalFilename(null);
    handleClose();
  }, [
    newFileAssetId,
    handleReplaceImage,
    idx,
    newFileKey,
    newOriginalFilename,
    handleNewFileKey,
    handleNewFileAssetId,
    handleNewOriginalFilename,
    handleClose,
  ]);

  return (
    <>
      <Button className={classes.btnModal} onClick={handleOpen} variant='text'>
        <img alt='' src={ChangeIcon} />
        <Typography variant='body2' className={classes.btnText}>
          {formatMessage({
            id: 'alter.gallery.image.cta.replace_image',
            defaultMessage: 'Replace image',
          })}
        </Typography>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        PaperProps={{
          style: {
            padding: '50px 30px 30px 30px',
            maxWidth: 'unset',
            width: 696,
          },
        }}>
        <DialogTitle classes={{ root: 'dialogImgTitle' }} className={classes.dialogImgTitle}>
          <Box className={classes.headingWrapper}>
            {formatMessage({
              id: 'alter.gallery.image.cta.replace_image',
              defaultMessage: 'Replace image',
            })}
            <IconButton aria-label='close' onClick={handleClose}>
              <img alt='' src={CloseIcon} />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={4}>
            <FormSnackbar
              openToastMessage={isWrongFormat}
              handleCloseToastMessage={() => setIsWorngFormat(false)}
              customMessage={formatMessage({
                id: 'toast.upload.fail',
                defaultMessage:
                  'File upload failed. Please verify the file type and size and try again.',
              })}
              customIcon={ErrorIcon}
            />
            <Grid item xs={5}>
              <GalleryAssetPreview
                assetService={assetService}
                casPublicId={casPublicId}
                resourceData={resourceData}
                isBinIcon={false}
              />
            </Grid>
            <Grid item xs={7}>
              {newFileKey && newFileAssetId && (
                <GalleryAssetPreview
                  assetService={assetService}
                  casPublicId={casPublicId}
                  resourceData={getDefaultGalleryAsset(
                    newFileAssetId,
                    newFileKey,
                    newOriginalFilename,
                  )}
                  handleRemoveNewImage={() => handleNewFileKey('')}
                  isBinIcon
                />
              )}
              {uploads.length > 0 && (
                <UploadIndicator uploads={uploads} width={195} handleCancel={handleCancel} />
              )}
              {!newFileKey && !uploads.length && (
                <Box className={classes.dropzoneWrapper}>
                  <Dropzone
                    disabled={false}
                    maxFileSize={15000000}
                    supportedFileNames='JPEG, PNG, TIFF'
                    onDrop={handleDrop}
                    onDropRejected={() => setIsWorngFormat(true)}
                    accept='image/jpeg, image/png, image/tiff'
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleApplyChanges} disabled={!newFileAssetId}>
            {formatMessage({
              id: 'alter.gallery.image.cta.apply',
              defaultMessage: 'Apply changes',
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
