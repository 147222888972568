import { Box, styled } from '@mui/material';
import { GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const GridToolbarWrapper = styled(GridToolbarContainer)(() => ({
  height: '64px',
  padding: '12px 10px 12px 11px',
  borderBottom: `2px solid ${FigmaDesignTokens.Grey[50]}`,
  display: 'flex',
  alignItems: 'center',
}));

export const FilterButton = styled(GridToolbarFilterButton)(() => ({
  color: FigmaDesignTokens.Grey[500],
  fontFamily: 'Roboto-Medium',
  fontSize: '14px',
  fontWeight: 500,
  textTransform: 'initial',
  marginLeft: '12px',
}));

export const RangePickerWrapper = styled(Box)(() => ({
  display: 'flex',

  '.MuiInputBase-root': {
    height: '40px',
    maxWidth: '146px',
    marginRight: '8px',
  },
  '.MuiTextField-root': {
    width: '-webkit-fill-available',
  },
}));
