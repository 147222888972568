import { useMemo } from 'react';

import { getDashboardPathname, getEquipmentPathname } from '@/Mappers';
import { BreadcrumbProps } from '@/Types';

import useCurrentLanguage from '../useCurrentLanguage';
import useMemoedIntl from '../useMemoedIntl';

const useEquipmentAndServiceBreadcrumbs = (): BreadcrumbProps[] => {
  const { formatMessage } = useMemoedIntl();
  const currentLanguage = useCurrentLanguage();

  const staticBreadcrumbs = useMemo(
    () => [
      {
        title: formatMessage({ id: 'view.dashboard', defaultMessage: 'Dashboard' }),
        href: getDashboardPathname(currentLanguage),
      },
      {
        title: formatMessage({
          id: 'view.equipment_and_services.equipment_and_services',
          defaultMessage: 'Equipment & Services',
        }),
        href: getEquipmentPathname(currentLanguage),
      },
    ],
    [formatMessage, currentLanguage],
  );

  return staticBreadcrumbs;
};

export default useEquipmentAndServiceBreadcrumbs;
