import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppContext } from '@/Context';
import { EventsServiceFactory } from '@/Services';
import { EventList, FilterCheckboxParam, FilterParam, FilterPickersRangeParam } from '@/Types';

import useCurrentPartnerIdQuery from '../useCurrentPartnerIdQuery';

const useEventsListQuery = (filterValues: EventList, courseId?: number) => {
  const {
    state: { courseManager },
    dispatch,
  } = useAppContext();
  const { data: casPublicId = '' } = useCurrentPartnerIdQuery();
  const eventsService = useMemo(() => new EventsServiceFactory().getInstance(dispatch), [dispatch]);

  const queryResult = useQuery(
    [
      'getEventsListData',
      filterValues.courseName,
      filterValues.limit,
      filterValues.page,
      courseManager.dirtyFilters,
      casPublicId,
    ],
    async () => {
      const filtersParams: FilterCheckboxParam[] & FilterPickersRangeParam[] & FilterParam[] =
        eventsService.getFiltersParams(filterValues, courseId);

      const response = await eventsService.getEvents(casPublicId, 'ACTIVE', filtersParams);
      return response.data;
    },
    {
      cacheTime: 0,
      // staleTime: 1000 * 60 * 3,
      enabled: !!casPublicId,
    },
  );

  return queryResult;
};

// export const useInfiniteEventsListQuery = (filterValues: EventList, courseId?: number) => {
//   const {
//     state: { courseManager },
//     dispatch,
//   } = useAppContext();

//   const { data: casPublicId = '' } = useCurrentPartnerIdQuery();

//   const eventsService = useMemo(() => new EventsServiceFactory().getInstance(dispatch), [dispatch]);

//   const queryResult = useInfiniteQuery({
//     queryKey: ['getInfiniteEventsListData', filterValues.courseName, courseManager.dirtyFilters],
//     queryFn: ({ pageParam = 1 }) => {
//       const filtersParams: FilterCheckboxParam[] & FilterPickersRangeParam[] & FilterParam[] =
//         eventsService.getFiltersParams(
//           { ...filterValues, limit: 50, page: pageParam.toString() },
//           courseId,
//         );

//       return eventsService.getEvents(casPublicId, 'ACTIVE', filtersParams);
//     },
//     cacheTime: 0,
//     keepPreviousData: true,
//     getNextPageParam: lastPage => {
//       const { currentPage, pages } = lastPage.data.pagination;

//       if (currentPage < pages) {
//         return currentPage + 1;
//       }

//       return undefined; // No more pages
//     },
//     onSuccess: data => {
//       const lastPage = data.pages[data.pages.length - 1];
//       const { currentPage, pages } = lastPage.data.pagination;
//       if (currentPage < pages) {
//         queryResult.fetchNextPage();
//       }
//     },
//     enabled: !!casPublicId,
//   });

//   return queryResult;
// };

export default useEventsListQuery;
