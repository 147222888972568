import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const useUserFormStyles = makeStyles()(theme => ({
  mainContent: {
    width: 620,
    height: 'fit-content',
    borderRadius: 10,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: FigmaDesignTokens.White,
    margin: '0 auto',
    padding: '20px 76px',
    position: 'relative',
    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none',
    },
  },
  header: {
    textAlign: 'center',
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 25,
    fontWeight: 900,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: 0.5,
    color: FigmaDesignTokens.Blue[950],
    marginTop: 12,
  },
  headerForm: {
    color: FigmaDesignTokens.Blue[950],
    fontSize: 20,
    marginTop: '28px',
  },
  subHeader: {
    marginTop: 4,
    fontSize: 14,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 0.18,
    color: FigmaDesignTokens.Grey[950],
    textAlign: 'center',
  },
  userBtn: {
    borderRadius: 18,
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.83,
    letterSpacing: 0.34,
    color: theme.palette.background.default,
    height: 36,
    marginBottom: '40px',
    padding: '7px 11px',
  },
  mainActionBtn: {
    marginTop: '10px',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.1)',
    '&:disabled': {
      background: FigmaDesignTokens.Grey[200],
      color: theme.palette.info.dark,
    },
  },
  error: {
    fontSize: 14,
    color: theme.palette.error.main,
    textAlign: 'center',
    lineHeight: 1.43,
    letterSpacing: 0.18,
  },
  textField: {
    '& .MuiOutlinedInput-root ': {
      height: '53.63px',
    },
    '& label, input': {
      fontSize: 14,
    },
    '& label.Mui-error': {
      fontSize: 14,
    },
    marginBottom: 2,
    marginTop: 4,
  },
  formContainer: {
    paddingTop: 0,
  },
  legalHint: {
    fontSize: 10,
    textAlign: 'center',
    paddingLeft: 90,
    paddingRight: 90,
    lineHeight: 1.5,
    marginTop: 0,
    '& a': {
      textStyle: 'underline',
      fontWeight: 'bold',
      color: FigmaDesignTokens.Grey[700],
    },
  },
}));
