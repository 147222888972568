import { HttpService } from '@/Services';
import { Auth } from 'aws-amplify';
import { PartnerCoursesService } from '@/Services';
import { Dispatch } from 'react';
import { AnyStateAction } from '@/Context';

export class PartnerCoursesServiceFactory {
  public getInstance(dispatch: Dispatch<AnyStateAction>): PartnerCoursesService {
    return new PartnerCoursesService(new HttpService(Auth, dispatch));
  }
}
