import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const useVideoStyles = makeStyles()(() => ({
  uploadRadioGroupContainer: {
    flexDirection: 'row',
  },
  uploadRadioGroupLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 2.17,
      letterSpacing: 0.34,
      textAlign: 'center',
      color: FigmaDesignTokens.Grey[950],
    },
  },
}));
