import { Box, styled, Typography } from '@mui/material';

export const CourseName = styled(Typography)`
  font-weight: bold;
  display: flex;
  font-family: Roboto-Bold;
  font-size: 14px;
`;

export const PremiumImage = styled('img')`
  position: absolute;
  top: 0;
`;

export const Container = styled(Box)`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
`;
