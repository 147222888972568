import { useMemo } from 'react';

import { User } from '@/Types';

import { BlockedIconBox, IconBox, IconContainer, IconNameBox } from './ProfileIconCell.styles';

type ProfileIconCellProps = {
  data?: User;
};

export const ProfileIconCell = ({ data = {} as User }: ProfileIconCellProps) => {
  const initials = useMemo(
    () =>
      [data.givenName || '', data.name || '']
        .filter(Boolean)
        .map(name => name.slice(0, 1).toUpperCase())
        .join('')
        .trim(),
    [data],
  );

  return (
    <IconContainer>
      {data.status === 'Blocked' ? (
        <BlockedIconBox>
          <IconNameBox>{initials}</IconNameBox>
        </BlockedIconBox>
      ) : (
        <IconBox>
          <IconNameBox>{initials}</IconNameBox>
        </IconBox>
      )}
    </IconContainer>
  );
};
