import { Typography } from '@mui/material';
import clsx from 'clsx';

import { BasicTextStyleProps } from '@/Types';

//h1 25px
export const MainHeadline = ({ textContent, className, customColor }: BasicTextStyleProps) => (
  <Typography variant='h1' className={clsx(className)} color={customColor}>
    {textContent}
  </Typography>
);

//h2 20px
export const PageHeadline = ({
  textContent,
  className,
  customColor,
  textAlign = 'left',
}: BasicTextStyleProps) => (
  <Typography variant='h2' className={clsx(className)} color={customColor} textAlign={textAlign}>
    {textContent}
  </Typography>
);

//h3 18px
//Hansefit Regular text style is Roboto
export const SubHeadlineRegular = ({
  textContent,
  className,
  customColor,
  textTransform,
}: BasicTextStyleProps) => (
  <Typography
    variant='h3'
    className={clsx(className)}
    color={customColor}
    textTransform={textTransform}>
    {textContent}
  </Typography>
);

//h4 16px
//Hansefit Display text style is NutmegHeadline-UltraBlack
// 2024-04-16 we're overwriting the theme style for this component
export const SubHeadlineDisplay = ({
  textContent,
  className,
  customColor,
}: BasicTextStyleProps) => (
  <Typography
    variant='h4'
    className={clsx(className)}
    sx={{
      fontSize: 16,
      fontFamily: 'NutmegHeadline-UltraBlack',
      fontWeight: 900,
      textTransform: 'uppercase',
    }}
    color={customColor}>
    {textContent}
  </Typography>
);

//h5 14px
//Hansefit Regular text style is Roboto
export const RegularTextAccent = ({ textContent, className }: BasicTextStyleProps) => (
  <Typography variant='h5' className={clsx(className)}>
    {textContent}
  </Typography>
);

//h6 12px
//Hansefit Display text style is NutmegHeadline-UltraBlack
export const DisplayTextAccent = ({
  textContent,
  className,
  customColor,
  id,
}: BasicTextStyleProps) => (
  <Typography variant='h6' className={clsx(className)} color={customColor} id={id}>
    {textContent}
  </Typography>
);

//roboto 12px
export const OverlineText = ({
  textContent,
  className,
  customColor,
  fontWeight = 400,
  fontStyle = 'normal',
  textTransform = 'uppercase',
}: BasicTextStyleProps) => (
  <Typography
    variant='overline'
    className={clsx(className)}
    color={customColor}
    fontStyle={fontStyle}
    textTransform={textTransform}
    fontWeight={fontWeight}>
    {textContent}
  </Typography>
);
