import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { CustomTooltip } from '@/App/Shared/Tooltip/CustomTooltip';
import { useResetPasswordMutation } from '@/Queries';
import Reload from '@/Static/Icons/resend.svg';
import { Partner, User } from '@/Types';
import handleCatchError from '@/Utils/handleCatchError';

import {
  ActiveStatus,
  BlockedStatus,
  Container,
  DeletedStatus,
  PendingButton,
  PendingButtonText,
  PendingStatus,
  StatusWrapper,
} from './StatusCell.styles';

type StatusCellProps = {
  data: User;
  handleOpenToastMessage: (message: string) => void;
  casPublicId: Partner['casPublicId'];
};

export const StatusCell = ({ data: row, handleOpenToastMessage, casPublicId }: StatusCellProps) => {
  const intl = useIntl();

  const { mutateAsync: resetPassword } = useResetPasswordMutation();

  const resendInvitation = useCallback(async () => {
    try {
      await resetPassword({ publicId: casPublicId, email: row.email });

      handleOpenToastMessage(
        intl.formatMessage({
          id: 'view.user_management.user_management.table.status.message.success',
          defaultMessage: 'Invitation successfully sent',
        }),
      );
    } catch (error) {
      handleCatchError(error);
    }
  }, [resetPassword, casPublicId, row.email, handleOpenToastMessage, intl]);

  if (row.status === 'Deleted') {
    return (
      <StatusWrapper>
        <DeletedStatus>
          {intl.formatMessage({
            id: 'view.user_management.user_management.table.status.deleted',
            defaultMessage: 'Deleted',
          })}
        </DeletedStatus>
      </StatusWrapper>
    );
  }

  if (row.status === 'Active') {
    return (
      <StatusWrapper>
        <ActiveStatus>
          {intl.formatMessage({
            id: 'view.user_management.user_management.table.status.active',
            defaultMessage: 'active',
          })}
        </ActiveStatus>
      </StatusWrapper>
    );
  }
  if (row.status === 'Pending') {
    return (
      <Container>
        <StatusWrapper>
          <PendingStatus>
            {intl.formatMessage({
              id: 'view.user_management.user_management.table.status.pending',
              defaultMessage: 'Invitation pending',
            })}
          </PendingStatus>
        </StatusWrapper>
        <CustomTooltip
          title={intl.formatMessage({
            id: 'view.user_management.user_management.table.tooltip.resend_invite',
            defaultMessage: 'Resend email',
          })}>
          <PendingButton onClick={resendInvitation}>
            <img alt='' src={Reload} />
            <PendingButtonText>
              {intl.formatMessage({
                id: 'view.user_management.user_management.table.tooltip.resend_invite',
                defaultMessage: 'Resend email',
              })}
            </PendingButtonText>
          </PendingButton>
        </CustomTooltip>
      </Container>
    );
  }
  if (row.status === 'Blocked') {
    return (
      <StatusWrapper>
        <BlockedStatus>
          {intl.formatMessage({
            id: 'view.user_management.user_management.table.status.blocked',
            defaultMessage: 'locked',
          })}
        </BlockedStatus>
      </StatusWrapper>
    );
  }

  return null;
};
