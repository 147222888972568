const fileTypeToSimpleName = (fileType: string) => {
  switch (fileType) {
    case 'application/pdf':
      return 'pdf';
    case 'image/jpeg':
      return 'jpg';
    case 'image/png':
      return 'png';
    case 'image/gif':
      return 'gif';
    case 'image/svg+xml':
      return 'svg';
    case 'text/plain':
      return 'txt';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'pptx';
    case 'application/vnd.ms-powerpoint':
      return 'ppt';
    case 'application/msword':
      return 'doc';
    case 'application/vnd.ms-excel':
      return 'xls';
    case 'application/zip':
      return 'zip';
    case 'application/x-rar-compressed':
      return 'rar';

    default:
      return 'file';
  }
};

export default fileTypeToSimpleName;
