import { styled, Typography } from '@mui/material';

import FigmaDesignTokens from '../../../../../../design/design-tokens.json';

export const Container = styled('div')`
  width: 270px;
  height: 150px;
  border-radius: 12px;
  border: 2px dashed ${FigmaDesignTokens.Grey[300]};
  background-color: ${FigmaDesignTokens.Grey[50]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const Header = styled(Typography)`
  font-family: Roboto-Bold;
  font-size: 14px;
  color: ${FigmaDesignTokens.Blue[950]};
`;
