import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const useTimeZoneModalStyles = makeStyles()(theme => ({
  modalFieldWrapper: {
    paddingLeft: 14,
    height: 56,
  },
  modalFieldHeader: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: 0.18,
    color: FigmaDesignTokens.Grey[950],
  },
  modalFieldValue: {
    fontSize: 12,
    letterSpacing: 0.11,
    color: FigmaDesignTokens.Grey[700],
  },
  modalFieldBtn: {
    borderRadius: 0,
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.83,
    letterSpacing: 0.34,
    height: 22,
    color: theme.palette.primary.dark,
    padding: 0,
  },
  additionalTimeZoneTitle: {
    maxWidth: 600,
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    '& .MuiDialogTitle-root': {
      padding: 0,
      paddingBottom: 20,
    },
  },
  dialogContent: {
    padding: '0 !important',
    overflow: 'unset',
  },
  dialogActions: {
    padding: 0,
    justifyContent: 'flex-end',
  },
  closeButton: {
    padding: 0,
  },
  cta: {
    padding: '7px 26px',
    borderRadius: 18,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.83,
    letterSpacing: 0.34,
  },
}));
