import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const useHeaderStyles = (columnHeaderHeight?: number) =>
  makeStyles()(theme => ({
    menuWrapper: {
      top: 52,
      right: -57,
      position: 'absolute',
      zIndex: 1,
      height: '80vh',
      maxHeight: 575,
    },
    btnPopover: {
      height: '40px',
      backgroundColor: 'inherit',
      border: 'none',
      gap: 10,
      '& svg': {
        color: FigmaDesignTokens.Grey[950],
      },
      '&:hover': {
        border: 'none',
      },
    },
    popover: {
      width: '382px',
      boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      overflow: 'hidden',
      maxHeight: '80vh',
      height: 'max-content',
      display: 'flex',
      flexDirection: 'column',
      background: FigmaDesignTokens.White,
    },
    headerWrapper: {
      backgroundColor: FigmaDesignTokens.Blue[950],
      display: 'flex',
      padding: '18px 28px 12px 28px',
      cursor: 'default',
    },
    navigationList: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 1,
      justifyContent: 'space-between',
      marginTop: '22px',
      marginBottom: '12px',
      maxHeight: 300,
      overflow: 'hidden',
    },
    navigationHeaderWrapper: {
      marginBottom: '17px',
      marginLeft: '28px',
      display: 'flex',
      gap: 10,
      alignItems: 'center',
      cursor: 'default',
    },
    navigationHeaderImg: {
      width: '20px',
      height: '20px',
    },
    btnNewStudio: {
      width: 'fit-content',
      padding: '4px 11px',
      borderRadius: '18px',
      border: `solid 1px ${theme.palette.primary.dark}`,
      height: '30px',
      lineHeight: 1.83,
      letterSpacing: '0.34px',
      color: theme.palette.primary.dark,
      fontSize: '12px',
      fontWeight: 500,
      marginLeft: '28px',
      marginBottom: '20px',
    },
    divider: {
      height: '2px',
      borderRadius: '1px',
      boxShadow: `inset 0 1px 1px 0 ${theme.palette.background.default}`,
      backgroundColor: FigmaDesignTokens.Grey[200],
    },
    logoutContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 20,
      marginTop: 20,
      paddingBottom: 22,
    },
    logoutLink: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      lineHeight: 1.86,
      letterSpacing: 0.4,
      color: FigmaDesignTokens.Grey[950],
    },

    partnerListContainer: {
      overflowY: 'scroll',
      height: '100%',
    },
    stickyBottom: {
      width: '100%',
      height: 'auto',
    },

    stickyBackground: {
      position: 'fixed',
      width: '100%',
      minWidth: 'calc(100% - 300px)',
      height: columnHeaderHeight || 130,
      left: 0,
      top: 0,
      backgroundColor: theme.palette.background.default,
      boxShadow: '0 5px 5px 0 rgba(52, 54, 61, 0.05)',
      zIndex: 10,
    },
    headerContainer: {
      paddingTop: columnHeaderHeight ? 24 : 42,
      transition: 'padding-top 0.20s ease-out',
    },
    userProfileIconContainer: {
      paddingTop: 10,
    },
    smallPaddingToBody: {
      paddingTop: 12,
    },
    tabAndPublishContainer: {
      minHeight: 48,
      alignItems: 'center',
      marginTop: 40,
      transition: 'margin-top 0.20s ease-out',
      marginBottom: 4,
    },
    smallMarginToTopHeader: {
      marginTop: 10,
    },
    saveAndPublishContainer: {
      textAlign: 'right',
      justifyContent: 'flex-end',
      paddingRight: 30,
    },
    saveAndPublishButton: {
      marginLeft: '16px',
    },
    stickyInner: {
      zIndex: 11,
    },
    tabNavigationContainer: {
      alignItems: 'center',
      marginBottom: 20,
    },

    stickyPublishButtonInner: {
      marginRight: 30,
    },
    ctaAndLanguageWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    smallMarginTop: {
      marginTop: 15,
    },
    logoutBtnLink: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      lineHeight: 1.86,
      letterSpacing: 0.4,
      gap: 8,
      color: FigmaDesignTokens.Grey[950],
      textTransform: 'none',
      '&.MuiButton-root:hover': {
        textDecoration: 'underline',
        backgroundColor: 'unset',
      },
    },
    logoutIcon: {
      marginRight: '0',
      marginLeft: '10px',
    },
  }));

export const useLanguageDropdownStyles = makeStyles()(theme => ({
  languageContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      borderRadius: '10px',
      backgroundColor: FigmaDesignTokens.Grey[200],
    },
  },
  active: {
    backgroundColor: FigmaDesignTokens.Grey[200],
    ':hover': { backgroundColor: theme.palette.info.dark },
    '&:first-of-type': {
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
    },
    '&:last-child': {
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
    },
  },
  languageRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '5px 10px',
    '&:first-of-type': {
      gap: '20px',
    },
    '&:not(:first-of-type)': {
      borderTop: '1px solid',
      position: 'absolute',
      top: '45px',
      zIndex: '100',
    },
  },
}));

export const useSiteAndProfileBarStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },

  leftContainer: {
    width: 'auto',
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  headerContainer: {
    paddingTop: 16,
    height: 35,
  },
}));
