import { Box } from '@mui/material';
import clsx from 'clsx';

import { useGetPartnerQuery } from '@/Queries';

import { useRemoveOpeningHourStyles } from './OpeningHours.styles';

export const RemoveOpeningHour = (): JSX.Element => {
  const { classes } = useRemoveOpeningHourStyles();
  const { data: partner } = useGetPartnerQuery();

  return (
    <Box className={clsx(classes.root, { [classes.disabled]: partner?.readonly })}>
      <Box className={classes.icon} />
    </Box>
  );
};
