import * as Yup from 'yup';
import { IntlShape } from 'react-intl/src/types';

export const OpeningHoursFormValidation = ({
  intl,
  isSetTriggerValidate,
}: {
  intl: IntlShape;
  isSetTriggerValidate?: Function | undefined;
}) =>
  Yup.object().shape({
    openingHours: Yup.array().of(
      Yup.object().shape({
        times: Yup.array().of(
          Yup.object().shape({
            from: Yup.string()
              .notRequired()
              .test({
                test(value) {
                  const toValue = this.parent.to;
                  isSetTriggerValidate?.(true);
                  return toValue && value;
                },
                message: intl.formatMessage({
                  id: 'form.description_and_opening_hours.validate.required',
                  defaultMessage: 'Please add opening and close time',
                }),
              }),

            to: Yup.string()
              .notRequired()
              .test({
                test(value) {
                  const fromValue = this.parent.from;
                  isSetTriggerValidate?.(true);
                  return fromValue && value;
                },
                message: '',
              }),
          }),
        ),
      }),
    ),
  });
