import { Grid, Icon } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import moment from 'moment';
import { useIntl } from 'react-intl';

import KeyboardDatePicker from '@/App/Shared/Form/Components/Formik/FormikDatePicker';
import { FormikErrorMessage } from '@/App/Shared/Form/Components/Formik/FormikErrorMessage';
import { TimeZoneModal } from '@/Components';
import { EventsService } from '@/Services';
import DatePickerIcon from '@/Static/Icons/atoms-symbols-icons-calendar.svg';
import { Event } from '@/Types';

import { AppointmentsTime } from './AppointmentsTime';
import { useSingleEventFormStyles } from './Event.styles';

type SingleEventFormProps = {
  eventsService: EventsService;
};

export const SingleEventForm = ({ eventsService }: SingleEventFormProps) => {
  const { values, setFieldValue, validateField } = useFormikContext<Event>();
  const intl = useIntl();
  const { classes } = useSingleEventFormStyles();
  const maxDate = moment().add(1, 'years').toDate();

  return (
    <>
      <Grid container item spacing={3}>
        <Grid item xs={6}>
          <Field
            className={classes.datepicker}
            component={KeyboardDatePicker}
            name='appointments.startDate'
            value={values.appointments.startDate}
            label={intl.formatMessage({
              id: 'form.event.field.appointments.start_date.label',
              defaultMessage: 'Course date*',
            })}
            variant='inline'
            inputVariant='outlined'
            onChange={async (date: Date) => {
              setFieldValue('appointments.startDate', date);
              await validateField('appointments.hour');
              await validateField('appointments.minute');
            }}
            format='dd.MM.yyyy'
            disablePast
            minDate={new Date()}
            maxDate={maxDate}
            maxDateMessage={intl.formatMessage({
              id: 'form.event.field.appointments.max_date.error',
              defaultMessage: 'Date must be no more than 12 months in the future',
            })}
            autoComplete='off'
            autoOk
            components={{
              OpenPickerIcon: () => (
                <Icon>
                  <img src={DatePickerIcon} alt='' />
                </Icon>
              ),
            }}
          />
          <FormikErrorMessage name='appointments.startDate' />
        </Grid>
      </Grid>

      <Grid container item spacing={3}>
        <AppointmentsTime
          eventsService={eventsService}
          hourName='appointments.hour'
          minuteName='appointments.minute'
        />

        <Grid item xs={6}>
          <TimeZoneModal />
        </Grid>
      </Grid>
    </>
  );
};
