import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const useConfirmationEmailStyles = makeStyles()(theme => ({
  mainContent: {
    width: 620,
    height: 'fit-content',
    borderRadius: 10,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: FigmaDesignTokens.White,
    margin: '64px auto',
    padding: '40px 36px',
    position: 'relative',
    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerIcon: {
    margin: '0 auto',
    marginBottom: 15,
  },
  headerForm: {
    color: FigmaDesignTokens.Blue[950],
    fontSize: 20,
    textAlign: 'center',
  },
  subHeader: {
    marginTop: 4,
    padding: '0 20px',
  },
}));
