import { TableBody } from '@mui/material';
import React from 'react';
import { EquipmentAndServiceRows } from './EquipmentAndServiceRows';
import { Partner } from '@/Types';

export interface EquipmentAndServiceTableBodyProps {
  partner: Partner;
  handleChange: (property: string, value: string, idx: number) => void;
}

export const EquipmentAndServiceTableBody = (
  props: EquipmentAndServiceTableBodyProps,
): JSX.Element => {
  const { handleChange, partner } = props;

  return (
    <TableBody>
      {(partner.categoryPrimary === 194 ||
        partner.categoryPrimary === 197 ||
        partner.categories.includes(197) ||
        partner.categories.includes(194)) && (
        <EquipmentAndServiceRows
          handleChange={handleChange}
          property='fitnessServices'
          groupHeader='Fitness'
          disabled={partner.readonly}
        />
      )}

      <EquipmentAndServiceRows
        handleChange={handleChange}
        property='wellnessServices'
        groupHeader='Wellness'
        disabled={partner.readonly}
      />

      <EquipmentAndServiceRows
        handleChange={handleChange}
        property='services'
        groupHeader='Services'
        disabled={partner.readonly}
      />
    </TableBody>
  );
};
