import { Box, styled } from '@mui/material';

import theme from '@/App/Theme/Theme';

import { withTransientProps } from '../../../../App/Theme/withTransientProps';
import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const CourseIconWrapper = styled(Box, withTransientProps)<{ $isPastCourse: boolean }>`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: ${props =>
    props.$isPastCourse ? FigmaDesignTokens.Grey[500] : theme.palette.primary.main};
  border-radius: 4px;
`;

export const CourseIcon = styled('img')`
  height: 48px;
  width: 48px;
  filter: 'brightness(0) invert(1)';
`;
