import moment from 'moment';
import { flatMap } from 'lodash';
import { EventPerWeek } from '@/Types';

export class Dashboard {
  public getFormattedDateTime(date: string, time: string): string {
    return moment(date + ' ' + time, 'YYYY-MM-DD').format('DD.MM.YYYY, ') + time + ' Uhr';
  }

  public getMaxValue(events: EventPerWeek[]): number {
    return Math.max(...flatMap(events, 'amount'));
  }

  public getHeightOfPerformedEvents(event: EventPerWeek, max: number): number {
    return Math.round((event.amount / max) * 58);
  }
}
