import { BodyTextRegular } from '@/Components';

type SubHeaderProps = {
  title: string;
  color?: string;
  className?: string | { [x: string]: boolean };
};

export const SubHeader = ({ title, color, className }: SubHeaderProps) => (
  <BodyTextRegular textContent={title} customColor={color} className={className} fontWeight={500} />
);
