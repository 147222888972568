import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, Grid } from '@mui/material';
import Footer from '../Footer/Footer';

export interface CenteredLayoutProps {
  children: JSX.Element | JSX.Element[];
  leftNavigation: JSX.Element;
}

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '1090px',
    width: '100%',
    margin: '0 42px',
    minHeight: '100vh',
  },
}));

export const TwoRowsWithNavigationLayout = (props: CenteredLayoutProps): JSX.Element => {
  const { children, leftNavigation } = props;
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      {leftNavigation}
      <Box className={classes.right}>
        <Grid container>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>

        <Footer />
      </Box>
    </Box>
  );
};
