import { useLocation, Link } from 'react-router-dom';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import SidebarNavItemStyles from '../Styles/SidebarNavItem.styles';

type LeftNavigationItemProps = {
  title: string;
  href: string;

  needsToBeTranslated: boolean;
  isGroupHeader?: boolean;
  defaultMessage?: string;
  navLevel?: 'group-header' | 'subheader' | 'nav-item';
};

export const MenuNavigationItem = ({
  title,
  href,
  isGroupHeader,
  needsToBeTranslated,
  defaultMessage,
  navLevel,
}: LeftNavigationItemProps) => {
  const { classes } = SidebarNavItemStyles();

  const location = useLocation();
  const isActive = !isGroupHeader
    ? location.pathname === href
    : location.pathname.slice(0, location.pathname.lastIndexOf('/')) ===
      href.slice(0, href.lastIndexOf('/'));
  const isBottomLevel = navLevel === 'nav-item';

  return (
    <Link
      to={href}
      className={clsx(classes.navigationListItemContainer, {
        [classes.navigationListItemContainerActive]: isActive,
        [classes.subItemContainer]: isBottomLevel && isActive,
        [classes.subItemLink]: isBottomLevel,
      })}>
      <Box
        className={clsx(classes.navigationListItem, {
          [classes.navigationListItemActive]: isActive,
          [classes.subItem]: isBottomLevel,
        })}>
        {needsToBeTranslated ? (
          <FormattedMessage id={title} defaultMessage={defaultMessage} />
        ) : (
          title
        )}
      </Box>
    </Link>
  );
};
