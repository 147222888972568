import { Auth } from 'aws-amplify';
import { Dispatch } from 'react';
import { DeeplService } from '../I18n/Deepl.service';
import { HttpService } from '@/Services';
import { AnyStateAction } from '@/Context';

export class DeeplServiceFactory {
  public getInstance(dispatch: Dispatch<AnyStateAction>): DeeplService {
    return new DeeplService(new HttpService(Auth, dispatch));
  }
}
