import { createStyles } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../../design/design-tokens.json';

export const TextFieldPhotoStyles = (component: any) =>
  withStyles(component, theme =>
    createStyles({
      root: {
        width: '100%',
        '& .MuiInputBase-root': {},
        '& .MuiOutlinedInput-notchedOutline': {
          border: `solid 1.5px ${FigmaDesignTokens.Grey[100]}`,
          borderRadius: '14px',
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: 14,
          color: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-input': {
          padding: '7px 32px 7px 14px',
          height: '24px',
        },
        '& .MuiFilledInput-root.Mui-disabled': {},
        '& .MuiFilledInput-underline:before': {},
        '& .MuiFormLabel-root': {
          fontSize: '14px',
          fontFamily: 'Roboto',
        },
        '& .MuiInputBase-input': {},
        '& .MuiInputBase-input.Mui-disabled': {},
        '& .MuiInputLabel-outlined': {
          color: FigmaDesignTokens.Grey[950],
          transform: 'translate(14px, 12px) scale(1)',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
          transform: 'translate(14px, -6px) scale(1)',
          fontSize: '12px',
          color: theme.palette.primary.main,
        },
      },
    }),
  );
