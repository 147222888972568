import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppContext } from '@/Context';
import { PartnerServiceFactory } from '@/Services';

const useCurrentPartnerIdQuery = () => {
  const { dispatch } = useAppContext();
  const partnerServiceInstance = useMemo(
    () => new PartnerServiceFactory().getInstance(dispatch),
    [dispatch],
  );

  const queryResult = useQuery(
    ['getCurrentPartnerId'],
    async () => await partnerServiceInstance.getCurrentPartnerId(),
    {
      cacheTime: 0,
    },
  );

  return queryResult;
};

export default useCurrentPartnerIdQuery;
