import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useMemo } from 'react';

import useMemoedIntl from '@/Hooks/useMemoedIntl';
import {
  DateSelectionType,
  GetMemberStatsResponseType,
  GetTrainInstrStatsResponseType,
} from '@/Types';

import { CustomDataGrid } from '../../../App/Shared/DataGridTable/Table/DataGridTable';
import StatisticsTableToolbar from './StatisticsTableToolbar';

const CheckInStatsTable = <T extends GetMemberStatsResponseType | GetTrainInstrStatsResponseType>({
  data = [],
  lastColumn,
  dateRange,
  setDateRange,
  isLoading,
}: {
  data?: T['data'];
  setDateRange: (date: DateSelectionType) => void;
  dateRange: DateSelectionType;
  lastColumn: 'checkins' | 'check_out_year_month_day';
  isLoading: boolean;
}) => {
  const { formatMessage } = useMemoedIntl();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'eu_id',
        display: 'flex',
        headerName: formatMessage({
          id: 'view.statistic.check_in_all.table.header.eu_id',
          defaultMessage: 'HF user ID',
        }),
      },
      {
        field: 'company_name',
        flex: 2,
        display: 'flex',
        headerName: formatMessage({
          id: 'view.statistic.check_in_all.table.header.company_name',
          defaultMessage: 'Company name',
        }),
      },
      {
        field: lastColumn,
        flex: 0.8,
        display: 'flex',
        headerName:
          lastColumn === 'checkins'
            ? formatMessage({
                id: 'view.statistic.check_in.table.header.checkins',
                defaultMessage: 'Number of checkins',
              })
            : formatMessage({
                id: 'view.statistic.check_in.table.header.check_out_year_month_day',
                defaultMessage: 'Date',
              }),
        type: lastColumn === 'check_out_year_month_day' ? 'date' : undefined,
        valueGetter: (value: GridCellParams<T['data'], 'checkins' | 'check_out_year_month_day'>) =>
          lastColumn === 'checkins' ? value : moment(value.toString()).toDate(),
      },
    ],
    [formatMessage, lastColumn],
  );

  return (
    <CustomDataGrid
      columns={columns}
      rows={data}
      isRowSelectable={() => false}
      loading={isLoading}
      columnHeaderHeight={48}
      getRowId={row => row.id}
      autoHeight
      pagination
      hideFooter={!data.length}
      slots={{
        toolbar: StatisticsTableToolbar,
      }}
      slotProps={{
        toolbar: {
          handleSubmit: setDateRange,
          value: dateRange,
          fieldsToExport: columns,
        },
      }}
    />
  );
};

export default CheckInStatsTable;
