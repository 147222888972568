import { useMemo } from 'react';

import {
  getCategoriesPathname,
  getCheckInOptionsPathname,
  getCommonInformationPathname,
  getDashboardPathname,
} from '@/Mappers';
import { BreadcrumbProps } from '@/Types';

import useCurrentLanguage from '../useCurrentLanguage';
import useMemoedIntl from '../useMemoedIntl';

const useStudioProfileBreadcrumbs = (currentTab: number): BreadcrumbProps[] => {
  const { formatMessage } = useMemoedIntl();
  const currentLanguage = useCurrentLanguage();

  const dynamicBreadcrumbs = useMemo(() => {
    const staticBreadcrumbs = [
      {
        title: formatMessage({ id: 'view.dashboard', defaultMessage: 'Dashboard' }),
        href: getDashboardPathname(currentLanguage),
      },
      {
        title: formatMessage({
          id: 'view.common_information',
          defaultMessage: 'General information',
        }),
        href: getCommonInformationPathname(currentLanguage),
      },
    ];

    if (currentTab === 1) {
      staticBreadcrumbs.push({
        title: formatMessage({
          id: 'view.common_information.check_in_options',
          defaultMessage: 'Check-in options',
        }),
        href: getCheckInOptionsPathname(currentLanguage),
      });
    } else if (currentTab === 2) {
      staticBreadcrumbs.push({
        title: formatMessage({
          id: 'view.common_information.categories',
          defaultMessage: 'Categories',
        }),
        href: getCategoriesPathname(currentLanguage),
      });
    } else {
      staticBreadcrumbs.push({
        title: formatMessage({
          id: 'view.common_information.address_and_contact',
          defaultMessage: 'Address & Contact',
        }),
        href: getCommonInformationPathname(currentLanguage),
      });
    }
    return staticBreadcrumbs;
  }, [formatMessage, currentTab, currentLanguage]);

  return dynamicBreadcrumbs;
};

export default useStudioProfileBreadcrumbs;
