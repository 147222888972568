import WarningIcon from '@/Static/Icons/atoms-symbols-alert-warning.svg';
import { Container, Icon, Header, SubHeader } from './NoResultsOverlay.styles';

type NoResultsOverlayProps = {
  header: string;
  subHeader: string;
};

export const NoResultsOverlay = ({ header, subHeader }: NoResultsOverlayProps) => (
  <Container>
    <Icon src={WarningIcon} />
    <Header variant='h2'>{header}</Header>
    <SubHeader variant='body1'>{subHeader}</SubHeader>
  </Container>
);
