import { Close, Info } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import { CasStatusType, CheckInCheckoutEventType } from '@/Types';
import { combineStrings, fromUnixToTime } from '@/Utils';

import FigmaDesignTokens from '../../../design/design-tokens.json';
import AnimatedCircle from '../AnimatedCircle';
import useStyles from './CheckinCheckoutToast.styles';

const CheckinCheckoutToast = ({
  data: {
    firstname,
    lastname,
    euPictureUrl,
    company,
    euStatus,
    checkInTimestamp,
    checkOutTimestamp,
    errors,
    offline,
    failed,
  },
  id,
  type,
  duration,
  createdAt,
  variant = 'big',
}: {
  data: CheckInCheckoutEventType['data'];
  id?: string;
  type: CheckInCheckoutEventType['name'];
  duration?: number;
  createdAt?: string;
  variant?: 'big' | 'small';
}) => {
  const { formatMessage, formatTime } = useIntl();
  const theme = useTheme();

  // we need themes here
  const backgroundColor = useMemo(() => {
    if (failed) {
      return FigmaDesignTokens.Red[50];
    }

    if (offline) {
      return FigmaDesignTokens.Orange[50];
    }

    if (type === 'checkIn') {
      return FigmaDesignTokens.Green[50];
    }

    return FigmaDesignTokens.Grey[200];
  }, [failed, type, offline]);

  const fillColor = useMemo(() => {
    if (failed) {
      return FigmaDesignTokens.Red[600];
    }

    if (type === 'checkIn') {
      return FigmaDesignTokens.Green[500];
    }

    return theme.palette.primary.main;
  }, [failed, theme.palette.primary.main, type]);

  const statusToColors = useCallback(
    (status: CasStatusType) => {
      switch (status.toLowerCase()) {
        case 'a':
          return FigmaDesignTokens.Green[500];
        case 'e':
          return FigmaDesignTokens.Orange[500];
        case 'l':
        case 'd':
          return FigmaDesignTokens.Red[600];
        case 'n':
          return theme.palette.primary.main;
        case 'i':
        case 'r':
        default:
          return FigmaDesignTokens.Grey[500];
      }
    },
    [theme.palette.primary.main],
  );

  const { classes } = useStyles({
    backgroundColor,
    fillColor,
    variant,
    statusTagColor: statusToColors(euStatus || 'i'),
  })();

  const handleClose = () => toast.remove(id);

  const titleMessage = useMemo(() => {
    if (type === 'checkIn') {
      if (errors.length > 0) {
        return <FormattedMessage id='alert.live-checkin.failed' defaultMessage='Check-In Failed' />;
      }

      if (offline) {
        return (
          <FormattedMessage id='alert.live-checkin.offline' defaultMessage='Check-In Offline' />
        );
      }

      return (
        <FormattedMessage id='alert.live-checkin.successful' defaultMessage='Check-In Successful' />
      );
    }

    if (type === 'checkOut') {
      if (errors.length > 0) {
        return (
          <FormattedMessage id='alert.live-checkout.failed' defaultMessage='Check-Out Failed' />
        );
      }

      if (offline) {
        return (
          <FormattedMessage id='alert.live-checkout.offline' defaultMessage='Check-Out Offline' />
        );
      }

      return (
        <FormattedMessage
          id='alert.live-checkout.successful'
          defaultMessage='Check-Out Successful'
        />
      );
    }

    return null;
  }, [errors, offline, type]);

  const subTitleMessage = useMemo(() => {
    if (type === 'checkIn' && offline && !errors.length && checkInTimestamp) {
      return (
        <FormattedMessage
          id='alert.live-checkin.offline_sub'
          defaultMessage='Check-In from {time} received successfully'
          values={{
            time: formatTime(fromUnixToTime(checkInTimestamp)),
          }}
        />
      );
    }

    if (type === 'checkOut' && offline && !errors.length && checkOutTimestamp) {
      return (
        <FormattedMessage
          id='alert.live-checkout.offline_sub'
          defaultMessage='Check-Out from {time} received successfully'
          values={{
            time: formatTime(fromUnixToTime(checkOutTimestamp)),
          }}
        />
      );
    }

    return null;
  }, [checkInTimestamp, checkOutTimestamp, errors.length, formatTime, offline, type]);

  const translationMessages: Record<CasStatusType, MessageDescriptor> = useMemo(
    () =>
      defineMessages({
        n: {
          id: 'cas_status.new',
          defaultMessage: 'New',
        },
        e: {
          id: 'cas_status.eligible',
          defaultMessage: 'Eligible',
        },
        a: { id: 'cas_status.active', defaultMessage: 'Active' },
        i: {
          id: 'cas_status.inactive',
          defaultMessage: 'Inactive',
        },
        d: {
          id: 'cas_status.deleted',
          defaultMessage: 'Deleted',
        },
        l: {
          id: 'cas_status.locked',
          defaultMessage: 'Locked',
        },
        r: {
          id: 'cas_status.recess',
          defaultMessage: 'Recess',
        },
      }),
    [],
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.infoContainer}>
          <Info className={classes.infoIcon} />
          <div className={classes.messageContainer}>
            {titleMessage}
            {subTitleMessage ? <div className={classes.subTitle}>{subTitleMessage}</div> : null}
          </div>
        </div>
        <div className={classes.closeContainer}>
          <button className={classes.closeButton} onClick={handleClose}>
            <Close style={{ width: 24, height: 24 }} />
            <span className={classes['sr-only']}>Close</span>
          </button>
        </div>
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.imageContainer}>
          <img src={euPictureUrl} alt='' className={classes.image} />
        </div>

        <div className={classes.infoTextContainer}>
          <div className={classes.boldText}>{combineStrings(firstname, lastname)}</div>
          <div>{company}</div>
          {euStatus ? (
            <div className={classes.statusTag}>
              {formatMessage(translationMessages[euStatus.toLowerCase() as CasStatusType])}
            </div>
          ) : null}
          {duration && createdAt ? (
            <div className={classes.circleContainer}>
              <AnimatedCircle
                duration={duration}
                createdAt={createdAt}
                fillColor={fillColor}
                width={30}
                height={30}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CheckinCheckoutToast;
