import { Box, styled, Typography } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 146px;
`;

export const Icon = styled('img')`
  width: 44px;
  height: 44px;
`;

export const Header = styled(Typography)`
  margin-top: 18px;
`;

export const SubHeader = styled(Typography)`
  font-size: 14px;
  font-family: 'Roboto-Regular';
  margin-top: 2px;
`;
