import { Grid } from '@mui/material';
import { Top } from './Top';
import { Stats } from './Stats';

import { StatsData } from '@/Types';

type TopAndStatsProps = {
  stats: StatsData;
};

export const TopAndStats = (props: TopAndStatsProps): JSX.Element => {
  const { stats } = props;

  return (
    <Grid container>
      <Grid item xs={8}>
        {stats.top3Events.length > 0 && <Top stats={stats} />}
      </Grid>
      <Grid item xs={4}>
        {stats.performedEvents > 0 && <Stats stats={stats} />}
      </Grid>
    </Grid>
  );
};
