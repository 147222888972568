import { Box, Grid } from '@mui/material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  DisplayTextAccent,
  LineDivider,
  MainHeadline,
  RegularTextAccent,
} from '@/Components';
import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';
import { getNewCoursePathname } from '@/Mappers';
import { ReactComponent as AddIcon } from '@/Static/Icons/bluePlusIcon.svg';

import { useEventHeaderDashStyles } from './EventHeader.styles';

export const EventListHeaderDash = ({ showAdd = false }: { showAdd?: boolean }) => {
  const { classes } = useEventHeaderDashStyles();
  const currentLanguage = useCurrentLanguage();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const handleNewCourseForm = useCallback(() => {
    navigate(getNewCoursePathname(currentLanguage));
  }, [navigate, currentLanguage]);

  return (
    <Grid item xs={12} container justifyContent='space-between'>
      <Box>
        <DisplayTextAccent
          textContent={formatMessage({
            id: 'view.courses_and_events.events.subheader.course_manager',
            defaultMessage: 'Course Manager',
          })}
        />
        <MainHeadline
          textContent={formatMessage({
            id: 'view.courses_and_events.events.header.your_courses_and_events',
            defaultMessage: 'Your courses & dates',
          })}
        />
        <LineDivider verticalSpace={28} />
        {showAdd && (
          <RegularTextAccent
            textContent={formatMessage({
              id: 'view.courses_and_events.events.table_header.your_next_events',
              defaultMessage: 'Your 6 next dates',
            })}
          />
        )}
      </Box>

      {showAdd && (
        <Button
          variant='contained'
          onClick={handleNewCourseForm}
          icon={<AddIcon />}
          className={classes.addNewCourseBtn}>
          {formatMessage({
            id: 'view.courses_and_events.events.cta_add_new_course',
            defaultMessage: 'Create new course',
          })}
        </Button>
      )}
    </Grid>
  );
};
