import * as Yup from 'yup';
import { IntlShape } from 'react-intl/src/types';

export const InviteUserValidation = (intl: IntlShape) =>
  Yup.object().shape({
    email: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.forgot_password.stepper.step.provide_existing_email.error.email.required',
          defaultMessage: 'Please enter a valid e-mail address.',
        }),
      )
      .email(
        intl.formatMessage({
          id: 'form.forgot_password.stepper.step.provide_existing_email.error.email.type',
          defaultMessage: 'Invalid format.',
        }),
      ),
    name: Yup.string().required(
      intl.formatMessage({
        id: 'form.invite_user.error.given_name.required',
        defaultMessage: 'Please enter your first name.',
      }),
    ),
    surname: Yup.string().required(
      intl.formatMessage({
        id: 'form.invite_user.error.name.required',
        defaultMessage: 'Please enter your last name.',
      }),
    ),
  });
