import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const useEventListHintStyles = makeStyles()(theme => ({
  root: {
    padding: '20px 17px 20px 17px',
    borderRadius: 10,
    backgroundColor: FigmaDesignTokens.Grey[100],
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
  },
  descriptionText: {
    marginTop: 8,
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.11,
    color: FigmaDesignTokens.Grey[950],
  },
  scheduleBtn: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.83,
    letterSpacing: 0.34,
    color: theme.palette.primary.dark,
    borderRadius: 18,
    border: `solid 1px ${theme.palette.primary.dark}`,
    padding: '4px 21px',
    minWidth: 160,
  },
}));
