import { Grid } from '@mui/material';
import { ConnectedFocusError } from 'focus-formik-error';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { BodyTextSmall, Button } from '@/Components';
import { DelayModalTypes, useAppContext } from '@/Context';
import { useConfirmDialog } from '@/Hooks/useConfirmDialog';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import useIsFirstTranslation from '@/Hooks/useIsFirstTranslation';
import useReadStorage from '@/Hooks/useReadStorage';
import { OpeningHourMap } from '@/Mappers';
import { useUpdatePartnerDataMutation } from '@/Queries';
import { DeeplServiceFactory } from '@/Services';
import { LanguageType, OpeningHoursPartial, Partner } from '@/Types';
import { getTargetLanguage, OpeningHoursFormValidation } from '@/Utils';
import { CrazyUseRefCallbackStuffTypes, isOpeningHoursFormRef } from '@/Utils/formRefTypeguards';
import handleCatchError from '@/Utils/handleCatchError';

import { WeekdayRow } from './OpeningHours/WeekdayRow';

type OpeningHoursFormProps = {
  casPublicId: Partner['casPublicId'];
  partner: Partner;
  refCallback: (ref: CrazyUseRefCallbackStuffTypes, tabNumber: number) => void;
  handlePrimaryCtaDisabled: (value: boolean) => void;
  setOpenToastMessage: (value: boolean) => void;
};

export const OpeningHoursForm = ({
  partner,
  refCallback,
  casPublicId,
  handlePrimaryCtaDisabled,
  setOpenToastMessage,
}: OpeningHoursFormProps) => {
  const {
    dispatch,
    state: { delayModal },
  } = useAppContext();

  const deeplService = useMemo(() => new DeeplServiceFactory().getInstance(dispatch), [dispatch]);
  const intl = useIntl();
  const pageLanguage = useCurrentLanguage();
  const currentLanguage =
    useReadStorage<LanguageType>('vpp_translation_content_language', 'LOCAL_STORAGE') ||
    pageLanguage;
  const targetLanguage = getTargetLanguage(currentLanguage);

  const handleConfirmDialog = useConfirmDialog();
  const { isFirstTranslation, setIsFirstTranslation } = useIsFirstTranslation();
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const openingHourService = useMemo(() => new OpeningHourMap(), []);

  const formikRef = useRef<FormikProps<OpeningHoursPartial>>(null);

  const [initialState] = useState<OpeningHoursPartial>({
    holidays: partner.holidays,
    openingHours:
      partner.openingHours.length > 0
        ? partner.openingHours
        : openingHourService.getInitialOpenedOpeningHoursState(),
    showOpeningTimes: partner.showOpeningTimes,
  });

  const handleDisable = useCallback(() => {
    formikRef.current?.setFieldValue(
      'openingHours',
      openingHourService.getClosedOpeningHoursState(),
    );
    formikRef.current?.setFieldValue('showOpeningTimes', false);
    handleConfirmDialog(true);
    handlePrimaryCtaDisabled(false);
  }, [formikRef, handleConfirmDialog, handlePrimaryCtaDisabled, openingHourService]);

  const handleEnable = useCallback(() => {
    formikRef.current?.setFieldValue(
      'openingHours',
      openingHourService.getInitialOpenedOpeningHoursState(),
    );
    formikRef.current?.setFieldValue('showOpeningTimes', true);
    handleConfirmDialog(true);
    handlePrimaryCtaDisabled(false);
  }, [handleConfirmDialog, handlePrimaryCtaDisabled, openingHourService]);

  const handleNotesTranslation = useCallback(
    async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (!currentLanguage) {
        return;
      }

      let translation = '';
      if (e.target.value) {
        const result = await deeplService.getTranslation(
          e.target.value,
          targetLanguage,
          currentLanguage,
        );

        translation = deeplService.getTranslationFromResponse(result.data, currentLanguage);
      }

      if (deeplService.isQualifiedToTranslate(currentLanguage, targetLanguage)) {
        const fieldName = 'holidays.' + targetLanguage;
        formikRef.current?.setFieldValue(fieldName, translation);
        handleConfirmDialog(true);
      }

      if (!isFirstTranslation) {
        setIsFirstTranslation(true);
      }
    },
    [
      deeplService,
      currentLanguage,
      targetLanguage,
      isFirstTranslation,
      handleConfirmDialog,
      setIsFirstTranslation,
    ],
  );

  const handleTranslationChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      handlePrimaryCtaDisabled(false);
      formikRef.current?.handleChange(e);
    },
    [handlePrimaryCtaDisabled],
  );

  useEffect(() => {
    if (isOpeningHoursFormRef(formikRef) && formikRef.current) {
      refCallback(formikRef, 1);
    }
  }, [refCallback, formikRef]);

  const { mutateAsync: updatePartnerData } = useUpdatePartnerDataMutation();

  const handleSubmit = async (values: OpeningHoursPartial) => {
    if (!delayModal.notShowProfileModal) {
      dispatch({ type: DelayModalTypes.SET_OPEN, payload: { open: true } });
    }

    const formValues = {
      openingHours: values.openingHours,
      holidays: values.holidays,
      showOpeningTimes: values.showOpeningTimes,
    };

    try {
      await updatePartnerData({ casPublicId, updatedData: formValues });

      handlePrimaryCtaDisabled(true);
      handleConfirmDialog(false);
      setOpenToastMessage(true);
    } catch (error) {
      handleCatchError(error);
    }
  };

  const validateOpeningHours = useCallback(
    values => {
      const initialStateStringify = JSON.stringify(initialState);
      const currentStateStringify = JSON.stringify(values);

      if (initialStateStringify !== currentStateStringify) {
        handleConfirmDialog(true);
        handlePrimaryCtaDisabled(false);
      }
    },
    [initialState, handleConfirmDialog, handlePrimaryCtaDisabled],
  );

  if (!currentLanguage) {
    return null;
  }

  return (
    <Formik
      innerRef={formikRef}
      validateOnChange={validateAfterSubmit}
      enableReinitialize={false}
      validateOnBlur={false}
      initialValues={initialState}
      validate={validateOpeningHours}
      validationSchema={() =>
        OpeningHoursFormValidation({
          intl,
          isSetTriggerValidate: !validateAfterSubmit ? setValidateAfterSubmit : undefined,
        })
      }
      onSubmit={handleSubmit}>
      {({ values: { showOpeningTimes, openingHours } }) => (
        <Form>
          <ConnectedFocusError />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContentContainer>
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <FormHeader
                      title={intl.formatMessage({
                        id: 'form.description_and_opening_hours.header.opening_hours',
                        defaultMessage: 'Opening hours',
                      })}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={4}>
                  <Grid item xs={8}>
                    <BodyTextSmall
                      textContent={intl.formatMessage({
                        id: 'form.description_and_opening_hours.description.opening_hours',
                        defaultMessage: `Please enter your opening hours and make sure that you keep them up to date.
                              If you do not want to display opening hours, you can remove them at any time.`,
                      })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      disabled={partner.readonly}
                      onClick={() => (showOpeningTimes ? handleDisable() : handleEnable())}
                      variant={showOpeningTimes ? 'outlined' : 'contained'}>
                      {showOpeningTimes
                        ? intl.formatMessage({
                            id: 'form.description_and_opening_hours.button.no_opening_hours',
                            defaultMessage: 'No opening hours ads',
                          })
                        : intl.formatMessage({
                            id: 'form.description_and_opening_hours.button.add_opening_hours',
                            defaultMessage: 'Add opening hours',
                          })}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    {showOpeningTimes &&
                      openingHours.map((openingHour, idx) => (
                        <WeekdayRow
                          key={idx}
                          openingHour={openingHour}
                          idx={idx}
                          handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
                          openingHourService={openingHourService}
                          setValidateAfterSubmit={setValidateAfterSubmit}
                        />
                      ))}
                  </Grid>
                </Grid>
              </ContentContainer>
            </Grid>

            <Grid item xs={12}>
              <ContentContainer>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FormHeader
                      title={intl.formatMessage({
                        id: 'form.description_and_opening_hours.header.opening_hours',
                        defaultMessage: 'Opening hours',
                      })}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      disabled={partner?.readonly}
                      onChange={handleTranslationChange}
                      onBlur={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                        handleNotesTranslation(e)
                      }
                      component={FormikTextField}
                      multiline
                      rows={4}
                      variant='outlined'
                      name={`holidays.${currentLanguage}`}
                      type='text'
                      label={`${intl.formatMessage({
                        id: 'form.description_and_opening_hours.holidays',
                        defaultMessage: 'Special opening hours on holidays',
                      })} | ${currentLanguage.toUpperCase()}`}
                    />
                  </Grid>
                </Grid>
              </ContentContainer>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
