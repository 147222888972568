import { Box, styled } from '@mui/material';

export const HintContainer = styled(Box)`
  margin-top: 50px;
  width: 254px;
`;

export const TextFieldsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
