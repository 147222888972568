import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';

import 'react-image-crop/dist/ReactCrop.css';
import { Field, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { SubHeader } from '@/App/Shared/Form/Components/Header/SubHeader';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';

import { InfoMessage, Button } from '@/Components';
import { SelectOption, Event } from '@/Types';

import CloseIcon from '@/Static/Icons/atoms-symbols-category-close-big.svg';
import { ReactComponent as Arrow } from '@/Static/Icons/atoms-symbols-arrow-light.svg';

import { useTimeZoneModalStyles } from './Styles/TimeZomeModal.styles';

export const TimeZoneModal = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const { classes } = useTimeZoneModalStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const { values, setFieldValue } = useFormikContext<Event>();

  const [timeZoneLocalValue, setTimeZoneLocalValue] = useState<string>(
    values.appointments.timeZone,
  );

  const timeZoneOptions = [
    {
      value: 'Europe/Berlin',
      displayValue: formatMessage({
        id: 'form.event.timezone.timezone.summer_time',
        defaultMessage: 'Central European Summer Time (CEST)',
      }),
    },
    {
      value: 'Europe/New York',
      displayValue: formatMessage({
        id: 'form.event.timezone.timezone.standard_time',
        defaultMessage: 'Eastern Standard Time (EST)',
      }),
    },
  ];

  const handleSave = useCallback(() => {
    setFieldValue('appointments.timeZone', timeZoneLocalValue);
    handleClose();
  }, [setFieldValue, timeZoneLocalValue, handleClose]);

  return (
    <Box>
      <Box className={classes.modalFieldWrapper}>
        <Typography variant='body2' className={classes.modalFieldHeader}>
          <FormattedMessage id='form.event.timezone.timezone.label' defaultMessage='Time zone' />:
        </Typography>
        <Typography variant='body2' className={classes.modalFieldValue}>
          {
            timeZoneOptions.find(option => option.value === values.appointments.timeZone)
              ?.displayValue
          }
        </Typography>
        <Button variant='text' className={classes.modalFieldBtn} onClick={handleOpen}>
          {formatMessage({
            id: 'form.event.timezone.timezone.cta',
            defaultMessage: 'Change time zone:',
          })}
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        PaperProps={{
          style: {
            padding: '40px 50px 70px 40px',
            maxWidth: 800,
            borderRadius: 10,
            boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.1)',
          },
        }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box className={classes.dialogTitle}>
              <DialogTitle>
                <Box>
                  <FormHeader
                    title={formatMessage({
                      id: 'form.event.timezone.header',
                      defaultMessage: 'Change time zone',
                    })}
                  />
                  <SubHeader
                    title={formatMessage({
                      id: 'form.event.timezone.subheader',
                      defaultMessage:
                        'You can change the time zone if your courses take place in a different time zone.',
                    })}
                    className={classes.additionalTimeZoneTitle}
                  />
                </Box>
              </DialogTitle>
              <IconButton aria-label='close' onClick={handleClose} className={classes.closeButton}>
                <img alt='' src={CloseIcon} />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={FormikTextField}
                    variant='outlined'
                    value={timeZoneLocalValue}
                    name='appointments.timeZone'
                    onChange={(value: any) => {
                      setTimeZoneLocalValue(value.target.value);
                    }}
                    label={formatMessage({
                      id: 'form.event.timezone.field.timezone.label',
                      defaultMessage: 'Select time zone',
                    })}
                    select
                    SelectProps={{
                      IconComponent: () => (
                        <Arrow className='MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined' />
                      ),
                    }}>
                    {timeZoneOptions.map((type: SelectOption, k: number) => (
                      <MenuItem key={k} value={type.value}>
                        {type.displayValue}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={6}>
              <InfoMessage
                title={formatMessage({
                  id: 'form.event.timezone.hint.timezone.header',
                  defaultMessage: 'Info time zones',
                })}
                description={formatMessage({
                  id: 'form.event.hint.series_events.description',
                  defaultMessage:
                    'You can create a single appointment or a series of appointments. To create a series of appointments, simply change the appointment type to series appointment.',
                })}
                type='information'
              />
            </Grid>

            <Grid item xs={6}>
              <DialogActions className={classes.dialogActions}>
                <Button autoFocus className={classes.cta} onClick={handleSave}>
                  {formatMessage({
                    id: 'form.photo_and_video.photo_upload.image.cta.submit_edit_image_detail',
                    defaultMessage: 'Apply changes',
                  })}
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
};
