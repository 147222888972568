import {
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import { LineDivider } from '@/Components';
import MapPin from '@/Static/Icons/atoms-symbols-map-pin.svg';

import FigmaDesignTokens from '../../design/design-tokens.json';

type MapNavigationProps = {
  lat: number;
  lng: number;
  handleChange: (lat: number, lng: number) => void;
};

const useStyles = makeStyles()(() => ({
  mapNavWrapper: {
    paddingTop: 11,
  },
  manNavLabelWrapper: {
    paddingRight: 30,
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  labelText: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: 0.18,
    color: FigmaDesignTokens.Grey[950],
  },
  button: {
    padding: 0,
    minWidth: 'unset',
    backgroundColor: FigmaDesignTokens.Blue[100],
  },
}));

export const MapNavigation = (props: MapNavigationProps): JSX.Element => {
  const { lat, lng, handleChange } = props;
  const { classes } = useStyles();
  const intl = useIntl();

  const handleUp = useCallback(() => {
    const updatedLat = lat + 0.00001;

    handleChange(updatedLat, lng);
  }, [lat, lng, handleChange]);

  const handleDown = useCallback(() => {
    const updatedLat = lat - 0.00001;

    handleChange(updatedLat, lng);
  }, [lat, lng, handleChange]);

  const handleLeft = useCallback(() => {
    const updatedLong = lng - 0.00001;

    handleChange(lat, updatedLong);
  }, [lat, lng, handleChange]);

  const handleRight = useCallback(() => {
    const updatedLong = lng + 0.00001;

    handleChange(lat, updatedLong);
  }, [lat, lng, handleChange]);

  return (
    <Grid item container alignItems='center' spacing={1} className={classes.mapNavWrapper}>
      <LineDivider horizontalSpace='inherit' />
      <Box className={classes.manNavLabelWrapper}>
        <img alt='' src={MapPin} />
        <Typography variant='body2' className={classes.labelText}>
          {intl.formatMessage({
            id: 'map_navigation.move_pin.text',
            defaultMessage: 'Position pin ',
          })}
        </Typography>
      </Box>
      <Grid item>
        <Button color='primary' className={classes.button} onClick={handleLeft}>
          <KeyboardArrowLeft fontSize='medium' />
        </Button>
      </Grid>

      <Grid item>
        <Button color='primary' className={classes.button} onClick={handleRight}>
          <KeyboardArrowRight fontSize='medium' onClick={handleUp} />
        </Button>
      </Grid>

      <Grid item>
        <Button color='primary' className={classes.button} onClick={handleUp}>
          <KeyboardArrowUp fontSize='medium' onClick={handleUp} />
        </Button>
      </Grid>

      <Grid item>
        <Button color='primary' className={classes.button} onClick={handleDown}>
          <KeyboardArrowDown fontSize='medium' onClick={handleDown} />
        </Button>
      </Grid>
    </Grid>
  );
};
