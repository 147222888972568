import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const useAddWithIconAndTitleHourStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '20px',
    boxSizing: 'unset',
    gap: 8,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    width: 26,
    height: 26,
    cursor: 'pointer',
  },
  disabled: {
    pointerEvents: 'none',
  },
}));

export const useDeleteRowStyles = makeStyles()(theme => ({
  root: {
    width: 26,
    height: 26,
    margin: '20px 0 16px 10px',
    backgroundColor: theme.palette.warning.main,
    padding: 4,
    borderRadius: 14,
    display: 'flex',
    fill: FigmaDesignTokens.White,
    flexDirection: 'row',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const usePlusConnectorStyles = makeStyles()(() => ({
  root: {
    marginRight: 8,
    width: 18,
  },
}));

export const useOpeningHourSelectIconStyles = makeStyles()(() => ({
  root: {
    fill: FigmaDesignTokens.Blue[950],
  },
}));

export const useRemoveOpeningHourStyles = makeStyles()(theme => ({
  root: {
    width: 26,
    height: 26,
    margin: '20px 0 16px 10px',
    padding: '11px 7px 10px',
    backgroundColor: theme.palette.warning.main,
    borderRadius: 14,
    display: 'flex',
    flexDirection: 'row',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    width: 12,
    height: 5,
    backgroundColor: FigmaDesignTokens.White,
  },
  disabled: {
    pointerEvents: 'none',
  },
}));

export const useWeekdayRowStyles = makeStyles()(theme => ({
  rowOdd: {
    backgroundColor: FigmaDesignTokens.Grey[50],
  },
  rowEven: {
    backgroundColor: theme.palette.background.default,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 96,
    borderRadius: 4,
    padding: '14px 20px',
  },
  weekday: {
    width: 84,
  },
  opened: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 145,
  },
  hoursContainer: {
    display: 'flex',
    marginLeft: 45,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  hoursItemContainer: {
    display: 'flex',
    flex: 1,
    gap: 8,
    alignItems: 'center',
    '& label.MuiInputLabel-outlined': {
      backgroundColor: theme.palette.background.default,
    },
    '& p': {
      position: 'absolute',
      width: 300,
      bottom: -18,
    },
  },
  additionalTimes: {
    marginLeft: 20,
  },
}));
