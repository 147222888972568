import { Button, styled } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const ActionButton = styled(Button)`
  padding: 0;
  width: 32px;
  min-width: 32px;
`;
