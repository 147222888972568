import { CourseTypes } from '@/Types';
import { StyleCategory } from '@/Views/MarketingMaterial/Components/TableCell.tsx/TableCell.styles';

export interface CourseTypeCellProps {
  value?: CourseTypes;
  isCancelled?: boolean;
}

export const CourseTypeCell = ({ value, isCancelled = false }: CourseTypeCellProps) => (
  <StyleCategory $cancelled={isCancelled}>{value}</StyleCategory>
);
