const NAMESPACE = 'UI';
const actionType = (type: string) => `${NAMESPACE}_${type}`;

export const uiConstants = {
  SHOW_ALERT: actionType('SHOW_ALERT'),
  HIDE_ALERT: actionType('HIDE_ALERT'),

  SHOW_SPINNER_OVERLAY: actionType('SHOW_SPINNER_OVERLAY'),
  HIDE_SPINNER_OVERLAY: actionType('HIDE_SPINNER_OVERLAY'),

  PUSH_MODAL: actionType('PUSH_MODAL'),
  POP_MODAL: actionType('POP_MODAL'),
};
