export class ErrorMap {
  public scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  public scrollToErrorField(fieldErrorName: string) {
    const element = document.querySelector(`input[name='${fieldErrorName}']`);
    if (!element) return;

    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
