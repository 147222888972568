import * as Yup from 'yup';
import { IntlShape } from 'react-intl/src/types';

export const ManualCheckinValidation = (intl: IntlShape) =>
  Yup.object().shape({
    totp: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'form.manual_checkin.error.checkinId.required',
          defaultMessage: 'CheckIn ID is required',
        }),
      )
      .min(
        6,
        intl.formatMessage({
          id: 'form.manual_checkin.error.checkinId.must_be_sixchars',
          defaultMessage: 'CheckIn ID must be 6 characters long',
        }),
      )
      .max(
        6,
        intl.formatMessage({
          id: 'form.manual_checkin.error.checkinId.must_be_sixchars',
          defaultMessage: 'CheckIn ID must be 6 characters long',
        }),
      )
      .matches(
        /^\d+$/,
        intl.formatMessage({
          id: 'form.manual_checkin.error.checkinId.only_numbers',
          defaultMessage: 'CheckIn ID only contains numbers',
        }),
      ),
    euId: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.manual_checkin.error.userId.required',
          defaultMessage: 'User ID is required',
        }),
      )
      .min(
        8,
        intl.formatMessage({
          id: 'form.manual_checkin.error.checkinId.min_numbers',
          defaultMessage: 'Customer number must be 8 characters long',
        }),
      )
      .max(
        8,
        intl.formatMessage({
          id: 'form.manual_checkin.error.checkinId.max_numbers',
          defaultMessage: 'Customer number must be 8 characters long',
        }),
      )
      .matches(
        /^[0-9]{2}[A-Z0-9]{6}$/,
        intl.formatMessage({
          id: 'form.manual_checkin.error.checkinId.pattern_regex',
          defaultMessage: 'First two characters must be digits(0-9)',
        }),
      ),
  });
