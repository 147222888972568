import { HttpService } from '@/Services';
import { Auth } from 'aws-amplify';
import { Dispatch } from 'react';
import { AnyStateAction } from '@/Context';
import { MarketingMaterialService } from '@/Services';

export class MarketingMaterialsServiceFactory {
  public getInstance(dispatch: Dispatch<AnyStateAction>): MarketingMaterialService {
    return new MarketingMaterialService(new HttpService(Auth, dispatch));
  }
}
