import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/system';

import { GridToolbarWrapper } from '@/App/Shared/DataGridTable/Toolbar/Toolbar.styles';
import { Button } from '@/Components';

import { ReactComponent as BluePlusIcon } from '@/Static/Icons/bluePlusIcon.svg';

type ToolbarProps = {
  handleSubmit?: () => void;
  submitText?: string;
  label?: string;
  cta?: {
    onClick: Function;
    text: string;
  };
};

const useStyles = makeStyles()(() => ({
  ctaWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  label: {
    fontWeight: 600,
  },
}));

const Toolbar = ({ handleSubmit, submitText, label }: ToolbarProps) => {
  const { classes } = useStyles();

  return (
    <GridToolbarWrapper>
      <div className={classes.label}>{label}</div>

      {handleSubmit && submitText && (
        <Box className={classes.ctaWrapper}>
          <Button onClick={handleSubmit} variant='outlined' icon={<BluePlusIcon />}>
            {submitText}
          </Button>
        </Box>
      )}
    </GridToolbarWrapper>
  );
};

export default Toolbar;
