import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';
import theme from '../../Theme/Theme';

const useModalStyles = makeStyles()({
  modalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 515,
    background: FigmaDesignTokens.White,
    padding: '40px 40px 54px 40px',
    borderRadius: 10,
    color: FigmaDesignTokens.Grey[950],
  },
  titleModal: {
    color: FigmaDesignTokens.txt.Brand.brandActive,
    textTransform: 'uppercase',
    fontSize: '20px',
    fontWeight: 900,
    lineHeight: 1.5,
    letterSpacing: '0.4px',
  },
  contentModal: {
    marginTop: 18,
    marginBottom: 22,
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: '0.18px',
  },
  customCheckbox: {
    padding: 0,
    marginRight: 14,
    color: theme.palette.secondary.main,
  },
  checkboxText: {
    fontSize: 12,
    lineHeight: 2.17,
    letterSpacing: '0.34px',
  },
  headerWrapper: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    minWidth: 560,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 24px',
  },
  userInfoWrapper: {
    display: 'flex',
    gap: 20,
  },
  pictureWrapper: {
    flex: 1,

    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: 8,
    },
  },
  InfoWrapper: {
    flex: 4,
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 24,
  },
  clickable: {
    cursor: 'pointer',
  },
  textField: {
    '& .MuiOutlinedInput-root ': {
      height: '53.63px',
    },
    '& label, input': {
      fontSize: 14,
    },
    '& label.Mui-error': {
      fontSize: 14,
    },
    marginBottom: 2,
    marginTop: 4,
  },
});

export default useModalStyles;
