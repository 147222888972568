import { createAsyncThunk } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { RootState } from '@/Store';
import { CognitoUser } from 'amazon-cognito-identity-js';

export const Login = createAsyncThunk<
  CognitoUser,
  { username: string; password: string },
  { state: RootState }
>('auth/login', async ({ username, password }, { rejectWithValue }) => {
  try {
    const user = await Auth.signIn({ username, password });
    return user;
  } catch (err) {
    console.log('error during login', err);
    return rejectWithValue(err);
  }
});
