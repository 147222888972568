import React from 'react';
import DownloadIcon from '@/Static/Icons/atoms-icon-download.svg';
import { styled } from '@mui/material';

type DownloadButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const StyledButton = styled('button')`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DownloadButton = ({ onClick }: DownloadButtonProps) => (
  <StyledButton className='' onClick={onClick} title='Download'>
    <img src={DownloadIcon} alt='' />
  </StyledButton>
);

export default DownloadButton;
