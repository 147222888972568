import { HttpService } from '@/Services';
import { AxiosError, AxiosResponse } from 'axios';
import { SupportData, FilterParam } from '@/Types';

export class SupportService {
  public httpService: HttpService;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  public async getFAQ(
    partnerId: string,
    queryParams?: FilterParam[],
  ): Promise<AxiosResponse<SupportData> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();
    let language = 'de';
    queryParams?.forEach(param => {
      if (param.id === 'language') {
        language = param.value;
      }
    });
    const baseUrl = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${partnerId}/faq?`;
    return await axiosInstance.get(baseUrl + `language=${language}`);
  }
}
