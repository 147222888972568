import { Box } from '@mui/material';
import clsx from 'clsx';
import { useMemo } from 'react';

import { Typography } from '@/Components/Modals/Styles/AdministrationModal.styles';
import { UserAttr } from '@/Types';

import { UserProfileIconStyles } from './UserProfileIcon.styles';

type UserProfileIconProps = {
  userAttr?: UserAttr;
  size: 'small' | 'large';
};

export const UserProfileIcon = ({ userAttr, size }: UserProfileIconProps) => {
  const { classes } = UserProfileIconStyles();

  const initials = useMemo(
    () =>
      [userAttr?.given_name || '', userAttr?.name || '']
        .filter(Boolean)
        .map(name => name.slice(0, 1).toUpperCase())
        .join('')
        .trim(),
    [userAttr],
  );

  return (
    <Box className={classes.userProfileContainer}>
      <Box className={clsx(classes.userProfileCircle, classes[size])}>
        {size === 'large' ? (
          <Typography className={classes.useProfileInitials}>{initials}</Typography>
        ) : (
          <Typography className={classes.userProfileShortcutName}>{initials}</Typography>
        )}
      </Box>
    </Box>
  );
};
