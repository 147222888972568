import { Box, styled, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const Input = styled(TextField)`
  width: 310px;

  & input {
    height: 40px;
    padding: 0 0 0 14px;
    font-family: 'Roboto-Regular';
    font-size: 14px;
    opacity: 1;
    color: ${FigmaDesignTokens.Grey[950]};

    & ::placeholder {
      color: ${FigmaDesignTokens.Grey[500]};
    }
  }

  .MuiOutlinedInput-root {
    padding-right: 12px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${FigmaDesignTokens.Grey[200]};
    opacity: 1;
  }

  & .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${FigmaDesignTokens.bg.brand.brand};
      opacity: 1;
    }

    img:last-child {
      filter: brightness(0) saturate(100%) invert(12%) sepia(57%) saturate(6609%) hue-rotate(219deg)
        brightness(95%) contrast(103%);
    }
  }
`;

export const IconContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledClearIcon = styled('img')`
  filter: none;
`;

export const StyledSearchIcon = styled('img')``;

export const useToolbarStyles = makeStyles()(() => ({
  ctaWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  inviteNewButton: {
    background: FigmaDesignTokens.Blue[100],
    color: FigmaDesignTokens.Grey[950],
    height: '32px',
    '&:hover': {
      backgroundColor: FigmaDesignTokens.Grey[300],
      boxShadow: 'none',
    },
  },
}));
