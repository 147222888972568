import { Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { useAppContext } from '@/Context';
import { RegisterPartnerService, RegisterPartnerServiceFactory } from '@/Services';

import { ConfirmationStep } from '../RegisterPartner/Steps/ConfirmationStep';
import { ChangePasswordStep } from './Steps/ChangePasswordStep';
import { ProvideExistingEmailStep } from './Steps/ProvideExistingEmailStep';

export const ForgotPasswordForm = () => {
  const [step, setStep] = useState(0);
  const { dispatch } = useAppContext();
  const [registerPartnerService, setRegisterPartnerService] = useState<RegisterPartnerService>();
  const [existingEmail, setExistingEmail] = useState<string>();
  const [password, setPassword] = useState<string>();

  useEffect(() => {
    setRegisterPartnerService(new RegisterPartnerServiceFactory().getInstance(dispatch));
  }, [dispatch]);

  const handleStep = useCallback((value: number) => {
    setStep(value);
  }, []);

  const handleExistingEmail = useCallback((email: string) => {
    setExistingEmail(email);
  }, []);

  const handlePassword = useCallback((password: string) => {
    setPassword(password);
  }, []);

  return (
    <>
      {registerPartnerService && (
        <Grid container direction='column' alignItems='center'>
          {step === 0 && (
            <ProvideExistingEmailStep
              handleStep={handleStep}
              handleExistingEmail={handleExistingEmail}
              registerPartnerService={registerPartnerService}
            />
          )}

          {step === 1 && existingEmail && (
            <ChangePasswordStep
              handleStep={handleStep}
              handlePassword={handlePassword}
              registerPartnerService={registerPartnerService}
              email={existingEmail}
            />
          )}
          {step === 2 && (
            <ConfirmationStep
              email={existingEmail}
              password={password}
              isEmailConfirmed
              showHeader
            />
          )}
        </Grid>
      )}
    </>
  );
};
