import { Box, Modal } from '@mui/material';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { Button } from '@/Components';
import { useAppContext } from '@/Context';
import { editAddressDataCourseMapper } from '@/Mappers';
import { EventsServiceFactory } from '@/Services';
import CloseIcon from '@/Static/Icons/atoms-symbols-category-close-big-transparent.svg';
import { EditAddressDataCourseType, EventListItem, Partner } from '@/Types';
import { EditAddressInfoCourseValidation } from '@/Utils';

import { LocationInfoForm } from '../Forms/Event/LocationInfoForm';
import {
  CloseButton,
  useEditAddressInfoCourseModalStyles,
} from './Styles/EditAddressInformationCourseModal.styles';

const take24HoursInMillisec = 24 * 60 * 60 * 1000;

type EditAddressInformationCourseModalProps = {
  show: boolean;
  onHide: () => void;
  values: EventListItem;
  casPublicId: Partner['casPublicId'];
  courseId: string;
  updateEvents: () => void;
};

export const EditAddressInformationCourseModal = ({
  show,
  onHide,
  values,
  casPublicId,
  updateEvents,
}: EditAddressInformationCourseModalProps) => {
  const intl = useIntl();
  const { classes } = useEditAddressInfoCourseModalStyles();

  const { dispatch } = useAppContext();
  const eventsService = new EventsServiceFactory().getInstance(dispatch);

  const eventDate = moment(`${values.date} ${values.time}`, 'YYYY-MM-DD HH:mm');
  const allowToEdit = eventDate.diff(moment()) > take24HoursInMillisec;

  const [initialState] = useState<EditAddressDataCourseType>({
    additionalInformation: values.additionalInformation,
    address: values.onsiteAddress
      ? values.onsiteAddress
      : {
          street: '',
          streetNumber: '',
          city: '',
          postcode: '',
          coordLat: 0,
          coordLong: 0,
        },
  });

  const closeModal = () => {
    onHide();
  };

  const handleFormSubmit = async (formValues: EditAddressDataCourseType) => {
    const response = await eventsService.updateOnsiteEvent(
      casPublicId,
      editAddressDataCourseMapper(formValues, values),
    );

    if (!response.isAxiosError) {
      updateEvents();
      closeModal();
    }
  };

  return (
    <Modal open={show} onClose={closeModal}>
      <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
        <Formik
          validationSchema={() => EditAddressInfoCourseValidation(intl)}
          validateOnChange
          validateOnBlur
          initialValues={initialState}
          onSubmit={handleFormSubmit}>
          {() => (
            <Form>
              <Box className={classes.formWrapper}>
                <Box className={classes.header}>
                  <FormHeader
                    title={intl.formatMessage({
                      id: 'form.event.header.venue',
                      defaultMessage: 'Venue',
                    })}
                  />
                  <CloseButton aria-label='close' onClick={closeModal} variant='text'>
                    <img alt='' src={CloseIcon} onClick={closeModal} />
                  </CloseButton>
                </Box>
                <LocationInfoForm
                  xsContainer={12}
                  isShowTitle={false}
                  contentContainerPadding={20}
                  isEditable={allowToEdit}
                />

                <Button
                  type='submit'
                  textTransform
                  className={classes.submitButton}
                  disabled={!allowToEdit}>
                  {intl.formatMessage({
                    id: 'cta.save_changes',
                    defaultMessage: 'Save changes',
                  })}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
