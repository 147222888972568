import { Box, styled } from '@mui/material';

const AdvertismentHeaderWrapper = styled(Box)(({ theme }) => ({
  '.hf-gallery': {
    '.navigation': {
      position: 'absolute',
      top: '50%',
      transform: 'translate(0, -50%)',
      zIndex: '4',
      backgroundColor: theme.palette.white,
      color: theme.palette.grey[500],
      height: '32px',
      padding: '0',
      borderRadius: '50%',
      minWidth: '32px',
      svg: {
        width: '16px',
        height: '16px',
      },
      '&.left': {
        left: '8px',
      },
      '&.right': {
        right: '8px',
        svg: {
          transform: 'rotate(180deg)',
        },
      },
    },
    '.image-gallery-bullets': {
      bottom: '8px',
      '.image-gallery-bullet': {
        border: 'none',
        backgroundColor: theme.palette.grey[400],
        padding: '4px',
        margin: '0 2px',
        '&.active': {
          transform: 'unset',
          backgroundColor: theme.palette.grey[300],
        },
      },
    },
  },
}));

export default AdvertismentHeaderWrapper;
