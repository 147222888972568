import { Box } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { EventsForm, Header, SpinnerLoader } from '@/Components';
import { useAppContext } from '@/Context';
import useScheduleBreadcrumbs from '@/Hooks/Breadcrumbs/useScheduleBreadcrumbs';
import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';
import { getNewCoursePathname } from '@/Mappers';
import { useCurrentPartnerIdQuery, useGetPartnerQuery } from '@/Queries';
import InfoCourseIcon from '@/Static/Icons/course_delay_icon.svg';
import { EventList } from '@/Types';
import { CrazyUseRefCallbackStuffTypes, isEventListFormRef } from '@/Utils/formRefTypeguards';

import { FormSnackbar } from '../../App/Shared/Notification/Components/FormSnackbar';

const Schedule = () => {
  const intl = useIntl();
  const {
    state: {
      courseManager: { events, updatedEvents },
    },
  } = useAppContext();

  const navigate = useNavigate();
  const [openToastMessage, setOpenToastMessage] = useState(false);
  const currentLanguage = useCurrentLanguage();
  const [coursesEventsFormRef, setCoursesEventsFormRef] = useState<FormikHelpers<EventList>>();
  const [primaryCtaTitle, setPrimaryCtaTitle] = useState('');
  const [primaryCtaDisabled, setPrimaryCtaDisabled] = useState(true);

  const { data: partnerData, isLoading: isLoadingPartnerData } = useGetPartnerQuery();

  const { data: casPublicId, isLoading: isLoadingCasPublicId } = useCurrentPartnerIdQuery();

  const handleToastMessage = useCallback((openToastMessage: boolean) => {
    setOpenToastMessage(openToastMessage);
  }, []);

  const receiveRef = useCallback((ref: CrazyUseRefCallbackStuffTypes) => {
    if (isEventListFormRef(ref) && ref.current) {
      setCoursesEventsFormRef(ref.current);
    }
  }, []);

  const handlePrimaryCta = useCallback(async () => {
    if (coursesEventsFormRef) {
      await coursesEventsFormRef.submitForm();
    }
  }, [coursesEventsFormRef]);

  useEffect(() => {
    setPrimaryCtaTitle('');

    if (events && events.result && events.result.length === 0) {
      setPrimaryCtaDisabled(true);
    } else if (!updatedEvents) {
      setPrimaryCtaDisabled(true);
    } else {
      setPrimaryCtaDisabled(false);
    }
  }, [events, updatedEvents]);

  useEffect(() => {
    if (partnerData && !partnerData.showCourseManager) {
      navigate(getNewCoursePathname(currentLanguage));
    }
  }, [currentLanguage, navigate, partnerData]);

  const currentBreadcrumbs = useScheduleBreadcrumbs();

  const isLoading = isLoadingPartnerData || isLoadingCasPublicId;

  return (
    <>
      {isLoading && <SpinnerLoader isFullScreen />}
      <Header
        showLanguageSwitcher={false}
        callToActions={{
          primary: {
            title: primaryCtaTitle,
            callback: handlePrimaryCta,
            disabled: primaryCtaDisabled,
            variant: 'contained',
          },
        }}
        header={intl.formatMessage({
          id: 'breadcrumbs.schedule',
          defaultMessage: 'Schedule',
        })}
        breadcrumbs={currentBreadcrumbs}
      />
      <FormSnackbar
        openToastMessage={openToastMessage}
        handleCloseToastMessage={() => handleToastMessage(false)}
        customMessage={intl.formatMessage({
          id: 'toast.save.course',
          defaultMessage:
            'Deine Änderungen werden innerhalb der nächsten 10 min. in der App und im Web sichtbar.',
        })}
        customIcon={InfoCourseIcon}
      />
      {casPublicId && partnerData && (
        <Box mt={6}>
          <EventsForm
            casPublicId={casPublicId}
            refCallback={receiveRef}
            handleToastMessage={handleToastMessage}
            course={null}
          />
        </Box>
      )}
    </>
  );
};

export default Schedule;
