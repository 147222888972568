import { Modal } from '@mui/material';
import { CloseButton } from './EventsModal.styles';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { SubHeader } from '@/App/Shared/Form/Components/Header/SubHeader';

import CloseIcon from '@/Static/Icons/atoms-symbols-category-close-big-transparent.svg';
import { Container, Content } from './EventsModal.styles';
import { ReactNode } from 'react';

export interface EventsModalProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  header: string;
  subHeader: string;
}

export const EventsModal = ({ open, onClose, children, header, subHeader }: EventsModalProps) => (
  <Modal open={open} onClose={onClose}>
    <Container>
      <CloseButton aria-label='close' onClick={onClose} variant='text'>
        <img alt='' src={CloseIcon} />
      </CloseButton>
      <FormHeader title={header} />
      <SubHeader title={subHeader} />
      <Content>{children}</Content>
    </Container>
  </Modal>
);
