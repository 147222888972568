import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const useEquipmentTableHeadStyles = makeStyles()(theme => ({
  hint: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  hintInfo: {
    marginLeft: 4,
    fontSize: 12,
    color: FigmaDesignTokens.Grey[950],
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.11,
  },
  tableCell: {
    padding: 0,
    borderBottom: 'none',
    '& .sticky-outer-wrapper': {
      height: '100% !important',
    },
    '& .sticky-outer-wrapper.active .sticky-inner-wrapper': {
      zIndex: 9,
    },
  },
  tableCellBlue: {
    '& .sticky-outer-wrapper': {
      backgroundColor: theme.palette.background.default,
    },
  },
  categoryContainer: {
    padding: '9px 27px 9px 12px',
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  categoryContainerSimple: {
    padding: '9px 27px 9px 12px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  categoryContainerSimpleGroupHeader: {
    padding: 0,
    paddingLeft: 10,
    alignItems: 'flex-end',
  },
  categoryContainerMockUp: {
    height: 1,
    overflow: 'hidden',
  },
  categoryContainerFirst: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  categoryContainerLast: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  categoryContainerHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 162,
    margin: '0 auto',
    textAlign: 'center',
  },
  stickyInner: {
    backgroundColor: FigmaDesignTokens.White,
    height: 60,
  },
}));

export const useEquipmentsAndServicesFormStyles = makeStyles()(theme => ({
  divider: {
    height: '2px',
    borderRadius: '1px',
    boxShadow: `inset 0 1px 1px 0 ${theme.palette.background.default}`,
    backgroundColor: FigmaDesignTokens.Grey[200],
    marginTop: 50,
    marginBottom: 50,
  },
}));
