import fp from 'lodash/fp';

import { AlertProps, PopModalProps, RootStateProps, UiActionProps, UIStateProps } from '@/Types';

import { uiConstants } from './UI.constants';

const initialState: UIStateProps = {
  alerts: [],
  isSpinnerOverlayVisible: false,
  spinnerOverlayAdditionalTitle: '',
  isOverlayTransparent: true,
  modals: [],
  isOnline: true,
};

export const getUIState = (state: RootStateProps) => state.ui;

const selector = (property: keyof UIStateProps) => fp.flow(getUIState, fp.get(property));

export const getModals = selector('modals') || [];

export const getAlerts = selector('alerts');

export const getIsOnline = selector('isOnline');

const popModal = (modals: PopModalProps[], modalType: string) => {
  const lastModalByTypeIndex = fp.findLastIndex({ modalType }, modals);
  const newModals = fp.clone(modals);
  newModals.splice(lastModalByTypeIndex, 1);
  return newModals;
};

const popAlert = (alerts: AlertProps[], title: string) => {
  const newAlerts = fp.clone(alerts);
  return newAlerts.filter(a => a.title !== title);
};
function ui(state: UIStateProps = initialState, action: UiActionProps) {
  switch (action.type) {
    case uiConstants.SHOW_ALERT:
      if ('title' in action && 'variant' in action) {
        return {
          ...state,
          alerts: [
            ...state.alerts,
            {
              show: true,
              title: action.title,
              variant: action.variant,
            },
          ],
        };
      }
      return state;

    case uiConstants.HIDE_ALERT:
      if ('title' in action) {
        return {
          ...state,
          alerts: popAlert(state.alerts, action.title),
        };
      }
      return state;

    case uiConstants.SHOW_SPINNER_OVERLAY:
      if ('isOverlayTransparent' in action && 'spinnerOverlayAdditionalTitle' in action) {
        return {
          ...state,
          isSpinnerOverlayVisible: true,
          isOverlayTransparent: action.isOverlayTransparent,
          spinnerOverlayAdditionalTitle: action.spinnerOverlayAdditionalTitle,
        };
      }
      return state;

    case uiConstants.HIDE_SPINNER_OVERLAY:
      return {
        ...state,
        isSpinnerOverlayVisible: false,
        spinnerOverlayAdditionalTitle: '',
        isOverlayTransparent: true,
      };

    case uiConstants.PUSH_MODAL:
      if ('modal' in action) {
        return {
          ...state,
          modals: [...state.modals, action.modal],
        };
      }
      return state;

    case uiConstants.POP_MODAL:
      if ('modalType' in action) {
        return {
          ...state,
          modals: popModal(state.modals, action.modalType),
        };
      }
      return state;

    default:
      return state;
  }
}

export default ui;
