const extensionFileType = {
  aac: 'AAC audio',
  arc: 'Archive document (multiple files embedded)',
  avif: 'AVIF image',
  avi: 'AVI: Audio Video Interleave',
  azw: 'Amazon Kindle eBook format',
  bin: 'Any kind of binary data',
  bmp: 'Windows OS/2 Bitmap Graphics',
  bz: 'BZip archive',
  bz2: 'BZip2 archive',
  cda: 'CD audio',
  csh: 'C-Shell script',
  css: 'Cascading Style Sheets (CSS)',
  csv: 'Comma-separated values (CSV)',
  doc: 'Microsoft Word',
  docx: 'Microsoft Word (OpenXML)',
  eot: 'MS Embedded OpenType fonts',
  epub: 'Electronic publication (EPUB)',
  gz: 'GZip Compressed Archive',
  gif: 'Graphics Interchange Format (GIF)',
  htm: 'HyperText Markup Language (HTML)',
  html: 'HyperText Markup Language (HTML)',
  ico: 'Icon format',
  ics: 'iCalendar format',
  jar: 'Java Archive (JAR)',
  jpeg: 'JPEG images',
  jpg: 'JPEG images',
  js: 'JavaScript',
  json: 'JSON format',
  jsonld: 'JSON-LD format',
  mid: 'Musical Instrument Digital Interface (MIDI)',
  midi: 'Musical Instrument Digital Interface (MIDI)',
  mjs: 'JavaScript module',
  mp3: 'MP3 audio',
  mp4: 'MP4 video',
  mpeg: 'MPEG Video',
  mpkg: 'Apple Installer Package',
  odp: 'OpenDocument presentation document',
  ods: 'OpenDocument spreadsheet document',
  odt: 'OpenDocument text document',
  oga: 'OGG audio',
  ogv: 'OGG video',
  ogx: 'OGG',
  opus: 'Opus audio',
  otf: 'OpenType font',
  png: 'Portable Network Graphics',
  pdf: 'Adobe PDF',
  php: 'Hypertext Preprocessor (Personal Home Page)',
  ppt: 'Microsoft PowerPoint',
  pptx: 'Microsoft PowerPoint (OpenXML)',
  rar: 'RAR archive',
  rtf: 'Rich Text Format (RTF)',
  sh: 'Bourne shell script',
  svg: 'Scalable Vector Graphics (SVG)',
  tar: 'Tape Archive (TAR)',
  tif: 'Tagged Image File Format (TIFF)',
  tiff: 'Tagged Image File Format (TIFF)',
  ts: 'MPEG transport stream',
  ttf: 'TrueType Font',
  txt: 'Text, (generally ASCII or ISO 8859-n)',
  vsd: 'Microsoft Visio',
  wav: 'Waveform Audio Format',
  weba: 'WEBM audio',
  webm: 'WEBM video',
  webp: 'WEBP image',
  woff: 'Web Open Font Format (WOFF)',
  woff2: 'Web Open Font Format (WOFF)',
  xhtml: 'XHTML',
  xls: 'Microsoft Excel',
  xlsx: 'Microsoft Excel (OpenXML)',
  xml: 'XML',
  xul: 'XUL',
  zip: 'ZIP archive',
  '3gp': 'Video/Audio Container 3gpp',
  '3g2': 'Video/Audio Container 3gpp2',
  '7z': '7-Zip Archive',
  ai: 'Adobe Illustrator',
  ait: 'Adobe Illustrator',
  eps: 'Encapsulated PostScript',
  epsf: 'Encapsulated PostScript',
  ps: 'PostScript',
  psd: 'Photoshop',
  pdd: 'Photoshop',
};

export const getFormattedFileType = (fileType: string) => {
  if (!fileType) {
    return '';
  }
  const type = extensionFileType[fileType as keyof typeof extensionFileType];
  return type || fileType;
};
export const getFormattedFileSize = (size: number) => {
  if (size < 1024 * 1024) {
    return (size / 1024).toFixed(1).toString() + ' KB';
  }
  return (size / (1024 * 1024)).toFixed(1).toString() + ' MB';
};

export const getFiletypeFromFilename = (filename: string) =>
  filename?.split('.')?.pop()?.toLowerCase();
