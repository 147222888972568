import { styled } from '@mui/material';
import { Box } from '@mui/system';
import { withTransientProps } from '@/App/Theme/withTransientProps';

export const Container = styled(Box, withTransientProps)<{ $height?: string }>`
  display: flex;
  height: ${props => (props.$height ? props.$height : '72vh')};
  width: 100%;
`;

export const Wrapper = styled(Box)`
  flex-grow: 1;
`;
