import { Box, Button, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { ConnectedFocusError } from 'focus-formik-error';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormikErrorMessage } from '@/App/Shared/Form/Components/Formik/FormikErrorMessage';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import { BodyTextSmall, LineDivider, MainHeadline, PageHeadline } from '@/Components';
import { RegisterPartnerService } from '@/Services';
import { SdkErrorType } from '@/Types/SdkError';
import { ForgotPasswordValidation } from '@/Utils';

import FigmaDesignTokens from '../../../../../design/design-tokens.json';
import { LoginPhotos } from '../../Login/LoginPhotos';
import { useChangePasswordStyles } from '../ForgotPassword.styles';

interface ChangePasswordStepProps {
  handleStep: (value: number) => void;
  handleExistingEmail: (email: string) => void;
  registerPartnerService: RegisterPartnerService;
}

export const ProvideExistingEmailStep = ({
  handleStep,
  registerPartnerService,
  handleExistingEmail,
}: ChangePasswordStepProps) => {
  const { classes } = useChangePasswordStyles();
  const { formatMessage } = useIntl();
  const [asyncErrorMessage, setAsyncErrorMessage] = useState<string>();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [validateOnChange, setValidateOnChange] = useState(false);

  return (
    <>
      <MainHeadline
        textContent={formatMessage({
          id: 'app.brand_with_alternative_name',
          defaultMessage: 'Hansefit Verbundpartner-portal',
        })}
        customColor={FigmaDesignTokens.White}
        className={classes.mainHeader}
      />
      <Box className={classes.mainContent}>
        <PageHeadline
          textContent={formatMessage({
            id: 'form.forgot_password.stepper.step.provide_existing_email.header',
            defaultMessage: 'Password forgotten',
          })}
          textAlign='center'
        />

        <Formik
          validationSchema={ForgotPasswordValidation}
          initialValues={{ email: '' }}
          validateOnBlur={false}
          validateOnChange={validateOnChange}
          onSubmit={async (values, formik) => {
            try {
              setAsyncErrorMessage(undefined);
              await Auth.forgotPassword(values.email);
              handleExistingEmail(values.email);
              handleStep(1);
            } catch (error: unknown) {
              const e = error as SdkErrorType;
              formik.setFieldError('email', ' ');

              setAsyncErrorMessage(registerPartnerService.getTranslateMessageKey(e.code));
              setSubmitDisabled(true);
            }
          }}
          validate={() => {
            if (!validateOnChange) {
              setValidateOnChange(true);
            }

            setAsyncErrorMessage(undefined);
            setSubmitDisabled(false);
          }}>
          {formik => (
            <>
              <ConnectedFocusError />

              <Form className={classes.formWrapper}>
                <BodyTextSmall
                  textContent={formatMessage({
                    id: 'form.forgot_password.stepper.step.provide_existing_email.subheader',
                    defaultMessage:
                      'Please enter the e-mail address you used to register for the Verbundpartner portal.',
                  })}
                />
                <LineDivider verticalSpace={32} />
                <Field
                  className={classes.textField}
                  component={FormikTextField}
                  variant='outlined'
                  name='email'
                  type='email'
                  label={
                    <FormattedMessage
                      id='form.forgot_password.stepper.step.provide_existing_email.field.email.label'
                      defaultMessage='E-mail address'
                    />
                  }
                />
                <FormikErrorMessage name='email' />

                {asyncErrorMessage && (
                  <Typography variant='body2' className={classes.error}>
                    <FormattedMessage id={asyncErrorMessage} defaultMessage='Error' />
                  </Typography>
                )}
                <LineDivider verticalSpace={32} />

                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={submitDisabled || !formik.isValid}
                  className={clsx(classes.mainActionBtn, classes.userBtn)}>
                  <FormattedMessage
                    id='form.forgot_password.stepper.step.provide_existing_email.cta'
                    defaultMessage='Reset password'
                  />
                </Button>
              </Form>
            </>
          )}
        </Formik>
        <LoginPhotos />
      </Box>
    </>
  );
};
