import { makeStyles } from 'tss-react/mui';

export const useLanguageSwitcherStyles = makeStyles()(theme => ({
  languageContainer: {
    backgroundColor: theme.palette.error.main,
    position: 'relative',
  },
  language: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
    padding: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    paddingLeft: 12,
    paddingRight: 12,
    right: 0,
    border: `1px solid ${theme.palette.background.paper}`,
    transition: 'right .24s ease-in-out',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  languages: {
    position: 'absolute',
    right: -42,
    top: 208,
  },
  languageDe: {
    marginBottom: 6,
  },
  active: {
    cursor: 'pointer',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  inactive: {
    right: 10,
  },
}));
