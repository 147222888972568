import { SubHeadlineDisplay } from '@/Components';

type FormHeaderProps = {
  title: string;
  className?: string;
};

export const FormHeader = ({ title, className }: FormHeaderProps) => (
  <SubHeadlineDisplay textContent={title} className={className} />
);
