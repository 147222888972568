import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Field } from 'formik';

import { FormikCheckbox } from '@/App/Shared/Form/Components/Formik/FormikCheckbox';
import { UserStatus } from '@/Mappers';
import { useAppDispatch } from '@/Hooks/useAppDispatch';
import { uiActions } from '@/Store';
import { Role, User } from '@/Types';
import { Typography } from '@/Components/Modals/Styles/AdministrationModal.styles';

type AdminRoleCellProps = {
  currentUserId: string;
  data: User;
  updateUser: (user: User) => void;
};

export const AdminRoleCell = ({ data: row, currentUserId, updateUser }: AdminRoleCellProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const header =
    row.role === Role.ADMIN
      ? intl.formatMessage({
          id: 'view.user_management.user_management.modal.remove_admin_right.header',
          defaultMessage: 'Remove admin rights',
        })
      : intl.formatMessage({
          id: 'view.user_management.user_management.modal.set_admin_right.header',
          defaultMessage: 'Grant admin rights',
        });

  const descText =
    row.role === Role.ADMIN
      ? intl.formatMessage({
          id: 'view.user_management.user_management.modal.remove_admin_right.description',
          defaultMessage: 'You take away the admin rights from the user.',
        })
      : intl.formatMessage({
          id: 'view.user_management.user_management.modal.set_admin_right.description',
          defaultMessage: 'You now give this user admin rights.',
        });

  const submitButtonTitle =
    row.role === Role.ADMIN
      ? intl.formatMessage({
          id: 'view.user_management.user_management.modal.remove_admin_right.cta',
          defaultMessage: 'Remove admin rights now',
        })
      : intl.formatMessage({
          id: 'view.user_management.user_management.modal.set_admin_right.cta',
          defaultMessage: 'Assign admin rights now',
        });

  const handleSubmit = useCallback(
    userStatus => {
      switch (userStatus) {
        case Role.ADMIN:
          updateUser({ ...row, role: Role.User });
          break;
        case Role.User:
          updateUser({ ...row, role: Role.ADMIN });
          break;
      }
    },
    [row, updateUser],
  );

  const handleOpen = () => {
    dispatch(
      uiActions.showModal('ADMINISTRATION', {
        handleSubmit,
        header,
        submitButtonTitle,
        children: () => (
          <>
            <Typography>
              {row.givenName} {row.name}
            </Typography>
            {descText}
          </>
        ),
        userStatus: row.role,
      }),
    );
  };

  return (
    <>
      <Field
        disabled={
          row.status === UserStatus.DELETED ||
          row.status === UserStatus.BLOCKED ||
          currentUserId === row.cognitoId
        }
        type='checkbox'
        checked={row.role === Role.ADMIN}
        component={FormikCheckbox}
        onChange={handleOpen}
      />
    </>
  );
};
