import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { AuthSelectors } from '@/Store/Auth';

import { useAppSelector } from '../../../../Hooks/useAppSelector';
import { SignInForm } from '../Shared/SignInForm';
import { useLoginStyles } from './Login.styles';
import { LoginPhotos } from './LoginPhotos';

export const Login = () => {
  const { classes } = useLoginStyles();
  const intl = useIntl();
  const { credentials } = useAppSelector(AuthSelectors.authState);

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Typography variant='body1' className={clsx(classes.header, classes.mainHeader)}>
        {intl
          .formatMessage({
            id: 'form.login.mainheader',
            defaultMessage: 'Hansefit Verbundpartner-portal',
          })
          .toUpperCase()}
      </Typography>
      <Box className={classes.mainContent}>
        <Box p='20px'>
          <Box>
            <Typography variant='body1' className={clsx(classes.header, classes.headerForm)}>
              {intl
                .formatMessage({ id: 'form.login.header', defaultMessage: 'Login' })
                .toUpperCase()}
            </Typography>
          </Box>

          <Box>
            <Typography variant='body2' className={classes.subHeader}>
              {intl.formatMessage({
                id: 'form.login.subheader',
                defaultMessage: 'Welcome to our website! Please log in with your login data.',
              })}
            </Typography>
          </Box>
        </Box>
        <Box>
          <SignInForm variant={1} email={credentials?.email} password={credentials?.password} />
        </Box>
        <LoginPhotos />
      </Box>
    </Box>
  );
};
