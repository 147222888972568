import { Checkbox } from 'formik-mui';
import { withStyles } from 'tss-react/mui';
import { createStyles } from '@mui/material';

export const FormikCheckbox = withStyles(Checkbox, theme =>
  createStyles({
    root: {
      color: theme.palette.secondary.main,
    },
  }),
);
