import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { EventListItem } from '@/Types';
import { Container } from './BookingsCell.styles';

import RegistrationIcon from '@/Static/Icons/registration.svg';

type BookingsCellProps = {
  gridRenderCellParams: GridRenderCellParams<EventListItem, number>;
};

export const BookingsCell = ({ gridRenderCellParams }: BookingsCellProps) => (
  <Container $cancelled={gridRenderCellParams.row.cancelled}>
    <img alt='' src={RegistrationIcon} />
    {gridRenderCellParams.value} / {gridRenderCellParams.row.capacity}
  </Container>
);
