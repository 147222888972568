import { Button, Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import Profile from '@/Static/Icons/atoms-symbols-icon-profile.svg';

import FigmaDesignTokens from '../../../../../design/design-tokens.json';

interface GuestSwitchBtnProps {
  href: string;
  header: string;
  btnText: string;
}

const useStyles = makeStyles()(theme => ({
  root: {
    marginTop: 49,
    fontSize: 12,
  },
  switchWrapper: {
    marginRight: 40,
    width: 'auto',
  },
  header: {
    fontWeight: 500,
    letterSpacing: 0.11,
    color: FigmaDesignTokens.Blue[950],
    marginBottom: 8,
    fontSize: 12,
  },
  btn: {
    padding: '1px 13px',
    borderRadius: 12,
    backgroundColor: theme.palette.primary.main,
    lineHeight: 1.67,
    letterSpacing: 0.15,
    color: FigmaDesignTokens.Grey[200],
    fontSize: 12,
    fontWeight: 'normal',
  },
  btnIcon: {
    height: 24,
    width: 24,
    marginRight: 3,
  },
}));

export const GuestSwitchBtn = (props: GuestSwitchBtnProps) => {
  const { href, btnText, header } = props;
  const { classes } = useStyles();
  const navigate = useNavigate();

  const handleRegister = useCallback(() => {
    navigate(href);
  }, [href, navigate]);

  return (
    <Grid container justifyContent='flex-end' className={classes.root}>
      <Grid className={classes.switchWrapper} container direction='column' alignItems='flex-end'>
        <Typography variant='body2' className={classes.header}>
          {header}
        </Typography>
        <Button
          className={classes.btn}
          variant='contained'
          color='primary'
          onClick={handleRegister}>
          <img alt='' src={Profile} className={classes.btnIcon} />
          {btnText.toUpperCase()}
        </Button>
      </Grid>
    </Grid>
  );
};
