import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import moment from 'moment/moment';
import { StyledDate } from './DateCell.styles';

export interface DateCellProps {
  gridRenderCellParams: GridRenderCellParams;
}

export const DateCell = ({ gridRenderCellParams }: DateCellProps) => (
  <StyledDate $cancelled={gridRenderCellParams.row.cancelled}>
    {gridRenderCellParams.value ? moment(gridRenderCellParams.value).format('DD.MM.YYYY') : ''}
  </StyledDate>
);
