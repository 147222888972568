import { ActionMap } from './Actions';

export enum DelayModalTypes {
  SET_OPEN = 'SET_OPEN',
  SET_NOT_SHOW_PROFILE = 'SET_NOT_SHOW_PROFILE',
}

export interface DelayModalState {
  open: boolean;
  notShowProfileModal: boolean | null;
  action?: string;
}

export type DelayModalPayload = {
  [DelayModalTypes.SET_OPEN]: {
    open: boolean;
    action?: string;
  };
  [DelayModalTypes.SET_NOT_SHOW_PROFILE]: {
    notShowProfileModal: boolean;
  };
};

export type DelayModalActions = ActionMap<DelayModalPayload>[keyof ActionMap<DelayModalPayload>];

export const DelayModalReducer = (state: DelayModalState, action: DelayModalActions) => {
  switch (action.type) {
    case DelayModalTypes.SET_OPEN:
      return {
        ...state,
        ...action.payload,
      };
    case DelayModalTypes.SET_NOT_SHOW_PROFILE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
