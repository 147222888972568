import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@/Context';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import { SupportServiceFactory } from '@/Services';

import useCurrentPartnerIdQuery from './useCurrentPartnerIdQuery';

const useFaqListQuery = () => {
  const { dispatch } = useAppContext();
  const currentLanguage = useCurrentLanguage();

  const { data: casPublicId } = useCurrentPartnerIdQuery();
  const supportServiceInstance = new SupportServiceFactory().getInstance(dispatch);

  const queryResult = useQuery(
    ['getFaqList', casPublicId, currentLanguage],
    () => {
      if (!casPublicId) {
        return Promise.resolve(null);
      }
      const params = [
        {
          id: 'language',
          value: currentLanguage,
        },
      ];
      return supportServiceInstance.getFAQ(casPublicId, params);
    },
    {
      enabled: !!casPublicId,
    },
  );

  return queryResult;
};

export default useFaqListQuery;
