import { Box, styled } from '@mui/material';
import { Form } from 'formik';

import { withTransientProps } from '@/App/Theme/withTransientProps';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const Container = styled(Box, withTransientProps)`
  width: 100%;
  max-width: 1090px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${FigmaDesignTokens.White};
  border-radius: 4px;
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;
