import fp from 'lodash/fp';

const dropLastWord = (wordsToDrop: string[]) => (words: string[] | undefined) => {
  if (!words || words.length === 0) return [];
  const regex = new RegExp(`(${wordsToDrop.join('|')})`, 'i');
  return regex.test(fp.last(words) || '') ? fp.dropLast(1, words) : words;
};

/**
 * Converts modal name to screaming snake case, dropping the word `modal` (if present).
 * @example ProfilePhotoModal -> PROFILE_PHOTO
 * @example SomeTestComponent -> SOME_TEST_COMPONENT
 */
export const modalNameToConstant = fp.flow(
  fp.snakeCase,
  fp.split('_'),
  fp.map(fp.toUpper),
  dropLastWord(['modal']),
  fp.join('_'),
);
