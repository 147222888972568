import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const useChangePasswordStyles = makeStyles()(theme => ({
  mainContent: {
    width: 620,
    height: 'fit-content',
    borderRadius: 10,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: FigmaDesignTokens.White,
    margin: '0 auto',
    padding: '36px 50px',
    textAlign: 'center',
    position: 'relative',
    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none',
    },
  },
  formWrapper: {
    display: 'flex',
    width: 460,
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
  },
  mainHeader: {
    marginTop: 10,
    marginBottom: 48,
    width: 391,
    textAlign: 'center',
  },

  userBtn: {
    borderRadius: 18,
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.83,
    letterSpacing: 0.34,
    color: theme.palette.background.default,
    height: 36,
    marginBottom: 14,
    padding: '7px 11px',
    maxWidth: 200,
  },
  mainActionBtn: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.1)',
    '&:disabled': {
      background: FigmaDesignTokens.Grey[200],
      color: theme.palette.info.dark,
    },
    marginTop: 4,
  },
  secondBtn: {
    color: theme.palette.primary.dark,
  },
  secondBtnWrapper: {
    width: 'auto',
    marginTop: 12,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  secondBtnHeader: {
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 0.18,
    color: FigmaDesignTokens.Grey[950],
  },
  backToLoginBtn: {
    fontSize: 12,
    color: theme.palette.primary.dark,
    marginTop: 36,
  },
  error: {
    fontSize: 14,
    color: theme.palette.error.main,
    textAlign: 'center',
    lineHeight: 1.43,
    letterSpacing: 0.18,
    marginBottom: '24px',
  },
  multiInputsWrapper: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 30,
    paddingRight: 30,
  },
  newPasswordWrapper: {
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 24,
    marginTop: 32,
  },
  inputBox: {
    width: 60,
  },
  textField: {
    '& label, input': {
      fontSize: 14,
    },
    '& label.Mui-error': {
      fontSize: 14,
    },
  },
  divider: {
    backgroundColor: FigmaDesignTokens.Grey[50],
    height: 2,
    marginTop: 4,
    marginBottom: '34px',
  },
  submitBtnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
