import clsx from 'clsx';
import { Button as MuiButton, CircularProgress } from '@mui/material';

import { ButtonProps } from '@/Types';

import { useButtonStyles } from './Button.styles';

export const Button = ({
  variant = 'contained',
  size = 'sm',
  width = 'auto',
  valuePosition = 'center',
  iconPosition = 'left',
  withShadow = false,
  textTransform = false,
  link = false,
  children,
  onClick,
  className,
  iconClassName,
  icon,
  type,
  autoFocus,
  disabled,
  isLoading = false,
}: ButtonProps) => {
  const hasIcon = !!icon;
  const { classes } = useButtonStyles({
    width,
    withShadow,
    textTransform,
    size,
    variant,
    hasIcon,
    link,
    valuePosition,
  })();

  return (
    <MuiButton
      className={clsx(classes.button, className)}
      variant={variant}
      onClick={onClick}
      type={type}
      disabled={disabled}
      autoFocus={autoFocus}>
      {isLoading && <CircularProgress thickness={6} size={24} />}
      {icon && iconPosition === 'left' && (
        <span className={clsx(classes.iconLeft, iconClassName)}>{icon}</span>
      )}
      {children}
      {icon && iconPosition === 'right' && (
        <span className={clsx(classes.iconRight, iconClassName)}>{icon}</span>
      )}
    </MuiButton>
  );
};
