import { makeStyles } from 'tss-react/mui';

export const useCheckInOptionsFormStyles = makeStyles()(theme => ({
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  switchFieldAndLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.background.default,
    marginTop: 30,
  },
  checkinInformationWrapper: {
    marginBottom: 8,
  },
}));
