import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';
import { UserStatus } from '@/Mappers';
import { UsersResponse, User } from '@/Types';

export const usersService = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT,
    prepareHeaders: async headers => {
      const session = await Auth.currentSession();

      headers.set('authorization', `Bearer ${session.getIdToken().getJwtToken()}`);
      headers.set('Content-Type', 'application/merge-patch+json');

      return headers;
    },
  }),

  endpoints: builder => ({
    getActiveUsers: builder.query<User[], { publicId: string; cognitoId: string }>({
      query({ publicId }) {
        return `getUsers/${publicId}`;
      },
      transformResponse: (response: UsersResponse, meta, { cognitoId }): User[] =>
        response.detail.filter(
          user => user.status !== UserStatus.DELETED && user.cognitoId !== cognitoId,
        ),
    }),
    getCurrentUser: builder.query<User | null, { publicId: string; cognitoId: string }>({
      query({ publicId }) {
        if (!publicId) {
          return '';
        }
        return `getUsers/${publicId}`;
      },
      transformResponse: (response: UsersResponse, meta, { cognitoId }): User | null => {
        if (!cognitoId) {
          return null;
        }
        const currentUser = response.detail.find(user => user.cognitoId === cognitoId);
        return currentUser || null;
      },
    }),
  }),
});

export const { useGetActiveUsersQuery, useGetCurrentUserQuery } = usersService;
