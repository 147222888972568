import { useIntl } from 'react-intl';

import { Header, LineDivider } from '@/Components';
import ChangePasswordForm from '@/Components/Forms/CurrentUserForm/ChangePasswordForm';
import useChangePasswordBreadcrumbs from '@/Hooks/Breadcrumbs/useChangePasswordBreadcrumbs';

const ChangePassword = () => {
  const intl = useIntl();
  const currentBreadcrumbs = useChangePasswordBreadcrumbs();

  return (
    <>
      <Header
        showLanguageSwitcher={false}
        header={intl.formatMessage({
          id: 'view.current_user.header',
          defaultMessage: 'My user account',
        })}
        breadcrumbs={currentBreadcrumbs}
      />
      <LineDivider verticalSpace={48} />
      <ChangePasswordForm />
    </>
  );
};

export default ChangePassword;
