import { Box, Grid, IconButton, Typography } from '@mui/material';
import clsx from 'clsx';
import { sanitize } from 'dompurify';
import { ConnectedFocusError } from 'focus-formik-error';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useMemo, useRef, useState } from 'react';

import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import { BodyTextSmall, Button, CaptionText, PageHeadline } from '@/Components';
import { useAppContext } from '@/Context';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { PartnerService, RegisterPartnerServiceFactory } from '@/Services';
import CloseIcon from '@/Static/Icons/atoms-symbols-category-close-big.svg';
import { ValidatePartnerDataType } from '@/Types';
import { ValidatePartnerValidation } from '@/Utils';

import { MultiInput } from '../../Shared/Autotabs/MultiInput';
import { StartCode } from '../../Shared/Autotabs/StartCode';
import { RegisterPhotos } from '../../Shared/Photos/RegisterPhotos';
import { useValidatePartnerStepStyles } from './Steps.styles';

type ValidatePartnerStepProps = {
  handleStep: (value: number) => void;
  handleClose?: () => void;
  handleValidatePartnerData: (params: ValidatePartnerDataType) => void;
  handleUpdatePartnerNames?: () => void;
  partnerService?: PartnerService;
  validatePartnerStepView: VALIDATE_PARTNER_STEP_VIEW;
  header: string;
  subHeader: string;
  ctaTitle: string;
  addPartner: boolean;
};

export enum VALIDATE_PARTNER_STEP_VIEW {
  REGISTER = 'REGISTER',
  INVITE_PARTNER = 'INVITE_PARTNER',
}

export const ValidatePartnerStep = ({
  handleStep,
  handleValidatePartnerData,

  validatePartnerStepView,
  header,
  subHeader,
  ctaTitle,
  handleClose,
  partnerService,
  handleUpdatePartnerNames,
  addPartner,
}: ValidatePartnerStepProps) => {
  const formikRef = useRef<FormikProps<ValidatePartnerDataType>>(null);
  const { classes } = useValidatePartnerStepStyles();
  const { formatMessage } = useMemoedIntl();
  const { dispatch } = useAppContext();
  const [asyncErrorMessage, setAsyncErrorMessage] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [validateOnChange, setValidateOnChange] = useState(false);
  const registerPartnerService = useMemo(
    () => new RegisterPartnerServiceFactory().getInstance(dispatch),
    [dispatch],
  );

  const sanitizedLegalHint = useMemo(
    () =>
      sanitize(
        formatMessage({
          id: 'form.register.stepper.step.initial_startcode.legal_hint',
          defaultMessage:
            'By clicking on "Go ahead", I declare that I have taken note of the content',
        }),
      ),
    [formatMessage],
  );

  return (
    <Formik
      innerRef={formikRef}
      validationSchema={ValidatePartnerValidation}
      validate={() => {
        if (!validateOnChange) {
          setValidateOnChange(true);
        }

        setAsyncErrorMessage(false);
        setSubmitDisabled(false);
      }}
      initialValues={{
        partnerId: '',
        startCode: [
          { partKey: '', partKeyOld: '' },
          { partKey: '', partKeyOld: '' },
          { partKey: '', partKeyOld: '' },
        ],
        name: '',
        publicId: '',
      }}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
      onSubmit={async (values: ValidatePartnerDataType, formik) => {
        try {
          if (!!values.partnerId && registerPartnerService) {
            const code = values.startCode.map(value => value.partKey).join('');
            const response = await registerPartnerService.isValid(values.partnerId, code);

            if (
              response?.data?.success &&
              values.partnerId &&
              values.startCode &&
              response?.data?.name
            ) {
              if (addPartner) {
                const addPartnerResponse = await registerPartnerService.addPartner({
                  partnerId: values.partnerId,
                  startCode: values.startCode.map(value => value.partKey).join(''),
                });

                if (partnerService && handleUpdatePartnerNames) {
                  if (typeof partnerService.refreshTokens === 'function') {
                    await partnerService.refreshTokens(handleUpdatePartnerNames);
                  } else {
                    handleUpdatePartnerNames();
                    console.error('Unable to refresh token, continue without refreshing token.');
                  }
                }

                handleValidatePartnerData({
                  partnerId: values.partnerId,
                  startCode: values.startCode,
                  name: addPartnerResponse?.data.detail ? addPartnerResponse?.data.detail.name : '',
                  publicId: addPartnerResponse?.data.detail
                    ? addPartnerResponse?.data.detail?.publicId
                    : '',
                });
              } else {
                handleValidatePartnerData({
                  partnerId: values.partnerId,
                  startCode: values.startCode,
                  name: '',
                  publicId: '',
                });
              }

              handleStep(1);
            } else {
              formik.setFieldError('startCode[0].partKey', ' ');
              formik.setFieldError('startCode[1].partKey', ' ');
              formik.setFieldError('startCode[2].partKey', ' ');
              formik.setFieldError('partnerId', ' ');

              setAsyncErrorMessage(true);
              setSubmitDisabled(true);
            }
          }
        } catch (error) {
          console.error({ error });
          setAsyncErrorMessage(true);
          setSubmitDisabled(true);
        }
      }}>
      {formik => (
        <Form>
          <ConnectedFocusError />

          {validatePartnerStepView === VALIDATE_PARTNER_STEP_VIEW.INVITE_PARTNER && handleClose && (
            <Box className={classes.closeButtonWrapper}>
              <IconButton aria-label='close' onClick={handleClose} className={classes.closeButton}>
                <img alt='' src={CloseIcon} />
              </IconButton>
            </Box>
          )}
          <Box className={classes.mainContent}>
            <Grid container spacing={4}>
              <Grid item>
                <Grid>
                  <PageHeadline textContent={header} textAlign='center' />
                </Grid>
                <Grid>
                  <BodyTextSmall textContent={subHeader} textAlign='center' />
                </Grid>
              </Grid>
              <Grid container item spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <BodyTextSmall
                        textContent={formatMessage({
                          id: 'form.register.stepper.step.initial_startcode.your_partner_id',
                          defaultMessage: 'Your Partner ID',
                        })}
                        className={classes.fieldTitle}
                        textAlign='center'
                      />
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={6} className={classes.textFieldContainer}>
                      <Field
                        component={FormikTextField}
                        className={classes.textField}
                        variant='outlined'
                        name='partnerId'
                        type='text'
                        placeholder='1234'
                      />
                    </Grid>
                    <Grid item xs={3} />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <BodyTextSmall
                    textContent={formatMessage({
                      id: 'form.register.stepper.step.initial_startcode.your_startcode',
                      defaultMessage: 'Your start code',
                    })}
                    className={classes.fieldTitle}
                    textAlign='center'
                  />
                  <MultiInput
                    validateOnChange={false}
                    name='startCode'
                    subName='partKey'
                    registerPartnerService={registerPartnerService}>
                    {(autoTab, handleChange, inputsRef, handlePaste) => (
                      <StartCode
                        autoTab={autoTab}
                        handleChange={handleChange}
                        inputsRef={inputsRef}
                        handlePaste={handlePaste}
                      />
                    )}
                  </MultiInput>
                  {(!formik.isValid || asyncErrorMessage) && (
                    <CaptionText
                      className={classes.error}
                      textContent={formatMessage({
                        id: 'form.register.stepper.step.initial_startcode.error',
                        defaultMessage:
                          'Unfortunately we could not verify you as a Hansefit partner. Please check your entries',
                      })}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={12}
                alignItems='center'
                direction='column'
                justifyContent='center'>
                {validatePartnerStepView === VALIDATE_PARTNER_STEP_VIEW.REGISTER && (
                  <Grid>
                    <Typography
                      className={classes.legalHint}
                      dangerouslySetInnerHTML={{
                        __html: sanitizedLegalHint,
                      }}
                    />
                  </Grid>
                )}
                <Grid>
                  <Button
                    type='submit'
                    variant='contained'
                    className={clsx(classes.mainActionBtn, classes.userBtn)}
                    disabled={submitDisabled}>
                    {ctaTitle}
                  </Button>
                </Grid>

                {validatePartnerStepView === VALIDATE_PARTNER_STEP_VIEW.REGISTER && (
                  <Grid
                    container
                    direction='column'
                    className={classes.secondBtnWrapper}
                    spacing={0}>
                    <Typography variant='body2' className={classes.secondBtnHeader}>
                      {formatMessage({
                        id: 'form.register.stepper.step.initial_startcode.is_not_hansefit_member',
                        defaultMessage: 'You are not yet a Hansefit partner?',
                      })}
                    </Typography>
                    <Button
                      variant='text'
                      onClick={() => window.open('https://hansefit.de/partner', '_blank')}
                      textTransform
                      className={clsx(classes.userBtn, classes.secondBtn)}>
                      {formatMessage({
                        id: 'form.register.stepper.step.initial_startcode.is_not_hansefit_member.cta',
                        defaultMessage: 'Apply now to become a partner.',
                      })}
                    </Button>
                  </Grid>
                )}
              </Grid>
              {validatePartnerStepView === VALIDATE_PARTNER_STEP_VIEW.REGISTER && (
                <RegisterPhotos />
              )}
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
