import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const useCropGalleryImageStyles = makeStyles()(() => ({
  cropWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  btnModal: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    justifyContent: 'flex-start',
    textTransform: 'none',
  },
  btnText: {
    fontSize: '12px',
    color: FigmaDesignTokens.Blue[950],
    textDecoration: 'underline',
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    '& .MuiDialogTitle-root': {
      padding: 0,
      paddingBottom: 20,
    },
  },
  dialogContent: {
    marginTop: 28,
    padding: 0,
  },
  dialogActions: {
    marginTop: 32,
    padding: 0,
  },
  closeButton: {
    padding: 0,
  },
  cta: {
    borderRadius: 32,
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.83,
    letterSpacing: 0.34,
  },
}));
