import 'moment/locale/de';

import { Box, Grid, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { FormikSwitch } from '@/App/Shared/Form/Components/Formik/FormikSwitch';
import { SwitchLabel } from '@/App/Shared/Form/Components/Label/SwitchLabel';
import { Notification } from '@/App/Shared/Notification/Components/Notification';
import { InfoMessage, SubHeadlineRegular } from '@/Components';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { WeekdayService } from '@/Mappers/Weekday';
import { EventsService } from '@/Services';
import { CategoriesTypes, Course, DayOption, Event, SelectOption } from '@/Types';

import { useAppointmentsFormStyles } from './Event.styles';
import { SeriesEventsForm } from './SeriesEventsForm';
import { SingleEventForm } from './SingleEventForm';

type EventTimeFormProps = {
  asyncEventFormErrorMessage?: string;
  course: Course;
  eventsService: EventsService;
  handlePrimaryCtaDisabled: (value: boolean) => void;
  handleSecondaryCtaDisabled: (value: boolean) => void;
};

export const AppointmentsForm = ({
  asyncEventFormErrorMessage,
  course,
  eventsService,
  handlePrimaryCtaDisabled,
  handleSecondaryCtaDisabled,
}: EventTimeFormProps) => {
  const { classes } = useAppointmentsFormStyles();
  const { formatMessage } = useMemoedIntl();
  const {
    setFieldValue,
    values,

    isValid,
    isValidating,

    initialValues,
  } = useFormikContext<Event>();
  const currentLanguage = useCurrentLanguage();

  const days: DayOption[] = useMemo(() => {
    const weekdayService = new WeekdayService();
    const days = weekdayService.getWeekdayOptions(currentLanguage);

    if (days) {
      const firstEl = days.shift();
      if (firstEl) {
        days.push(firstEl);
      }
    }

    return days;
  }, [currentLanguage]);

  const handleDaySelect = useCallback(
    (id: string) => {
      const day = days.find(day => day.id === id);
      if (day) {
        values.appointments.seriesSettings?.weekdays.push(+day.id);
        const weekdays = values.appointments.seriesSettings?.weekdays;

        if (weekdays) {
          setFieldValue('appointments.seriesSettings.weekdays', [...weekdays]);
        }
      }
    },
    [days, values.appointments.seriesSettings?.weekdays, setFieldValue],
  );

  const handleDayDelete = useCallback(
    (day: DayOption | CategoriesTypes) => {
      const filtered = values.appointments.seriesSettings?.weekdays.filter(
        selectedDayId => selectedDayId !== parseInt(day.id),
      );

      setFieldValue('appointments.seriesSettings.weekdays', filtered);
    },
    [values.appointments.seriesSettings?.weekdays, setFieldValue],
  );

  const intervals: SelectOption[] = useMemo(
    () => [
      {
        value: '1',
        displayValue: formatMessage({
          id: 'form.event.field.appointments.seriesSettings.repetitions.option.1',
          defaultMessage: 'Weekly',
        }),
      },
      {
        value: '2',
        displayValue: formatMessage({
          id: 'form.event.field.appointments.seriesSettings.repetitions.option.2',
          defaultMessage: 'every 2 weeks',
        }),
      },
      {
        value: '3',
        displayValue: formatMessage({
          id: 'form.event.field.appointments.seriesSettings.repetitions.option.3',
          defaultMessage: 'every 3 weeks',
        }),
      },
    ],
    [formatMessage],
  );

  useEffect(() => {
    const isDisabled = !(!isEqual(initialValues, values) && isValid);
    handlePrimaryCtaDisabled(isDisabled);
    handleSecondaryCtaDisabled(isDisabled);
  }, [
    isValid,
    handlePrimaryCtaDisabled,
    handleSecondaryCtaDisabled,
    isValidating,
    initialValues,
    values,
  ]);

  return (
    <Grid item xs={12}>
      <ContentContainer>
        <Grid container spacing={4}>
          <Grid item container spacing={4}>
            <Grid item xs={12}>
              <Typography variant='body1' className={classes.courseName}>
                {course.courseName[currentLanguage]}
              </Typography>
              <SubHeadlineRegular
                textContent={formatMessage({
                  id: 'form.event.header.add_events_data',
                  defaultMessage: 'Add dates',
                })}
                className={classes.managerLabel}
              />
            </Grid>
            {asyncEventFormErrorMessage && (
              <Grid item xs={12}>
                <Notification severity='error' message={asyncEventFormErrorMessage} />
              </Grid>
            )}
          </Grid>

          <Grid item container xs={8}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <SubHeadlineRegular
                    textContent={formatMessage({
                      id: 'form.event.subheader.choose_appointment_type',
                      defaultMessage: 'Selection appointment type',
                    })}
                    className={classes.managerLabel}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.switchWrapper}>
                    <Box className={classes.switchFieldAndLabel}>
                      <Field
                        color='primary'
                        type='checkbox'
                        component={FormikSwitch}
                        name='isSeries'
                      />
                      <SwitchLabel
                        title={formatMessage({
                          id: 'form.event.subheader.appointment_type.series',
                          defaultMessage: 'Series date',
                        })}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {!values.isSeries ? (
                <SingleEventForm eventsService={eventsService} />
              ) : (
                <SeriesEventsForm
                  days={days}
                  handleDayDelete={handleDayDelete}
                  handleDaySelect={handleDaySelect}
                  intervals={intervals}
                  eventsService={eventsService}
                />
              )}
            </Grid>
          </Grid>

          <Grid xs={4} item container>
            <Grid item xs={12}>
              <InfoMessage
                title={formatMessage({
                  id: 'form.event.hint.series_events.header',
                  defaultMessage: 'Single appointment or series of appointments',
                })}
                description={formatMessage({
                  id: 'form.event.hint.series_events.description',
                  defaultMessage:
                    'You can create a single appointment or a series of appointments. To create a series of appointments, simply change the appointment type to series appointment.',
                })}
                type='information'
              />
            </Grid>
          </Grid>
        </Grid>
      </ContentContainer>
    </Grid>
  );
};
