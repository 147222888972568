import { Box, StepIconProps } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export interface EnableCourseManagerDialogStepIconProps extends StepIconProps {
  stepNumber: number;
}

const useStyles = makeStyles()(theme => ({
  root: {
    fontSize: 12,
    backgroundColor: FigmaDesignTokens.White,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: theme.palette.primary.main,
    lineHeight: 2,
    letterSpacing: 0.11,
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'row',
    zIndex: 2,
    height: 24,
    width: 24,
    padding: 0,
    margin: 0,
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: FigmaDesignTokens.White,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  completed: {
    backgroundColor: FigmaDesignTokens.Green[500],
    color: FigmaDesignTokens.White,
    border: `1px solid ${FigmaDesignTokens.Green[500]}`,
  },
  stepNumber: {
    fontWeight: 900,
  },
}));

export const EnableCourseManagerDialogStepIcon = (
  props: EnableCourseManagerDialogStepIconProps,
) => {
  const { active, completed, stepNumber } = props;
  const { classes } = useStyles();
  return (
    <Box
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      <Box className={classes.stepNumber}>{stepNumber}</Box>
    </Box>
  );
};
