import { IntlShape } from 'react-intl';

import { CourseTypes, EditAddressDataCourseType, EventListItem } from '@/Types';

export const getCourseLevels = () => [
  {
    value: 'Anfänger',
    id: 'form.course.field.level.option.1',
    defaultMessage: 'Beginner',
  },
  {
    value: 'Fortgeschritten',
    id: 'form.course.field.level.option.2',
    defaultMessage: 'Advanced',
  },
  {
    value: 'Alle',
    id: 'form.course.field.level.option.3',
    defaultMessage: 'Suitable for all',
  },
];

export const getCourseTypes = () => [
  {
    value: CourseTypes.ONLINE,
    id: 'form.course.field.courseType.options.online',
    defaultMessage: 'Online',
  },
  {
    value: CourseTypes.ONSITE,
    id: 'form.course.field.courseType.options.onsite',
    defaultMessage: 'Onsite',
  },
];

export const getCourseTypeTranslation = (courseType: CourseTypes, intl: IntlShape) => {
  switch (courseType) {
    case CourseTypes.ONLINE: {
      return intl.formatMessage({
        id: 'form.course.field.courseType.options.online',
        defaultMessage: 'Online',
      });
    }
    case CourseTypes.ONSITE: {
      return intl.formatMessage({
        id: 'form.course.field.courseType.options.onsite',
        defaultMessage: 'Onsite',
      });
    }
    default:
      return '';
  }
};

export const courseTypes = {
  ONSITE_COURSE: 'OnsiteCourse',
  ONLINE_COURSE: 'OnlineCourse',
};

export const isOnsiteCourseType = (courseType: CourseTypes) =>
  courseType === courseTypes.ONSITE_COURSE;

export const editAddressDataCourseMapper = (
  formValues: EditAddressDataCourseType,
  values: EventListItem,
) => [
  {
    eventId: values.eventId,
    address: {
      street: formValues.address.street,
      streetNumber: formValues.address.streetNumber,
      city: formValues.address.city,
      postcode: formValues.address.postcode,
      coordLat: formValues.address.coordLat,
      coordLong: formValues.address.coordLong,
    },
    appointment: {
      startDate: values.date,
      time: values.time,
      timeZone: values.timeZone,
      duration: values.duration,
      capacity: values.capacity,
    },
    additionalInformation: formValues.additionalInformation,
    published: values.published,
    cancelled: values.cancelled,
  },
];
