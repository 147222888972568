import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

export interface NotificationLayoutProps {
  children: ReactNode;
}

const useStyles = makeStyles()(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing(4),
  },
}));

export const NotificationLayout = (props: NotificationLayoutProps): JSX.Element => {
  const { children } = props;
  const { classes } = useStyles();

  return <Box className={classes.container}>{children}</Box>;
};
