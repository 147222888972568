import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const useEquipmentRowsStyles = makeStyles()(theme => ({
  tableRow: {
    '&:hover': {
      '& td div': {
        backgroundColor: FigmaDesignTokens.Blue[100],
      },
    },
  },
  tableRowGroup: {
    '&:hover': {
      '& td div': {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  tableCell: {
    borderBottom: 'none',
    '& .sticky-outer-wrapper.active .sticky-inner-wrapper': {
      zIndex: 9,
    },
  },
  tableCellHeader: {
    textAlign: 'left',
    padding: 0,
    paddingBottom: 10,
  },
  tableCellHeaderGroupContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: 10,
    paddingBottom: 0,
  },
  borderRoundRight: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  borderRoundLeft: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  tableCellContainer: {
    backgroundColor: theme.palette.background.default,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: 50,

    '.MuiFormGroup-root': {
      justifyContent: 'center',
    },
  },
  tableCellContainerFirst: {
    justifyContent: 'left',
    padding: 12,
    marginRight: 10,
    borderRadius: 10,
  },
  stickyInner: {
    backgroundColor: theme.palette.background.paper,
    height: 72,
  },
}));
