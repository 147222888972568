import { makeStyles } from 'tss-react/mui';

export const useCurrentUserFormStyles = makeStyles()(theme => ({
  contentContainerWrapper: {
    width: 1090,
    display: 'block',
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  switchFieldAndLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
