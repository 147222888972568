import { Box, styled, Typography } from '@mui/material';

import { withTransientProps } from '@/App/Theme/withTransientProps';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const StreamLinkData = styled(Typography, withTransientProps)<{
  $streamLink: string | boolean | undefined;
  $published: boolean;
  $cancelled: boolean;
}>`
  text-transform: uppercase;
  color: ${FigmaDesignTokens.White};
  font-size: 14px;
  font-family: Roboto-Medium;
  padding: 1px 8px;
  border-radius: 4px;
  background-color: ${props =>
    props.$streamLink
      ? props.$cancelled
        ? FigmaDesignTokens.Grey[500]
        : FigmaDesignTokens.Green[500]
      : props.$cancelled
        ? FigmaDesignTokens.Grey[500]
        : FigmaDesignTokens.Red[500]};
  width: fit-content;
`;

export const StreamingLinkDataContainer = styled(Box)`
  width: 116px;
`;
