import { combineReducers } from '@reduxjs/toolkit';

import { usersService } from '@/Services';

import AuthReducer from './Auth/authSlice';
import ui from './UI/UI.reducer';

const rootReducer = combineReducers({
  auth: AuthReducer,
  ui,
  [usersService.reducerPath]: usersService.reducer,
});

export default rootReducer;
