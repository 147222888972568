import { Box } from '@mui/material';
import clsx from 'clsx';

import Theme from '@/App/Theme/Theme';
import { OverlineText } from '@/Components';
import { useGetPartnerQuery } from '@/Queries';
import AddIcon from '@/Static/Icons/bluePlusIcon.svg';

import { useAddWithIconAndTitleHourStyles } from './OpeningHours.styles';

type AddWithIconAndTitleHourProps = {
  title: string;
};

export const AddWithIconAndTitleHour = (props: AddWithIconAndTitleHourProps): JSX.Element => {
  const { title } = props;
  const { classes } = useAddWithIconAndTitleHourStyles();
  const { data: partner } = useGetPartnerQuery();

  return (
    <Box className={clsx(classes.root, { [classes.disabled]: partner?.readonly })}>
      <img alt='' src={AddIcon} className={classes.icon} />

      <OverlineText textContent={title} customColor={Theme.palette.primary.dark} fontWeight={500} />
    </Box>
  );
};
