import { AxiosError } from 'axios';

const handleCatchError = (error: unknown) => {
  if (error instanceof Error) {
    console.error(error.message);
  } else if ((error as AxiosError).isAxiosError) {
    const axiosError = error as AxiosError;
    console.error('Axios error:', axiosError.response?.data);
  } else {
    console.error('Unknown error:', error);
  }
};

export default handleCatchError;
