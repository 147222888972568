import { ChangeEvent, useCallback, useState } from 'react';
import { IconButton } from '@mui/material';
import { useIntl } from 'react-intl';
import { GridFilterModel, GridLogicOperator, useGridApiContext } from '@mui/x-data-grid-pro';

import SearchIcon from '@/Static/Icons/atoms-icon-search-24-x-24.svg';
import ClearIcon from '@/Static/Icons/atoms-icons-clear-16-x-16.svg';
import { IconContainer, Input, StyledClearIcon, StyledSearchIcon } from './Search.styles';

type SearchProps = {
  searchFields: string[];
};

export const Search = ({ searchFields }: SearchProps) => {
  const apiRef = useGridApiContext();
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const search = useCallback(
    (term: string) => {
      if (apiRef.current) {
        setSearchTerm(term);
        apiRef.current.setPage(0);

        const newModel: GridFilterModel = {
          items: searchFields.map(searchField => ({
            field: searchField,
            operator: 'contains',
            value: term,
          })),
          logicOperator: GridLogicOperator.Or,
        };

        apiRef.current.setFilterModel(newModel);
      }
    },
    [apiRef, searchFields],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      search(e.target.value);
    },
    [search],
  );

  const handleClear = useCallback(() => {
    search('');
  }, [search]);

  return (
    <Input
      placeholder={intl.formatMessage({
        id: 'view.course.table.action.search',
        defaultMessage: 'Search',
      })}
      value={searchTerm}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <IconContainer>
            {searchTerm && (
              <IconButton onClick={handleClear}>
                <StyledClearIcon alt='' src={ClearIcon} />
              </IconButton>
            )}
            <StyledSearchIcon alt='' src={SearchIcon} />
          </IconContainer>
        ),
      }}
    />
  );
};
