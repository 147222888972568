import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { AxiosError } from 'axios';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Notification } from '@/App/Shared/Notification/Components/Notification';
import { NotificationLayout } from '@/App/Shared/Notification/NotificationLayout';
import { SpinnerLoader } from '@/Components';
import { CourseManagerTypes, useAppContext } from '@/Context';
import { ServerErrorTypes } from '@/Context/ServerErrorReducer';
import { getDefaultFiltersValues } from '@/Models';
import useEventsListQuery from '@/Queries/Events/useEventsListQuery';
import { EventsServiceFactory, PartnerCoursesServiceFactory } from '@/Services';
import {
  BulkSaveFeedback,
  Course,
  EventList,
  EventsData,
  Partner,
  UpdateEventTypes,
} from '@/Types';
import { CrazyUseRefCallbackStuffTypes, isEventListFormRef } from '@/Utils/formRefTypeguards';

import { Container, StyledForm } from './EventsForm.styles';
import { CancelEvents } from './Modals/CancelEvents';
import { EditEvents } from './Modals/EditEvents';
import { EditEventsStream } from './Modals/EditEventsStream';
import { PublishEvents } from './Modals/PublishEvents';
import { EventsTable } from './Table/EventsTable';

type EventListFormProps = {
  casPublicId: Partner['casPublicId'];
  refCallback: (ref: CrazyUseRefCallbackStuffTypes, tabNumber: number) => void;
  handleToastMessage: (value: boolean, message?: string) => void;
  course: Course | null;
  hideCourseName?: boolean;
  showAddNew?: boolean;
};

export const EventsForm = ({
  refCallback,
  casPublicId,
  handleToastMessage,
  course,
}: EventListFormProps) => {
  const { dispatch } = useAppContext();
  const formikRef = useRef<FormikProps<EventList>>(null);
  const [editStreamingDataModalOpen, setEditStreamingDataModalOpen] = useState(false);
  const [editEventModalOpen, setEditEventModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [eventIds, setEventIds] = useState<string[]>([]);
  const [pastCourseCancellation, setPastCourseCancellation] = useState('');
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [paginationState, setPaginationState] = useState<EventsData['pagination']>({
    totalCount: 0,
    itemsPerPage: 10,
    pages: 0,
    currentPage: 1,
  } as EventsData['pagination']);

  const scheduleDisplayColumns = {
    cancelled: { minWidth: 100 },
    courseName: { minWidth: 150 },
    courseType: { minWidth: 100 },
    date: { minWidth: 100 },
    time: { minWidth: 100 },
    duration: { minWidth: 110 },
    bookings: { minWidth: 140 },
    streamLink: { minWidth: 170 },
    tableActions: { minWidth: 46 },
  };

  const eventsService = useMemo(() => new EventsServiceFactory().getInstance(dispatch), [dispatch]);
  const partnerCoursesService = useMemo(
    () => new PartnerCoursesServiceFactory().getInstance(dispatch),
    [dispatch],
  );

  const baseFilter: EventList = useMemo(() => {
    const defaultFilterValues = {
      ...getDefaultFiltersValues(),
      ...(formikRef.current?.values || {}),
      page: paginationState.currentPage.toString(),
      limit: paginationState.itemsPerPage,
    };

    if (course?.courseName) {
      return { ...defaultFilterValues, courseName: [course.courseName] };
    }
    return defaultFilterValues;
  }, [course?.courseName, paginationState.currentPage, paginationState.itemsPerPage]);

  const {
    isLoading,

    refetch: refetchEventsListData,
    data: eventsData,
    isSuccess,
  } = useEventsListQuery(baseFilter, course?.courseId);

  useEffect(() => {
    if (eventsData && isSuccess && !isLoading) {
      dispatch({ type: CourseManagerTypes.SET_EVENTS, payload: { events: eventsData } });
      dispatch({
        type: CourseManagerTypes.SET_DIRTY_FILTERS,
        payload: { dirtyFilters: false },
      });

      setPaginationState(eventsData.pagination);
    }
  }, [eventsData, dispatch, isSuccess, isLoading]);

  useEffect(() => {
    if (isEventListFormRef(formikRef) && formikRef.current) {
      refCallback(formikRef, 1);
    }
  }, [refCallback, formikRef]);

  useEffect(() => {
    if (eventsData && formikRef.current) {
      formikRef.current?.setFieldValue('eventsData', eventsData);
    }
  }, [eventsData, formikRef]);

  const handleCloseEditEvent = useCallback(() => {
    setEditEventModalOpen(false);
    setEventIds([]);
  }, []);

  const handleSubmitLocal = useCallback(
    (updatedFields: UpdateEventTypes, customEventIds?: string[]) => {
      if (!formikRef.current) return;
      const newUpdatedEvents = eventsService.getNewUpdatedEvents(
        formikRef.current.values,
        updatedFields,
        customEventIds ? customEventIds : eventIds,
      );
      dispatch({
        type: CourseManagerTypes.SET_UPDATED_EVENTS,
        payload: { updatedEvents: newUpdatedEvents },
      });
      formikRef.current.setFieldValue('updatedEvents', newUpdatedEvents);

      const newEvents = formikRef.current.values.eventsData.result.map(event => {
        const newEvent = newUpdatedEvents.find(
          newUpdatedEvent => newUpdatedEvent.eventId === event.eventId,
        );
        return newEvent ? newEvent : event;
      });

      formikRef.current.setFieldValue('eventsData.result', newEvents);

      formikRef.current.submitForm();
      handleCloseEditEvent();
      setSelectedRows([]);
    },
    [eventsService, eventIds, dispatch, handleCloseEditEvent],
  );

  const handleCancelEvents = useCallback(
    async (eventIds: string[]) => {
      if (eventsData && eventsData.result) {
        const results = await Promise.allSettled(
          eventsService.cancelEvents(casPublicId, eventIds, eventsData.result),
        );

        const hasAxiosErrors = results.filter(
          response =>
            response.status === 'fulfilled' &&
            response.value instanceof AxiosError &&
            response.value.isAxiosError,
        );

        const bulkSaveFeedbackData: BulkSaveFeedback = {};
        const bulkSaveError = {};

        results.forEach((result, idx) => {
          if (result.status === 'fulfilled' && !result.value.isAxiosError) {
            const eventId = eventIds[idx];

            bulkSaveFeedbackData[eventId] = { type: 'SUCCESS' };

            const eventIndex = eventsData.result.findIndex(
              event => event.eventId.toString() === eventId,
            );

            if (eventIndex !== -1) {
              formikRef.current?.setFieldValue(`eventsData.result[${eventIndex}].cancelled`, true);
            }
          } else {
            const currentEvent = eventsData.result.find(
              event => event.eventId.toString() === eventIds[idx],
            );
            if (
              currentEvent &&
              currentEvent.date &&
              currentEvent.time &&
              new Date(currentEvent.date).setHours(
                Number(currentEvent.time.split(':')[0]),
                Number(currentEvent.time.split(':')[1]),
              ) < new Date().getTime()
            ) {
              dispatch({ type: ServerErrorTypes.REMOVE_SERVER_ERROR });
              setPastCourseCancellation('past.course.cancellation.notification');
            }
          }
        });

        if (hasAxiosErrors.length === 0) {
          if (Object.keys(bulkSaveError).length === 0) {
            handleToastMessage(true);
            setSelectedRows([]);
          }

          dispatch({
            type: CourseManagerTypes.SET_UPDATED_EVENTS,
            payload: { updatedEvents: null },
          });

          if (formikRef.current) {
            formikRef.current.setFieldValue('updatedEvents', []);
            formikRef.current.setFieldValue('checkedEvents', []);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventsData, casPublicId, dispatch, handleToastMessage],
  );

  const handleEditSingleEventStream = useCallback((eventIds: string[]) => {
    setEventIds(eventIds);
    setEditStreamingDataModalOpen(true);
  }, []);

  const handleOpenEditSingleEvent = useCallback((eventIds: string[]) => {
    setEventIds(eventIds);
    setEditEventModalOpen(true);
  }, []);

  const handleOpenPublishEventsModal = (eventIds: string[]) => {
    setEventIds(eventIds);
    setPublishModalOpen(true);
  };

  const handleClosePublishEventsModal = () => {
    setEventIds([]);
    setPublishModalOpen(false);
  };

  const handleOpenCancelEventModal = useCallback((eventIds: string[]) => {
    setEventIds(eventIds);
    setCancelModalOpen(true);
  }, []);

  const handleCloseCancelEventModal = useCallback(() => {
    setEventIds([]);
    setCancelModalOpen(false);
  }, []);

  const handleCloseEditEventStream = useCallback(() => {
    setEditStreamingDataModalOpen(false);
    setTimeout(() => {
      setEventIds([]);
    });
  }, []);

  const handleErrorAction = useCallback(
    async (formik: FormikHelpers<EventList>) => {
      handleToastMessage(true);
      dispatch({
        type: CourseManagerTypes.SET_UPDATED_EVENTS,
        payload: { updatedEvents: null },
      });
      formik.setFieldValue('updatedEvents', []);
      formik.setFieldValue('checkedEvents', []);
      if (course && course.courseId) {
        const courseResponse = await partnerCoursesService.getCourse(casPublicId, course.courseId);

        dispatch({
          type: CourseManagerTypes.SET_COURSE,
          payload: { course: courseResponse.data },
        });
      }
    },
    [casPublicId, course, dispatch, handleToastMessage, partnerCoursesService],
  );

  const handlePublishSubmit = useCallback(
    async (updatedFields: UpdateEventTypes, customEventIds?: string[]) => {
      if (!formikRef.current) return;
      const newUpdatedEvents = eventsService.getNewUpdatedEvents(
        formikRef.current.values,
        updatedFields,
        customEventIds ? customEventIds : eventIds,
      );
      dispatch({
        type: CourseManagerTypes.SET_UPDATED_EVENTS,
        payload: { updatedEvents: newUpdatedEvents },
      });
      const response = await eventsService.updateEvents(casPublicId, newUpdatedEvents);

      if (!response.isAxiosError) {
        handleErrorAction(formikRef.current);
        formikRef.current.setFieldValue('updatedEvents', newUpdatedEvents);
        const newEvents = formikRef.current.values.eventsData.result.map(event => {
          const newEvent = newUpdatedEvents.find(
            newUpdatedEvent => newUpdatedEvent.eventId === event.eventId,
          );
          return newEvent ? newEvent : event;
        });
        formikRef.current.setFieldValue('eventsData.result', newEvents);
      }

      handleCloseEditEvent();
    },
    [eventsService, eventIds, dispatch, casPublicId, handleCloseEditEvent, handleErrorAction],
  );

  const handlePublishEvents = useCallback(
    (eventIds: string[]) => {
      if (formikRef.current) {
        const eventIdsForUpdate: string[] = [];
        eventIds.forEach(eventId => {
          const eventsData = formikRef.current?.values.eventsData;
          if (eventsData) {
            const eventToUpdate = eventsData.result.find(
              eventItem => eventItem.eventId.toString() === eventId,
            );

            if (eventToUpdate) {
              eventIdsForUpdate.push(eventId);
            }
          }
        });

        if (eventIdsForUpdate.length > 0) {
          handlePublishSubmit({ published: true }, eventIdsForUpdate);
        }
        handleCloseEditEvent();
      }
    },
    [handleCloseEditEvent, handlePublishSubmit, formikRef],
  );

  useEffect(() => {
    dispatch({
      type: CourseManagerTypes.SET_UPDATED_EVENTS,
      payload: { updatedEvents: null },
    });
  }, [dispatch]);

  const handleSubmit = async (values: EventList, formik: FormikHelpers<EventList>) => {
    const response = await eventsService.updateEvents(casPublicId, values.updatedEvents);

    if (!response.isAxiosError) {
      handleErrorAction(formik);
    }

    refetchEventsListData();
  };

  return (
    <>
      {pastCourseCancellation && (
        <NotificationLayout>
          <Notification severity='error' message={pastCourseCancellation} />
        </NotificationLayout>
      )}
      <Formik
        innerRef={formikRef}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{ ...baseFilter, eventsData: eventsData! }}
        onSubmit={handleSubmit}>
        {({ values }) => (
          <Container>
            {isLoading && <SpinnerLoader />}

            {values.eventsData && !isLoading && (
              <>
                {eventIds && eventIds.length >= 1 && (
                  <>
                    <EditEventsStream
                      eventsService={eventsService}
                      handleSubmit={handleSubmitLocal}
                      handleCancel={handleCancelEvents}
                      isOpen={editStreamingDataModalOpen}
                      handleClose={handleCloseEditEventStream}
                      eventIds={eventIds}
                    />
                    <EditEvents
                      isOpen={editEventModalOpen}
                      handleClose={handleCloseEditEvent}
                      handleSubmit={handleSubmitLocal}
                      handleCancel={handleCancelEvents}
                      eventIds={eventIds}
                    />

                    <PublishEvents
                      open={publishModalOpen}
                      eventIds={eventIds}
                      handlePublish={handlePublishEvents}
                      handleClose={handleClosePublishEventsModal}
                    />
                    <CancelEvents
                      eventIds={eventIds}
                      handleClose={handleCloseCancelEventModal}
                      handleCancel={handleCancelEvents}
                      open={cancelModalOpen}
                    />
                  </>
                )}

                <StyledForm>
                  <EventsTable
                    isLoading={isLoading}
                    displayColumns={scheduleDisplayColumns}
                    handleEditEventsModal={handleOpenEditSingleEvent}
                    handleEditEventsStreamDataModal={handleEditSingleEventStream}
                    handlePublishEventsModal={handleOpenPublishEventsModal}
                    handleCancelEventsModal={handleOpenCancelEventModal}
                    paginationState={paginationState}
                    setPaginationState={setPaginationState}
                    handleSelectedRows={ids => setSelectedRows(ids)}
                    selectedRows={selectedRows}
                    handleUpdateEvents={() => refetchEventsListData()}
                  />
                </StyledForm>
              </>
            )}
          </Container>
        )}
      </Formik>
    </>
  );
};
