import { TableBody } from '@mui/material';
import { EquipmentAndServiceRows } from './EquipmentAndServiceRows';

export interface EquipmentAndServiceTableBodyProps {
  handleChange: (property: string, value: string, idx: number) => void;
}

export const EquipmentAndServiceContractTableBody = (
  props: EquipmentAndServiceTableBodyProps,
): JSX.Element => {
  const { handleChange } = props;

  return (
    <TableBody>
      <EquipmentAndServiceRows handleChange={handleChange} property='contractServices' disabled />
    </TableBody>
  );
};
