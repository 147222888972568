import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

const drawerOpenWidth = 300;
const drawerCloseWidth = 80;

export const useSidebarNavigationStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    '& .contactContainer': {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 66,
    },
    scrollbarColor: `${FigmaDesignTokens.Grey[500]} ${theme.palette.primary.dark}`,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      width: 8,
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.main,
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: FigmaDesignTokens.Grey[100],
    },
    '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
      backgroundColor: theme.palette.primary.main,
    },
    '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
      backgroundColor: theme.palette.primary.main,
    },
    '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
      backgroundColor: FigmaDesignTokens.Grey[100],
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: FigmaDesignTokens.Grey[100],
    },
  },
  backdrop: {
    zIndex: 1000,
  },
  naviIconWrapper: {
    position: 'fixed',
    top: 50,
    left: '280px',
    height: '40px',
    width: '40px',
    zIndex: 1201,
    padding: '5px',
  },
  naviIconCloseWrapper: {
    transform: 'rotate(180deg)',
  },
  brandContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '28px',
    gap: '16px',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    height: 'auto',
    minHeight: 72,
    '&:hover': {
      cursor: 'pointer',
    },

    transition: theme.transitions.create(['padding', 'margin-bottom', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('lg')]: {
      padding: 0,
      justifyContent: 'center',
      height: 72,
    },
  },
  alignLeft: {
    justifyContent: 'flex-start',
    paddingLeft: 28,
  },

  brandContainerHide: {
    display: 'none',
  },
  logo: {
    backgroundImage: 'url("/logo.svg")',
    width: 40,
    height: 40,
    marginBottom: 2,
    backgroundRepeat: 'inherit',
    [theme.breakpoints.down('md')]: {
      backgroundRepeat: 'no-repeat',
    },
    transition: theme.transitions.create(['width', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logoFullSize: {
    width: 40,
    minHeight: 40,
    backgroundRepeat: 'inherit',
  },
  drawer: {
    width: drawerOpenWidth,
    [theme.breakpoints.down('md')]: {
      width: drawerCloseWidth,
      position: 'absolute',
    },
  },
  drawerOpen: {
    width: drawerOpenWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerClose: {
    width: drawerCloseWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('lg')]: {
      width: drawerOpenWidth,
    },
  },
  paper: {
    overflow: 'hidden',
    top: 0,
    left: 0,
    width: drawerCloseWidth,
    backgroundColor: theme.palette.primary.dark,
    [theme.breakpoints.up('lg')]: {
      width: drawerOpenWidth,
    },
  },
  paperOpen: {
    width: drawerOpenWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  paperClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  navigationList: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    lineHeight: 1,
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {},
  },
  hidden: {
    display: 'none',
  },
  show: {
    display: 'inherit',
  },
  menuIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuIcon: {
    width: 25,
    marginTop: 14,
  },
  brandWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  menuContainer: {
    marginTop: 24,
    flexDirection: 'column',
    height: '85%',
  },
  itemsWrapper: {
    overflow: 'auto',
    height: '100%',
    paddingBottom: 24,
  },
}));
