import { AxiosError, AxiosResponse } from 'axios';

import { HttpService } from '@/Services';
import { Category, Course, CoursesData } from '@/Types';

export enum COURSE_ACTIONS {
  SAVE = 'SAVE',
  ARCHIVE = 'ARCHIVE',
  DELETE = 'DELETE',
}

const API_URL = process.env.REACT_APP_PIMCORE_ENDPOINT;

export class PartnerCoursesService {
  public httpService: HttpService;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  public async getCourseCategories(
    language: string,
  ): Promise<AxiosResponse<Category[]> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.get(`${API_URL}api/course_categories?language=${language}`);
  }

  public async getCourse(
    id: string,
    courseId: number | string,
  ): Promise<AxiosResponse<Course> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.get(`${API_URL}api/partners/${id}/courses/${courseId}`);
  }

  public async getCourses(
    id: string,
    isActiveCourse: boolean,
    currentPage: number,
  ): Promise<CoursesData> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();
    const url = `${API_URL}api/partners/${id}/courses?open_events=${isActiveCourse}&pagination[page]=`;

    try {
      const coursesData = (await axiosInstance.get(`${url}${currentPage + 1}`)).data as CoursesData;

      return coursesData;
    } catch (e) {
      throw new Error(`Failed to fetch courses: ${(e as AxiosError).message}`);
    }
  }

  public async createOnsiteCourse(
    id: string,
    data: Partial<Course>,
  ): Promise<AxiosResponse<Course> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    const adjustedData = {
      ...data,
      courseDescription: data.shortDescription,
      courseLevel: data.level,
      courseCapacity: data.capacity,
      courseNeededAccessories: data.neededAccessoires,
      courseMainCategory: data.mainCategory,
    };

    return await axiosInstance.post(`${API_URL}api/partners/${id}/onsite/courses`, adjustedData);
  }

  public async createOnlineCourse(
    id: string,
    data: Partial<Course>,
  ): Promise<AxiosResponse<Course> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.post(`${API_URL}api/partners/${id}/courses`, data);
  }

  public async updateOnsiteCourse(
    id: string,
    courseId: number,
    data: Partial<Course>,
  ): Promise<AxiosResponse<Course> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxiosForPatchRequest();

    const adjustedData = {
      ...data,
      courseDescription: data.shortDescription,
      courseLevel: data.level,
      courseCapacity: data.capacity,
      courseNeededAccessories: data.neededAccessoires,
      courseMainCategory: data.mainCategory,
    };

    return await axiosInstance.patch(
      `${API_URL}api/partners/${id}/courses/${courseId}/onsite`,
      adjustedData,
    );
  }

  public async updateOnlineCourse(
    id: string,
    courseId: number,
    data: Partial<Course>,
  ): Promise<AxiosResponse<Course> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxiosForPatchRequest();

    return await axiosInstance.patch(`${API_URL}api/partners/${id}/courses/${courseId}`, data);
  }

  public async deleteCourse(
    id: string,
    courseId: number,
  ): Promise<AxiosResponse<Course> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxiosForPatchRequest();

    return await axiosInstance.delete(`${API_URL}api/partners/${id}/courses/${courseId}`);
  }

  public async archiveCourse(
    id: string,
    courseId: number,
  ): Promise<AxiosResponse<Course> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.put(`${API_URL}api/partners/${id}/courses/${courseId}`, {
      archived: true,
    });
  }

  public async activateServicePackage(
    id: string,
    servicePackageName: string,
  ): Promise<AxiosResponse<Course> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.put(`${API_URL}api/partners/${id}/service_packages`, {
      servicePackageName,
    });
  }
}
