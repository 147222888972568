import { useCallback, useEffect, useState } from 'react';

type StorageType = 'LOCAL_STORAGE' | 'SESSION_STORAGE';

function useReadStorage<T>(key: string, storageType: StorageType) {
  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = useCallback(() => {
    // Prevent build error "window is undefined" but keep keep working
    if (typeof window === 'undefined') {
      return null;
    }

    try {
      const item =
        storageType === 'LOCAL_STORAGE'
          ? window.localStorage.getItem(key)
          : window.sessionStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : null;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return null;
    }
  }, [key, storageType]);

  const handleStorageChange = useCallback(() => {
    setStoredValue(readValue());
  }, [readValue]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(readValue);

  // Listen if localStorage changes
  useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // this only works for other documents, not the current one
    window.addEventListener(storageType + '-storage', handleStorageChange);

    // this is a custom event, triggered in writeValueToLocalStorage
    // See: useLocalStorage()
    window.addEventListener(storageType, handleStorageChange);

    return () => {
      window.removeEventListener(storageType + '-storage', handleStorageChange);
      window.removeEventListener(storageType, handleStorageChange);
    };
  }, [handleStorageChange, storageType]);

  return storedValue;
}

export default useReadStorage;
