import { Box, Button, Grid, styled, Typography } from '@mui/material';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const SupportWrapper = styled(Typography)(({ theme }) => ({
  background: FigmaDesignTokens.White,
  padding: '36px 36px 80px',
  borderRadius: 4,
  '& .header_title': {
    fontSize: 20,
  },
  '.linkText': {
    color: theme.palette.primary.main,
    fontWeight: 500,
    textDecoration: 'underline !important',
  },
}));

export const FAQTitle = styled(Typography)(() => ({
  fontWeight: 700,
  lineHeight: '32px',
  padding: '24px',
}));

export const ModalTitle = styled(FAQTitle)(() => ({
  padding: '0 24px 24px',
}));

export const QuestionWrapper = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${FigmaDesignTokens.Grey[300]}`,
  padding: '24px',
  '& span': {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));

export const LearnMoreButton = styled(Button)(({ theme }) => ({
  margin: '24px',
  color: theme.palette.text.primary,
  borderRadius: 18,
  textTransform: 'capitalize',
  borderColor: theme.palette.text.primary,
}));

export const ModalWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  backgroundColor: theme.palette.background.paper,
  border: 'none',
  outline: 'none',
}));

export const CloseButton = styled(Button)`
  width: 36px;
  height: 36px;
  background: none;
  min-width: 36px;
  min-height: 36px;
  border-radius: 20px;
`;

export const ModalContent = styled(Box)(() => ({
  overflow: 'auto',
  height: '100%',
  maxHeight: '85vh',
  '& p': {
    padding: '0 32px 32px 32px',
  },
}));

export const EmptyWrapper = styled(Box)(() => ({
  width: '100%',
  marginTop: '24px',
  gap: 16,
  padding: '21px 24px',
  objectFit: 'contain',
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: `2px solid ${FigmaDesignTokens.Grey[100]}`,
  '.info': {
    gap: 16,
    display: 'flex',
    alignItems: 'center',
  },

  '.text': {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
  },
}));

export const GridWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '55%',
    flexBasis: '75%',
  },
  '.card_wrapper': {
    border: `2px solid ${FigmaDesignTokens.Grey[100]}`,
    borderRadius: 8,
    padding: 48,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
  },
  '& .link_all': {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.38,
    boxShadow: 'unset',
    textTransform: 'unset',
    padding: '7px 16px',
    borderRadius: 18.5,
    background: theme.palette.primary.main,
    textDecoration: 'unset',
    color: FigmaDesignTokens.White,
    width: 'fit-content',
  },

  '& .link_all:hover': {
    backgroundColor: theme.palette.primary.dark,
  },

  '& .card_link': {
    display: 'block',
    marginBottom: 24,
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.38,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },

  '& .card_link:hover': {
    textDecoration: 'underline !important',
  },

  '& .card_title': {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 16,
    fontWeight: 900,
    lineHeight: 1.38,
    color: FigmaDesignTokens.Grey[950],
    textTransform: 'uppercase',
    marginBottom: 16,
  },

  '& .card_text': {
    fontSize: 16,
    lineHeight: 1.38,
    color: FigmaDesignTokens.Grey[500],
    marginBottom: 36,
  },
}));

export const SupportDetailWrapper = styled(Box)(() => ({
  '.content': {
    background: FigmaDesignTokens.White,
    padding: 36,
    borderRadius: 4,
    marginTop: '24px',

    '& .title': {
      fontSize: '20px',
    },

    '&__text': {
      fontSize: 16,
      lineHeight: 1.38,
      color: FigmaDesignTokens.Grey[500],
      margin: '16px 0 48px',
    },

    '&__item': {
      borderTop: `1px solid ${FigmaDesignTokens.Grey[300]}`,
      padding: '24px 0',
      display: 'flex',
      gap: 36,
      '&:hover, &:hover + .content__item': {
        borderTop: `2px solid ${FigmaDesignTokens.bg.brand.brand}`,
      },
      '&:last-child:hover': {
        borderBottom: `2px solid ${FigmaDesignTokens.bg.brand.brand}`,
      },

      '&_title': {
        fontSize: 18,
        fontWeight: 500,
        lineHeight: 1.22,
        color: FigmaDesignTokens.Grey[950],
        width: '30%',
        minWidth: '30%',
      },

      '&_info': {
        width: '-webkit-fill-available',
      },

      '&_text': {
        fontSize: 16,
        lineHeight: 1.38,
        color: FigmaDesignTokens.Grey[950],
      },

      '&_link': {
        cursor: 'pointer',
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: 1.38,
        color: FigmaDesignTokens.bg.brand.brand,
        display: 'inline-flex',
        marginTop: 24,
        textDecoration: 'unset',
      },
    },

    '.answer': {
      fontSize: 18,
      lineHeight: 1.44,
      color: FigmaDesignTokens.Grey[950],
      marginBottom: 20,
      marginTop: 20,
      '&__item': {
        lineHeight: '1.5',
      },
    },
  },
}));
