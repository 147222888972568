export enum StatisticsType {
  Company = 'company',
  Member = 'member',
  Overview = 'overview',
  Training = 'training_instruction',
}

export type CompanyStatsDataType = {
  company_id: number;
  company_name: string;
  checkins: number;
  eus: number;
  id: string;
};

export type MemberStatsDataType = {
  company_id: number;
  company_name: string;
  checkins: number;
  id: string;
  eu_id?: number;
};
export type TrainInstructionDataType = {
  company_name: string;
  check_out_year_month_day: number;
  id: string;
  eu_id?: number;
};

export type CheckedOnDayType = {
  eu_id: string;
  company_id: number;
  company_name: string;
  checkin_type: 'card' | 'hansefitapp' | 'hansefitcard' | 'vpp'; // add other checkin types here
  check_in_time: string;
  check_out_time?: string;
  id: string;
  check_in_timestamp_localtime: string;
  check_out_timestamp_localtime?: string;
  visit_duration: number;
  value: number;
  course_type: 'onsite' | 'online';
};

export type OverviewStatsDataType = Record<string, CheckedOnDayType[]>;

export type GetStatsRequestParamsProps = {
  partnerPublicId: string | undefined;
  statsType: StatisticsType;
  startDate?: string;
  endDate?: string;
};

export type BasicStatisticsResponseType = {
  startDay: string;
  endDay: string;
  totalCheckins: number;
};

export type ExtendedCheckedOnDayType = CheckedOnDayType & {
  checkedInDateTime: number;
  checkedOutDateTime: number | null;
};

export type GetCompanyStatsResponseType = BasicStatisticsResponseType & {
  data: CompanyStatsDataType[];
};

export type GetMemberStatsResponseType = BasicStatisticsResponseType & {
  data: MemberStatsDataType[];
};

export type GetOverviewStatsResponseType = BasicStatisticsResponseType & {
  data: OverviewStatsDataType;
};

export type GetTrainInstrStatsResponseType = BasicStatisticsResponseType & {
  data: TrainInstructionDataType[];
};

export type GetStatisticsResponseType =
  | GetCompanyStatsResponseType
  | GetMemberStatsResponseType
  | GetOverviewStatsResponseType
  | GetTrainInstrStatsResponseType;

export type CheckinRowsType =
  | CheckedOnDayType
  | {
      id?: string;
      date: string;
      checkins: number;
    };

export type DateSelectionType = {
  startDate?: Date;
  endDate?: Date;
};

export type CheckInAllFieldProps = {
  date: null | Date;
  isOnlyShow: boolean;
};
