import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Grid, Link, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { HashLink } from 'react-router-hash-link';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { BodyTextRegular, PageHeadline, SpinnerLoader } from '@/Components';
import { useQuery } from '@/Hooks/useQueryParams';
import { useFaqListQuery } from '@/Queries';

import { EmptyWrapper, SupportWrapper } from './Faq.styles';
import { FAQCard } from './FaqCard';
import { FaqSectionPage } from './FaqSectionPage';
import HubspotSupportForm, { ANCHOR_ID } from './HubspotSupportForm';

const FaqPage = () => {
  const { formatMessage } = useIntl();
  const query = useQuery();

  const { isLoading, data } = useFaqListQuery();
  const categoryFAQs = useMemo(() => data?.data?.categoryFaqs || [], [data]);

  const categoryFaq = query.get('faq');
  const selectedFAQ = useMemo(() => {
    if (!categoryFaq || !categoryFAQs.length) {
      return null;
    }
    return categoryFAQs.find(faq => faq.id.toString() === categoryFaq) || null;
  }, [categoryFaq, categoryFAQs]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryFaq]);

  if (isLoading) {
    return (
      <ContentContainer center>
        <SpinnerLoader />
      </ContentContainer>
    );
  }

  if (categoryFaq) {
    return <FaqSectionPage data={selectedFAQ} />;
  }

  const scrollWithOffset = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80; // 72px header + 8px small top margin
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  return (
    <SupportWrapper>
      <Box pb={7}>
        <PageHeadline
          textContent={formatMessage({
            id: 'support.title',
            defaultMessage: 'Support',
          })}
        />
        <BodyTextRegular
          textContent={formatMessage(
            {
              id: 'navigation.support.go_to_hubspot_form',
              defaultMessage:
                'You have another question that is not answered here? Use the {form} below or our generell {faqLink}.',
            },
            {
              form: (
                <HashLink to={`#${ANCHOR_ID}`} scroll={scrollWithOffset} className='linkText'>
                  {formatMessage({
                    id: 'navigation.support.go_to_hubspot_form.link_text',
                    defaultMessage: 'form',
                  })}
                </HashLink>
              ),
              faqLink: (
                <Link
                  href={formatMessage({
                    id: 'navigation.support.go_to_hansefit_faq.link_action',
                    defaultMessage: 'https://hansefit.de/partner/faq/',
                  })}
                  target='_blank'
                  className='linkText'>
                  {formatMessage({
                    id: 'navigation.support.go_to_hansefit_faq.link_text',
                    defaultMessage: 'FAQ’s',
                  })}
                </Link>
              ),
            },
          )}
        />
      </Box>
      <Grid container spacing={4} pb={7}>
        {!!categoryFAQs.length &&
          categoryFAQs.map((card, index) => <FAQCard data={card} key={index} />)}
        {!categoryFAQs.length && (
          <Grid item xs={12}>
            <EmptyWrapper>
              <Box className='info'>
                <ErrorOutlineIcon />
                <Typography className='text' variant='body1'>
                  <strong>
                    {formatMessage({
                      id: 'faq.no_available',
                      defaultMessage: 'No FAQs are available',
                    })}
                  </strong>
                </Typography>
              </Box>
            </EmptyWrapper>
          </Grid>
        )}
      </Grid>
      <HubspotSupportForm />
    </SupportWrapper>
  );
};

export default FaqPage;
