import FigmaDesignTokens from '../design/design-tokens.json';

export class CropMap {
  public getCroppedImgAsBlob(image: any, crop: { [key: string]: any }): Promise<Blob | null> {
    const canvas = document.createElement('canvas');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;

    // New lines to be added
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';
      ctx.fillStyle = FigmaDesignTokens.White;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
      );
    }

    if (image) {
      return new Promise((resolve, reject) => {
        canvas.toBlob(
          blob => {
            if (blob) {
              resolve(blob);
            }
          },
          'image/jpeg',
          1,
        );
      });
    } else {
      return Promise.resolve(null);
    }
  }
}
