import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../design/design-tokens.json';

const useStyles = () =>
  makeStyles()(theme => ({
    avatar: {
      width: 'auto',
      height: 'auto',
      maxHeight: 65,
      maxWidth: 65,
    },
    avatarContainer: {
      width: 48,
      height: 48,
      borderRadius: '50%',
      backgroundColor: FigmaDesignTokens.Grey[300],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    checkoutButton: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      backgroundColor: theme.palette.error.light,
      borderRadius: 15,
      padding: '5px 12px',
      textTransform: 'uppercase',
      fontSize: 11,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
        color: FigmaDesignTokens.White,
        borderColor: theme.palette.error.main,
      },
      minWidth: 'inherit',
      width: 'auto',
    },
  }));

export default useStyles;
