import { configureStore } from '@reduxjs/toolkit';

import { usersService } from '@/Services';

import rootReducer from './rootReducer';

export * from './UI/UI.actions';

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(usersService.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
