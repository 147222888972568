import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const usePastEventListStyles = makeStyles()(() => ({
  contentContainerWrapper: {
    width: 1090,
    display: 'block',
  },
  addNewEventsBtn: {
    padding: '2px 18px 2px 2px',
    backgroundColor: FigmaDesignTokens.Blue[100],
    borderRadius: 15,
    height: 30,
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.83,
    letterSpacing: 0.34,
    color: FigmaDesignTokens.Blue[950],
    boxShadow: 'none',
    marginRight: 39,
    '& .MuiButton-startIcon': {
      marginRight: 8,
      marginLeft: 0,
    },
    '&:hover': {
      backgroundColor: FigmaDesignTokens.Grey[300],
    },
  },
}));

export const useTableBodyStyles = makeStyles()(theme => ({
  root: {
    borderRight: '170px transparent solid',
  },
  tableRow: {
    '&:hover': {
      '& .participantsText': {
        color: FigmaDesignTokens.Grey[950],
      },
      '& td div, & td button:not([disabled])': {
        backgroundColor: FigmaDesignTokens.Blue[100],
        color: FigmaDesignTokens.Grey[500],
      },
      '& td div:not(.tableCellContainerParticipants)': {},
      '& td .tableCellContainerUtil': {
        textDecoration: 'none',
        '& .utilizationTextWithDecor': {},
      },
    },
  },
  tableCell: {
    borderBottom: 'none',
    textAlign: 'left',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  tableCellContainerRound: {
    borderRadius: 10,
    backgroundColor: theme.palette.background.default,
    marginTop: 5,
    marginBottom: 5,
    marginRight: 10,
  },
  tableCellContainerFirstRow: {
    marginTop: '17px!important',
  },
  tableCellContainerFirst: {
    paddingLeft: 24,
  },
  tableCellContainerFirstRound: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  tableCellContainerLastRound: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    marginRight: 10,
  },
  tableCellContainerLast: {
    marginRight: 0,
  },
  tableCellContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    backgroundColor: theme.palette.background.default,
    height: 50,
    marginTop: 5,
    marginBottom: 5,
  },
  tableCellContainerStatus: {
    color: FigmaDesignTokens.Grey[400],
  },
  downloadBtn: {
    padding: 0,
    margin: '0 auto',
    lineHeight: 1.29,
    letterSpacing: 0.13,
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'none',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },

  tableCellContainerOdd: {
    backgroundColor: FigmaDesignTokens.Grey[50],
  },
  tableCellContainerUtil: {
    color: FigmaDesignTokens.Grey[950],
  },
}));

export const useTableHeadStyles = makeStyles()(() => ({
  root: {
    borderRight: '170px transparent solid',
  },
  tableCell: {
    padding: 0,
    borderBottom: 'none',
  },
  tableCellContainerFirst: {
    paddingLeft: 24,
  },
  tableCellContainerLast: {
    textAlign: 'center',
  },
}));
