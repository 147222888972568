import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const useSidebarGuestFooterStyles = makeStyles()(() => ({
  root: {
    color: FigmaDesignTokens.White,
    marginTop: 40,
  },
  navigationListItem: {
    fontSize: 12,
    lineHeight: 2,
    letterSpacing: 0.11,
    color: FigmaDesignTokens.White,
    marginLeft: 50,
  },
  topPartLinks: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
  },
}));
