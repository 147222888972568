import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppContext } from '@/Context';
import { PartnerServiceFactory } from '@/Services';
import { LanguageType } from '@/Types';

const usePartnerCategoriesQuery = ({ currentLanguage }: { currentLanguage: LanguageType }) => {
  const { dispatch } = useAppContext();
  const partnerServiceInstance = useMemo(
    () => new PartnerServiceFactory().getInstance(dispatch),
    [dispatch],
  );

  const queryResult = useQuery(
    ['getPartnerCategories', currentLanguage],
    async () => {
      const response = await partnerServiceInstance.getPartnerCategories(currentLanguage);

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 5,
    },
  );

  return queryResult;
};

export default usePartnerCategoriesQuery;
