import { BreadcrumbProps } from '@/Types';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import useCurrentUserBreadcrumbs from './useCurrentUserBreadcrumbs';
import useCurrentLanguage from '../useCurrentLanguage';

const useChangePasswordBreadcrumbs = (): BreadcrumbProps[] => {
  const { formatMessage } = useIntl();
  const currentLanguage = useCurrentLanguage();

  const currentBreadcrumbs = useCurrentUserBreadcrumbs();

  const staticBreadcrumbs = useMemo(
    () => [
      ...currentBreadcrumbs,
      {
        title: formatMessage({
          id: 'breadcrumbs.my_user_account.change_password',
          defaultMessage: 'Change password',
        }),
        href: `${currentLanguage}/change-password`,
      },
    ],
    [currentBreadcrumbs, currentLanguage, formatMessage],
  );

  return staticBreadcrumbs;
};

export default useChangePasswordBreadcrumbs;
