import React from 'react';

interface TabPanelNavigationProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
export const TabPanelNavigation = (props: TabPanelNavigationProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  );
};
