import moment from 'moment';

export const today = moment().toDate();
export const yesterday = moment().subtract(1, 'day').toDate();

export const firstOfCurrentMonth = moment().startOf('month').toDate();

export const defaultStatisticsRange = () => {
  const dates = [firstOfCurrentMonth, yesterday].sort((a, b) => a.valueOf() - b.valueOf());

  // if not in the same month, use start and end of last month
  if (yesterday.getMonth() !== firstOfCurrentMonth.getMonth()) {
    return {
      startDate: moment().subtract(1, 'day').startOf('month').toDate(),
      endDate: moment().subtract(1, 'day').endOf('month').toDate(),
    };
  }

  return {
    startDate: dates[0],
    endDate: dates[1],
  };
};
