import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { MarketingMaterialItem } from '@/Types';
import { StyleCategory } from './TableCell.styles';

type CategoryCellProps = {
  gridRenderCellParams: GridRenderCellParams<MarketingMaterialItem, number>;
};

export const CategoryCell = ({ gridRenderCellParams }: CategoryCellProps) => {
  const { categoryName } = gridRenderCellParams.row;
  return <StyleCategory>{categoryName}</StyleCategory>;
};
