import { createAsyncThunk } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { RootState } from '@/Store';
import * as Sentry from '@sentry/react';

export const Authenticate = createAsyncThunk<void, void, { state: RootState }>(
  'auth/authenticate',
  () =>
    Auth.currentAuthenticatedUser({ bypassCache: true }).then(user => {
      Sentry.setUser({
        email: user.attributes.email,
      });
    }),
);
