import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from '@/Context';
import { PartnerServiceFactory } from '@/Services';

import useCurrentPartnerIdQuery from './useCurrentPartnerIdQuery';

const useGetPartnerQuery = () => {
  const { dispatch } = useAppContext();
  const { data: casId = '' } = useCurrentPartnerIdQuery();
  const navigate = useNavigate();

  const queryResult = useQuery(
    ['useGetPartnerQuery', casId],
    async () => {
      const partnerServiceInstance = new PartnerServiceFactory().getInstance(dispatch);

      const partnerResponse = await partnerServiceInstance.getPartnerById(casId);
      return partnerResponse.data;
    },
    {
      staleTime: 1000 * 60 * 5,
      enabled: !!casId,
      onError: error => {
        console.error('Error getting partner by id, probably logged out', error);
        navigate('/logout');
      },
    },
  );

  return queryResult;
};

export default useGetPartnerQuery;
