import { createStyles } from '@mui/material';
import { withStyles } from 'tss-react/mui';

export const SwitchStyles = (component: any) =>
  withStyles(component, theme =>
    createStyles({
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        width: 48,
        height: 'initial',
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: theme.palette.background.paper,
          opacity: 1,
          transform: 'translateX(14px)',
        },
        '& .MuiSwitch-switchBase': {
          top: 5,
          left: -5,
        },
        '& .MuiSwitch-switchBase:hover': {
          background: 'none',
        },
        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
        },
        '& .MuiSwitch-thumb': {
          width: 16,
          height: 16,
        },
        '& .MuiSwitch-track': {
          height: 20,
          width: 36,
          borderRadius: 10,
        },
      },
    }),
  );
