import { Typography } from '@mui/material';
import clsx from 'clsx';

import { BasicTextStyleProps } from '@/Types';

//roboto 16px
export const BodyTextRegular = ({
  textContent,
  className,
  customColor,
  fontWeight = 400,
  textDecoration = 'none',
  textTransform,
}: BasicTextStyleProps) => (
  <Typography
    variant='body1'
    textTransform={textTransform}
    className={clsx(className)}
    color={customColor}
    fontWeight={fontWeight}
    sx={{ textDecoration }}>
    {textContent}
  </Typography>
);

//roboto 14px
export const BodyTextSmall = ({
  textContent,
  className,
  customColor,
  fontWeight = 400,
  fontStyle = 'normal',
  textTransform,
  textDecoration = 'none',
  textAlign = 'left',
}: BasicTextStyleProps) => (
  <Typography
    variant='body2'
    className={clsx(className)}
    color={customColor}
    fontStyle={fontStyle}
    textTransform={textTransform}
    fontWeight={fontWeight}
    sx={{ textDecoration }}
    textAlign={textAlign}>
    {textContent}
  </Typography>
);

//roboto 10px
export const CaptionText = ({
  textContent,
  className,
  customColor,
  fontWeight = 400,
  fontStyle = 'normal',
  textTransform,
  textDecoration = 'none',
}: BasicTextStyleProps) => (
  <Typography
    variant='caption'
    className={clsx(className)}
    color={customColor}
    fontStyle={fontStyle}
    textTransform={textTransform}
    fontWeight={fontWeight}
    sx={{ textDecoration }}>
    {textContent}
  </Typography>
);
