import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { CourseManagerTypes, useAppContext } from '@/Context';
import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';
import {
  getAddEventPathname,
  getCoursePathname,
  getEventsPathname,
  getNewCoursePathnameWithTemplate,
  getPastEventsPathname,
} from '@/Mappers';
import { useGetPartnerQuery } from '@/Queries';
import AddEventsIcon from '@/Static/Icons/atoms-symbols-events-add-event.svg';
import AddEventsDisabledIcon from '@/Static/Icons/atoms-symbols-events-add-event-disabled.svg';
import CopyIcon from '@/Static/Icons/atoms-symbols-events-copy.svg';
import CopyDisabledIcon from '@/Static/Icons/atoms-symbols-events-copy-disabled.svg';
import EventsEditIcon from '@/Static/Icons/atoms-symbols-events-edit.svg';
import EventsEditDisabledIcon from '@/Static/Icons/atoms-symbols-events-edit-disabled.svg';
import EventsListIcon from '@/Static/Icons/atoms-symbols-events-list.svg';
import EventsListDisabledIcon from '@/Static/Icons/atoms-symbols-events-list-disabled.svg';
import { Course } from '@/Types';

import { CustomTooltip } from '../../../../App/Shared/Tooltip/CustomTooltip';
import { ActionButton, Container } from './ActionsCell.styles';

type ActionsCellProps = {
  rowData: Course;
  isPastCourse?: boolean;
};

export const ActionsCell = ({ rowData, isPastCourse }: ActionsCellProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const language = useCurrentLanguage();
  const { data: partner } = useGetPartnerQuery();
  const { dispatch } = useAppContext();

  const handleCourseForm = useCallback(
    (courseId: number | undefined) => {
      courseId && navigate(getCoursePathname(language, courseId));
    },
    [navigate, language],
  );

  const handleEventForm = useCallback(
    (courseId: number | undefined) => {
      courseId && navigate(getAddEventPathname(language, courseId));
    },
    [navigate, language],
  );

  const handleEventListForm = useCallback(
    (courseId: number | undefined) => {
      if (!courseId) {
        return;
      }

      if (isPastCourse) {
        navigate(getPastEventsPathname(language, courseId));
      } else {
        navigate(getEventsPathname(language, courseId));
      }
    },
    [isPastCourse, navigate, language],
  );

  const handleNewCourseInitialForm = useCallback(
    (course: Course) => {
      dispatch({
        type: CourseManagerTypes.SET_COURSE,
        payload: { course: null },
      });
      setTimeout(() => {
        if (course.courseId) {
          navigate(getNewCoursePathnameWithTemplate(language, course.courseId));
        }
      });
    },
    [navigate, dispatch, language],
  );

  return (
    <Container>
      {partner?.readonly ? (
        <ActionButton disabled>
          <img alt='' src={EventsEditDisabledIcon} />
        </ActionButton>
      ) : (
        <CustomTooltip
          title={intl.formatMessage({
            id: 'view.courses.current_courses.table.events.tooltip.edit_course',
            defaultMessage: 'Edit course',
          })}>
          <ActionButton onClick={() => handleCourseForm(rowData.courseId)}>
            <img alt='' src={EventsEditIcon} />
          </ActionButton>
        </CustomTooltip>
      )}

      {partner?.readonly ? (
        <ActionButton disabled>
          <img alt='' src={AddEventsDisabledIcon} />
        </ActionButton>
      ) : (
        <CustomTooltip
          title={intl.formatMessage({
            id: 'view.courses.current_courses.table.events.tooltip.add_event',
            defaultMessage: 'Add appointment',
          })}>
          <ActionButton onClick={() => handleEventForm(rowData.courseId)}>
            <img alt='' src={AddEventsIcon} />
          </ActionButton>
        </CustomTooltip>
      )}

      {rowData.totalEvents === 0 || partner?.readonly ? (
        <ActionButton disabled>
          <img alt='' src={EventsListDisabledIcon} />
        </ActionButton>
      ) : (
        <CustomTooltip
          title={intl.formatMessage({
            id: 'view.courses.current_courses.table.events.tooltip.events',
            defaultMessage: 'Dates',
          })}>
          <ActionButton onClick={() => handleEventListForm(rowData.courseId)}>
            <img alt='' src={EventsListIcon} />
          </ActionButton>
        </CustomTooltip>
      )}

      {partner?.readonly ? (
        <ActionButton disabled>
          <img alt='' src={CopyDisabledIcon} />
        </ActionButton>
      ) : (
        <CustomTooltip
          title={intl.formatMessage({
            id: 'view.courses.current_courses.table.events.tooltip.copy_as_template',
            defaultMessage: 'Copy as template',
          })}>
          <ActionButton onClick={() => handleNewCourseInitialForm(rowData)}>
            <img alt='' src={CopyIcon} />
          </ActionButton>
        </CustomTooltip>
      )}
    </Container>
  );
};
