import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';

import { ReactComponent as InfoIcon } from '@/Static/Icons/atoms-symbols-alert-notifitcation.svg';
import { ReactComponent as WarningIcon } from '@/Static/Icons/atoms-symbols-alert-warning.svg';
import { ReactComponent as WarningAlertIcon } from '@/Static/Icons/warning-alert.svg';

import { useInfoMessageStyles } from './InfoMessage.styles';
import { BodyTextRegular } from '../Typography';

export type TypeInfoProps = 'information' | 'warning' | 'warningAlert';

type InfoMessageProps = {
  title: string;
  description?: string;
  iconWidth?: string;
  sxContainer?: SxProps;
  type?: TypeInfoProps;
  Action?: React.FC;
};

const getIcon = (type: TypeInfoProps, iconClassName: string | undefined) => {
  switch (type) {
    case 'information':
      return <InfoIcon width={iconClassName} />;
    case 'warning':
      return <WarningIcon width={iconClassName} />;
    case 'warningAlert':
      return <WarningAlertIcon width={iconClassName} />;
    default:
      return '';
  }
};

export const InfoMessage = ({
  type = 'information',
  title,
  description,
  Action,
  iconWidth = '24',
  sxContainer,
}: InfoMessageProps) => {
  const { classes } = useInfoMessageStyles(type)();
  return (
    <Box
      className={clsx(classes.infoContainer, classes.infoContainerBackground)}
      sx={{ ...(sxContainer || {}) }}>
      <Box>{getIcon(type, iconWidth)}</Box>
      <Box>
        <Box className={classes.contentHeader}>
          <BodyTextRegular textContent={title} fontWeight={600} />
          {Action && <Action />}
        </Box>
        <BodyTextRegular textContent={description || ''} className={classes.textColor} />
      </Box>
    </Box>
  );
};
