import { DatePicker } from '@mui/x-date-pickers-pro';
import moment from 'moment';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { firstOfCurrentMonth, yesterday, today } from '@/Utils';
import { RangePickerWrapper } from '../DataGridTable/Toolbar/Toolbar.styles';

type SelectedDates = { startDate?: Date; endDate?: Date };
type DateRangePickerProps = {
  fieldLabel?: string;
  onSelect: (dates: SelectedDates) => void;
  value?: SelectedDates;
};

const InputDateRangePicker = ({ onSelect, value }: DateRangePickerProps) => {
  const initialDates = {
    startDate: today.getDate() === 1 ? yesterday : firstOfCurrentMonth,
    endDate: yesterday,
  };
  const [statsRange, setStatsRange] = useState<SelectedDates>(value || initialDates);
  const [pickerOpen, setPickerOpen] = useState(false);

  const selectStartDate = (date: Date | null) => {
    if (!date) return;
    setStatsRange(prev => ({ startDate: date, endDate: prev.endDate }));

    onSelect({
      startDate: moment(date).toDate(),
      endDate: moment(statsRange.endDate).toDate(),
    });
    setPickerOpen(true);
  };
  const { formatMessage } = useIntl();

  const selectEndDate = (date: Date | null) => {
    if (!date) return;
    setStatsRange(prev => ({ startDate: prev.startDate, endDate: date }));
    onSelect({
      startDate: moment(statsRange.startDate).toDate(),
      endDate: moment(date).toDate(),
    });
    setPickerOpen(false);
  };

  return (
    <RangePickerWrapper>
      <DatePicker
        label={formatMessage({ id: 'dateRangePicker.from', defaultMessage: 'From' })}
        value={statsRange.startDate}
        onChange={selectStartDate}
        disableFuture
        maxDate={yesterday}
        views={['year', 'month', 'day']}
        disableHighlightToday
      />
      <DatePicker
        label={formatMessage({ id: 'dateRangePicker.to', defaultMessage: 'To' })}
        value={statsRange.endDate}
        onChange={selectEndDate}
        open={pickerOpen}
        onOpen={() => setPickerOpen(true)}
        onClose={() => setPickerOpen(false)}
        disableFuture
        maxDate={yesterday}
        views={['year', 'month', 'day']}
        disableHighlightToday
      />
    </RangePickerWrapper>
  );
};

export default InputDateRangePicker;
