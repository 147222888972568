import { AlertColor } from '@mui/material';

import Error from '@/Static/Icons/atoms-symbols-alert-error.svg';
import Info from '@/Static/Icons/atoms-symbols-alert-info.svg';
import Warning from '@/Static/Icons/atoms-symbols-alert-warning.svg';

export type NotificationIconProps = {
  severity: AlertColor;
};

export const NotificationIcon = ({ severity }: NotificationIconProps) => (
  <>
    {severity === 'error' && <img alt='' src={Error} />}
    {severity === 'info' && <img alt='' src={Info} />}
    {severity === 'warning' && <img alt='' src={Warning} />}
  </>
);
