import { Box, TextField, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { Field, FieldAttributes } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import useModalStyles from '@/App/Shared/Modal/ModalStyles';
import { LineDivider, SubHeadlineDisplay } from '@/Components';
import checkInMockup from '@/Static/Img/app-check-in-id-mockup.png';
import { ManualCheckInputType, TOTPErrorResponseType } from '@/Types';

import ActionModal from './ActionModal';

export const CheckInModal = ({
  onHide,
  handleSubmit,
}: {
  onHide: () => void;
  handleSubmit: (data: ManualCheckInputType) => Promise<void>;
}) => {
  const intl = useIntl();
  const { classes } = useModalStyles();

  const [errors, setErrors] = useState<TOTPErrorResponseType | null>(null);

  const manualCheckinDefaults: ManualCheckInputType = {
    totp: '',
    euId: '',
  };

  const clearErrors = () => setErrors(null);

  const closeModal = () => {
    clearErrors();
    onHide();
  };

  const handleModalSubmit = (data: ManualCheckInputType) =>
    handleSubmit(data)
      .then(() => {
        closeModal();
      })
      .catch(error => {
        const errors = error as AxiosError;

        if (errors?.response?.data) {
          setErrors(errors.response.data as TOTPErrorResponseType);
        }
      });

  const onChangeCustomerNumber = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: FieldAttributes<any>,
  ) => {
    const uppercasedValue = event.target.value.toUpperCase();
    field.onChange({
      target: {
        name: field.name,
        value: uppercasedValue,
      },
    });
    clearErrors();
  };

  const modalContent = (
    <>
      <Box
        className={classes.userInfoWrapper}
        sx={{
          alignItems: 'center',
          marginTop: '-20px',
        }}>
        <Box className={classes.pictureWrapper}>
          <img src={checkInMockup} alt='Check-In Mobile App' />
        </Box>
        <Typography className={classes.InfoWrapper} variant='body1'>
          {intl.formatMessage({
            id: 'manual_checkin.modal.user_info.description',
            defaultMessage:
              'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
          })}
        </Typography>
      </Box>
      <Box>
        <LineDivider verticalSpace={32} />
        <SubHeadlineDisplay
          textContent={intl.formatMessage({
            id: 'manual_checkin.modal.subtitle.chekinID',
            defaultMessage: 'Check-in ID',
          })}
        />
        <LineDivider verticalSpace={12} />
        <Field name='totp' type='number'>
          {({ field, meta }: FieldAttributes<any>) => (
            <TextField
              {...field}
              error={(meta.touched && meta.error) || errors?.error || errors?.violations}
              helperText={(meta.touched && meta.error) || errors?.error || errors?.violations}
              onFocus={() => clearErrors()}
              label={intl.formatMessage({
                id: 'manual_checkin.modal.textfield.checkinID.label',
                defaultMessage: 'Check-In ID',
              })}
              fullWidth
              placeholder={intl.formatMessage({
                id: 'manual_checkin.modal.textfield.checkinID.placeholder',
                defaultMessage: 'Please enter the 6-digit ID here',
              })}
              className={classes.textField}
            />
          )}
        </Field>
      </Box>
      <Box>
        <LineDivider verticalSpace={32} />
        <SubHeadlineDisplay
          textContent={intl.formatMessage({
            id: 'manual_checkin.modal.subtitle.customer_number',
            defaultMessage: 'Customer number',
          })}
        />
        <LineDivider verticalSpace={12} />
        <Field name='euId'>
          {({ field, meta }: FieldAttributes<any>) => (
            <TextField
              {...field}
              error={meta.touched && meta.error}
              helperText={meta.touched && meta.error}
              onChange={event => onChangeCustomerNumber(event, field)}
              label={intl.formatMessage({
                id: 'manual_checkin.modal.textfield.customer_number.label',
                defaultMessage: 'Customer number',
              })}
              onFocus={() => clearErrors()}
              fullWidth
              placeholder={intl.formatMessage({
                id: 'manual_checkin.modal.textfield.customer_number.placeholder',
                defaultMessage: 'Please enter the 8-digit customer number here',
              })}
              className={classes.textField}
            />
          )}
        </Field>
      </Box>
    </>
  );
  return (
    <ActionModal
      title={intl.formatMessage({
        id: 'manual_checkin.modal.header',
        defaultMessage: 'Add Check-In',
      })}
      content={modalContent}
      ctaDenyTitle={intl.formatMessage({
        id: 'manual_checkin.modal.cta.cancel',
        defaultMessage: 'Cancel',
      })}
      ctaConfirmTitle={intl.formatMessage({
        id: 'manual_checkin.modal.cta.submit',
        defaultMessage: 'Add Check-in ',
      })}
      onClose={closeModal}
      onSubmit={handleModalSubmit}
      initialState={manualCheckinDefaults}
    />
  );
};
