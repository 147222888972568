import { Box, StepIconProps } from '@mui/material';
import clsx from 'clsx';

import { useRegisterStepIconStyles } from './Stepper.styles';

export interface RegisterStepIconProps extends StepIconProps {
  stepNumber: number;
  label: string;
}

export const RegisterStepIcon = (props: RegisterStepIconProps) => {
  const { active, completed, stepNumber, label } = props;
  const { classes } = useRegisterStepIconStyles();
  return (
    <Box
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      <Box className={classes.stepNumber}>{stepNumber}</Box>
      <Box>{label}</Box>
    </Box>
  );
};
