import { Box, Link } from '@mui/material';
import { useIntl } from 'react-intl';

import { useSidebarGuestFooterStyles } from '../Styles/SidebarGuestFooter.styles';

export const SidebarGuestFooter = (): JSX.Element => {
  const { classes } = useSidebarGuestFooterStyles();
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <Box className={classes.topPartLinks}>
        <Link
          href={intl.formatMessage({
            id: 'footer.links.imprint.href',
            defaultMessage: 'https://hansefit.de/impressum/',
          })}
          className={classes.navigationListItem}>
          {intl.formatMessage({ id: 'footer.links.imprint.text', defaultMessage: 'Imprint' })}
        </Link>
        <Link
          href={intl.formatMessage({
            id: 'footer.links.gdpr.href',
            defaultMessage: 'https://hansefit.de/datenschutz/',
          })}
          className={classes.navigationListItem}>
          {intl.formatMessage({ id: 'footer.links.gdpr.text', defaultMessage: 'Privacy' })}
        </Link>
        <Link
          href={intl.formatMessage({
            id: 'footer.links.legal.href',
            defaultMessage: 'https://static.hansefit.de/partner_nutzungsbedingungen.html',
          })}
          className={classes.navigationListItem}>
          {intl.formatMessage({ id: 'footer.links.legal.text', defaultMessage: 'Terms of use' })}
        </Link>
      </Box>

      <Box height='24px' lineHeight='2px'>
        <Link
          href={intl.formatMessage({
            id: 'footer.links.homepage.href',
            defaultMessage: 'https://www.hansefit.de',
          })}
          className={classes.navigationListItem}>
          {intl.formatMessage({
            id: 'footer.links.homepage.href',
            defaultMessage: 'https://www.hansefit.de',
          })}
        </Link>
      </Box>
    </Box>
  );
};
