import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import { Button, Flag } from '@/Components';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

const useStyles = makeStyles()(() => ({
  root: {
    position: 'relative',
  },
  container: {
    position: 'absolute',
    width: 300,
    top: 206,
    right: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '13px 19px 15px 15px',
    borderRadius: 9,
    backgroundColor: FigmaDesignTokens.Grey[100],
    zIndex: 3,
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  flag: {
    marginRight: 5,
  },
  header: {
    fontSize: 14,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 0.13,
    color: FigmaDesignTokens.Grey[950],
  },
  description: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.11,
    color: FigmaDesignTokens.Grey[950],
    textAlign: 'left',
    marginTop: 10,
  },
  cta: {
    alignSelf: 'flex-end',
    marginTop: 8,
  },
}));

export type TranslateNotificationProps = {
  handleClose: () => void;
};

export const TranslateNotification = ({ handleClose }: TranslateNotificationProps): JSX.Element => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.headerWrapper}>
          <Box className={classes.flag}>
            <Flag width={20} height={20} src='/assets/flags/en.svg' />
          </Box>

          <Typography className={classes.header}>
            {formatMessage({
              id: 'translate.notification.title',
              defaultMessage: ' Automatic Translation',
            })}
          </Typography>
        </Box>
        <Typography className={classes.description}>
          {formatMessage({
            id: 'translate.notification.description',
            defaultMessage:
              'Your texts will be translated automatically. If you want to check the translations, You can choose between the original text and the by selecting the respective language Switch translation back and forth.',
          })}
        </Typography>
        <Button className={classes.cta} size='md' onClick={handleClose}>
          {formatMessage({
            id: 'translate.notification.btn.title',
            defaultMessage: 'OK, understood',
          })}
        </Button>
      </Box>
    </Box>
  );
};
