import { AxiosError, AxiosResponse } from 'axios';

import { HttpService } from '@/Services';
import {
  AddressAndContactPartialType,
  CategoriesAndTagsPartial,
  Category,
  CheckInOptionsPartial,
  DescriptionPartial,
  EquipmentsAndServicesUpdate,
  LanguageType,
  OpeningHoursPartial,
  Partner,
  PartnerListItem,
  PartnerRoleStatus,
  Role,
  StatsData,
  User,
} from '@/Types';

export class PartnerService {
  public httpService: HttpService;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  public async getPartnerById(id: string): Promise<AxiosResponse<Partner> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.get(`${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}`);
  }

  public async getPartnerCategories(
    language: LanguageType,
  ): Promise<AxiosResponse<Category[]> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.get(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partner_categories?language=${language}`,
    );
  }

  public async getPartnersNames(): Promise<AxiosResponse<PartnerListItem[]> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.get(`${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners-names`);
  }

  public async getStats(id: string): Promise<AxiosResponse<StatsData> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.get(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}/dashboard/stats`,
    );
  }

  public async updateData(
    id: string,
    data:
      | AddressAndContactPartialType
      | CategoriesAndTagsPartial
      | CheckInOptionsPartial
      | OpeningHoursPartial
      | DescriptionPartial
      | EquipmentsAndServicesUpdate,
  ): Promise<AxiosResponse & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxiosForPatchRequest();
    return await axiosInstance.patch(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}`,
      data,
    );
  }

  public async getCurrentPartnerId(): Promise<string> {
    const partnerIdFromLocalStorage = localStorage.getItem('publicId');

    if (partnerIdFromLocalStorage) {
      return Promise.resolve(partnerIdFromLocalStorage);
    } else {
      const response = await this.httpService.getAuthClass().currentSession();
      const partnerRoleStatus: PartnerRoleStatus[] = JSON.parse(
        response.getIdToken().payload.partner_role_status,
      );

      if (partnerRoleStatus.length > 0) {
        localStorage.setItem('publicId', partnerRoleStatus[0].publicId);
        return Promise.resolve(partnerRoleStatus[0].publicId);
      } else {
        return Promise.resolve('');
      }
    }
  }

  public async refreshTokens(callback: () => void) {
    const currentUser = await this.httpService.getAuthClass().currentAuthenticatedUser();
    const currentSession = await this.httpService.getAuthClass().currentSession();
    await currentUser.refreshSession(currentSession.getRefreshToken(), callback);
  }

  public async getCurrentUserRole(): Promise<User['role'] | null> {
    const response = await this.httpService.getAuthClass().currentSession();
    const currentPartnerId = await this.getCurrentPartnerId();
    const partnerRoleStatus: PartnerRoleStatus[] = JSON.parse(
      response.getIdToken().payload.partner_role_status,
    );

    const partnerRole = partnerRoleStatus.find(f => f.publicId === currentPartnerId);

    if (partnerRoleStatus.length > 0 && partnerRole) {
      return Promise.resolve(partnerRole.role);
    }
    return Promise.resolve(Role.User);
  }

  public async getPartnerRoles(): Promise<{ [key: string]: string }[]> {
    const response = await this.httpService.getAuthClass().currentSession();
    return JSON.parse(response.getIdToken().payload.partner_role_status);
  }

  public async getPartnerDetails(id: string): Promise<AxiosResponse<Partner> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.get(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}/details`,
    );
  }
}
