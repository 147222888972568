import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../../design/design-tokens.json';

export const useFaqHelpStyles = makeStyles()(theme => ({
  root: {
    padding: '20px 30px',
    borderRadius: 9,
    backgroundColor: FigmaDesignTokens.White,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
    flex: 1,
  },
  header: {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 12,
    fontWeight: 900,
    lineHeight: 1.5,
    letterSpacing: 0.4,
    color: FigmaDesignTokens.Grey[950],
    marginBottom: 20,
  },
  shortInfo: {
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 0.18,
    color: FigmaDesignTokens.Grey[950],
    marginBottom: 30,
  },
  faqBtn: {
    padding: '7px 22px',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.83,
    letterSpacing: 0.34,
    borderRadius: 18,
    height: 30,
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
  },
  linkType: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    fontSize: 14,
  },
  linkTypeIcon: {
    marginRight: 8,
  },
  linkTypeHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0.18,
    marginTop: 20,
  },
}));

export const useProfileInfoStyles = makeStyles()(theme => ({
  root: {
    padding: '30px',
    borderRadius: 9,
    backgroundColor: FigmaDesignTokens.White,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
  },
  placeholderLogo: {
    maxWidth: '266px',
    height: '154px',
    borderRadius: 7,
  },
  logo: {
    width: '266px',
    height: 'auto',
    borderRadius: 7,
  },
  placeholder: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  leftSideInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  rightSideInfo: {
    flex: 1,
    marginLeft: '65px',
  },
  categoryInfo: {
    paddingRight: 11,
    borderRadius: 16,
    border: `solid 1px ${FigmaDesignTokens.Grey[300]}`,
    width: 'fit-content',
    height: 32,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  categoryIcon: {
    height: 32,
    width: 32,
    filter:
      'brightness(0) saturate(100%) invert(7%) sepia(7%) saturate(1021%) hue-rotate(187deg) brightness(87%) contrast(93%)',
  },
  headerProgressBar: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 1.29,
    letterSpacing: 0.13,
    color: FigmaDesignTokens.Blue[950],
  },
  progressBarText: {
    marginBottom: 0,
  },
  progressBarWrapper: {},
  progressBarInnerWrapper: {
    float: 'right',
  },
  btnContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '32px',
    gap: '15px',
    '& button': {
      flex: 'auto',
    },
  },

  previewPartner: {
    display: 'flex',
    width: '205px',
    height: '30px',
    padding: '7px 11px',
    borderRadius: '18px',
    lineHeight: 1.83,
    letterSpacing: 0.34,
    fontSize: 12,
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
  },
  noCategoryInfoMargin: {
    marginLeft: 11,
  },
  completeWrapper: {
    display: 'flex',
    marginTop: '10px',
    alignItems: 'center',

    '& img': {
      marginRight: 10,
      width: 20,
      height: 20,
    },
    '& p': {
      fontFamily: 'Roboto',
      letterSpacing: '0.18px',
    },
  },
  profileStatusLink: {
    color: FigmaDesignTokens.Grey[950],
    textDecoration: 'none',
    ':hover': {
      color: theme.palette.primary.dark,
      textDecoration: 'underline',
      fontWeight: '500',
    },
  },
}));
