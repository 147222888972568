import { useMatch } from 'react-router-dom';

export enum EVENT_LIST_VIEW {
  DASHBOARD = 'DASHBOARD',
  SCHEDULE = 'SCHEDULE',
  EVENTS = 'EVENTS',
}

export const useEventsTableView = () => {
  const isDashboard = useMatch('/:language/dashboard');
  const isSchedule = useMatch('/:language/terminplan');
  const isEvents = useMatch('/:language/kurse/:courseId/termine');

  if (isDashboard) return EVENT_LIST_VIEW.DASHBOARD;
  if (isSchedule) return EVENT_LIST_VIEW.SCHEDULE;
  if (isEvents) return EVENT_LIST_VIEW.EVENTS;
};
