import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useIntl } from 'react-intl';
import { EventListItem } from '@/Types';
import { StyledTime } from './TimeCell.styles';

type TimeCellProps = {
  gridRenderCellParams: GridRenderCellParams<EventListItem, number>;
};

export const TimeCell = ({ gridRenderCellParams }: TimeCellProps) => {
  const intl = useIntl();

  return (
    <StyledTime $cancelled={gridRenderCellParams.row.cancelled}>
      {gridRenderCellParams.value} {intl.formatMessage({ id: 'time', defaultMessage: 'clock' })}
    </StyledTime>
  );
};
