import {
  AddressAndContactPartialType,
  AdministrationFormik,
  Course,
  DescriptionPartial,
  GalleryAsset,
  LogoAssetProps,
  OpeningHoursPartial,
  VideoAsset,
  Event,
  EventList,
} from '@/Types';
import { FormikProps } from 'formik';

export function isLogoAssetRef(ref: any): ref is React.RefObject<FormikProps<LogoAssetProps>> {
  return ref && ref.current && 'values' in ref.current;
}

export function isGalleryFormRef(ref: any): ref is React.RefObject<FormikProps<GalleryAsset>> {
  return ref && ref.current && 'values' in ref.current;
}

export function isVideoFormRef(ref: any): ref is React.RefObject<FormikProps<VideoAsset>> {
  return ref && ref.current && 'values' in ref.current;
}

export function isAddressFormRef(
  ref: any,
): ref is React.RefObject<FormikProps<AddressAndContactPartialType>> {
  return ref && ref.current && 'values' in ref.current;
}

export function isDescriptionFormRef(
  ref: any,
): ref is React.RefObject<FormikProps<DescriptionPartial>> {
  return ref && ref.current && 'values' in ref.current;
}

export function isOpeningHoursFormRef(
  ref: any,
): ref is React.RefObject<FormikProps<OpeningHoursPartial>> {
  return ref && ref.current && 'values' in ref.current;
}
export function isCourseFormRef(ref: any): ref is React.RefObject<FormikProps<Course>> {
  return ref && ref.current && 'values' in ref.current;
}
export function isEventFormRef(ref: any): ref is React.RefObject<FormikProps<Event>> {
  return ref && ref.current && 'values' in ref.current;
}
export function isEventListFormRef(ref: any): ref is React.RefObject<FormikProps<EventList>> {
  return ref && ref.current && 'values' in ref.current;
}

// @TODO: Don't try this at home
export type CrazyUseRefCallbackStuffTypes =
  | React.RefObject<FormikProps<LogoAssetProps>>
  | React.RefObject<FormikProps<{ gallery: GalleryAsset[] }>>
  | React.RefObject<FormikProps<VideoAsset>>
  | React.RefObject<FormikProps<AddressAndContactPartialType>>
  | React.RefObject<FormikProps<DescriptionPartial>>
  | React.RefObject<FormikProps<OpeningHoursPartial>>
  | React.RefObject<FormikProps<AdministrationFormik>>
  | React.RefObject<FormikProps<Course>>
  | React.RefObject<FormikProps<Event>>
  | React.RefObject<FormikProps<EventList>>
  | undefined;
