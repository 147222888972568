import { Button, styled } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const CloseButton = styled(Button)`
  width: 36px;
  height: 36px;
  background: none;
  min-width: 36px;
  min-height: 36px;
  border-radius: 20px;
  margin: 0;
  padding: 0;
`;

export const useEditAddressInfoCourseModalStyles = makeStyles()(theme => ({
  submitButton: {
    marginTop: 18,
  },

  formWrapper: {
    background: theme.palette.background.paper,
    width: 620,
    padding: 28,
    borderRadius: 10,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
