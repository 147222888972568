import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { CustomDataGrid } from '@/App/Shared/DataGridTable/Table/DataGridTable';
import { NoResultsOverlay } from '@/App/Shared/DataGridTable/Table/NoResultsOverlay';
import { NoRowsOverlay } from '@/App/Shared/DataGridTable/Table/NoRowsOverlay';
import { SpinnerLoader } from '@/Components';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import { useTableRows } from '@/Hooks/useTableRows';
import { getEventTableColumns } from '@/Mappers';
import { getDefaultFiltersValues } from '@/Models';
import { useEventsListQuery } from '@/Queries';

import { EventListHint } from './EventListHint';
import { Container } from './EventsForm.styles';
import { EventListHeaderDash } from './Header/EventListHeaderDash';

const displayColumns = {
  cancelled: { minWidth: 100 },
  courseName: { minWidth: 130 },
  internalCourseName: { minWidth: 130 },
  courseType: { minWidth: 80 },
  date: { minWidth: 100 },
  time: { minWidth: 100 },
  duration: { minWidth: 110 },
  bookings: { minWidth: 140 },
  streamLink: { minWidth: 140 },
};

const EventsDashboardWidget = () => {
  const baseFilter = useMemo(
    () => ({ ...getDefaultFiltersValues(), limit: 6, page: '1', canceled: [false] }),
    [],
  );
  const intl = useIntl();
  const currentLanguage = useCurrentLanguage();
  const navigate = useNavigate();

  const { isLoading, data: eventsData } = useEventsListQuery(baseFilter);

  const columns = getEventTableColumns(displayColumns, intl);

  const rows = useTableRows({
    uniqueIdKey: 'eventId',
    data: eventsData?.result || [],
  });

  return (
    <Container>
      {isLoading && <SpinnerLoader />}

      <Box sx={{ width: '100%' }}>
        <EventListHeaderDash showAdd={rows.length > 0} />

        <CustomDataGrid
          columns={columns}
          rows={rows}
          rowHeight={66}
          hideColumns={rows.length === 0}
          disableRowSelectionOnClick
          hideFooterSelectedRowCount
          hideFooter
          loading={isLoading}
          getRowId={row => row.eventId}
          autoHeight
          slots={{
            toolbar: null,
            noRowsOverlay: () => (
              <NoRowsOverlay
                header={intl.formatMessage({
                  id: 'view.courses_and_events.events.table.empty_header_events',
                  defaultMessage: 'You did not create events yet. Start now!',
                })}
                subHeader={intl.formatMessage({
                  id: 'view.courses_and_events.events.table.empty_sub_header',
                  defaultMessage: 'You can only create online courses over this portal.',
                })}
                ctaTitle={intl.formatMessage({
                  id: 'view.courses_and_events.events.table.cta.empty',
                  defaultMessage: 'Create new course',
                })}
                ctaHandler={() => navigate(`/${currentLanguage}/kursuebersicht/laufende-kurse`)}
              />
            ),
            noResultsOverlay: () => (
              <NoResultsOverlay
                header={intl.formatMessage({
                  id: 'view.courses_and_events.events.table.empty_filter_header',
                  defaultMessage: 'No Events found',
                })}
                subHeader={intl.formatMessage({
                  id: 'view.courses_and_events.events.table.empty_filter_sub_header',
                  defaultMessage: 'Please change your search criteria and try again.',
                })}
              />
            ),
          }}
        />
        {rows.length > 0 && <EventListHint />}
      </Box>
    </Container>
  );
};

export default EventsDashboardWidget;
