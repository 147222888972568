import { Transition } from 'history';
import { useCallback, useEffect, useState } from 'react';

import { ReactRouterPromptProps } from '../ReactRouterPrompt';

const initialConfirmState = {
  isActive: false,
  proceed: () => {},
  cancel: () => {},
};

declare interface InitialStateType {
  isActive: boolean;
  proceed: (value: unknown) => void;
  cancel: (value: unknown) => void;
}

declare interface ConfirmLeaveReturnType extends InitialStateType {
  onConfirm: (tx: Transition) => Promise<boolean>;
  resetConfirmation: () => void;
}

const useConfirm = (when: ReactRouterPromptProps['when']): ConfirmLeaveReturnType => {
  const [confirm, setConfirm] = useState<InitialStateType>(initialConfirmState);

  useEffect(() => {
    const previousOnBeforeUnload = window.onbeforeunload;

    if (confirm.isActive) {
      window.onbeforeunload = () => false;
    }

    return () => {
      window.onbeforeunload = previousOnBeforeUnload;
    };
  }, [confirm]);

  const resetConfirmation = useCallback(() => {
    setConfirm(initialConfirmState);
  }, []);

  const onConfirm = useCallback(
    (tx: Transition) => {
      const promise = new Promise(async (resolve, reject) => {
        setConfirm((prevState: InitialStateType) => ({
          ...prevState,
          isActive: true,
          proceed: resolve,
          cancel: reject,
        }));

        // Go ahead and resolve the promise when the `when` function
        // returns `false`, which means the prompt should not be displayed
        // and navigation should occur.
        if (typeof when === 'function') {
          const shouldPrompt = when(tx.location, tx.action);
          if (!shouldPrompt) {
            resolve(true);
          }
        }
      });

      return promise.then(
        () => {
          setConfirm({ ...confirm, isActive: false });

          return true;
        },
        () => {
          setConfirm({ ...confirm, isActive: false });

          return false;
        },
      );
    },
    [confirm, when],
  );

  return {
    ...confirm,
    onConfirm,
    resetConfirmation,
  };
};

export default useConfirm;
