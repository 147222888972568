import { Box } from '@mui/material';
import clsx from 'clsx';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';

import Upload from '@/Static/Icons/atoms-symbols-icons-media-upload.svg';

import { CustomizedButtonUpload } from '../../App/Shared/Form/Components/Customized/CustomizedButtonUpload';
import { BodyTextSmall, OverlineText } from '../Typography';
import { useDropzoneStyles } from './Dropzone.styles';

interface DropzoneProps {
  accept: string;
  onDrop: any;
  disabled: boolean;
  supportedFileNames: string;
  maxFileSize?: number;
  onDropRejected?: (fileRejections: FileRejection[]) => void | undefined;
}

export const Dropzone = (props: DropzoneProps): JSX.Element => {
  const { onDrop, accept, disabled, supportedFileNames, maxFileSize, onDropRejected } = props;
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    maxSize: maxFileSize,
    onDropRejected,
  });
  const { classes } = useDropzoneStyles();
  const { formatMessage } = useIntl();

  return (
    <Box {...getRootProps()} className={clsx(classes.dropzone, { [classes.disabled]: disabled })}>
      <input className='dropzone-input' {...getInputProps()} />
      <div className={classes.dropzoneContent}>
        <img alt='' src={Upload} />
        <BodyTextSmall
          fontWeight={600}
          textContent={formatMessage({
            id: 'form.photo_and_video.common.dropzone.header',
            defaultMessage: 'Select file or add via drag and drop',
          })}
        />
        <OverlineText
          textTransform='capitalize'
          textContent={`${formatMessage({
            id: 'form.photo_and_video.common.dropzone.subheader',
            defaultMessage: 'Supported file formats:',
          })} ${supportedFileNames}`}
        />
        <CustomizedButtonUpload aria-controls='file-upload' variant='outlined'>
          {formatMessage({
            id: 'form.photo_and_video.common.dropzone.cta',
            defaultMessage: 'Select file',
          })}
        </CustomizedButtonUpload>
      </div>
    </Box>
  );
};
