import { Box, Checkbox, Modal, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { Button } from '@/Components';
import { DelayModalTypes, useAppContext } from '@/Context';
import useModalStyles from './ModalStyles';

type GeneralModalProps = {
  open: boolean;
  title: string;
  iconSrc: string;
  content: string;
  onChecked: (value: boolean) => void;
};

export const DelayModal = ({ open, title, iconSrc, content, onChecked }: GeneralModalProps) => {
  const { classes } = useModalStyles();
  const { formatMessage } = useIntl();
  const { dispatch } = useAppContext();

  const handleClose = () => {
    dispatch({ type: DelayModalTypes.SET_OPEN, payload: { open: false } });
  };

  return (
    <Modal open={open}>
      <Box className={classes.modalWrapper}>
        <Box display='flex' alignItems='center'>
          <Box mr={1.5}>
            <img alt='' src={iconSrc} />
          </Box>
          <Typography className={classes.titleModal}>{title}</Typography>
        </Box>
        <Box>
          <Typography className={classes.contentModal}>{content}</Typography>
          <Box display='flex' alignItems='center'>
            <Checkbox
              onChange={e => {
                onChecked(e.target.checked);
              }}
              className={classes.customCheckbox}
            />
            <Typography className={classes.checkboxText}>
              {formatMessage({
                id: 'modal.not.show.message',
                defaultMessage: 'Diese Meldung nicht mehr anzeigen',
              })}
            </Typography>
          </Box>
        </Box>
        <Box mt={2.5}>
          <Button onClick={handleClose}>
            {formatMessage({
              id: 'view.courses_and_events.events.dialog.edit_events.cta.action',
              defaultMessage: 'Understood',
            })}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DelayModal;
