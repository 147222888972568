import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../../design/design-tokens.json';

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    padding: '30px',
    borderRadius: 9,
    backgroundColor: FigmaDesignTokens.White,
    boxShadow: `0 20px 20px 0 ${FigmaDesignTokens.Grey[100]}`,
  },

  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30,
    alignItems: 'center',
  },

  rowItem: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 0.5fr) repeat(2, 1fr) repeat(1, 0.6fr)',
    borderRadius: 10,
    padding: 15,
    fontSize: 14,
    marginBottom: 5,
    alignItems: 'center',
  },

  buttonContainer: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'flex-end',
  },

  nowRowsOverlayBackground: {
    backgroundColor: FigmaDesignTokens.Blue[100],
    borderRadius: 4,
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
  },
  addCheckInButton: {
    background: FigmaDesignTokens.Blue[100],
    color: FigmaDesignTokens.Blue[950],
    height: '32px',
    '&:hover': {
      backgroundColor: FigmaDesignTokens.Grey[300],
      boxShadow: 'none',
    },
  },
}));

export default useStyles;
