import { Box } from '@mui/material';
import { useLoginPhotosStyles } from './Login.styles';

export const LoginPhotos = () => {
  const { classes } = useLoginPhotosStyles();

  return (
    <Box>
      <img alt='' src='/Images/einzel-motiv-a-sie-sport-rgb-2.png' className={classes.img} />
    </Box>
  );
};
