import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import { Box, IconButton, Tabs } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';
import { TabNavigation } from './TabNavigation';

export interface TabsNavigationProps {
  tabs: { label: string; disabled: boolean }[];
  value: number;
  handleChange: (event: React.SyntheticEvent<Element, Event>, newValue: number) => void;
}

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  tabs: {
    minHeight: 'initial',
    '& button': {
      minHeight: '20px',
    },
    '& button:hover': {
      color: theme.palette.primary.main,
    },
    '& button.Mui-disabled': {
      color: theme.palette.info.dark,
      fontWeight: 500,
      opacity: 1,
    },
  },
  button: {
    padding: '12px',
    color: FigmaDesignTokens.Purple[950],
  },
  navPlaceholder: {
    marginLeft: 48,
  },
}));

export const TabsNavigation = ({ tabs, value, handleChange }: TabsNavigationProps) => {
  const { classes } = useStyles();
  const [navigationEnabled, setNavigationEnabled] = useState(true);

  const handlePrev = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const newTab = value - 1;
      if (newTab >= 0) {
        handleChange(e, newTab);
      }
    },
    [value, handleChange],
  );

  const handleNext = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const newTab = value + 1;
      if (newTab < tabs.length) {
        handleChange(e, newTab);
      }
    },
    [tabs, value, handleChange],
  );

  useEffect(() => {
    if (tabs.length < 2) {
      setNavigationEnabled(false);
    } else {
      const disabledTabs = tabs.filter(tab => tab.disabled);
      if (tabs.length - disabledTabs.length < 2) {
        setNavigationEnabled(false);
      }
    }
  }, [tabs]);

  return (
    <Box className={classes.root}>
      {navigationEnabled && (
        <IconButton className={classes.button} onClick={handlePrev}>
          <ChevronLeftOutlined />
        </IconButton>
      )}
      {!navigationEnabled && <Box className={classes.navPlaceholder} />}

      <Tabs
        variant='fullWidth'
        value={value}
        className={classes.tabs}
        indicatorColor='primary'
        onChange={handleChange}
        TabIndicatorProps={{ style: { display: 'none' } }}>
        {tabs.map((tab, idx) => (
          <TabNavigation
            key={idx}
            disabled={tab.disabled}
            label={<FormattedMessage id={tab.label} />}
          />
        ))}
      </Tabs>

      {navigationEnabled && (
        <IconButton className={classes.button} onClick={handleNext}>
          <ChevronRightOutlined />
        </IconButton>
      )}
    </Box>
  );
};
