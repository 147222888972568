export class EquipmentAndServiceMap {
  public getServicesHeader() {
    const wellnessServicesEl = document.querySelector('#wellnessServices');
    const fitnessServicesEl = document.querySelector('#fitnessServices');
    const servicesEl = document.querySelector('#services');

    const wellnessServicesElOffsetY =
      wellnessServicesEl && wellnessServicesEl.getBoundingClientRect().top + window.scrollY - 180;

    const fitnessServicesElOffsetY =
      fitnessServicesEl && fitnessServicesEl.getBoundingClientRect().top + window.scrollY - 180;

    const servicesElOffsetY =
      servicesEl && servicesEl.getBoundingClientRect().top + window.scrollY - 180;

    const scrollOffsetY = window.scrollY;

    const wellnessServicesRange =
      wellnessServicesElOffsetY &&
      servicesElOffsetY &&
      wellnessServicesElOffsetY <= scrollOffsetY &&
      scrollOffsetY < servicesElOffsetY;

    const servicesRange = servicesElOffsetY && servicesElOffsetY <= scrollOffsetY;

    const fitnessServicesRange =
      fitnessServicesElOffsetY && fitnessServicesElOffsetY < scrollOffsetY;

    if (wellnessServicesRange) {
      return 'Wellness';
    } else if (servicesRange) {
      return 'Services';
    } else if (fitnessServicesRange) {
      return 'Fitness';
    } else {
      return '';
    }
  }
}
