import * as Yup from 'yup';
import { IntlShape } from 'react-intl/src/types';

export const PartnerAccountValidation = (_language: string, intl: IntlShape) =>
  Yup.object().shape({
    email: Yup.string().email(
      intl.formatMessage({
        id: 'form.invite_user.error.email.valid',
        defaultMessage: 'Please enter a valid email address and save your changes again. ',
      }),
    ),
    given_name: Yup.string().required(
      intl.formatMessage({
        id: 'form.invite_user.error.given_name.required',
        defaultMessage: 'Please enter your first name.',
      }),
    ),
    name: Yup.string().required(
      intl.formatMessage({
        id: 'form.invite_user.error.name.required',
        defaultMessage: 'Please enter your last name.',
      }),
    ),
  });
