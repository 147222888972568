import { isEmpty } from 'lodash';

import { AuthSelectors } from '@/Store/Auth';

import AuthenticatedRoutes from './AuthenticatedRoutes';
import { useAppSelector } from './Hooks/useAppSelector';
import { NotAuthenticatedRoutes } from './NotAuthenticatedRoutes';

const Routes = () => {
  const { isAuthenticated, partnerRoles } = useAppSelector(AuthSelectors.authState);

  const partnerPublicId = localStorage.getItem('publicId');

  return (
    <>
      {partnerPublicId || (isAuthenticated && !isEmpty(partnerRoles)) ? (
        <AuthenticatedRoutes />
      ) : (
        <NotAuthenticatedRoutes />
      )}
    </>
  );
};

export default Routes;
