import { CSSProperties } from 'react';
import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

const useStyles = (style?: CSSProperties) =>
  makeStyles()(theme => ({
    container: {
      pointerEvents: 'auto',
      marginBottom: '0.5rem', // You can adjust the value as needed
      width: '24rem', // Equivalent to w-96 in Tailwind CSS
      opacity: 0,
      borderRadius: '0.5rem', // Equivalent to rounded-sm in Tailwind CSS
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
      background: FigmaDesignTokens.White, // Equivalent to bg-white in Tailwind CSS
      color: theme.palette.text.primary, // Equivalent to text-black in Tailwind CSS
      fontSize: '0.875rem', // Equivalent to text-sm in Tailwind CSS
      lineHeight: '1.25rem', // Equivalent to leading-5 in Tailwind CSS
      overflow: 'hidden',
      position: 'absolute',
      ...(style || {}),
    },
  }));

export default useStyles;
