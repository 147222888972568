import { BlockedName, Container, Name } from './NameCell.styles';
import { User } from '@/Types';

type NameCellProps = {
  data: User;
};

export const NameCell = ({ data: row }: NameCellProps) => (
  <Container>
    {row.status === 'Blocked' && (
      <BlockedName>
        {row.givenName} {row.name}
      </BlockedName>
    )}
    {row.status !== 'Blocked' && (
      <Name>
        {row.givenName} {row.name}
      </Name>
    )}
  </Container>
);
