import { AxiosResponse } from 'axios';
import { isNull } from 'lodash';

import { HttpService } from '@/Services';
import {
  DeeplTranslationResponseType,
  handleTranslationProps,
  LanguageType,
  TermType,
} from '@/Types';

// export for compability with old code
export class DeeplService {
  public httpService: HttpService;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }
  public async getTerms(language: LanguageType): Promise<AxiosResponse<TermType>> {
    const axiosInstance = await this.httpService.getAxios();
    return axiosInstance.get(
      process.env.REACT_APP_TRANSLATIONS_ENDPOINT + `po?language=${language}&project=VPP`,
    );
  }

  public async getTranslation(
    text: string,
    targetLang: string,
    sourceLang: string,
  ): Promise<AxiosResponse<DeeplTranslationResponseType>> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();
    return axiosInstance.post(process.env.REACT_APP_TRANSLATIONS_ENDPOINT + 'deepl', {
      sourceLang,
      targetLang,
      text,
    });
  }

  public getTranslationFromResponse(
    translationResponse: DeeplTranslationResponseType,
    currentLanguage: LanguageType,
  ) {
    const translation = translationResponse.translations.find(
      translation => translation.detected_source_language === currentLanguage.toUpperCase(),
    );

    if (translation) {
      return translation.text;
    } else {
      return '__NOT_TRANSLATED__';
    }
  }

  public getLanguages(): LanguageType[] {
    return ['de', 'en'];
  }

  public isQualifiedToTranslate(pageLanguage: LanguageType, targetLanguage: LanguageType) {
    return pageLanguage !== targetLanguage;
  }

  public isQualifiedForBlacklisting(pageLanguage: LanguageType, currentLanguage: LanguageType) {
    return pageLanguage !== currentLanguage;
  }

  public isBlacklisted(field: string, sourceLanguage: LanguageType, scope: string) {
    const blacklist = this.getBlacklist();
    return !!blacklist.find(
      item => item.field === scope + '_' + field && item.language === sourceLanguage,
    );
  }

  public getBlacklist(): { field: string; language: LanguageType }[] {
    const blacklist = sessionStorage.getItem('vpp_blacklist');
    if (blacklist) {
      return JSON.parse(blacklist);
    }
    return [];
  }

  public addToBlacklist(field: string, language: LanguageType, scope: string): void {
    const blacklist = this.getBlacklist();
    if (!this.isBlacklisted(field, language, scope)) {
      blacklist.push({ field: scope + '_' + field, language });
    }

    sessionStorage.setItem('vpp_blacklist', JSON.stringify(blacklist));
  }

  public async handleTranslation({
    currentValue,
    currentLanguage,
    field,

    targetLanguage,
    isFirstTranslation,
    setIsFirstTranslation,
    setFieldValue,
  }: handleTranslationProps): Promise<void> {
    if (currentValue) {
      const result = await this.getTranslation(currentValue, targetLanguage, currentLanguage);
      const translation = this.getTranslationFromResponse(result.data, currentLanguage);

      if (result?.data) {
        setFieldValue(`${field}.${targetLanguage}`, translation);
      }

      if (isNull(isFirstTranslation)) {
        setIsFirstTranslation(true);
      }
    } else {
      setFieldValue(`${field}.${targetLanguage}`, '');
    }
  }
}

export default DeeplService;
