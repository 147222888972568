import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const CurrentUserDeleteDialogStyles = makeStyles()(() => ({
  iconSecond: {
    marginLeft: 20,
  },
  iconFirst: {
    marginLeft: 10,
  },
  userName: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0.18,
    color: FigmaDesignTokens.Grey[800],
    lineHeight: 1.43,
    fontFamily: 'Roboto',
    paddingLeft: 30,
    paddingRight: 30,
  },
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: 485,
      borderRadius: 10,
    },
  },
  dialogContent: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 0.18,
    color: FigmaDesignTokens.Grey[800],
    minHeight: 40,
  },
  dialogActions: {
    paddingTop: 20,
    paddingBottom: 36,
    paddingLeft: 30,
    paddingRight: 30,
    justifyContent: 'flex-end',
  },
  headerWrapper: {
    paddingTop: 32,
    paddingLeft: 30,
    paddingRight: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    padding: 0,
    marginLeft: 20,
    height: 41,
  },
  confirmButton: {
    color: FigmaDesignTokens.White,
    padding: '7px 28px',
    '& button': {
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: 0.34,
      color: FigmaDesignTokens.Grey[100],
    },
  },
  deleteUserBtn: {
    padding: 0,
    width: 'fit-content',
    marginTop: 6,
    fontSize: 12,
    letterSpacing: 0.34,
    marginBottom: 20,
  },
}));
