import {
  useCurrentUserInfoQuery,
  useDashboardStatsQuery,
  useGetPartnerQuery,
  usePartnerCategoriesQuery,
} from '@/Queries';

import { useCurrentLanguage } from '../../Hooks/useCurrentLanguage';
import { DashContent } from './Components/Content/DashContent';
import Header from './Components/Header';

const Dashboard = () => {
  const { data: partner } = useGetPartnerQuery();
  const currentLanguage = useCurrentLanguage();

  const { data: userAttributes, isLoading: isLoadingUserAttributes } = useCurrentUserInfoQuery();

  const { data: partnerCategories, isLoading: isLoadingPartnerCategories } =
    usePartnerCategoriesQuery({ currentLanguage });
  const { data: dashboardStats, isLoading: isLoadingDashboardStats } = useDashboardStatsQuery();

  const isLoading =
    isLoadingUserAttributes || isLoadingPartnerCategories || isLoadingDashboardStats;

  if (!isLoading && partner && partnerCategories && userAttributes && dashboardStats) {
    return (
      <>
        <Header userAttr={userAttributes?.attributes} partner={partner} />
        <DashContent
          partnerCategories={partnerCategories}
          partner={partner}
          stats={dashboardStats}
        />
      </>
    );
  }

  return null;
};

export default Dashboard;
