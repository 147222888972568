import { AssetService } from '@/Services';
import { useCallback, useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import prettyBytes from 'pretty-bytes';
import { GalleryAsset, Partner } from '@/Types';
import Trash from '@/Static/Icons/bin.svg';
import { useGalleryAssetPreviewStyles } from './Assets.styles';
import clsx from 'clsx';

export interface GalleryAssetPreviewProps {
  resourceData: GalleryAsset;
  handleRemoveImage?: (value: number) => void;
  handleRemoveNewImage?: () => void;
  casPublicId: Partner['casPublicId'];
  isBinIcon: boolean;
  assetService: AssetService;
}

export const GalleryAssetPreview = ({
  resourceData,
  handleRemoveImage,
  casPublicId,
  assetService,
  isBinIcon,
  handleRemoveNewImage,
}: GalleryAssetPreviewProps) => {
  const { classes } = useGalleryAssetPreviewStyles();
  const [signedResource, setSignedResource] = useState<string>();
  const [fileSize, setFileSize] = useState(0);

  const getFileDetails = useCallback(async () => {
    if (!resourceData.uri) return;

    const s3Object = await assetService.S3GetObject(casPublicId, resourceData.uri, 'Gallery');
    const signedResource = await assetService.S3GetSignedResource(
      casPublicId,
      resourceData.uri,
      'Gallery',
    );

    if (s3Object) setFileSize(s3Object.ContentLength);

    if (signedResource) {
      setSignedResource(signedResource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [casPublicId, resourceData.uri]);

  useEffect(() => {
    getFileDetails();
  }, [getFileDetails]);

  const handleRemove = useCallback(
    (value: number) => {
      if (handleRemoveNewImage) {
        handleRemoveNewImage();
      }
      if (handleRemoveImage) {
        handleRemoveImage(value);
      }
    },
    [handleRemoveNewImage, handleRemoveImage],
  );

  return (
    <Box className={classes.assetWrapper}>
      <img src={signedResource} alt='' className={classes.tileImage} />
      <Box className={classes.wrapper}>
        <Box>
          <Typography variant='body2' className={clsx(classes.text, classes.name)}>
            {resourceData.originalFilename}
          </Typography>
          <Typography variant='body2' className={classes.text}>
            {prettyBytes(fileSize)}
          </Typography>
        </Box>
        {isBinIcon && resourceData.assetId && (
          <IconButton
            className={classes.iconBtn}
            onClick={() => resourceData.assetId && handleRemove(resourceData.assetId)}
            aria-label={`remove-image-${resourceData.assetId}`}
            color='primary'>
            <img alt='' src={Trash} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
