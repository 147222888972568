import * as Yup from 'yup';
import { IntlShape } from 'react-intl/src/types';

export const EditAddressInfoCourseValidation = (intl: IntlShape) =>
  Yup.object().shape({
    address: Yup.object().shape({
      isPartnerAddress: Yup.boolean(),
      street: Yup.string().when('isPartnerAddress', {
        is: false,
        then: Yup.string().required(
          intl.formatMessage({
            id: 'form.event.error.appointments.location_settings.street.required',
            defaultMessage: 'Please enter a street name.',
          }),
        ),
      }),
      streetNumber: Yup.string().when('isPartnerAddress', {
        is: false,
        then: Yup.string().required(
          intl.formatMessage({
            id: 'form.event.error.appointments.location_settings.streetNumber.required',
            defaultMessage: 'Please enter a house number.',
          }),
        ),
      }),
      city: Yup.string().when('isPartnerAddress', {
        is: false,
        then: Yup.string().required(
          intl.formatMessage({
            id: 'form.event.error.appointments.location_settings.city.required',
            defaultMessage: 'Please enter a city name.',
          }),
        ),
      }),
      postcode: Yup.string().when('isPartnerAddress', {
        is: false,
        then: Yup.string().required(
          intl.formatMessage({
            id: 'form.event.error.appointments.location_settings.zip.required',
            defaultMessage: 'Please enter a postal code.',
          }),
        ),
      }),
      additionalInformation: Yup.string().max(
        255,
        intl.formatMessage({
          id: 'form.event.error.appointments.location_settings.additional_info.length',
          defaultMessage: 'This field must be less than 255 characters long.',
        }),
      ),
    }),
  });
