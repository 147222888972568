import { Typography, useTheme } from '@mui/material';
import moment from 'moment/moment';
import { useMemo } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { TIME_AND_DATE_FORMAT } from '@/Models';
import { CheckInCheckoutEventType } from '@/Types';
import { fromUnixToTime } from '@/Utils';

type CheckedInCheckedOutStatusProps = Pick<
  CheckInCheckoutEventType['data'],
  'offline' | 'checkInSource' | 'checkOutSource' | 'checkInTimestamp' | 'checkOutTimestamp'
>;

export const CheckedInCheckedOutStatus = ({
  checkInTimestamp,
  checkOutTimestamp,

  checkInSource,
  checkOutSource,
  offline,
}: CheckedInCheckedOutStatusProps) => {
  const intl = useMemoedIntl();
  const checkInConverted = fromUnixToTime(checkInTimestamp);
  const checkOutConverted = fromUnixToTime(checkOutTimestamp);
  const theme = useTheme();
  const checkInWasYesterday = moment(checkInConverted).isBefore(moment().startOf('day'));

  const checkInOutTranslations: {
    action: 'checkin' | 'checkout';
    type:
      | CheckInCheckoutEventType['data']['checkInSource']
      | CheckInCheckoutEventType['data']['checkOutSource'];
    message: string;
  }[] = useMemo(
    () => [
      {
        action: 'checkin',
        type: 'vpp',
        message: intl.formatMessage({
          id: `view.current_checkins.table.checked_in_via_vpp`,
          defaultMessage: `with Vpp`,
        }),
      },
      {
        action: 'checkin',
        type: 'app',
        message: intl.formatMessage({
          id: `view.current_checkins.table.checked_in_via_app`,
          defaultMessage: `with App`,
        }),
      },
      {
        action: 'checkin',
        type: 'card',
        message: intl.formatMessage({
          id: `view.current_checkins.table.checked_in_via_card`,
          defaultMessage: `with Card`,
        }),
      },
      {
        action: 'checkout',
        type: 'vpp',
        message: intl.formatMessage({
          id: `view.current_checkout.table.checked_out_via_vpp`,
          defaultMessage: `with Vpp`,
        }),
      },
      {
        action: 'checkout',
        type: 'app',
        message: intl.formatMessage({
          id: `view.current_checkout.table.checked_out_via_app`,
          defaultMessage: `with App`,
        }),
      },
      {
        action: 'checkout',
        type: 'card',
        message: intl.formatMessage({
          id: `view.current_checkout.table.checked_out_via_card`,
          defaultMessage: `with Card`,
        }),
      },
      {
        action: 'checkout',
        type: 'automatic time-based',
        message: intl.formatMessage({
          id: `view.current_checkout.table.checked_out_via_automatic time-based`,
          defaultMessage: `Automatic checkout`,
        }),
      },
    ],
    [intl],
  );

  // If there is no checkout timestamp, the user is still checked in
  if (checkInConverted && !checkOutConverted) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <div
          style={{
            color: theme.palette.success.main,
            fontWeight: 600,
          }}>
          <FormattedMessage
            id='view.current_checkins.table.checked_in_at'
            defaultMessage='Checked-In at'
          />
          {checkInWasYesterday ? (
            <FormattedDate value={checkInConverted} {...TIME_AND_DATE_FORMAT} />
          ) : (
            <FormattedTime value={checkInConverted} />
          )}
        </div>
        {offline && (
          <Typography>
            <FormattedMessage
              id='view.current_checkins.table.post_offline_checkout'
              defaultMessage='Post-retrieved offline Check-Out'
            />
          </Typography>
        )}

        {checkInSource && !offline && (
          <Typography>
            {
              checkInOutTranslations.find(
                mssg => checkInSource === mssg.type && mssg.action === 'checkin',
              )?.message
            }
          </Typography>
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
      <div
        style={{
          fontWeight: 600,
        }}>
        {checkInConverted && (
          <>
            {checkInWasYesterday ? (
              <FormattedDate value={checkInConverted} {...TIME_AND_DATE_FORMAT} />
            ) : (
              <FormattedTime value={checkInConverted} />
            )}{' '}
            -{' '}
          </>
        )}
        {checkOutConverted && (
          <>
            {checkInWasYesterday ? (
              <FormattedDate value={checkOutConverted} {...TIME_AND_DATE_FORMAT} />
            ) : (
              <FormattedTime value={checkOutConverted} />
            )}
          </>
        )}
      </div>
      {offline && (
        <Typography>
          <FormattedMessage
            id='view.current_checkins.table.post_offline_checkin'
            defaultMessage='Post-retrieved offline Check-In'
          />
        </Typography>
      )}

      {checkOutSource && !offline && (
        <Typography>
          {
            checkInOutTranslations.find(
              mssg => checkOutSource === mssg.type && mssg.action === 'checkout',
            )?.message
          }
        </Typography>
      )}
    </div>
  );
};
