import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

type ContentContainerProps = {
  children: ReactNode;
  center?: boolean;
  padding?: number;
};

const useStyles = (padding?: number) =>
  makeStyles()(() => ({
    contentContainer: {
      maxWidth: '100%',
    },
    content: {
      borderRadius: 9,
      backgroundColor: FigmaDesignTokens.White,
      padding: typeof padding === 'number' ? padding : 30,
      paddingBottom: typeof padding === 'number' ? padding : 60,
      zIndex: 2,
    },
    center: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
  }));

export const ContentContainer = (props: ContentContainerProps) => {
  const { children, center, padding } = props;
  const { classes } = useStyles(padding)();

  return (
    <Box className={classes.contentContainer}>
      <Grid container>
        <Grid item xs={12} className={clsx(classes.content, { [classes.center]: center })}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};
