export const isValidFileType = (fileName: string, validFileExtensions: string[]) => {
  const fileNameExtension = fileName.split('.').pop();

  if (!fileNameExtension) {
    return false;
  }

  return validFileExtensions.includes(fileNameExtension);
};

export const validateFileExtension = (files: FileList | undefined, validFileExtensions: string[]) =>
  !files || // Check if `files` is defined
  files.length === 0 || // Check if `files` is not an empty list
  Array.from(files).every(file => isValidFileType(file.name.toLowerCase(), validFileExtensions));

export const validateFileSize = (files: FileList | undefined, maxFileSize = 0) =>
  !files || // Check if `files` is defined
  files.length === 0 || // Check if `files` is not an empty list
  Array.from(files).every(file => file.size <= maxFileSize);
