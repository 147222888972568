import { Box } from '@mui/material';
import clsx from 'clsx';

import { Flag } from '@/Components';
import { useAppContext } from '@/Context';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import useReadStorage from '@/Hooks/useReadStorage';
import { useStorage } from '@/Hooks/useStorage';
import { LanguageType } from '@/Types/Language';

import { useLanguageSwitcherStyles } from './LanguageSwitcher.styles';

const InlineContentLanguageSwitcher = () => {
  const currentPageLanguage = useCurrentLanguage();
  const language = useReadStorage<LanguageType>(
    'vpp_translation_content_language',
    'LOCAL_STORAGE',
  );
  const {
    state: {
      serverError: { show, height },
    },
  } = useAppContext();

  const { classes } = useLanguageSwitcherStyles();

  const [currentLanguage, setCurrentLanguage] = useStorage(
    'vpp_translation_content_language',
    language || currentPageLanguage || 'de',
    'LOCAL_STORAGE',
  );

  return (
    <Box className={classes.languageContainer}>
      <Box className={classes.languages} style={{ top: show ? 278 + height : undefined }}>
        <Box
          className={clsx(classes.language, classes.languageDe, {
            [classes.active]: currentLanguage === 'de',
            [classes.inactive]: currentLanguage !== 'de',
          })}
          onClick={() => setCurrentLanguage('de')}>
          <Flag src='/assets/flags/de.svg' width={20} height={20} />
        </Box>
        <Box
          className={clsx(classes.language, {
            [classes.active]: currentLanguage === 'en',
            [classes.inactive]: currentLanguage !== 'en',
          })}
          onClick={() => setCurrentLanguage('en')}>
          <Flag src='/assets/flags/en.svg' width={20} height={20} />
        </Box>
      </Box>
    </Box>
  );
};

export default InlineContentLanguageSwitcher;
