import { ManagedUpload } from 'aws-sdk/clients/s3';
import { useCallback, useState } from 'react';

import { Upload } from '@/Types';

export const useUploadCancel = () => {
  const [uploads, setUploads] = useState<Upload[]>([]);

  const handleCancel = useCallback((managedUpload: ManagedUpload) => {
    managedUpload.abort.bind(managedUpload);
    managedUpload.abort();
    setUploads([]);
  }, []);

  return { uploads, handleCancel, setUploads };
};
