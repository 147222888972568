import { CheckInCheckoutEventType } from '@/Types';

export const checkInSorter = (aData: CheckInCheckoutEventType, bData: CheckInCheckoutEventType) => {
  const hasCheckOutA = aData.name === 'checkOut' && aData.data.checkOutTimestamp;
  const hasCheckOutB = bData.name === 'checkOut' && bData.data.checkOutTimestamp;
  const sortValueA =
    aData.name === 'checkOut' && aData.data.checkOutTimestamp
      ? aData.data.checkOutTimestamp
      : aData.data.checkInTimestamp;
  const sortValueB =
    bData.name === 'checkOut' && bData.data.checkOutTimestamp
      ? bData.data.checkOutTimestamp
      : bData.data.checkInTimestamp;

  if (!hasCheckOutA && !hasCheckOutB) {
    // If both items don't have a checkOutTimestamp, sort by checkInTimestamp in descending order.
    return sortValueB! - sortValueA!;
  } else if (!hasCheckOutA) {
    // If item A doesn't have a checkOutTimestamp, move it to the top.
    return -1;
  } else if (!hasCheckOutB) {
    // If item B doesn't have a checkOutTimestamp, move it to the top.
    return 1;
  } else {
    // Both items have a checkOutTimestamp, so compare them by timestamp.
    return sortValueB! - sortValueA!;
  }
};
