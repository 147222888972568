import { Box, Button, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { ConnectedFocusError } from 'focus-formik-error';
import { Field, Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { BodyTextSmall, LineDivider, MainHeadline, PageHeadline } from '@/Components';
import { RegisterPartnerService } from '@/Services';
import { SdkErrorType } from '@/Types/SdkError';
import { ChangePasswordValidation } from '@/Utils';

import { FormikErrorMessage } from '../../../../../App/Shared/Form/Components/Formik/FormikErrorMessage';
import { FormikTextField } from '../../../../../App/Shared/Form/Components/Formik/FormikTextField';
import FigmaDesignTokens from '../../../../../design/design-tokens.json';
import { MultiInput } from '../../Shared/Autotabs/MultiInput';
import { VerificationCode } from '../../Shared/Autotabs/VerificationCode';
import { RegisterPhotos } from '../../Shared/Photos/RegisterPhotos';
import { useChangePasswordStyles } from '../ForgotPassword.styles';

export interface ChangePasswordStepProps {
  handleStep: (value: number) => void;
  handlePassword: (password: string) => void;
  email: string;
  registerPartnerService: RegisterPartnerService;
}

export const ChangePasswordStep = ({
  handleStep,
  email,
  registerPartnerService,
  handlePassword,
}: ChangePasswordStepProps) => {
  const { classes } = useChangePasswordStyles();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [asyncErrorMessage, setAsyncErrorMessage] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [validateOnChange, setValidateOnChange] = useState(false);

  const handleBackToLogin = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  return (
    <>
      <MainHeadline
        textContent={formatMessage({
          id: 'app.brand_with_alternative_name',
          defaultMessage: 'Hansefit Verbundpartner-portal',
        })}
        customColor={FigmaDesignTokens.White}
        className={classes.mainHeader}
      />
      <Box className={classes.mainContent}>
        <PageHeadline
          textContent={formatMessage({
            id: 'form.forgot_password.stepper.step.change_password.header',
            defaultMessage: 'Verify email',
          })}
          textAlign='center'
        />
        <BodyTextSmall
          textContent={formatMessage({
            id: 'form.forgot_password.stepper.step.change_password.subheader',
            defaultMessage:
              'We have sent you an email. Please enter the verification code from the email here to verify your email address.',
          })}
        />

        <Formik
          validationSchema={ChangePasswordValidation}
          validateOnChange={validateOnChange}
          validateOnBlur={false}
          initialValues={{
            verificationCode: [
              { partCode: '', partCodeOld: '' },
              { partCode: '', partCodeOld: '' },
              { partCode: '', partCodeOld: '' },
              { partCode: '', partCodeOld: '' },
              { partCode: '', partCodeOld: '' },
              { partCode: '', partCodeOld: '' },
            ],
            password: '',
            passwordConfirmation: '',
          }}
          validate={() => {
            if (!validateOnChange) {
              setValidateOnChange(true);
            }

            setAsyncErrorMessage('');

            setSubmitDisabled(false);
          }}
          onSubmit={async (values, formik) => {
            setAsyncErrorMessage('');
            const code = values.verificationCode.map(value => value.partCode).join('');
            try {
              await Auth.forgotPasswordSubmit(email, code, values.password);
              handlePassword(values.password);
              handleStep(2);
            } catch (error: unknown) {
              const sdkError = error as SdkErrorType;

              setAsyncErrorMessage(registerPartnerService.getTranslateMessageKey(sdkError.code));
              setSubmitDisabled(true);

              if (sdkError.code === 'CodeMismatchException') {
                formik.setFieldError('verificationCode[0].partCode', ' ');
                formik.setFieldError('verificationCode[1].partCode', ' ');
                formik.setFieldError('verificationCode[2].partCode', ' ');
                formik.setFieldError('verificationCode[3].partCode', ' ');
                formik.setFieldError('verificationCode[4].partCode', ' ');
                formik.setFieldError('verificationCode[5].partCode', ' ');
              }

              if (sdkError.code === 'InvalidPasswordException') {
                formik.setFieldError('password', ' ');
              }
            }
          }}>
          {formik => (
            <Form>
              <ConnectedFocusError />
              <LineDivider verticalSpace={18} />

              <MultiInput
                validateOnChange={validateOnChange}
                name='verificationCode'
                subName='partCode'
                registerPartnerService={registerPartnerService}>
                {(autoTab, handleChange, inputsRef, handlePaste) => (
                  <VerificationCode
                    autoTab={autoTab}
                    handleChange={handleChange}
                    inputsRef={inputsRef}
                    handlePaste={handlePaste}
                  />
                )}
              </MultiInput>

              <Box className={classes.secondBtnWrapper}>
                <BodyTextSmall
                  textContent={formatMessage({
                    id: 'form.forgot_password.stepper.step.change_password.not_received_email.hint',
                    defaultMessage: "You didn't get an email?",
                  })}
                />
                <Button
                  onClick={() => Auth.forgotPassword(email)}
                  variant='text'
                  className={clsx(classes.userBtn, classes.secondBtn)}>
                  {formatMessage({
                    id: 'form.forgot_password.stepper.step.change_password.not_received_email.cta',
                    defaultMessage: 'Send email again',
                  }).toUpperCase()}
                </Button>
              </Box>

              <Box className={classes.divider} />

              <PageHeadline
                textContent={formatMessage({
                  id: 'form.forgot_password.stepper.step.change_password.new_password.header',
                  defaultMessage: 'Create new password',
                })}
                textAlign='center'
              />

              <BodyTextSmall
                textContent={formatMessage({
                  id: 'form.forgot_password.stepper.step.change_password.new_password.subheader',
                  defaultMessage: 'Please set a new password.',
                })}
              />
              <Box className={classes.newPasswordWrapper}>
                <Field
                  className={classes.textField}
                  component={FormikTextField}
                  variant='outlined'
                  name='password'
                  type='password'
                  label={
                    <FormattedMessage
                      id='form.forgot_password.stepper.step.change_password.field.password.label'
                      defaultMessage='Password'
                    />
                  }
                />
                <FormikErrorMessage name='password' />
              </Box>

              <Box className={classes.newPasswordWrapper}>
                <Field
                  className={classes.textField}
                  component={FormikTextField}
                  variant='outlined'
                  name='passwordConfirmation'
                  type='password'
                  label={
                    <FormattedMessage
                      id='form.forgot_password.stepper.step.change_password.field.password_confirmation.label'
                      defaultMessage='Repeat password'
                    />
                  }
                />
                <FormikErrorMessage name='passwordConfirmation' />
              </Box>

              {asyncErrorMessage.length > 0 && (
                <Typography variant='body2' className={classes.error}>
                  <FormattedMessage id={asyncErrorMessage} />
                </Typography>
              )}
              <Box className={classes.submitBtnWrapper}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={submitDisabled || !formik.isValid}
                  className={clsx(classes.mainActionBtn, classes.userBtn)}>
                  {formatMessage({
                    id: 'form.forgot_password.stepper.step.change_password.new_password.cta',
                    defaultMessage: 'Save new password',
                  }).toUpperCase()}
                </Button>
              </Box>

              <RegisterPhotos />
            </Form>
          )}
        </Formik>
      </Box>

      <Button variant='text' onClick={handleBackToLogin} className={classes.backToLoginBtn}>
        <FormattedMessage
          id='form.login.forgot_password.stepper.step.change_password.back_to_login'
          defaultMessage='Back to login'
        />
      </Button>
    </>
  );
};
