import { styled, Typography } from '@mui/material';

import { withTransientProps } from '@/App/Theme/withTransientProps';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const Container = styled(Typography, withTransientProps)<{ $cancelled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-family: Roboto-Regular;
  color: ${props => (props.$cancelled ? FigmaDesignTokens.Grey[500] : 'inherit')};
`;
