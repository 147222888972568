import { GridLocaleText } from '@mui/x-data-grid-pro';
import { useIntl } from 'react-intl';

export const useDataGridLocaleText: () => Partial<GridLocaleText> = () => {
  const intl = useIntl();

  return {
    // Columns selector toolbar button text
    toolbarColumns: intl.formatMessage({ id: 'table.toolbar.columns', defaultMessage: 'Columns' }),
    toolbarColumnsLabel: intl.formatMessage({
      id: 'table.toolbar.columns.label',
      defaultMessage: 'Select columns',
    }),

    // Export selector toolbar button text
    toolbarExport: intl.formatMessage({ id: 'table.toolbar.export', defaultMessage: 'Export' }),
    toolbarExportLabel: intl.formatMessage({
      id: 'table.toolbar.export.label',
      defaultMessage: 'Export',
    }),
    toolbarExportCSV: intl.formatMessage({
      id: 'table.toolbar.export.csv',
      defaultMessage: 'Download as CSV',
    }),
    toolbarExportPrint: intl.formatMessage({
      id: 'table.toolbar.export.pdf',
      defaultMessage: 'Download as PDF',
    }),
    toolbarExportExcel: intl.formatMessage({
      id: 'table.toolbar.export.excel',
      defaultMessage: 'Download as Excel',
    }),

    // Filters toolbar button text
    toolbarFilters: intl.formatMessage({ id: 'table.toolbar.filter', defaultMessage: 'Filters' }),
    toolbarFiltersLabel: intl.formatMessage({
      id: 'table.toolbar.filter.label',
      defaultMessage: 'Show filters',
    }),
    toolbarFiltersTooltipHide: intl.formatMessage({
      id: 'table.toolbar.filter.tooltip.hide',
      defaultMessage: 'Hide filters',
    }),
    toolbarFiltersTooltipShow: intl.formatMessage({
      id: 'table.toolbar.filter.tooltip.show',
      defaultMessage: 'Show filters',
    }),

    // Filter panel text
    filterPanelAddFilter: intl.formatMessage({
      id: 'table.toolbar.filter.add',
      defaultMessage: 'Add filter',
    }),
    filterPanelRemoveAll: intl.formatMessage({
      id: 'table.toolbar.filter.remove_all',
      defaultMessage: 'Remove all',
    }),
    filterPanelDeleteIconLabel: intl.formatMessage({
      id: 'table.toolbar.filter.delete',
      defaultMessage: 'delete',
    }),
    filterPanelLinkOperator: intl.formatMessage({
      id: 'table.toolbar.filter.operators.logic',
      defaultMessage: 'Logic Operator',
    }),
    filterPanelOperators: intl.formatMessage({
      id: 'table.toolbar.filter.panel.operator',
      defaultMessage: 'Operator',
    }), // TODO v6: rename to filterPanelOperator
    filterPanelOperatorAnd: intl.formatMessage({
      id: 'table.toolbar.filter.operator.and',
      defaultMessage: 'And',
    }),
    filterPanelOperatorOr: intl.formatMessage({
      id: 'table.toolbar.filter.operator.or',
      defaultMessage: 'Or',
    }),
    filterPanelColumns: intl.formatMessage({
      id: 'table.toolbar.filter.panel.columns',
      defaultMessage: 'Columns',
    }),
    filterPanelInputLabel: intl.formatMessage({
      id: 'table.toolbar.filter.panel.input.label',
      defaultMessage: 'Value',
    }),
    filterPanelInputPlaceholder: intl.formatMessage({
      id: 'table.toolbar.filter.panel.input.placeholder',
      defaultMessage: 'Filter value',
    }),

    // Filter operators text
    filterOperatorContains: intl.formatMessage({
      id: 'table.toolbar.filter.operator.contains',
      defaultMessage: 'contains',
    }),
    filterOperatorEquals: intl.formatMessage({
      id: 'table.toolbar.filter.operator.equals',
      defaultMessage: 'equals',
    }),
    filterOperatorStartsWith: intl.formatMessage({
      id: 'table.toolbar.filter.operator.starts_with',
      defaultMessage: 'starts with',
    }),
    filterOperatorEndsWith: intl.formatMessage({
      id: 'table.toolbar.filter.operator.end_with',
      defaultMessage: 'ends with',
    }),
    filterOperatorIs: intl.formatMessage({
      id: 'table.toolbar.filter.operator.is',
      defaultMessage: 'is',
    }),
    filterOperatorNot: intl.formatMessage({
      id: 'table.toolbar.filter.operator.not',
      defaultMessage: 'is not',
    }),
    filterOperatorAfter: intl.formatMessage({
      id: 'table.toolbar.filter.operator.after',
      defaultMessage: 'is after',
    }),
    filterOperatorOnOrAfter: intl.formatMessage({
      id: 'table.toolbar.filter.operator.on_or_after',
      defaultMessage: 'is on or after',
    }),
    filterOperatorBefore: intl.formatMessage({ id: 'table.toolbar.filter.operator.before' }),
    filterOperatorOnOrBefore: intl.formatMessage({
      id: 'table.toolbar.filter.operator.on_or_before',
      defaultMessage: 'is before',
    }),
    filterOperatorIsEmpty: intl.formatMessage({
      id: 'table.toolbar.filter.operator.is_empty',
      defaultMessage: 'is empty',
    }),
    filterOperatorIsNotEmpty: intl.formatMessage({
      id: 'table.toolbar.filter.operator.is_not_empty',
      defaultMessage: 'is not empty',
    }),
    filterOperatorIsAnyOf: intl.formatMessage({
      id: 'table.toolbar.filter.operator.is_any_of',
      defaultMessage: 'is any of',
    }),

    // Filter values text
    filterValueAny: intl.formatMessage({
      id: 'table.toolbar.filter.value.any',
      defaultMessage: 'any',
    }),
    filterValueTrue: intl.formatMessage({
      id: 'table.toolbar.filter.value.true',
      defaultMessage: 'true',
    }),
    filterValueFalse: intl.formatMessage({
      id: 'table.toolbar.filter.value.false',
      defaultMessage: 'false',
    }),

    // Column menu text
    columnMenuLabel: intl.formatMessage({
      id: 'table.toolbar.column.menu',
      defaultMessage: 'Menu',
    }),
    columnMenuShowColumns: intl.formatMessage({
      id: 'table.toolbar.column.show_columns',
      defaultMessage: 'Show columns',
    }),
    columnMenuFilter: intl.formatMessage({
      id: 'table.toolbar.column.filter',
      defaultMessage: 'Filter',
    }),
    columnMenuHideColumn: intl.formatMessage({
      id: 'table.toolbar.column.hide',
      defaultMessage: 'Hide',
    }),
    columnMenuUnsort: intl.formatMessage({
      id: 'table.toolbar.column.unsort',
      defaultMessage: 'Unsort',
    }),
    columnMenuSortAsc: intl.formatMessage({
      id: 'table.toolbar.column.sort_by_asc',
      defaultMessage: 'Sort by ASC',
    }),
    columnMenuSortDesc: intl.formatMessage({
      id: 'table.toolbar.column.sort_by_desc',
      defaultMessage: 'Sort by DESC',
    }),
    // Column pinning text
    pinToLeft: intl.formatMessage({
      id: 'table.toolbar.column.pin_to_left',
      defaultMessage: 'Pin to left',
    }),
    pinToRight: intl.formatMessage({
      id: 'table.toolbar.column.pin_to_right',
      defaultMessage: 'Pin to right',
    }),
    unpin: intl.formatMessage({
      id: 'table.toolbar.column.upin',
      defaultMessage: 'Unpin',
    }),
    // Actions cell more text
    actionsCellMore: intl.formatMessage({
      id: 'employee.membership.table.row.more',
      defaultMessage: 'more',
    }),
    noRowsLabel: intl.formatMessage({
      id: 'table.body.row.no_rows',
      defaultMessage: 'No data found, try changing the dates',
    }),
    columnMenuManageColumns: intl.formatMessage({
      id: 'table.toolbar.column.manage_columns',
      defaultMessage: 'Manage columns',
    }),
    columnsManagementShowHideAllText: intl.formatMessage({
      id: 'table.toolbar.column.manage_columns.show_hide_all',
      defaultMessage: 'Show/Hide all',
    }),
    columnsManagementResetText: intl.formatMessage({
      id: 'table.toolbar.column.manage_columns.reset',
      defaultMessage: 'Resets',
    }),
  };
};
