import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import useCurrentLanguage from './useCurrentLanguage';

const useMemoedIntl = () => {
  const intl = useIntl();
  const currentLanguage = useCurrentLanguage();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => intl, [currentLanguage, intl.messages]);
};

export default useMemoedIntl;
