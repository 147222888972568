import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

const SidebarNavItemStyles = makeStyles()(() => ({
  navigationListItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 0,
    alignItems: 'center',
    height: 46,
    '&:hover': {
      backgroundColor: FigmaDesignTokens.Blue[700],
      cursor: 'pointer',
    },
  },
  navigationListItemContainerActive: {
    backgroundColor: FigmaDesignTokens.Blue[800],
  },
  navigationListItem: {
    marginLeft: 62,
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0.13,
    color: FigmaDesignTokens.Grey[100],
  },

  subItemContainer: {
    backgroundColor: FigmaDesignTokens.Blue[900],
  },
  subItemLink: {
    paddingLeft: 20,
  },
  subItem: {
    fontWeight: 400,
    marginLeft: 62,
  },

  navigationListItemActive: {
    opacity: 0.6,
  },
}));

export default SidebarNavItemStyles;
