import { Box, Button, styled, Typography } from '@mui/material';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const Name = styled(Typography)`
  display: flex;
  font-size: 14px;
  color: ${FigmaDesignTokens.Grey[950]};
`;

export const Container = styled(Box)`
  border-bottom: none;
  padding: 0;
  display: flex;
  align-items: center;
`;

export const StatusWrapper = styled(Box)`
  display: flex;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-family: Roboto-Medium;
  line-height: 1.43;
`;

export const ActiveStatus = styled(Box)`
  color: ${FigmaDesignTokens.Grey[50]};
  background-color: ${FigmaDesignTokens.Green[500]};
  border-color: ${FigmaDesignTokens.Green[500]};
  border-radius: 4px;
  padding: 1px 8px;
`;

export const DeletedStatus = styled(Box)`
  background-color: ${FigmaDesignTokens.Red[500]};
  color: ${FigmaDesignTokens.White};
  border-radius: 4px;
  border-color: ${FigmaDesignTokens.Red[500]};
  align-items: flex-start;
  padding: 1px 8px;
`;

export const BlockedStatus = styled(Box)`
  background-color: ${FigmaDesignTokens.Orange[500]};
  color: ${FigmaDesignTokens.White};
  border-color: ${FigmaDesignTokens.Orange[500]};
  border-radius: 4px;
  padding: 1px 8px;
`;

export const PendingStatus = styled(Box)`
  background-color: ${FigmaDesignTokens.Grey[400]};
  color: ${FigmaDesignTokens.White};
  border-radius: 4px;
  padding: 1px 8px;
`;

export const PendingButton = styled(Button)`
  padding: 0;
  min-width: unset;
  margin-left: 12px;
`;

export const PendingButtonText = styled(Typography)`
  margin-left: 12px;
  color: ${FigmaDesignTokens.Grey[500]};
  font-size: 16px;
  font-family: Roboto-Medium;
  text-transform: capitalize;
`;
