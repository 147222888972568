import { Modal } from '@mui/material';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';

import CloseIcon from '@/Static/Icons/atoms-symbols-category-close-big.svg';
import { Container, Content, CloseButton } from './Styles/AdministrationModal.styles';
import { ReactNode } from 'react';

export type AdvertismentExplorerFiltersModalProps = {
  show: boolean;

  header: string;

  onHide: () => void;
  children: ReactNode;
};

export const AdvertismentExplorerFiltersModal = ({
  show,
  onHide,
  children,
  header,
}: AdvertismentExplorerFiltersModalProps) => (
  <Modal open={show}>
    <Container
      sx={{
        width: '620px',
      }}>
      <CloseButton aria-label='close' onClick={onHide} variant='text'>
        <img alt='' src={CloseIcon} />
      </CloseButton>
      <FormHeader title={header} />
      <Content sx={{ marginBottom: 0 }}>{children}</Content>
    </Container>
  </Modal>
);
