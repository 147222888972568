import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { CustomDataGrid } from '@/App/Shared/DataGridTable/Table/DataGridTable';
import { NoResultsOverlay } from '@/App/Shared/DataGridTable/Table/NoResultsOverlay';
import { NoRowsOverlay } from '@/App/Shared/DataGridTable/Table/NoRowsOverlay';
import { useAppContext } from '@/Context';
import { useTableRows } from '@/Hooks/useTableRows';
import { UsersServiceFactory } from '@/Services';
import { AdministrationFormik, Partner, User } from '@/Types';
import { Toolbar } from '@/Views/CourseManager/Components/Toolbar/Toolbar';

import { ActionsCell } from '../AdministrationTableCell/ActionsCell';
import { AdminRoleCell } from '../AdministrationTableCell/AdminRoleCell';
import { EmailCell } from '../AdministrationTableCell/EmailCell';
import { NameCell } from '../AdministrationTableCell/NameCell';
import { ProfileIconCell } from '../AdministrationTableCell/ProfileIconCell';
import { StatusCell } from '../AdministrationTableCell/StatusCell';

type AdministrationTableProps = {
  handlePrimaryCtaDisabled: (value: boolean) => void;
  handleOpenToastMessage: (message?: string) => void;
  currentUserId: string;
  casPublicId: Partner['casPublicId'];
  handleAddNewUsers: () => void;
  isLoading?: boolean;
};

export const AdministrationTable = ({
  handleAddNewUsers,
  handlePrimaryCtaDisabled,
  handleOpenToastMessage,
  currentUserId,
  casPublicId,
  isLoading,
}: AdministrationTableProps) => {
  const { dispatch } = useAppContext();

  const {
    values: { users },
    setFieldValue,
    submitForm,
  } = useFormikContext<AdministrationFormik>();

  const intl = useIntl();
  const usersService = useMemo(() => new UsersServiceFactory().getInstance(dispatch), [dispatch]);

  const updateUser = useCallback(
    async (user: User) => {
      setFieldValue('updatedUser', user);
      handlePrimaryCtaDisabled(false);

      const newUsers = usersService.getNewUsers(users, user);
      setFieldValue('users', newUsers);

      await submitForm();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFieldValue, handlePrimaryCtaDisabled, users, submitForm],
  );

  const columns: GridColDef[] = [
    {
      field: 'Avatar',
      filterable: false,
      headerName: '',
      width: 80,
      pinnable: false,
      sortable: false,
      display: 'flex',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<User>) => <ProfileIconCell data={params.row} />,
    },
    {
      field: 'givenName.name',
      headerName: intl.formatMessage({
        id: 'view.user_management.user_management.table.header.name',
        defaultMessage: 'Name',
      }),
      width: 170,
      pinnable: false,
      sortable: false,
      display: 'flex',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<User>) => <NameCell data={params.row} />,
    },
    {
      field: 'email',
      headerName: intl.formatMessage({
        id: 'view.user_management.user_management.table.header.email',
        defaultMessage: 'Email',
      }),
      width: 240,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      display: 'flex',
      renderCell: (params: GridRenderCellParams<User>) => <EmailCell data={params.row} />,
    },
    {
      field: 'role',
      headerName: intl.formatMessage({
        id: 'view.user_management.user_management.table.header.admin_rights',
        defaultMessage: 'Role',
      }),
      width: 120,
      pinnable: false,
      sortable: false,
      display: 'flex',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<User>) => (
        <AdminRoleCell currentUserId={currentUserId} data={params.row} updateUser={updateUser} />
      ),
    },
    {
      field: 'status',
      headerName: intl.formatMessage({
        id: 'view.user_management.user_management.table.header.user_state',
        defaultMessage: 'Status',
      }),
      width: 356,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      display: 'flex',
      renderCell: (params: GridRenderCellParams<User>) => (
        <StatusCell
          data={params.row}
          casPublicId={casPublicId}
          handleOpenToastMessage={handleOpenToastMessage}
        />
      ),
    },
    {
      field: 'cta',
      filterable: false,
      cellClassName: 'actions-cell',
      headerName: '',
      width: 104,
      pinnable: false,
      sortable: false,
      disableColumnMenu: true,
      display: 'flex',
      renderCell: (params: GridRenderCellParams<User>) => (
        <ActionsCell data={params.row} currentUserId={currentUserId} updateUser={updateUser} />
      ),
    },
  ];

  const rows = useTableRows({
    uniqueIdKey: 'cognitoId',
    data: users || [],
    composedKeys: [['givenName', 'name']],
  });

  return (
    <CustomDataGrid
      slots={{
        toolbar: () => (
          <Toolbar
            {...{
              handleSubmit: handleAddNewUsers,
              submitText: intl.formatMessage({
                id: 'view.user_management.user_management.cta.add_new_user',
                defaultMessage: 'Add new user',
              }),
            }}
          />
        ),
        noRowsOverlay: () => (
          <NoRowsOverlay
            {...{
              header: intl.formatMessage({
                id: 'view.user_management.user_management.table.empty_filter_header',
                defaultMessage: 'No Users found',
              }),
              subHeader: intl.formatMessage({
                id: 'view.user_management.user_management.table.empty_filter_sub_header',
                defaultMessage: 'Please change your search criteria and try again.',
              }),
            }}
          />
        ),
        noResultsOverlay: () => (
          <NoResultsOverlay
            {...{
              header: intl.formatMessage({
                id: 'view.user_management.user_management.table.empty_table_header',
                defaultMessage: 'No existing users',
              }),
              subHeader: intl.formatMessage({
                id: 'view.user_management.user_management.table.empty_table_sub_header',
                defaultMessage: 'Only active users are shown here.',
              }),
              ctaTitle: intl.formatMessage({
                id: 'view.user_management.user_management.cta.add_new_user',
                defaultMessage: 'Add new user',
              }),
              ctaHandler: handleAddNewUsers,
            }}
          />
        ),
      }}
      columns={columns}
      autoHeight
      rowHeight={66}
      rows={rows}
      hideFooter
      loading={isLoading}
      sx={{
        '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator--sideRight': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeader:nth-of-type(5) .MuiDataGrid-columnSeparator--sideRight': {
          display: 'none',
        },
      }}
    />
  );
};
