import { makeStyles } from 'tss-react/mui';

export const ErrorMessageStyles = makeStyles()(theme => ({
  root: {
    color: theme.palette.error.main,
    marginRight: 14,
    fontSize: '0.70rem',
    alignSelf: 'flex-start',
  },
}));
