import { IntlShape } from 'react-intl';

export const getStatisticsPages = (intl: IntlShape) => [
  {
    path: 'check-ins-alle',
    title: intl.formatMessage({
      id: 'view.side_navigation.category.statistic.checkins_all',
      defaultMessage: 'Check-Ins',
    }),
  },
  {
    path: 'check-ins-firma',
    title: intl.formatMessage({
      id: 'view.side_navigation.category.statistic.checkins_by_company',
      defaultMessage: 'Check-Ins pro Firma',
    }),
  },
  {
    path: 'check-ins-mitglieder',
    title: intl.formatMessage({
      id: 'view.side_navigation.category.statistic.checkins_by_user',
      defaultMessage: 'Check-Ins pro Mitglieder',
    }),
  },
  {
    path: 'training-instruction',
    title: intl.formatMessage({
      id: 'view.side_navigation.category.statistic.training_instruction',
      defaultMessage: 'Training Instruction',
    }),
  },
];
