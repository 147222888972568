import { Divider, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { FormikErrorMessage } from '@/App/Shared/Form/Components/Formik/FormikErrorMessage';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { SubHeader } from '@/App/Shared/Form/Components/Header/SubHeader';
import { Categories, SelectedCategories } from '@/Components';
import { useGetPartnerQuery } from '@/Queries';
import { Category, Course, DayOption } from '@/Types';

import { useCourseCategoriesStyles } from './Course.styles';

interface CourseCategoriesProps {
  categories: Category[];
  handlePrimaryCtaDisabled: (value: boolean) => void;
}

export const CourseCategories = ({
  categories,
  handlePrimaryCtaDisabled,
}: CourseCategoriesProps) => {
  const intl = useIntl();
  const { classes } = useCourseCategoriesStyles();
  const {
    setFieldValue,
    values,
    setFieldError,
    isValid,
    isValidating,
    validateForm,
    initialValues,
  } = useFormikContext<Course>();
  const { data: partner } = useGetPartnerQuery();

  const handlePrimaryCategoryDelete = useCallback(() => {
    setFieldValue('mainCategory', null);
    setFieldError(
      'mainCategory',
      intl.formatMessage({
        id: 'form.course.error.mainCategory.required',
        defaultMessage: 'Please specify a main category.',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldError, setFieldValue]);

  const handleCategoryDelete = useCallback(
    (category: Category | DayOption) => {
      if (!values.secondaryCategories) {
        return;
      }
      const filtered = values.secondaryCategories.filter(
        selectedCategoryId => selectedCategoryId !== Number.parseInt(category.id, 10),
      );
      setFieldValue('secondaryCategories', filtered);
      validateForm();
    },
    [values.secondaryCategories, setFieldValue, validateForm],
  );

  const handlePrimaryCategorySelect = useCallback(
    (id: Category['id']) => {
      const category = categories.find(category => category.id === id);

      if (category) {
        setFieldValue('mainCategory', Number.parseInt(category.id, 10));
        setFieldError('mainCategory', undefined);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categories],
  );

  const handleCategorySelect = useCallback(
    (id: string) => {
      const category = categories.find((category: Category) => category.id === id);
      if (category) {
        if (values.secondaryCategories) {
          values.secondaryCategories.push(Number.parseInt(category.id, 10));
          setFieldValue('secondaryCategories', [...values.secondaryCategories]);
        } else {
          setFieldValue('secondaryCategories', [Number.parseInt(category.id, 10)]);
        }

        validateForm();
      }
    },

    [categories, values.secondaryCategories, validateForm, setFieldValue],
  );

  useEffect(() => {
    // remove isNeededAccessoires from the difference check
    const compInitialValues = { ...initialValues };
    const compValues = { ...values };
    delete compInitialValues.isNeededAccessoires;
    delete compValues.isNeededAccessoires;
    handlePrimaryCtaDisabled(!(!isEqual(compInitialValues, compValues) && isValid));
  }, [isValid, handlePrimaryCtaDisabled, isValidating, initialValues, values]);

  return (
    <Grid item xs={12}>
      <ContentContainer>
        <Grid container spacing={2}>
          <Grid item container spacing={4}>
            <Grid item xs={12}>
              <FormHeader
                title={intl.formatMessage({
                  id: 'form.course.header.mainCategory',
                  defaultMessage: 'Primary course category*',
                })}
              />
            </Grid>
          </Grid>

          <Grid xs={12} item>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SubHeader
                  title={intl.formatMessage({
                    id: 'form.course.subheader.chosenCategory',
                    defaultMessage: 'Selected category:',
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectedCategories
                  disabled={!!partner?.readonly}
                  selectedCategories={values.mainCategory ? [values.mainCategory] : []}
                  categories={categories}
                  handleDelete={handlePrimaryCategoryDelete}
                  name='mainCategory'
                />
                <FormikErrorMessage name='mainCategory' />
              </Grid>
              <Grid item xs={12}>
                <Categories
                  multiSelect={false}
                  selectedCategories={values.mainCategory ? [values.mainCategory] : []}
                  handleClick={handlePrimaryCategorySelect}
                  categories={categories}
                  disabledIds={values.secondaryCategories ? values.secondaryCategories : []}
                  name='mainCategory'
                  readOnly={partner?.readonly}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item container spacing={4}>
            <Grid item xs={12}>
              <FormHeader
                title={intl.formatMessage({
                  id: 'form.course.header.secondaryCategories',
                  defaultMessage: 'More suitable categories',
                })}
              />
            </Grid>
          </Grid>

          <Grid xs={12} item>
            <Grid container spacing={2} className={classes.lastElementContainer}>
              <Grid item xs={12}>
                <SubHeader
                  title={intl.formatMessage({
                    id: 'form.course.subheader.chosenCategories',
                    defaultMessage: 'Selected categories:',
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectedCategories
                  disabled={!!partner?.readonly}
                  selectedCategories={values.secondaryCategories ? values.secondaryCategories : []}
                  categories={categories}
                  handleDelete={handleCategoryDelete}
                  name='secondaryCategories'
                />
                <FormikErrorMessage name='secondaryCategories' />
              </Grid>
              <Grid item xs={12}>
                <Categories
                  multiSelect
                  selectedCategories={values.secondaryCategories ? values.secondaryCategories : []}
                  handleClick={handleCategorySelect}
                  categories={categories}
                  disabledIds={values.mainCategory ? [values.mainCategory] : []}
                  name='secondaryCategories'
                  readOnly={partner?.readonly}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ContentContainer>
    </Grid>
  );
};
