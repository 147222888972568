import { Box, styled, Typography } from '@mui/material';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const Container = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled(Box)`
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${FigmaDesignTokens.Grey[100]};
  border-radius: 10px;
  text-align: center;
  max-width: 80%;
  justify-content: center;
  margin: 158px auto;
  width: 940px;
  height: 220px;
`;

export const HeaderContent = styled(Typography)`
  font-family: Roboto-Bold;
  color: ${FigmaDesignTokens.Blue[950]};
`;

export const SubContentWrapper = styled(Box)`
  width: 460px;
  max-width: 85%;
`;

export const SubHeader = styled(Typography)`
  margin-top: 2px;
  color: ${FigmaDesignTokens.Grey[950]};
  font-family: Roboto-Regular;
`;
