import { HttpService } from '@/Services';
import { Auth } from 'aws-amplify';
import { EventsService } from '../Events/Events.service';
import { Dispatch } from 'react';
import { AnyStateAction } from '../../Context/Context';

export class EventsServiceFactory {
  public getInstance(dispatch: Dispatch<AnyStateAction>): EventsService {
    return new EventsService(new HttpService(Auth, dispatch));
  }
}
