import { Box, styled } from '@mui/material';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const IconContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const IconBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: ${FigmaDesignTokens.bg.brand.brand};
`;

export const BlockedIconBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: ${FigmaDesignTokens.Grey[500]};
`;

export const IconNameBox = styled(Box)`
  font-family: Roboto-Medium;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  font-stretch: normal;
  letter-spacing: 2px;
  text-align: center;
  color: ${FigmaDesignTokens.White};
`;
