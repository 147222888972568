import { createPortal } from 'react-dom';
import { useToaster } from 'react-hot-toast';
import useStyles from './ToastContainer.styles';
import ToastItem from '../ToastItem';

const ToastContainer = () => {
  const { toasts } = useToaster();
  const { classes } = useStyles();

  if (!toasts) {
    return null;
  }

  return createPortal(
    <div className={classes.container}>
      {toasts.reverse().map((row, i) => (
        <ToastItem key={row.id} {...row} index={i} />
      ))}
    </div>,
    document.body,
  );
};

export default ToastContainer;
