import { useEffect, useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

type MapProps = {
  lat: number;
  lng: number;
  geoChanged: boolean;
  containerStyle?: React.CSSProperties;
};

export const Map = ({ lat, lng, geoChanged, containerStyle }: MapProps): JSX.Element => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  });
  const [zoom, setZoom] = useState<number>(17);

  useEffect(() => {
    if (geoChanged) {
      setZoom(18);
    }
  }, [geoChanged, setZoom, lat, lng]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <GoogleMap zoom={zoom} center={{ lat, lng }} mapContainerStyle={containerStyle}>
      <Marker position={{ lat, lng }} />
    </GoogleMap>
  );
};
