import { Button, MenuItem, styled } from '@mui/material';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const EditButton = styled(Button)`
  min-width: 32px;
`;

export const AlertMenuItem = styled(MenuItem)`
  color: ${FigmaDesignTokens.Red[500]};
`;
