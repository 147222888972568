import { Box, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { TranslationService } from '@/App/Shared/Translation/TranslationService';
import { Button } from '@/Components';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import Check from '@/Static/Icons/atoms-symbols-icons-check.svg';
import { ValidatePartnerDataType } from '@/Types/ValidatePartnerData';

import { useInvitePartnerConfirmationStyles } from './Steps.styles';

type InvitePartnerConfirmationStepProps = {
  studioName: string;
  handleCloseVerifyPartnerModal: () => void;
  newPartnerData: ValidatePartnerDataType;
};

export const InvitePartnerConfirmationStep = ({
  studioName,
  handleCloseVerifyPartnerModal,
  newPartnerData,
}: InvitePartnerConfirmationStepProps) => {
  const { classes } = useInvitePartnerConfirmationStyles();
  const intl = useIntl();
  const navigate = useNavigate();
  const currentLanguage = useCurrentLanguage();

  const handlePartnerSwitch = useCallback(() => {
    navigate(
      `/${currentLanguage}/partner-wechseln?publicId=${newPartnerData.publicId}&name=${newPartnerData.name}`,
    );
    handleCloseVerifyPartnerModal();
  }, [navigate, currentLanguage, newPartnerData, handleCloseVerifyPartnerModal]);

  return (
    <Box className={classes.mainContent}>
      <Grid item>
        <Grid container direction='column' justifyContent='center'>
          <img alt='' src={Check} className={classes.headerIcon} />
          <Typography variant='body1' className={clsx(classes.header, classes.headerForm)}>
            {intl
              .formatMessage({
                id: 'invite_partner.confirmation.step.header',
              })
              .toUpperCase()}
          </Typography>
        </Grid>
        <Grid>
          <Typography variant='body2' className={classes.subHeader}>
            {TranslationService.replacePlaceholder(
              intl.formatMessage({
                id: 'invite_partner.confirmation.step.subheader',
                defaultMessage: 'The studio {{partner}} was successfully added.',
              }),
              '{{partner}}',
              newPartnerData.name || '',
            )}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.btnWrapper}>
            <Button
              variant='contained'
              className={classes.changePartnerBtn}
              onClick={handlePartnerSwitch}>
              {TranslationService.replacePlaceholder(
                intl.formatMessage({
                  id: 'invite_partner.confirmation.step.change_to_partner',
                  defaultMessage: 'Switch to {{partner}}',
                }),
                '{{partner}}',
                newPartnerData.name,
              )}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.btnWrapper}>
            <Button
              variant='text'
              textTransform
              className={classes.stayOnCurrentPartnerBtn}
              onClick={handleCloseVerifyPartnerModal}>
              {TranslationService.replacePlaceholder(
                intl.formatMessage({
                  id: 'invite_partner.confirmation.step.stay_on_studio',
                  defaultMessage: 'Stay on profile of {{studio}}',
                }),
                '{{studio}}',
                studioName,
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
