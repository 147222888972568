import { FormikHelpers, FormikProps } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CtaFooter } from '@/App/Shared/Footer/CtaFooter';
import { FormSnackbar } from '@/App/Shared/Notification/Components/FormSnackbar';
import { AdministrationForm, Header, InvitationsForm, TabPanelNavigation } from '@/Components';
import useUserManagementBreadcrumbs from '@/Hooks/Breadcrumbs/useUserManagementBreadcrumbs';
import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { getCommonInfoTabIdx, getUsersTabIdx, getUsersTabName } from '@/Mappers';
import { useCurrentPartnerIdQuery, useCurrentUserInfoQuery } from '@/Queries';
import { AdministrationFormik, InvitationsFormik } from '@/Types';
import handleCatchError from '@/Utils/handleCatchError';

const UserManagementPage = () => {
  const { formatMessage } = useMemoedIntl();
  const { tab = '' } = useParams<{ tab: string }>();
  const navigate = useNavigate();
  const currentLanguage = useCurrentLanguage();
  const [currentTab, setCurrentTab] = useState(getCommonInfoTabIdx(tab));
  const [openToastMessage, setOpenToastMessage] = useState(false);
  const [customToastMessage, setCustomToastMessage] = useState<string>();
  const [administrationFormRef, setAdministrationFormRef] =
    useState<FormikProps<AdministrationFormik>>();
  const [invitationsFormRef, setInvitationsFormRef] = useState<FormikHelpers<InvitationsFormik>>();
  const [primaryCtaDisabled, setPrimaryCtaDisabled] = useState(true);
  const [primaryCtaTitle, setPrimaryCtaTitle] = useState('');

  const { data: userAttributes, isLoading: isLoadingUserAttributes } = useCurrentUserInfoQuery();
  const { data: casPublicId, isLoading: isLoadingCasPublicId } = useCurrentPartnerIdQuery();

  const handlePrimaryCtaDisabled = useCallback((value: boolean) => {
    setPrimaryCtaDisabled(value);
  }, []);

  const handleToastMessage = useCallback((value: boolean, customMessage?: string) => {
    setOpenToastMessage(value);
    if (customMessage) {
      setCustomToastMessage(customMessage);
    }
  }, []);

  const handleTabChange = useCallback(
    (_, newValue: number) => {
      const tabName = getUsersTabName(newValue);
      navigate(`/${currentLanguage}/benutzer/${tabName}`);
    },
    [currentLanguage, navigate],
  );

  useEffect(() => {
    const newTab = getUsersTabIdx(tab);
    setCurrentTab(newTab);
    setPrimaryCtaDisabled(true);

    if (newTab === 0) {
      setPrimaryCtaTitle('');
    }
    if (newTab === 1) {
      setPrimaryCtaTitle(
        formatMessage({
          id: 'view.invite_user.cta.single',
          defaultMessage: 'Send invitation',
        }),
      );
    }
  }, [tab, formatMessage]);

  const receiveRef = useCallback((ref: any, tabNumber: number) => {
    if (tabNumber === 0) {
      setAdministrationFormRef(ref.current);
    }

    if (tabNumber === 1) {
      setInvitationsFormRef(ref.current);
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      if (currentTab === 0 && administrationFormRef) {
        await administrationFormRef.submitForm();
      }

      if (currentTab === 1 && invitationsFormRef) {
        await invitationsFormRef.submitForm();
      }
    } catch (error: unknown) {
      handleCatchError(error);
    }
  }, [currentTab, administrationFormRef, invitationsFormRef]);

  const currentBreadcrumbs = useUserManagementBreadcrumbs(currentTab);

  const isLoading = isLoadingUserAttributes || isLoadingCasPublicId;

  return (
    <>
      <Header
        showLanguageSwitcher={false}
        callToActions={{
          primary: {
            title: primaryCtaTitle,
            callback: handleSubmit,
            disabled: primaryCtaDisabled,
            variant: 'contained',
          },
        }}
        handleTabChange={handleTabChange}
        currentTab={currentTab}
        header={formatMessage({
          id: 'view.user_management.user_management',
          defaultMessage: 'User management',
        })}
        breadcrumbs={currentBreadcrumbs}
        tabs={[
          { label: 'view.user_management.user_management', disabled: false },
          { label: 'view.user_management.invite_users', disabled: false },
        ]}
      />
      <FormSnackbar
        openToastMessage={openToastMessage}
        handleCloseToastMessage={() => handleToastMessage(false)}
        customMessage={customToastMessage}
      />
      {casPublicId && !isLoading && userAttributes?.attributes?.sub && (
        <>
          <TabPanelNavigation value={currentTab} index={0}>
            <AdministrationForm
              refCallback={receiveRef}
              casPublicId={casPublicId}
              currentUserId={userAttributes.attributes.sub}
              handleToastMessage={handleToastMessage}
              handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
            />
          </TabPanelNavigation>

          <TabPanelNavigation value={currentTab} index={1}>
            <InvitationsForm
              refCallback={receiveRef}
              casPublicId={casPublicId}
              handleToastMessage={handleToastMessage}
              handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
              handlePrimaryCtaTitle={setPrimaryCtaTitle}
            />
          </TabPanelNavigation>
        </>
      )}
      <CtaFooter
        callToActions={{
          primary: {
            title: primaryCtaTitle,
            callback: handleSubmit,
            disabled: primaryCtaDisabled,
            variant: 'contained',
          },
        }}
      />
    </>
  );
};

export default UserManagementPage;
