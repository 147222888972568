import { Box, Button, Grid, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { sanitize } from 'dompurify';
import { ConnectedFocusError } from 'focus-formik-error';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { isEmpty } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { FormikErrorMessage } from '@/App/Shared/Form/Components/Formik/FormikErrorMessage';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import { useAppDispatch } from '@/Hooks/useAppDispatch';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { RegisterPartnerServiceFactory } from '@/Services';
import { AuthActions } from '@/Store/Auth';
import { UserRegistrationFormProps } from '@/Types';
import { SdkErrorType } from '@/Types/SdkError';
import { getRegisterEmail, RegisterUserValidation } from '@/Utils';

import { RegisterPhotos } from '../Shared/Photos/RegisterPhotos';
import { useUserFormStyles } from './RegisterUser.styles';

const RegisterUserForm = () => {
  const { classes } = useUserFormStyles();
  const dispatch = useAppDispatch();
  const { formatMessage } = useMemoedIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const [asyncErrorMessage, setAsyncErrorMessage] = useState('');
  const registerPartnerService = new RegisterPartnerServiceFactory().getInstance(dispatch);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const loginPath = '/login';

  const handleSubmit = useCallback(
    async (values: UserRegistrationFormProps, formik: FormikHelpers<UserRegistrationFormProps>) => {
      setAsyncErrorMessage('');
      try {
        const email = values.email;
        const temporaryPassword = values.temporaryPassword;
        const password = values.password;
        const user = await Auth.signIn(email, temporaryPassword);

        await Auth.completeNewPassword(user, password);

        dispatch(AuthActions.Login({ username: email, password }));

        Sentry.setUser({ email });

        navigate(loginPath);
      } catch (e: unknown) {
        const sdkError = e as SdkErrorType;

        if (registerPartnerService) {
          setAsyncErrorMessage(registerPartnerService.getTranslateMessageKey(sdkError.code));
          setSubmitDisabled(true);
        }

        if (sdkError.code === 'NotAuthorizedException') {
          formik.setFieldError('email', ' ');
          formik.setFieldError('temporaryPassword', ' ');
        }

        if (sdkError.code === 'InvalidPasswordException') {
          formik.setFieldError('password', ' ');
        }
      }
    },
    [dispatch, navigate, registerPartnerService],
  );

  const sanitizedLegalHint = useMemo(
    () =>
      sanitize(
        formatMessage({
          id: 'form.register.stepper.step.initial_startcode.legal_hint',
          defaultMessage:
            'By clicking on "Go ahead", I declare that I have taken note of the content',
        }),
      ),
    [formatMessage],
  );

  if (!registerPartnerService) {
    return null;
  }

  return (
    <Formik
      validationSchema={RegisterUserValidation}
      validateOnChange
      validateOnBlur={false}
      validate={() => {
        setAsyncErrorMessage('');

        setSubmitDisabled(false);
      }}
      initialValues={{
        email: getRegisterEmail(location.search) || '',
        temporaryPassword: '',
        password: '',
        passwordConfirmation: '',
      }}
      onSubmit={handleSubmit}>
      {formik => {
        const isFullFormDataSubmit =
          isEmpty(formik.values.email) ||
          isEmpty(formik.values.password) ||
          isEmpty(formik.values.passwordConfirmation) ||
          isEmpty(formik.values.temporaryPassword);

        const isDisabledSubmit =
          submitDisabled || (!formik.isValid && isEmpty(formik.errors)) || isFullFormDataSubmit;

        return (
          <Form>
            <ConnectedFocusError />
            <Box className={classes.mainContent}>
              <Grid container spacing={4}>
                <Grid item>
                  <Grid>
                    <Typography
                      variant='body1'
                      className={clsx(classes.header, classes.headerForm)}>
                      {formatMessage({
                        id: 'form.register_user.header',
                        defaultMessage: 'User registration',
                      }).toUpperCase()}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography variant='body2' className={classes.subHeader}>
                      <FormattedMessage
                        id='form.register_user.subheader'
                        defaultMessage='Please enter your temporary password and then set a new one.'
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item spacing={2} justifyContent='center'>
                  <Grid container item spacing={2} justifyContent='center'>
                    <Grid item xs={12}>
                      <Field
                        className={classes.textField}
                        component={FormikTextField}
                        variant='outlined'
                        name='email'
                        type='email'
                        label={
                          <FormattedMessage
                            id='form.register_user.field.email.label'
                            defaultMessage='E-mail address*'
                          />
                        }
                      />
                      <FormikErrorMessage name='email' />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        className={classes.textField}
                        component={FormikTextField}
                        variant='outlined'
                        name='temporaryPassword'
                        type='password'
                        label={
                          <FormattedMessage
                            id='form.register_user.field.temporary_password.label'
                            defaultMessage='Temporary start password*'
                          />
                        }
                      />
                      <FormikErrorMessage name='temporaryPassword' />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        className={classes.textField}
                        component={FormikTextField}
                        variant='outlined'
                        name='password'
                        type='password'
                        label={
                          <FormattedMessage
                            id='form.register_user.field.password.label'
                            defaultMessage='new password*'
                          />
                        }
                      />
                      <FormikErrorMessage name='password' />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        className={classes.textField}
                        component={FormikTextField}
                        variant='outlined'
                        name='passwordConfirmation'
                        type='password'
                        label={
                          <FormattedMessage
                            id='form.register_user.field.password_repeat.label'
                            defaultMessage='repeat new password*'
                          />
                        }
                      />
                      <FormikErrorMessage name='passwordConfirmation' />
                    </Grid>
                  </Grid>

                  {asyncErrorMessage.length > 0 && (
                    <Grid item xs={12}>
                      <Typography variant='body2' className={classes.error}>
                        <FormattedMessage
                          id={asyncErrorMessage}
                          defaultMessage={asyncErrorMessage}
                        />
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                <Grid>
                  <Typography
                    className={classes.legalHint}
                    dangerouslySetInnerHTML={{
                      __html: sanitizedLegalHint,
                    }}
                  />
                </Grid>

                <Grid item container xs={12} alignItems='center' direction='column'>
                  <Grid>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      disabled={isDisabledSubmit}
                      className={clsx(classes.mainActionBtn, classes.userBtn)}>
                      {formatMessage({
                        id: 'form.register_user.cta.finish_registration',
                        defaultMessage: 'Complete registration',
                      }).toUpperCase()}
                    </Button>
                  </Grid>
                </Grid>
                <RegisterPhotos />
              </Grid>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RegisterUserForm;
