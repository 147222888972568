import React, { useCallback } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ConfirmationDialogTypes, AppContext } from '@/Context';
import { Button } from '@/Components';

type HeaderCtaProps = {
  variant: 'text' | 'outlined' | 'contained';
  title: string;
  disabled: boolean;
  callback: () => void;
};

type CtaFooterProps = {
  callToActions: {
    primary?: HeaderCtaProps;
    secondary?: HeaderCtaProps;
  };
};

export const useStyles = makeStyles()(() => ({
  saveAndPublishContainer: {
    paddingTop: 24,
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 30,
  },
  saveAndPublishButton: {
    marginLeft: '16px',
  },
}));

export const CtaFooter = (props: CtaFooterProps): JSX.Element => {
  const { callToActions } = props;
  const { primary, secondary } = callToActions;
  const { classes } = useStyles();
  const { dispatch } = React.useContext(AppContext);

  const handleStateAndSubmit = useCallback(() => {
    dispatch({
      type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
      payload: { show: false },
    });
    primary?.callback();
  }, [dispatch, primary]);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.saveAndPublishContainer}>
        {secondary && !!secondary.title && secondary.callback && (
          <Button
            variant='outlined'
            disabled={secondary.disabled}
            onClick={secondary.callback}
            className={classes.saveAndPublishButton}>
            {secondary.title}
          </Button>
        )}

        {primary && !!primary.title && !!primary.callback && (
          <Button
            disabled={primary.disabled}
            onClick={handleStateAndSubmit}
            variant={primary.variant}
            className={classes.saveAndPublishButton}>
            {primary.title}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
