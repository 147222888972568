import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { BodyTextSmall, Button, LineDivider, MainHeadline } from '@/Components';
import { useAppContext } from '@/Context';
import { RegisterPartnerServiceFactory } from '@/Services';
import Check from '@/Static/Icons/atoms-symbols-icons-check.svg';

import { LoginPhotos } from '../../Login/LoginPhotos';
import { SignInForm } from '../../Shared/SignInForm';
import { useConfirmationStepStyles } from './Steps.styles';

export interface ConfirmationStepProps {
  email?: string;
  password?: string;
  showHeader: boolean;
  isEmailConfirmed?: boolean;
}

export const ConfirmationStep = ({
  email,
  password,
  showHeader,
  isEmailConfirmed,
}: ConfirmationStepProps) => {
  const { classes } = useConfirmationStepStyles();
  const { formatMessage } = useIntl();
  const { dispatch } = useAppContext();
  const [feedbackText, setFeedbackText] = useState('');

  const registerPartnerService = useMemo(
    () => new RegisterPartnerServiceFactory().getInstance(dispatch),
    [dispatch],
  );

  const resendEmail = async () => {
    if (email) {
      const giveUIFeedback = (responce: 'success' | 'error') => {
        setFeedbackText(responce);
        setTimeout(() => {
          setFeedbackText('');
        }, 30000);
      };
      try {
        await registerPartnerService.resendCode({ email });

        giveUIFeedback('success');
      } catch (error) {
        giveUIFeedback('error');
      }
    }
  };

  return (
    <>
      {showHeader && (
        <MainHeadline
          className={clsx(classes.mainHeader)}
          textAlign='center'
          textContent={formatMessage({
            id: 'app.brand_with_alternative_name',
            defaultMessage: 'Hansefit Verbundpartner-portal',
          })}
        />
      )}

      <Box className={classes.mainContent}>
        <img alt='' src={Check} className={classes.headerIcon} />
        <MainHeadline
          className={clsx(classes.headerForm)}
          textAlign='center'
          textContent={formatMessage({
            id: 'form.register.stepper.step.header',
            defaultMessage: 'That worked!',
          })}
        />
        {!isEmailConfirmed && (
          <>
            <BodyTextSmall
              textAlign='center'
              textContent={formatMessage({
                id: 'form.register.stepper.step.email_sent',
                defaultMessage:
                  'Please verify your email address by clicking on the link. It should appear in your mailbox shortly',
              })}
              className={classes.subHeader}
            />
            <LineDivider verticalSpace={22} />
            <BodyTextSmall
              textAlign='center'
              textContent={formatMessage({
                id: 'form.register.stepper.step.email_verification.resend_verification.hint',
                defaultMessage: 'You did not get an email?',
              })}
              className={classes.subHeader}
            />
            <Button variant='text' className={classes.textBtn} onClick={resendEmail}>
              {formatMessage({
                id: 'form.register.stepper.step.email_verification.resend_verification.cta',
                defaultMessage: 'Send email again',
              })}
            </Button>
            {feedbackText && (
              <BodyTextSmall
                textAlign='center'
                fontWeight={600}
                textContent={
                  feedbackText === 'success'
                    ? formatMessage({
                        id: 'form.register.stepper.step.email_verification.resend_verification.feedback.success',
                        defaultMessage: 'Email was resent, check your mail',
                      })
                    : formatMessage({
                        id: 'form.register.stepper.step.email_verification.resend_verification.feedback.failure',
                        defaultMessage: 'An error occured, try again later',
                      })
                }
                className={
                  feedbackText === 'success' ? classes.feedbackSuccess : classes.feedbackFailure
                }
              />
            )}
          </>
        )}
        {isEmailConfirmed && (
          <>
            <BodyTextSmall
              textAlign='center'
              textContent={formatMessage({
                id: 'form.register.stepper.step.subheader',
                defaultMessage:
                  'Your registration for the Verbundpartner portal is now complete. Start now directly and complete your profile details.',
              })}
              className={classes.subHeader}
            />

            <Box className={classes.divider} />

            <Typography variant='body1' className={classes.loginHeader}>
              {formatMessage({ id: 'form.register.stepper.step.cta', defaultMessage: 'Login' })}
            </Typography>

            <SignInForm variant={2} email={email} password={password} />
          </>
        )}
        <LoginPhotos />
      </Box>
    </>
  );
};
