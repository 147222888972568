import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../../design/design-tokens.json';

export const useStatsStyles = makeStyles()(theme => ({
  root: {
    height: '100%',
    paddingTop: 20,
    paddingBottom: 26,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 9,
    backgroundColor: FigmaDesignTokens.White,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
  },
  takenPlaceEventsWrapper: {
    borderRadius: 10,
    paddingTop: 18,
    paddingBottom: 16,
    paddingLeft: 20,
    backgroundColor: FigmaDesignTokens.Grey[100],
    display: 'flex',
    gap: 14,
  },
  takenPlaceEventsNum: {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 40,
    fontWeight: 900,
    lineHeight: 0.88,
    letterSpacing: 0.8,
    color: theme.palette.primary.main,
  },
  thisWeekEventsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 32,
  },
  eventsColsWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: 200,
  },
  eventsColWrapper: {
    width: 40,
  },
  eventsCol: {
    backgroundColor: FigmaDesignTokens.Blue[100],
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    '& p': {
      fontFamily: 'NutmegHeadline-UltraBlack',
      fontSize: 12,
      fontWeight: 900,
      letterSpacing: 0.24,
      color: theme.palette.primary.main,
      marginBottom: 2,
    },
  },
  eventColLabel: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 2.4,
    letterSpacing: 0.09,
    textAlign: 'center',
    color: FigmaDesignTokens.Grey[700],
  },
}));

export const useTopStyles = makeStyles()(theme => ({
  root: {
    marginRight: 10,
    paddingTop: 20,
    paddingBottom: 34,
    paddingLeft: 30,
    paddingRight: 20,
    borderRadius: 9,
    backgroundColor: FigmaDesignTokens.White,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
  },
  tableCell: {
    borderBottom: 'none',
    textAlign: 'left',
    padding: 0,
  },
  tableCellFirstPos: {
    width: 180,
  },
  tableCellSecondPos: {
    width: 200,
  },
  tableCellThirdPos: {
    width: 148,
  },
  tableCellContainerFirstRound: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    justifyContent: 'flex-start',
  },
  tableCellContainerLastRound: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    marginRight: 10,
  },
  tableCellContainerLast: {
    marginRight: 0,
  },
  tableCellContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 0.18,
    color: FigmaDesignTokens.Blue[950],
    height: 50,
    marginTop: 5,
    marginBottom: 5,
  },
  participantsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  participantsText: {
    color: FigmaDesignTokens.Grey[950],
    fontWeight: 'normal',
  },
  tableCellContainerRound: {
    borderRadius: 10,
    marginRight: 10,
  },
  tableCellContainerFirst: {
    padding: 0,
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 25,
    fontWeight: 900,
    lineHeight: 1.4,
    letterSpacing: 0.5,
    color: theme.palette.background.default,
  },
  tableCellContainerBorder: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      right: 0,
      top: 5,
      width: 2,
      height: 40,
      borderRadius: 10,
      backgroundColor: FigmaDesignTokens.Grey[300],
    },
  },
  tableCellContainerFirstPos: {
    backgroundColor: theme.palette.primary.main,
  },
  tableCellContainerSecondPos: {
    backgroundColor: theme.palette.primary.dark,
  },
  tableCellContainerThirdPos: {
    backgroundColor: FigmaDesignTokens.Blue[950],
  },
  header: {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 25,
    fontWeight: 900,
    lineHeight: 1.4,
    letterSpacing: 0.5,
    color: FigmaDesignTokens.Blue[950],
    marginBottom: 10,
  },
}));
