import FigmaDesignTokens from '../../../design/design-tokens.json';

export const getMobileIcon = (
  iconType:
    | 'courseDuration'
    | 'courseLevel'
    | 'courseEquipment'
    | 'coursePrice'
    | 'info'
    | 'phoneLayout',
) => {
  switch (iconType) {
    case 'courseDuration':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'>
          <path
            d='M12 6V11.5C12 11.7761 12.2239 12 12.5 12H19M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z'
            stroke={FigmaDesignTokens.Grey[950]}
            stroke-linecap='round'
          />
        </svg>
      );
    case 'courseLevel':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'>
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M19 2.29897V17.701C19 18.4433 18.4627 19 17.7463 19H15.8657C15.1493 19 14.6119 18.4433 14.6119 17.701V2.29897C14.6119 1.5567 15.1493 1 15.8657 1H17.7463C18.3731 1 19 1.5567 19 2.29897ZM5.38806 10V17.701C5.38806 18.4433 4.85075 19 4.13433 19H2.25373C1.53731 19 1 18.4433 1 17.701V10C1 9.25773 1.53731 8.70103 2.25373 8.70103H4.13433C4.85075 8.70103 5.38806 9.25773 5.38806 10ZM12.194 6.75258V17.6082C12.194 18.3505 11.6567 18.9072 10.9403 18.9072H9.0597C8.34328 18.9072 7.80597 18.3505 7.80597 17.6082V6.75258C7.80597 6.01031 8.34328 5.45361 9.0597 5.45361H10.9403C11.5672 5.45361 12.194 6.10309 12.194 6.75258Z'
            stroke={FigmaDesignTokens.Grey[950]}
          />
        </svg>
      );
    case 'courseEquipment':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='22'
          height='13'
          viewBox='0 0 22 13'
          fill='none'>
          <path
            d='M7.7 6.77H14.4M2.4 6.77H1M19.7 6.77H21M6.4 12.07C5.7 12.07 5.1 11.47 5.1 10.77V2.77C5.1 2.07 5.7 1.47 6.4 1.47C7.1 1.47 7.7 2.07 7.7 2.77V10.77C7.7 11.47 7.1 12.07 6.4 12.07ZM3.7 4.07C3 4.07 2.4 4.67 2.4 5.37V8.07C2.4 8.77 3 9.37 3.7 9.37C4.4 9.37 5 8.77 5 8.07V5.37C5 4.67 4.4 4.07 3.7 4.07ZM15.7 12.07C16.4 12.07 17 11.47 17 10.77V2.77C17 2.07 16.4 1.47 15.7 1.47C15 1.47 14.4 2.07 14.4 2.77V10.77C14.4 11.47 15 12.07 15.7 12.07ZM18.4 4.07C19.1 4.07 19.7 4.67 19.7 5.37V8.07C19.7 8.77 19.1 9.37 18.4 9.37C17.7 9.37 17.1 8.77 17.1 8.07V5.37C17 4.67 17.6 4.07 18.4 4.07Z'
            stroke={FigmaDesignTokens.Grey[950]}
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      );
    case 'coursePrice':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'>
          <path
            d='M13 7.5C13 8.16667 12 9.5 8 9.5C4 9.5 3 8.16667 3 7.5M21 16.5C21 17.1667 20 18.5 16 18.5C12 18.5 11 17.1667 11 16.5M9.5 12H7C3.8 12 3 10.6667 3 10M9.5 14.5H7C3.8 14.5 3 13.1667 3 12.5M9.5 17H7C3.8 17 3 15.6667 3 15M9.5 19.5H7C3.8 19.5 3 18.1667 3 17.5V5.22223M11 14.2222V19C11 19.6667 12 21 16 21C20 21 21 19.6667 21 19V14.2222M13 5.22223V10.5M22 3L3 21M3 5C3 4.33333 4 3 8 3C12 3 13 4.33333 13 5C13 5.66667 12 7 8 7C4 7 3 5.66667 3 5ZM11 14C11 13.3333 12 12 16 12C20 12 21 13.3333 21 14C21 14.6667 20 16 16 16C12 16 11 14.6667 11 14Z'
            stroke={FigmaDesignTokens.Grey[950]}
            stroke-linecap='round'
          />
        </svg>
      );
    case 'info':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'>
          <path d='M12 16V11' stroke={FigmaDesignTokens.Grey[950]} stroke-linecap='round' />
          <rect
            x='2.5'
            y='2.5'
            width='19'
            height='19'
            rx='9.5'
            stroke={FigmaDesignTokens.Grey[950]}
          />
          <rect x='11.5' y='8' width='1' height='1' rx='0.5' fill={FigmaDesignTokens.Grey[950]} />
        </svg>
      );
    case 'phoneLayout':
      return (
        <svg
          width='100%'
          height='100%'
          viewBox='0 0 274 515'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          xmlSpace='preserve'
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 1.5,
          }}>
          <g>
            <g>
              <path
                d='M273.25,10.93c0,-6.032 -4.897,-10.93 -10.93,-10.93l-251.39,0c-6.032,0 -10.93,4.898 -10.93,10.93l0,492.182c0,6.033 4.898,10.93 10.93,10.93l251.39,0c6.033,0 10.93,-4.897 10.93,-10.93l0,-492.182Z'
                style={{ fill: FigmaDesignTokens.Grey[700] }}
              />
              <path
                d='M271.165,11.814c-0,-5.221 -4.239,-9.46 -9.461,-9.46l-250.158,-0c-5.221,-0 -9.46,4.239 -9.46,9.46l-0,490.844c-0,5.221 4.239,9.46 9.46,9.46l250.158,0c5.222,0 9.461,-4.239 9.461,-9.46l-0,-490.844Z'
                style={{ fill: FigmaDesignTokens.Grey[900] }}
              />
              <path
                d='M262.155,27.721c0,-1.385 -1.125,-2.51 -2.51,-2.51l-246.039,-0c-1.386,-0 -2.511,1.125 -2.511,2.51l0,443.143l251.06,-0l0,-443.143Z'
                style={{ fill: FigmaDesignTokens.White }}
              />
            </g>
            <g>
              <path
                d='M70.873,484.814l-0,10.148l-8.428,-4.902l8.428,-5.246Z'
                style={{ fill: 'none', stroke: FigmaDesignTokens.Grey[400], strokeWidth: '1px' }}
              />
              <circle
                cx='136.563'
                cy='490.392'
                r='4.843'
                style={{ fill: 'none', stroke: FigmaDesignTokens.Grey[400], strokeWidth: '1.46px' }}
              />
              <path
                d='M211.938,486.444c-0,-0.366 -0.298,-0.664 -0.665,-0.664l-8.571,0c-0.367,0 -0.664,0.298 -0.664,0.664l-0,8.163c-0,0.367 0.297,0.664 0.664,0.664l8.571,0c0.367,0 0.665,-0.297 0.665,-0.664l-0,-8.163Z'
                style={{ fill: 'none', stroke: FigmaDesignTokens.Grey[400], strokeWidth: '1.46px' }}
              />
            </g>
            <circle
              cx='84.46'
              cy='13.218'
              r='1.612'
              style={{ fill: FigmaDesignTokens.Green[800], fillOpacity: 0.6 }}
            />
            <circle
              cx='84.264'
              cy='12.915'
              r='0.645'
              style={{ fill: FigmaDesignTokens.Grey[600] }}
            />
          </g>
        </svg>
      );
  }
};
