import * as Yup from 'yup';
import { IntlShape } from 'react-intl/src/types';

export const EditEventStreamValidation = (intl: IntlShape) =>
  Yup.object().shape({
    streamLink: Yup.string()
      .required(
        intl.formatMessage({
          id: 'form.edit_event_stream.error.stream_link.required',
          defaultMessage: 'Please provide access link to the live stream.',
        }),
      )
      .matches(
        /(https?:\/\/)?([\w-])+\.{1}([a-zA-Z]{2,63})([/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/,
        intl.formatMessage({
          id: 'form.edit_event_stream.error.stream_link.matches',
          defaultMessage: 'Please enter a valid URL and save your changes again. ',
        }),
      ),
  });
