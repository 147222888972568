import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { SidebarNavGuest, SidebarNavigation, SwitchPartner } from '@/Components';
import CurrentCheckins from '@/Views/CurrentCheckins';
import UserManagementPage from '@/Views/Users';

import { TwoRowsWithNavGuestLayout } from './App/Shared/Layout/TwoRowsWithNavGuestLayout';
import { TwoRowsWithNavigationLayout } from './App/Shared/Layout/TwoRowsWithNavigationLayout';
import RequireAdmin from './Components/RequireAdmin';
import { ForgotPasswordPage } from './Views/Authentication/ForgotPasswordPage';
import LogoutPage from './Views/Authentication/Logout';
import ChangePassword from './Views/ChangePassword';
import CheckInAll from './Views/CheckInAll';
import CheckInCompany from './Views/CheckInByCompany';
import CheckInByUser from './Views/CheckInByUser';
import CheckInTrainInstruction from './Views/CheckInTrainInstruction';
import CoursesOverview from './Views/CourseManager';
import CoursesAndEvents from './Views/CoursesAndEvents';
import CurrentUser from './Views/CurrentUser';
import Dashboard from './Views/Dashboard';
import DescriptionAndOpeningHours from './Views/DescriptionAndOpeningHours';
import EquipmentAndService from './Views/EquipmentAndService';
import MarketingMaterial from './Views/MarketingMaterial';
import PhotoAndVideo from './Views/PhotoAndVideo';
import Schedule from './Views/Schedule';
import StudioProfile from './Views/StudioProfile';
import Support from './Views/Support';

const AuthenticatedRoutes = () => {
  const { pathname }: { pathname: string } = useLocation();

  const language = pathname.split('/').length > 2 ? pathname.split('/')[1] : 'de';

  return (
    <Routes>
      <Route
        path='/:language/dashboard'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <Dashboard />
          </TwoRowsWithNavigationLayout>
        }
      />
      <Route
        path='/:language/allgemeine-informationen/:tab'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <StudioProfile />
          </TwoRowsWithNavigationLayout>
        }
      />
      <Route
        path='/:language/beschreibung-und-oeffnungszeiten/:tab'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <DescriptionAndOpeningHours />
          </TwoRowsWithNavigationLayout>
        }
      />
      <Route
        path='/:language/ausstattung-und-leistungen'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <EquipmentAndService />
          </TwoRowsWithNavigationLayout>
        }
      />
      <Route
        path='/:language/foto-und-video/:tab'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <PhotoAndVideo />
          </TwoRowsWithNavigationLayout>
        }
      />
      <Route
        path='/:language/check-in-ubersicht/:tab'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <></>
          </TwoRowsWithNavigationLayout>
        }
      />
      <Route
        path='/:language/kurse/:courseId'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <CoursesAndEvents />
          </TwoRowsWithNavigationLayout>
        }
      />

      <Route
        path='/:language/kurse/:courseId/:action'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <CoursesAndEvents />
          </TwoRowsWithNavigationLayout>
        }
      />

      <Route
        path='/:language/kurse/:courseId/:action/:eventId'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <CoursesAndEvents />
          </TwoRowsWithNavigationLayout>
        }
      />
      <Route
        path='/:language/neuen-kurs-anlegen'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <CoursesAndEvents />
          </TwoRowsWithNavigationLayout>
        }
      />
      <Route
        path='/:language/kursuebersicht/:tab'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <CoursesOverview />
          </TwoRowsWithNavigationLayout>
        }
      />
      <Route
        path='/:language/terminplan'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <Schedule />
          </TwoRowsWithNavigationLayout>
        }
      />
      <Route
        path='/:language/mein-benutzerkonto'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <CurrentUser />
          </TwoRowsWithNavigationLayout>
        }
      />

      <Route
        path='/:language/benutzer/:tab'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <RequireAdmin>
              <UserManagementPage />
            </RequireAdmin>
          </TwoRowsWithNavigationLayout>
        }
      />

      <Route path='/:language/partner-wechseln' element={<SwitchPartner />} />

      <Route
        path='/:language/statistik/check-ins-alle'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <CheckInAll />
          </TwoRowsWithNavigationLayout>
        }
      />
      <Route
        path='/:language/statistik/check-ins-firma'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <CheckInCompany />
          </TwoRowsWithNavigationLayout>
        }
      />
      <Route
        path='/:language/statistik/check-ins-mitglieder'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <CheckInByUser />
          </TwoRowsWithNavigationLayout>
        }
      />
      <Route
        path='/:language/statistik/training-instruction'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <CheckInTrainInstruction />
          </TwoRowsWithNavigationLayout>
        }
      />

      <Route
        path='/:language/marketings-materialien'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <MarketingMaterial />
          </TwoRowsWithNavigationLayout>
        }
      />

      <Route
        path='/:language/unterstutzung'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <Support />
          </TwoRowsWithNavigationLayout>
        }
      />

      <Route
        path='/:language/current-checkins'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <CurrentCheckins />
          </TwoRowsWithNavigationLayout>
        }
      />

      {/* <Route
        path='/:language/einstellungen'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <Settings />
          </TwoRowsWithNavigationLayout>
        }
      /> */}
      <Route
        path='/forgot-password'
        element={
          <TwoRowsWithNavGuestLayout leftNavigation={<SidebarNavGuest />}>
            <ForgotPasswordPage />
          </TwoRowsWithNavGuestLayout>
        }
      />

      <Route
        path='/logout'
        element={
          <TwoRowsWithNavGuestLayout leftNavigation={<SidebarNavGuest />}>
            <LogoutPage />
          </TwoRowsWithNavGuestLayout>
        }
      />

      <Route
        path='/:language/change-password'
        element={
          <TwoRowsWithNavigationLayout leftNavigation={<SidebarNavigation />}>
            <ChangePassword />
          </TwoRowsWithNavigationLayout>
        }
      />
      <Route path='*' element={<Navigate to={`/${language}/dashboard`} />} />
    </Routes>
  );
};

export default AuthenticatedRoutes;
