import { Tooltip } from '@mui/material';
import { withStyles } from 'tss-react/mui';

export const CustomTooltip = withStyles(Tooltip, theme => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.1)',
    fontSize: 12,
    lineHeight: 1.7,
    letterSpacing: 0.11,
    color: theme.palette.text.primary,
    width: 'auto',
    padding: 10,
    borderRadius: 10,
  },
}));
