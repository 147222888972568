import * as Yup from 'yup';

export const ChangePasswordValidation = Yup.object().shape({
  password: Yup.string()
    .required('form.forgot_password.stepper.step.change_password.error.password.required')
    .min(3),
  passwordConfirmation: Yup.string()
    .oneOf(
      [Yup.ref('password')],
      'form.forgot_password.stepper.step.change_password.error.passwordConfirmation.match',
    )
    .required(
      'form.forgot_password.stepper.step.change_password.error.passwordConfirmation.required',
    ),
  verificationCode: Yup.array()
    .required()
    .of(
      Yup.object().shape({
        partCode: Yup.string().required().min(1).max(1),
      }),
    ),
});
