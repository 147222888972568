import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export interface CenteredLayoutProps {
  children: JSX.Element | JSX.Element[];
  leftNavigation: JSX.Element;
}

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100%',
    height: '100vh',
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    height: '100%',
  },
}));

export const TwoRowsWithNavGuestLayout = (props: CenteredLayoutProps): JSX.Element => {
  const { children, leftNavigation } = props;
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      {leftNavigation}
      <Box className={classes.right}>{children}</Box>
    </Box>
  );
};
