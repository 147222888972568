import DateRangeIcon from '@mui/icons-material/DateRange';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import { Backdrop, Box, Collapse, Drawer, Fade, useMediaQuery, useTheme } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';
import {
  getCurrentCoursesPathname,
  getDashboardPathname,
  getExpiredCoursesPathname,
  getStatisticsPages,
} from '@/Mappers';
import { useCurrentUserRoleQuery, useFaqListQuery } from '@/Queries';
import MenuIcon from '@/Static/Icons/atoms-symbols-icons-menu.svg';
import { Role } from '@/Types';

import { MenuNavigationItem } from '../MenuNavigationItem';
import MenuNavItemHeader from '../MenuNavigationItem/MenuNavItemHeader';
import { useSidebarNavigationStyles } from '../Styles/SidebarNavigation.styles';
import SidebarLogo from './SidebarLogo';

export const SidebarNavigation = () => {
  const { classes } = useSidebarNavigationStyles();
  const location = useLocation();
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const currentLanguage = useCurrentLanguage();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const { data } = useFaqListQuery();
  const categoryFAQs = useMemo(() => data?.data?.categoryFaqs || [], [data]);

  const handleToggleSidebar = useCallback(() => {
    const body = document.querySelector('body');
    if (body && !open) {
      body.style.overflowY = 'hidden';
    } else if (body && open) {
      body.style.overflowY = 'unset';
    }
    setOpen(!open);
  }, [open]);

  const { data: userRole } = useCurrentUserRoleQuery();

  const activeMainNavItem = useMemo(() => location.pathname.split('/')[2], [location.pathname]);

  return (
    <div className={classes.root}>
      <Backdrop
        open={open && !matches}
        className={classes.backdrop}
        onClick={handleToggleSidebar}
      />
      <Drawer
        classes={{
          paper: clsx(classes.paper, {
            [classes.paperOpen]: open,
            [classes.paperClose]: !open,
          }),
        }}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        variant='permanent'>
        <Link
          className={clsx(classes.brandContainer, { [classes.alignLeft]: open })}
          to={getDashboardPathname(currentLanguage)}>
          <Box className={clsx(classes.logo, { [classes.logoFullSize]: open })} />
          <Fade in={open || matches} timeout={800}>
            <Box
              className={clsx(classes.brandWrapper, {
                [classes.brandContainerHide]: !open && !matches,
              })}>
              <SidebarLogo />
            </Box>
          </Fade>
        </Link>
        <Fade in={!open && !matches}>
          <Box
            className={clsx(classes.menuIconWrapper, {
              [classes.hidden]: open || matches,
            })}
            onClick={handleToggleSidebar}>
            <img alt='' src={MenuIcon} className={classes.menuIcon} />
          </Box>
        </Fade>
        <Fade in={open || matches} timeout={200}>
          <Box className={classes.menuContainer}>
            <Box className={classes.itemsWrapper}>
              <Box className={clsx({ [classes.hidden]: !open && !matches })}>
                <Box className={classes.navigationList}>
                  <MenuNavItemHeader
                    isActive={
                      activeMainNavItem === 'allgemeine-informationen' ||
                      activeMainNavItem === 'beschreibung-und-oeffnungszeiten' ||
                      activeMainNavItem === 'ausstattung-und-leistungen' ||
                      activeMainNavItem === 'foto-und-video' ||
                      activeMainNavItem === 'current-checkins'
                    }
                    menuIcon={<HomeOutlinedIcon />}
                    name='view.side_navigation.category.my_studio_profile'
                    defaultMessage='Studio profile'
                    href={`/${currentLanguage}/allgemeine-informationen/adresse-und-kontakt`}
                  />
                  <Collapse
                    in={
                      activeMainNavItem === 'allgemeine-informationen' ||
                      activeMainNavItem === 'beschreibung-und-oeffnungszeiten' ||
                      activeMainNavItem === 'ausstattung-und-leistungen' ||
                      activeMainNavItem === 'foto-und-video' ||
                      activeMainNavItem === 'current-checkins'
                    }>
                    <MenuNavigationItem
                      isGroupHeader
                      needsToBeTranslated
                      title='view.common_information'
                      defaultMessage='General information'
                      href={`/${currentLanguage}/allgemeine-informationen/adresse-und-kontakt`}
                    />
                    <Collapse in={activeMainNavItem === 'allgemeine-informationen'}>
                      <MenuNavigationItem
                        needsToBeTranslated
                        title='view.common_information.address_and_contact'
                        defaultMessage='Address & Contact'
                        href={`/${currentLanguage}/allgemeine-informationen/adresse-und-kontakt`}
                        navLevel='nav-item'
                      />
                      <MenuNavigationItem
                        needsToBeTranslated
                        title='view.common_information.check_in_options'
                        defaultMessage='Check-in options'
                        href={`/${currentLanguage}/allgemeine-informationen/check-in-optionen`}
                        navLevel='nav-item'
                      />
                      <MenuNavigationItem
                        needsToBeTranslated
                        title='view.common_information.categories'
                        defaultMessage='Categories'
                        href={`/${currentLanguage}/allgemeine-informationen/kategorien`}
                        navLevel='nav-item'
                      />
                    </Collapse>

                    <MenuNavigationItem
                      isGroupHeader
                      needsToBeTranslated
                      title='view.description_and_opening_hours'
                      defaultMessage='Description & opening hours'
                      href={`/${currentLanguage}/beschreibung-und-oeffnungszeiten/beschreibung`}
                    />
                    <Collapse in={activeMainNavItem === 'beschreibung-und-oeffnungszeiten'}>
                      <MenuNavigationItem
                        needsToBeTranslated
                        title='view.description_and_opening_hours.description'
                        defaultMessage='Description'
                        href={`/${currentLanguage}/beschreibung-und-oeffnungszeiten/beschreibung`}
                        navLevel='nav-item'
                      />

                      <MenuNavigationItem
                        needsToBeTranslated
                        title='view.description_and_opening_hours.opening_hours'
                        defaultMessage='Opening hours'
                        href={`/${currentLanguage}/beschreibung-und-oeffnungszeiten/oeffnungszeiten`}
                        navLevel='nav-item'
                      />
                    </Collapse>
                    <MenuNavigationItem
                      needsToBeTranslated
                      title='view.equipment_and_services'
                      defaultMessage='Equipment & Services'
                      href={`/${currentLanguage}/ausstattung-und-leistungen`}
                    />
                    <MenuNavigationItem
                      isGroupHeader
                      needsToBeTranslated
                      title='view.photo_and_video'
                      defaultMessage='Image gallery'
                      href={`/${currentLanguage}/foto-und-video/logo`}
                    />
                    <Collapse in={activeMainNavItem === 'foto-und-video'}>
                      <MenuNavigationItem
                        needsToBeTranslated
                        title='view.photo_and_video.logo'
                        defaultMessage='Logo'
                        href={`/${currentLanguage}/foto-und-video/logo`}
                        navLevel='nav-item'
                      />
                      <MenuNavigationItem
                        needsToBeTranslated
                        title='view.photo_and_video.photo_upload'
                        defaultMessage='Photo upload'
                        href={`/${currentLanguage}/foto-und-video/foto-upload`}
                        navLevel='nav-item'
                      />

                      <MenuNavigationItem
                        needsToBeTranslated
                        title='view.photo_and_video.video_upload'
                        defaultMessage='Video Upload'
                        href={`/${currentLanguage}/foto-und-video/video-upload`}
                        navLevel='nav-item'
                      />
                    </Collapse>

                    <MenuNavigationItem
                      needsToBeTranslated
                      title='view.current_checkins'
                      defaultMessage='Current Check-Ins'
                      href={`/${currentLanguage}/current-checkins`}
                    />
                  </Collapse>
                </Box>
              </Box>
              <Box className={classes.navigationList}>
                <MenuNavItemHeader
                  isActive={activeMainNavItem === 'statistik'}
                  menuIcon={<QueryStatsIcon />}
                  name='view.side_navigation.category.statistic'
                  defaultMessage='Statistik'
                  href={`/${currentLanguage}/statistik/check-ins-alle`}
                />
                <Collapse in={activeMainNavItem === 'statistik'}>
                  {getStatisticsPages(intl).map(page => (
                    <MenuNavigationItem
                      key={page.path}
                      needsToBeTranslated
                      title={page.title}
                      href={`/${currentLanguage}/statistik/${page.path}`}
                    />
                  ))}
                </Collapse>
              </Box>
              <Box className={classes.navigationList}>
                <MenuNavItemHeader
                  isActive={
                    activeMainNavItem === 'kursuebersicht' ||
                    activeMainNavItem === 'terminplan' ||
                    activeMainNavItem === 'kurse' ||
                    activeMainNavItem === 'neuen-kurs-anlegen'
                  }
                  menuIcon={<DateRangeIcon />}
                  name='view.side_navigation.category.my_course_manager'
                  defaultMessage='Course Manager'
                  href={`/${currentLanguage}/kursuebersicht/laufende-kurse`}
                />
                <Collapse
                  in={
                    activeMainNavItem === 'kursuebersicht' ||
                    activeMainNavItem === 'terminplan' ||
                    activeMainNavItem === 'kurse' ||
                    activeMainNavItem === 'neuen-kurs-anlegen'
                  }>
                  <MenuNavigationItem
                    isGroupHeader
                    needsToBeTranslated={false}
                    title={intl.formatMessage({
                      id: 'view.courses.courses_overview',
                      defaultMessage: 'Course overview',
                    })}
                    defaultMessage='Course overview'
                    href={`/${currentLanguage}/kursuebersicht/laufende-kurse`}
                  />
                  <Collapse in={activeMainNavItem === 'kursuebersicht'}>
                    <MenuNavigationItem
                      needsToBeTranslated
                      title='view.courses_and_events.courses'
                      defaultMessage='Active courses'
                      href={getCurrentCoursesPathname(currentLanguage)}
                      navLevel='nav-item'
                    />
                    <MenuNavigationItem
                      needsToBeTranslated
                      title='view.courses_and_events.past_courses'
                      defaultMessage='Inactive courses & drafts'
                      href={getExpiredCoursesPathname(currentLanguage)}
                      navLevel='nav-item'
                    />
                  </Collapse>
                  <MenuNavigationItem
                    needsToBeTranslated
                    title='view.courses_and_dates'
                    defaultMessage='Schedule'
                    href={`/${currentLanguage}/terminplan`}
                  />
                </Collapse>
                {userRole === Role.ADMIN && (
                  <Box className={classes.navigationList}>
                    <MenuNavItemHeader
                      isActive={activeMainNavItem === 'benutzer'}
                      menuIcon={<PersonOutlineOutlinedIcon />}
                      name='view.user_management.users'
                      defaultMessage='User'
                      href={`/${currentLanguage}/benutzer/benutzerverwaltung`}
                    />
                    <Collapse in={activeMainNavItem === 'benutzer'}>
                      <MenuNavigationItem
                        needsToBeTranslated
                        title='view.user_management.user_management'
                        defaultMessage='User management'
                        href={`/${currentLanguage}/benutzer/benutzerverwaltung`}
                      />
                      <MenuNavigationItem
                        needsToBeTranslated
                        title='view.user_management.invite_users'
                        defaultMessage='Invite users'
                        href={`/${currentLanguage}/benutzer/benutzer-einladen`}
                      />
                    </Collapse>
                  </Box>
                )}
                <MenuNavItemHeader
                  isActive={activeMainNavItem === 'marketings-materialien'}
                  menuIcon={<FolderOutlinedIcon />}
                  name='marketing_material.title'
                  defaultMessage='Downloads'
                  href={`/${currentLanguage}/marketings-materialien`}
                  isExpandable={false}
                />
                {/* <MenuNavItemHeader
                  // isActive={navExpanded.settings}
                  menuIcon={<SettingsOutlined />}
                  name='view.settings'
                  navExpandHandler={() => handleExpand('SETTINGS')}
                  isExpandable={false}
                  defaultMessage='Settings'
                /> */}
                {categoryFAQs.length > 0 && (
                  <MenuNavItemHeader
                    isActive={activeMainNavItem === 'unterstutzung'}
                    menuIcon={<SupportOutlinedIcon />}
                    name='marketing_material.support'
                    defaultMessage={intl.formatMessage({
                      id: 'marketing_material.support',
                      defaultMessage: 'Support & Contact',
                    })}
                    href={`/${currentLanguage}/unterstutzung`}
                    isExpandable={false}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Fade>
      </Drawer>
    </div>
  );
};
