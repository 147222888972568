import { makeStyles } from 'tss-react/mui';

import Theme from '@/App/Theme/Theme';
import { TypeInfoProps } from './index';

const getBackgroundColor = (type: TypeInfoProps) => {
  switch (type) {
    case 'information':
      return Theme.palette.secondary.light;
    case 'warning':
      return Theme.palette.warning.light;
    case 'warningAlert':
      return Theme.palette.warning.light;
    default:
      return '';
  }
};

const getTextColor = (type: TypeInfoProps) => {
  switch (type) {
    case 'information':
      return Theme.palette.secondary.main;
    case 'warning':
      return Theme.palette.text.primary;
    case 'warningAlert':
      return Theme.palette.text.primary;
    default:
      return '';
  }
};

export const useInfoMessageStyles = (type: TypeInfoProps) =>
  makeStyles()(() => ({
    infoContainer: {
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'row',
      padding: 16,
      gap: 8,
    },
    infoContainerBackground: {
      backgroundColor: getBackgroundColor(type),
    },
    contentHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 8,
    },
    textColor: {
      color: getTextColor(type),
    },
  }));
