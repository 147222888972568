import { PartnerService } from '../Partner/Partner.service';
import { HttpService } from '@/Services';
import { Auth } from 'aws-amplify';
import { Dispatch } from 'react';
import { AnyStateAction } from '@/Context';

export class PartnerServiceFactory {
  public getInstance(dispatch: Dispatch<AnyStateAction>): PartnerService {
    return new PartnerService(new HttpService(Auth, dispatch));
  }
}
