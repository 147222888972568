import { Grid } from '@mui/material';
import clsx from 'clsx';

import { Category, DayOption } from '@/Types';

import { CustomizedChip } from '../../App/Shared/Form/Components/Customized/CustomizedChip';
import { useCategoriesStyles } from './Categories.styles';

type CategoriesProps = {
  categories: Category[] | DayOption[];
  multiSelect: boolean;
  selectedCategories: number[];
  handleClick: (id: string) => void;
  disabledIds: number[];
  name: string;
  readOnly?: boolean;
};

export const Categories = ({
  categories,
  multiSelect,
  handleClick,
  selectedCategories,
  disabledIds,
  name,
  readOnly = false,
}: CategoriesProps) => {
  const { classes } = useCategoriesStyles();

  return (
    <Grid container spacing={2} className={classes.container} id={name + 'Categories'}>
      {categories.map(category => (
        <Grid item className={classes.item} key={category.id}>
          <CustomizedChip
            color='secondary'
            key={category.id}
            disabled={
              (multiSelect &&
                !!selectedCategories.find(
                  selectedCategoryId => selectedCategoryId === Number.parseInt(category.id, 10),
                )) ||
              (selectedCategories.length > 0 && !multiSelect) ||
              disabledIds.indexOf(Number.parseInt(category.id, 10)) !== -1 ||
              readOnly
            }
            variant='outlined'
            icon={
              'iconUrlContour' in category && category.iconUrlContour ? (
                <img
                  alt=''
                  className={clsx(classes.img, classes.imgColorDefault, {
                    [classes.imgColorDisabled]:
                      (multiSelect &&
                        !!selectedCategories.find(
                          selectedCategoryId =>
                            selectedCategoryId === Number.parseInt(category.id, 10),
                        )) ||
                      (selectedCategories.length > 0 && !multiSelect) ||
                      disabledIds.indexOf(Number.parseInt(category.id, 10)) !== -1 ||
                      readOnly,
                  })}
                  src={category.iconUrlContour}
                />
              ) : undefined
            }
            label={
              'iconUrlContour' in category && category.iconUrlContour ? (
                <>{category.shortDescription}</>
              ) : (
                <>{category.shortDescription}</>
              )
            }
            onClick={() => handleClick(category.id)}
          />
        </Grid>
      ))}
    </Grid>
  );
};
