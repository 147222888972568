import './index.css';

import { QueryClientProvider } from '@tanstack/react-query';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { Provider as ReduxStoreProvider } from 'react-redux';

import store from '@/Store';

import App from './App';
import { AppProvider } from './Context';
import { queryClient } from './Helpers';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <ReduxStoreProvider store={store}>
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <AppProvider>
          <App />
        </AppProvider>
      </CookiesProvider>
    </QueryClientProvider>
  </ReduxStoreProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
