import * as Yup from 'yup';

export const ValidatePartnerValidation = Yup.object().shape({
  partnerId: Yup.number()
    .typeError('form.register.stepper.step.validate_partner.error.partner_id.invalid')
    .required('form.register.stepper.step.validate_partner.error.partner_id.required'),
  startCode: Yup.array()
    .required()
    .of(
      Yup.object().shape({
        partKey: Yup.string().required().min(4).max(4),
      }),
    ),
});
