import { Box, styled, Typography } from '@mui/material';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const Name = styled(Typography)`
  display: flex;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  color: ${FigmaDesignTokens.Grey[950]};
`;
export const BlockedName = styled(Typography)`
  display: flex;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  color: ${FigmaDesignTokens.Grey[500]};
`;

export const Container = styled(Box)`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
`;
