import { Course, EventListItem, EventsData } from '@/Types';

import { ActionMap } from './Actions';

export enum CourseManagerTypes {
  SET_COURSE = 'SET_COURSE',

  SET_EVENTS = 'SET_EVENTS',
  SET_UPDATED_EVENTS = 'SET_UPDATED_EVENTS',

  SET_DIRTY_FILTERS = 'SET_DIRTY_FILTERS',
}

export type CourseManagerAction = {
  type: CourseManagerTypes;
  payload: any;
};

export interface CourseManagerState {
  course: Course | null;

  events: EventsData | null;
  updatedEvents: EventListItem[] | null;
  dirtyFilters: boolean;
}

export type CourseManagerPayload = {
  [CourseManagerTypes.SET_COURSE]: {
    course: Course | null;
  };

  [CourseManagerTypes.SET_EVENTS]: {
    events: EventsData | null;
  };
  [CourseManagerTypes.SET_UPDATED_EVENTS]: {
    updatedEvents: EventListItem[] | null;
  };
  [CourseManagerTypes.SET_DIRTY_FILTERS]: {
    dirtyFilters: boolean;
  };
};

export type CourseManagerActions =
  ActionMap<CourseManagerPayload>[keyof ActionMap<CourseManagerPayload>];

export const CourseManagerReducer = (state: CourseManagerState, action: CourseManagerActions) => {
  switch (action.type) {
    case CourseManagerTypes.SET_COURSE:
      return {
        ...state,
        course: action.payload.course,
      };

    case CourseManagerTypes.SET_EVENTS:
      return { ...state, events: action.payload.events };
    case CourseManagerTypes.SET_UPDATED_EVENTS:
      return { ...state, updatedEvents: action.payload.updatedEvents };
    case CourseManagerTypes.SET_DIRTY_FILTERS:
      return { ...state, dirtyFilters: action.payload.dirtyFilters };
    default:
      return state;
  }
};
