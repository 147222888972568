import * as Yup from 'yup';

export const VerifyCodeValidation = Yup.object().shape({
  verificationCode: Yup.array()
    .required()
    .of(
      Yup.object().shape({
        partCode: Yup.string().required().min(1).max(1),
      }),
    ),
});
