import { getDashboardPathname, getStaticPathname } from '@/Mappers';
import { BreadcrumbProps } from '@/Types';
import { useIntl } from 'react-intl';
import useCurrentLanguage from '../useCurrentLanguage';
import { useMemo } from 'react';

const useStaticStatisticBreadcrumbs = (): BreadcrumbProps[] => {
  const { formatMessage } = useIntl();
  const currentLanguage = useCurrentLanguage();

  const staticBreadcrumbs = useMemo(
    () => [
      {
        title: formatMessage({ id: 'view.dashboard', defaultMessage: 'Dashboard' }),
        href: getDashboardPathname(currentLanguage),
      },
      {
        title: formatMessage({
          id: 'view.statistic',
          defaultMessage: 'statistik',
        }),
        href: getStaticPathname(currentLanguage, 'check-ins-alle'),
      },
    ],
    [formatMessage, currentLanguage],
  );

  return staticBreadcrumbs;
};

export default useStaticStatisticBreadcrumbs;
