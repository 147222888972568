import { Box, styled } from '@mui/material';

const Container = styled(Box)`
  width: 100%;
  max-width: 'calc(100% - 300px)';
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  border-radius: 4px;
`;

export default Container;
