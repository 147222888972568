import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const useAssetPreviewStyles = makeStyles()(() => ({
  logoWrapper: {},
  logoImg: {
    borderRadius: 8,
    maxHeight: '100%',
    maxWidth: '100%',
  },
  video: {
    width: '100%',
  },
  videoTitle: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: 0.11,
    color: FigmaDesignTokens.Grey[800],
  },
  fileInformationWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  trash: {
    width: 16,
    height: 16,
  },
}));

export const useGalleryAssetPreviewStyles = makeStyles()(() => ({
  assetWrapper: {
    width: 220,
  },
  tileImage: {
    borderRadius: '8px',
    backgroundColor: FigmaDesignTokens.Grey[300],
    width: 220,
    height: 210,
    minHeight: 210,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 9,
    marginBottom: 9,
  },
  text: {
    fontSize: 12,
    letterSpacing: 0.11,
    color: FigmaDesignTokens.Grey[800],
  },
  name: {
    fontWeight: 500,
  },
  iconBtn: {
    width: 24,
    height: 24,
  },
}));

export const useVideoLiveAssetStyles = makeStyles()(() => ({
  thumbnail: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
  },
}));
