import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const useAlterGalleryImageStyles = makeStyles()(() => ({
  btnModal: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    width: '100%',
    justifyContent: 'flex-start',
    textTransform: 'none',
  },
  btnText: {
    fontSize: '12px',
    color: FigmaDesignTokens.Blue[950],
    textDecoration: 'underline',
  },
  headingWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 20,
    fontWeight: 900,
    lineHeight: 1.5,
    letterSpacing: 0.4,
    color: FigmaDesignTokens.Blue[950],
    '& button': {
      padding: 0,
    },
  },
  dialogImgTitle: {
    padding: 0,
    marginBottom: 35,
  },
  img: {
    width: 220,
    height: 210,
  },
  dialogContent: {
    padding: 0,
    overflow: 'unset',
  },
  dropzoneWrapper: {
    marginRight: 8,
  },
}));
