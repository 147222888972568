import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import clsx from 'clsx';
import { Box, Breadcrumbs, Link } from '@mui/material';

import { BreadcrumbProps } from '@/Types';

import { useBreadcrumbsStyles } from './Breadcrumbs.styles';

type DynamicBreadcrumbsProps = {
  items: BreadcrumbProps[];
};

export const DynamicBreadcrumbs = ({ items }: DynamicBreadcrumbsProps): JSX.Element => {
  const { classes } = useBreadcrumbsStyles();
  const navigate = useNavigate();
  const [lastIndex, setLastIndex] = useState<number>();

  const handleRedirect = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>, breadcrumb: BreadcrumbProps) => {
      e.preventDefault();
      navigate(breadcrumb.href);
    },
    [navigate],
  );

  useEffect(() => {
    setLastIndex(items.length - 1);
  }, [setLastIndex, items]);

  return (
    <Box className={classes.breadcrumb}>
      <Breadcrumbs
        separator={<Box className={classes.breadcrumbSeparator} />}
        aria-label='breadcrumb'>
        {items.map((item, idx) => (
          <Link
            key={idx}
            className={clsx(classes.link, { [(classes.active, 'active')]: idx === lastIndex })}
            href={item.href}
            onClick={e => handleRedirect(e, item)}>
            {item.title}
          </Link>
        ))}
      </Breadcrumbs>
    </Box>
  );
};
