import { StepConnector } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import theme from '@/App/Theme/Theme';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const EnableCourseManagerDialogStepConnector = withStyles(StepConnector, {
  alternativeLabel: {
    zIndex: 1,
    left: 'calc(-50% + 10px)',
    right: 'calc(50% + 10px)',
  },
  active: {
    '& $line': {
      backgroundColor: FigmaDesignTokens.Green[500],
    },
  },
  completed: {
    '& $line': {
      backgroundColor: FigmaDesignTokens.Green[500],
    },
  },
  line: {
    height: 1,
    border: 0,
    backgroundColor: theme.palette.primary.main,
  },
});
