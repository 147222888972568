import { styled, Typography } from '@mui/material';

export const ColumnWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
`;

export const RowWrapper = styled('div')`
  display: flex;
  flex-direction: column;

  gap: 20px;
`;

export const HintWrapper = styled('div')`
  margin-top: 49px;
`;

export const Information = styled(Typography)`
  margin-top: 20px;
  margin-bottom: 52px;
`;
