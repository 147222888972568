import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticateExtraReducers } from './Authenticate';
import { LogoutExtraReducers } from './Logout';
import { LoginExtraReducers } from './Login';

import { DataFetchingStatus, AuthStateTypes } from '@/Types';

const initialState: AuthStateTypes = {
  isAuthenticated: false,
  fetchingStatus: DataFetchingStatus.Idle,
  credentials: null,
  partnerRoles: [],
};

const resetCredentials: CaseReducer<AuthStateTypes> = state => {
  state.credentials = null;
};

const setCredentials: CaseReducer<
  AuthStateTypes,
  PayloadAction<{ email: string; password: string }>
> = (state, { payload: { email, password } }) => {
  state.credentials = {
    email,
    password,
  };
};

const setPartnerRoles: CaseReducer<AuthStateTypes, PayloadAction<{ [key: string]: string }[]>> = (
  state,
  { payload },
) => {
  state.partnerRoles = payload;
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetCredentials,
    setCredentials,
    setPartnerRoles,
  },
  extraReducers: builder => {
    AuthenticateExtraReducers(builder);
    LogoutExtraReducers(builder);
    LoginExtraReducers(builder);
  },
});

export const { name, actions, caseReducers, reducer } = authSlice;

export default authSlice.reducer;
