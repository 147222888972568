import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@/Context';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import { MarketingMaterialsServiceFactory } from '@/Services';
import { MarketingMaterialItem } from '@/Types';
import handleCatchError from '@/Utils/handleCatchError';

import useCurrentPartnerIdQuery from '../useCurrentPartnerIdQuery';

const useGetDownloadsDataQuery = () => {
  const { dispatch } = useAppContext();
  const { data: casPublicId = '', isLoading: isLoadingCasPublicId } = useCurrentPartnerIdQuery();
  const currentLanguage = useCurrentLanguage();

  const queryResult = useQuery<MarketingMaterialItem[], Error>(
    ['getDownloadsData', casPublicId],
    async () => {
      const downloadsService = new MarketingMaterialsServiceFactory().getInstance(dispatch);
      const params = [{ id: 'language', value: currentLanguage }];
      const responseData = await downloadsService.getMarketingMaterialList(casPublicId, params);
      const { categoryMaterials } = responseData.data;

      if (!categoryMaterials) return [];

      const listItems = categoryMaterials.flatMap(
        item =>
          item?.marketingMaterials?.map(material => ({
            ...material,
            categoryName: item.categoryName,
          })) || [],
      );

      return listItems;
    },
    {
      onError: handleCatchError,
      enabled: !!casPublicId && !isLoadingCasPublicId,
      staleTime: 1000 * 60 * 5,
      placeholderData: [],
    },
  );

  return queryResult;
};

export default useGetDownloadsDataQuery;
