import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppContext } from '@/Context';
import { AssetServiceFactory } from '@/Services';
import { Partner } from '@/Types';

type useDeleteAllAssetsMutationProps = {
  casPublicId: Partner['casPublicId'];
  deleteAssetIdQueue: number[];
};

const useDeleteAllAssetsMutation = () => {
  const { dispatch } = useAppContext();
  const assetService = useMemo(() => new AssetServiceFactory().getInstance(dispatch), [dispatch]);

  return useMutation(({ casPublicId, deleteAssetIdQueue }: useDeleteAllAssetsMutationProps) =>
    assetService.deleteAllAssets(casPublicId, deleteAssetIdQueue),
  );
};

export default useDeleteAllAssetsMutation;
