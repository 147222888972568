import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppContext } from '@/Context';
import { DeeplServiceFactory } from '@/Services';
import { handleTranslationProps } from '@/Types';

const useTranslationMutation = () => {
  const { dispatch } = useAppContext();
  const partnerServiceInstance = useMemo(
    () => new DeeplServiceFactory().getInstance(dispatch),
    [dispatch],
  );

  return useMutation(
    ({
      currentValue,
      field,
      currentLanguage,
      setFieldValue,
      isFirstTranslation,
      setIsFirstTranslation,
      targetLanguage,
    }: handleTranslationProps) =>
      partnerServiceInstance.handleTranslation({
        currentValue,
        field,
        currentLanguage,
        setFieldValue,
        isFirstTranslation,
        setIsFirstTranslation,
        targetLanguage,
      }),
  );
};

export default useTranslationMutation;
