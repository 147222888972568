import { useEffect, useState } from 'react';

import FigmaDesignTokens from '../../../design/design-tokens.json';

type AnimatedCircleProps = {
  duration: number;
  createdAt: string;
  fillColor?: string;
  height: number;
  width: number;
};

const AnimatedCircle = ({ duration, createdAt, fillColor, height, width }: AnimatedCircleProps) => {
  const [endAngle, setEndAngle] = useState(0);
  const startAngle = 0;
  const radius = width / 2 - 6;

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const startTime = new Date(createdAt).getTime();
      const elapsedTime = currentTime - startTime;

      if (elapsedTime >= duration) {
        clearInterval(interval);
        setEndAngle(0);
      } else {
        const angleElapsed = (elapsedTime / duration) * 360;
        const newEndAngle = 360 - angleElapsed;
        setEndAngle(newEndAngle);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [createdAt, duration]);

  const startX = width / 2 + radius * Math.cos((startAngle * Math.PI) / 180);
  const startY = width / 2 + radius * Math.sin((startAngle * Math.PI) / 180);
  const endX = width / 2 + radius * Math.cos((endAngle * Math.PI) / 180);
  const endY = width / 2 + radius * Math.sin((endAngle * Math.PI) / 180);

  const path = `
    M ${startX} ${startY}
    A ${radius} ${radius} 0 ${endAngle > 180 ? 1 : 0} 1 ${endX} ${endY}
    L ${width / 2} ${width / 2}
    Z
  `;

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      style={{
        transform: 'rotate(-90deg)',
      }}>
      <circle
        cx={width / 2}
        cy={width / 2}
        r={width / 2 - 2}
        stroke={FigmaDesignTokens.Grey[300]}
        strokeWidth={3}
        fill='none'
      />

      <path d={path} fill={fillColor} strokeLinecap='square' />
    </svg>
  );
};

export default AnimatedCircle;
