import { Auth } from 'aws-amplify';
import axios from 'axios';
import { ManualCheckOutPayloadType, ManualCheckinPayloadType } from '@/Types';

export const checkInManually = async (body: ManualCheckinPayloadType) => {
  const currentSession = await Auth.currentSession();
  const customHeaders = {
    Authorization: 'Bearer ' + currentSession.getIdToken().getJwtToken(),
  };

  const instance = axios.create({
    timeout: 30000,
    headers: customHeaders,
  });

  const payload = { ...body }; //test user Bobby Singer: euId: 23Z7Q0M3

  const query = await instance.post<ManualCheckinPayloadType>(
    `${process.env.REACT_APP_MANUAL_CHECK_IN_OUT_URL}checkin`,
    payload,
  );

  return query.data;
};
export const checkOutManually = async (body: ManualCheckOutPayloadType) => {
  const currentSession = await Auth.currentSession();
  const customHeaders = {
    Authorization: 'Bearer ' + currentSession.getIdToken().getJwtToken(),
  };

  const instance = axios.create({
    timeout: 30000,
    headers: customHeaders,
  });

  const query = await instance.post<ManualCheckOutPayloadType>(
    `${process.env.REACT_APP_MANUAL_CHECK_IN_OUT_URL}checkout`,
    body,
  );

  return query.data;
};
