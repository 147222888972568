import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { CourseName } from './CourseNameCell.styles';
import { CustomTooltip } from '../../../../App/Shared/Tooltip/CustomTooltip';
import { Course } from '@/Types';

type InternalCourseNameCellProps = {
  gridRenderCellParams: GridRenderCellParams<Course, Course['internalName']>;
};

export const InternalCourseNameCell = ({ gridRenderCellParams }: InternalCourseNameCellProps) => (
  <CustomTooltip title={gridRenderCellParams.value || ''} placement='right-end'>
    <CourseName>{gridRenderCellParams.value}</CourseName>
  </CustomTooltip>
);
