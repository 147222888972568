import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const useEventHeaderDashStyles = makeStyles()(() => ({
  addNewCourseBtn: {
    padding: '2px 18px 2px 2px',
    backgroundColor: FigmaDesignTokens.Blue[100],
    borderRadius: 15,
    height: 30,
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.83,
    letterSpacing: 0.34,
    color: FigmaDesignTokens.Blue[950],
    boxShadow: 'none',
    '& .MuiButton-startIcon': {
      marginRight: 8,
      marginLeft: 0,
    },
    '&:hover': {
      backgroundColor: FigmaDesignTokens.Grey[300],
      boxShadow: 'none',
    },
  },
}));
