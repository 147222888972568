import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const ProfileDropdownItemStyles = makeStyles()(theme => ({
  navigationListItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 0,
    alignItems: 'center',
    marginBottom: 10,
  },
  navigationListItemRectangle: {
    width: 10,
    height: 26,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  navigationListItemActiveRectangle: {
    backgroundColor: FigmaDesignTokens.Blue[500],
  },
  navigationListItem: {
    marginLeft: 28,
    fontSize: 14,
    lineHeight: 1.86,
    letterSpacing: 0.4,
    color: FigmaDesignTokens.Grey[950],
    textDecoration: 'initial',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  navigationListItemActive: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));
