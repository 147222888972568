import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import getVideoId from 'get-video-id';

import { useVideoLiveAssetStyles } from './Assets.styles';

interface VideoLiveAssetPreviewProps {
  videoUrl: string;
  type: string;
}

export const VideoLiveAssetPreview = (props: VideoLiveAssetPreviewProps): JSX.Element => {
  const { videoUrl, type } = props;
  const { classes } = useVideoLiveAssetStyles();
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('');

  useEffect(() => {
    const { id } = getVideoId(videoUrl);
    if (!id) return;

    if (type === 'youtube') {
      const newThumbnailUrl = `https://i1.ytimg.com/vi/${id}/hqdefault.jpg`;
      setThumbnailUrl(newThumbnailUrl);
    } else if (type === 'vimeo') {
      const newThumbnailUrl = `https://vumbnail.com/${id}_large.jpg`;
      setThumbnailUrl(newThumbnailUrl);
    }
  }, [videoUrl, type]);

  return (
    <Grid item xs={12}>
      <Box>
        <img alt='' src={thumbnailUrl} className={classes.thumbnail} />
      </Box>
    </Grid>
  );
};
