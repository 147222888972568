import { makeStyles } from 'tss-react/mui';

export const useRegisterFormStyles = makeStyles()(theme => ({
  headerWrapper: {
    width: 528,
    height: 'fit-content',
  },

  mainHeader: {
    marginTop: 10,
    marginBottom: 48,
    textAlign: 'center',
  },
}));
