import { Box, Grid } from '@mui/material';
import { useIntl } from 'react-intl';

import { Header } from '@/Components';

import FAQPage from './Components/FaqPage';

const Support = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Header
        header={formatMessage({
          id: 'marketing_material.title',
          defaultMessage: 'Marketing material',
        }).toUpperCase()}
        defaultMessage='Marketing material'
        showLanguageSwitcher={false}
        columnHeaderHeight={80}
        hiddenTabs
        onlyShowProfile>
        <Box />
      </Header>
      <Box width={1090}>
        <Box height={16} />
        <Grid item xs={12}>
          <FAQPage />
        </Grid>
      </Box>
    </>
  );
};

export default Support;
