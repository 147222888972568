import { Dialog } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useAppContext } from '@/Context';
import { PartnerServiceFactory } from '@/Services';
import { Partner } from '@/Types';
import { ValidatePartnerDataType } from '@/Types/ValidatePartnerData';
import { InvitePartnerConfirmationStep } from '@/Views/Authentication/Components/RegisterPartner/Steps/InvitePartnerConfirmationStep';
import {
  VALIDATE_PARTNER_STEP_VIEW,
  ValidatePartnerStep,
} from '@/Views/Authentication/Components/RegisterPartner/Steps/ValidatePartnerStep';

import { useAddAnotherStudioModalStyles } from './Styles/AddAnotherStudioModal.styles';

type AddAnotherStudioModalProps = {
  show: boolean;
  onHide: () => void;
  partnerNamesListRefetch: Function;
  partner: Partner;
};

export const AddAnotherStudioModal = ({
  show,
  onHide,
  partnerNamesListRefetch,
  partner,
}: AddAnotherStudioModalProps) => {
  const { classes } = useAddAnotherStudioModalStyles();
  const intl = useIntl();
  const { dispatch } = useAppContext();
  const [step, setStep] = useState(0);
  const [newPartnerData, setNewPartnerData] = useState<ValidatePartnerDataType>();

  const partnerService = useMemo(
    () => new PartnerServiceFactory().getInstance(dispatch),
    [dispatch],
  );

  const handleCloseVerifyPartnerModal = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleStep = useCallback((step: number) => {
    setStep(step);
  }, []);

  const handleValidatePartnerData = useCallback((params: ValidatePartnerDataType) => {
    setNewPartnerData(params);
  }, []);

  const handleUpdatePartnerNames = useCallback(() => {
    if (partnerService) {
      partnerNamesListRefetch();
    }
  }, [partnerNamesListRefetch, partnerService]);

  return (
    <Dialog
      className={step === 0 ? '' : classes.dialogRoot}
      open={show}
      onClose={handleCloseVerifyPartnerModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      PaperProps={{
        style: {
          minWidth: 620,
          borderRadius: 10,
          boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.1)',
          overflowY: 'visible',
        },
      }}>
      {step === 0 && (
        <ValidatePartnerStep
          addPartner
          header={intl.formatMessage({
            id: 'invite_partner.stepper.step.initial_startcode.header',
            defaultMessage: 'Add another studio',
          })}
          subHeader={intl.formatMessage({
            id: 'invite_partner.stepper.step.initial_startcode.subheader',
            defaultMessage:
              'To add another studio, please enter the partner ID and start code of this studio.',
          })}
          ctaTitle={intl.formatMessage({
            id: 'invite_partner.stepper.step.initial_startcode.cta',
            defaultMessage: 'Add studio',
          })}
          validatePartnerStepView={VALIDATE_PARTNER_STEP_VIEW.INVITE_PARTNER}
          handleStep={handleStep}
          handleValidatePartnerData={handleValidatePartnerData}
          handleUpdatePartnerNames={handleUpdatePartnerNames}
          partnerService={partnerService}
          handleClose={handleCloseVerifyPartnerModal}
        />
      )}

      {step === 1 && !!newPartnerData && (
        <InvitePartnerConfirmationStep
          studioName={partner.studioName}
          newPartnerData={newPartnerData}
          handleCloseVerifyPartnerModal={handleCloseVerifyPartnerModal}
        />
      )}
      {step === 1 && !!newPartnerData && show && (
        <img
          alt=''
          src='/Images/einzel-motiv-a-er-sport-rgb-2.png'
          className={classes.personImage}
        />
      )}
    </Dialog>
  );
};
