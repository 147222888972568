export const sizeButtonStyles = {
  sm: '13px',
  md: '18px',
  lg: '20px',
  small: '13px',
  medium: '18px',
  large: '20px',
};

export const heightButtonStyles = {
  sm: '36px',
  md: '42px',
  lg: '44px',
  small: '36px',
  medium: '42px',
  large: '44px',
};

export const paddingButtonStyles = {
  sm: '7px 16px',
  md: '12px 16px',
  lg: '12px 16px',
  small: '7px 16px',
  medium: '12px 16px',
  large: '12px 16px',
};
