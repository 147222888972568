import { Box, Grid } from '@mui/material';
import { useIntl } from 'react-intl';

import { MainHeadline, RegularTextAccent } from '@/Components';
import LanguageDropdown from '@/Components/Header/LanguageDropdown';
import { ProfileDropdown } from '@/Components/Header/ProfileDropdown';
import { Partner, UserAttr } from '@/Types';

import { useBarStyles } from './Header.styles';

type DashSiteAndProfileBarProps = {
  userAttr: UserAttr;
  partner: Partner;
};

const DashSiteAndProfileBar = ({ userAttr, partner }: DashSiteAndProfileBarProps): JSX.Element => {
  const { classes } = useBarStyles();
  const intl = useIntl();

  return (
    <Grid item xs={12}>
      <Box className={classes.root}>
        <Box>
          <Box className={classes.headerContainer}>
            <RegularTextAccent
              textContent={intl.formatMessage({
                id: 'view.dashboard.profile_bar.subheader',
                defaultMessage: 'Welcome to the',
              })}
            />
            <MainHeadline
              textContent={intl.formatMessage({
                id: 'view.dashboard.profile_bar.header',
                defaultMessage: 'Hansefit Verbundpartner-portal ',
              })}
            />
          </Box>
        </Box>
        <Box className={classes.profileDropdownContainer}>
          <ProfileDropdown userAttr={userAttr} partner={partner} />
          <LanguageDropdown />
        </Box>
      </Box>
    </Grid>
  );
};

export default DashSiteAndProfileBar;
