import { makeStyles } from 'tss-react/mui';

export const useCategoriesStyles = makeStyles()(() => ({
  container: {
    marginTop: 8,
  },
  item: {
    position: 'relative',
  },
  img: {
    height: 32,
    width: 32,
  },
  imgColorDefault: {
    filter:
      'brightness(0) saturate(100%) invert(57%) sepia(5%) saturate(124%) hue-rotate(181deg) brightness(96%) contrast(87%)',
  },
  imgColorDisabled: {
    filter:
      'brightness(0) saturate(100%) invert(57%) sepia(5%) saturate(124%) hue-rotate(181deg) brightness(96%) contrast(87%)',
  },
}));

export const useSelectedCategoriesStyles = makeStyles()(() => ({
  item: {
    position: 'relative',
  },
  img: {
    height: 32,
    width: 32,
    filter:
      'brightness(0) saturate(100%) invert(15%) sepia(93%) saturate(3781%) hue-rotate(219deg) brightness(78%) contrast(104%)',
  },
}));
