import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { MarketingMaterialItem } from '@/Types';
import { CellContainer, StyleDescription, StyleFileType } from './TableCell.styles';
import { useMemo } from 'react';
import {
  getFormattedFileType,
  getFiletypeFromFilename,
  getFormattedFileSize,
} from '@/Utils/fileHelper';

type FileTypeCellProps = {
  gridRenderCellParams: GridRenderCellParams<MarketingMaterialItem, number>;
};

export const FileTypeCell = ({ gridRenderCellParams }: FileTypeCellProps) => {
  const { blob, originalFilename, creationDate } = gridRenderCellParams.row;
  const fileType = getFiletypeFromFilename(originalFilename);
  const type = fileType ? getFormattedFileType(fileType) : '';
  const { formatMessage } = useIntl();
  const fileSize = getFormattedFileSize(blob?.size || 0);
  const updateOn = useMemo(
    () => (creationDate ? moment.unix(creationDate).format('DD.MM.YYYY') : ''),
    [creationDate],
  );

  return (
    <CellContainer>
      <StyleFileType>{type}</StyleFileType>
      <StyleDescription>
        {blob && blob.size > 0 ? fileSize + ', ' : ''}
        {formatMessage({
          id: 'marketing_material.view.table.column.type.upload',
          defaultMessage: 'Uploaded on',
        })}{' '}
        {updateOn}
      </StyleDescription>
    </CellContainer>
  );
};
