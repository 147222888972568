import { Auth } from 'aws-amplify';
import axios from 'axios';

import { CheckInCheckoutEventType } from '@/Types';

export const getCurrentCheckIns = async () => {
  const currentSession = await Auth.currentSession();
  const customHeaders = {
    Authorization: 'Bearer ' + currentSession.getIdToken().getJwtToken(),
  };

  const instance = axios.create({
    timeout: 30000,
    headers: customHeaders,
  });

  const query = await instance.get<CheckInCheckoutEventType[]>(
    process.env.REACT_APP_CURRENT_CHECKINS_URL!,
  );

  return query.data;
};
