import { useState } from 'react';

import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { Dashboard } from '@/Mappers';
import { MainHeadline, RegularTextAccent } from '@/Components';
import { LineDivider } from '@/Components';
import { StatsData } from '@/Types';

import { useStatsStyles } from './TopAndStats.styles';

type StatsProps = {
  stats: StatsData;
};

export const Stats = (props: StatsProps): JSX.Element => {
  const { stats } = props;
  const { eventsPerWeek, performedEvents } = stats;
  const { classes } = useStatsStyles();
  const intl = useIntl();
  const [dashboardService] = useState<Dashboard>(new Dashboard());
  const [maxEventsPerWeek] = useState<number>(dashboardService.getMaxValue(eventsPerWeek));
  const [formattedPerformedEvents] = useState<string>(
    new Intl.NumberFormat('de-DE', { style: 'decimal' }).format(performedEvents),
  );

  return (
    <>
      {dashboardService && (
        <Box className={classes.root}>
          <MainHeadline
            textContent={intl.formatMessage({
              id: 'view.dashboard.stats.header',
              defaultMessage: 'Statistics',
            })}
          />
          <LineDivider verticalSpace={14} />
          <Box className={classes.takenPlaceEventsWrapper}>
            <Typography variant='body2' className={classes.takenPlaceEventsNum}>
              {formattedPerformedEvents}
            </Typography>
            <RegularTextAccent
              textContent={intl.formatMessage({
                id: 'view.dashboard.stats.performed_events',
                defaultMessage: 'Took place appointments ',
              })}
            />
          </Box>
          <Box className={classes.thisWeekEventsWrapper}>
            <RegularTextAccent
              textContent={intl.formatMessage({
                id: 'view.dashboard.stats.performed_events_per_week',
                defaultMessage: 'Dates carried out per week ',
              })}
            />
            <Box className={classes.eventsColsWrapper}>
              {eventsPerWeek.map((event, idx) => (
                <Box className={classes.eventsColWrapper} key={idx}>
                  <Box
                    className={clsx(classes.eventsCol)}
                    style={{
                      height: dashboardService.getHeightOfPerformedEvents(event, maxEventsPerWeek),
                    }}>
                    <Typography>{event.amount}</Typography>
                  </Box>

                  <Typography variant='body2' className={classes.eventColLabel}>
                    {intl.formatMessage({
                      id: 'view.dashboard.stats.calendar_week_shortcut',
                      defaultMessage: 'KW',
                    })}{' '}
                    {event.week}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
