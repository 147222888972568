import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const useBreadcrumbsStyles = makeStyles()(theme => ({
  link: {
    fontSize: 10,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: 0.4,
    color: FigmaDesignTokens.Grey[700],
    textDecoration: 'initial',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  active: {
    fontWeight: 500,
  },
  breadcrumbSeparator: {
    height: 12,
    width: 1,
    background: FigmaDesignTokens.Grey[950],
  },
  breadcrumb: {
    '& a': {
      fontSize: 14,
      color: theme.palette.primary.main,
      textTransform: 'capitalize',
      '&.active': {
        color: FigmaDesignTokens.Grey[950],
      },
    },
  },
}));
