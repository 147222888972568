import { HttpService, EnvironmentService } from '@/Services';
import { Auth } from 'aws-amplify';
import { RegisterPartnerService } from '../Authentication/RegisterPartner.service';
import { AnyStateAction } from '@/Context';
import { Dispatch } from 'react';

export class RegisterPartnerServiceFactory {
  public getInstance(dispatch: Dispatch<AnyStateAction>): RegisterPartnerService {
    return new RegisterPartnerService(new HttpService(Auth, dispatch), new EnvironmentService());
  }
}
