export enum ConfirmationDialogTypes {
  SET_CONFIRMATION_DIALOG = 'SET_CONFIRMATION_DIALOG',
}

export interface ConfirmationDialogAction {
  type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG;
  payload: ConfirmationDialogState;
}

export interface ConfirmationDialogState {
  show: boolean;
  message?: string;
}

export const ConfirmationDialogReducer = (
  state: ConfirmationDialogState,
  action: ConfirmationDialogAction,
) => {
  switch (action.type) {
    case ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
