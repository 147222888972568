import { useMemo } from 'react';

import {
  getDashboardPathname,
  getLogoPathname,
  getPhotoUploadPathname,
  getVideoUploadPathname,
} from '@/Mappers';
import { BreadcrumbProps } from '@/Types';

import useCurrentLanguage from '../useCurrentLanguage';
import useMemoedIntl from '../useMemoedIntl';

const usePhotoAndVideoBreadcrumbs = (currentTab: number): BreadcrumbProps[] => {
  const { formatMessage } = useMemoedIntl();
  const currentLanguage = useCurrentLanguage();

  const dynamicBreadcrumbs = useMemo(() => {
    const staticBreadcrumbs = [
      {
        title: formatMessage({ id: 'view.dashboard', defaultMessage: 'Dashboard' }),
        href: getDashboardPathname(currentLanguage),
      },
      {
        title: formatMessage({ id: 'view.photo_and_video', defaultMessage: 'Image gallery' }),
        href: getLogoPathname(currentLanguage),
      },
    ];

    if (currentTab === 1) {
      staticBreadcrumbs.push({
        title: formatMessage({
          id: 'view.photo_and_video.photo_upload',
          defaultMessage: 'Photo upload',
        }),
        href: getPhotoUploadPathname(currentLanguage),
      });
    } else if (currentTab === 2) {
      staticBreadcrumbs.push({
        title: formatMessage({
          id: 'view.photo_and_video.video_upload',
          defaultMessage: 'Video upload',
        }),
        href: getVideoUploadPathname(currentLanguage),
      });
    } else {
      staticBreadcrumbs.push({
        title: formatMessage({ id: 'view.photo_and_video.logo', defaultMessage: 'Logo' }),
        href: getLogoPathname(currentLanguage),
      });
    }
    return staticBreadcrumbs;
  }, [formatMessage, currentTab, currentLanguage]);

  return dynamicBreadcrumbs;
};

export default usePhotoAndVideoBreadcrumbs;
