import { createStyles } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../../design/design-tokens.json';

const TextFieldStyles = (component: any) =>
  withStyles(component, theme =>
    createStyles({
      root: {
        '& .MuiFormHelperText-root.Mui-error': {
          marginLeft: 0,
        },
        width: '100%',
        color: theme.palette.text.primary,
        '& .Mui-error input': {
          color: theme.palette.error.main,
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: 4,
          position: 'relative',
          '&.Mui-error': {
            '& .MuiOutlinedInput-input::placeholder': {
              color: theme.palette.error.main,
            },
          },
          '& .MuiOutlinedInput-input::placeholder': {
            color: theme.palette.primary.main,
            opacity: 1,
          },
          '& .MuiInputBase-inputMultiline::placeholder': {
            color: theme.palette.primary.dark,
          },
          '& .MuiOutlinedInput-input': {
            borderColor: theme.palette.error.main,
          },
        },
        '& label.Mui-disabled': {
          color: theme.palette.text.disabled,
        },
        '& label.Mui-disabled.MuiInputLabel-root:not(.Mui-focused):not(.Mui-error):not(.MuiFormLabel-filled)':
          {
            color: theme.palette.text.disabled,
          },
        '& .MuiFormLabel-root': {
          '&.MuiFormLabel-filled': {
            fontSize: 14,
            '&.Mui-disabled': {
              color: FigmaDesignTokens.Grey[400],
            },
            '&.Mui-error': {
              color: theme.palette.error.main,
              fontSize: 14,
            },
            color: theme.palette.primary.main,
          },
          '&.Mui-focused': {
            '&.Mui-error': {
              color: theme.palette.error.main,
              fontSize: 14,
            },
          },
        },
        '& .MuiSelect-iconOutlined': {
          fill: FigmaDesignTokens.Grey[950],
          position: 'absolute',
          right: 4,
        },
        '& .MuiFormLabel-root.Mui-error': {
          color: theme.palette.error.main,
          fontSize: 14,
        },
        '& .MuiFormLabel-root.Mui-disabled': {
          color: FigmaDesignTokens.Grey[400],
        },
        '& .MuiInputLabel-outlined:not(.Mui-focused):not(.Mui-error):not(.MuiFormLabel-filled)': {
          color: theme.palette.text.primary,
          fontSize: 16,
        },
        '& .MuiInputLabel-outlined.Mui-focused': {
          fontSize: 14,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          fontSize: 14,
        },
        '& .MuiPickersDay-root.Mui-selected': {
          backgroundColor: `${FigmaDesignTokens.Blue[600]}!important`,
        },
        '& p': {
          color: theme.palette.text.primary,
        },
      },
    }),
  );

export default TextFieldStyles;
