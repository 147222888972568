import { Grid } from '@mui/material';
import { CustomizedChip } from '../../App/Shared/Form/Components/Customized/CustomizedChip';
import { Close } from '@mui/icons-material';

import { Tag } from '@/Types';

import { useTagsStyles } from './Tags.styles';

type SelectedTagsProps = {
  tags: Tag[];
  handleDelete: (tag: Tag) => void;
};

export const SelectedTags = (props: SelectedTagsProps): JSX.Element => {
  const { tags, handleDelete } = props;
  const { classes } = useTagsStyles();
  return (
    <Grid container className={classes.container} spacing={2}>
      {tags.map(tag => (
        <Grid item key={tag.id}>
          <CustomizedChip
            className={classes.root}
            label={tag.title}
            onDelete={() => handleDelete(tag)}
            color='secondary'
            deleteIcon={<Close />}
          />
        </Grid>
      ))}
    </Grid>
  );
};
