import { FormHelperText, Grid } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { get } from 'lodash';
import { useIntl } from 'react-intl';

import { FormikAutocomplete } from '@/App/Shared/Form/Components/Formik/FormikAutocomplete';
import { SubHeader } from '@/App/Shared/Form/Components/Header/SubHeader';
import { EventsService } from '@/Services';
import { Event } from '@/Types';

import { useAppointmentsTimeStyles } from './Event.styles';

export interface AppointmentsTimeProps {
  eventsService: EventsService;
  hourName: string;
  minuteName: string;
}

export const AppointmentsTime = ({
  eventsService,
  hourName,
  minuteName,
}: AppointmentsTimeProps) => {
  const { touched, errors, values } = useFormikContext<Event>();
  const intl = useIntl();
  const { classes } = useAppointmentsTimeStyles();
  //@ts-ignore
  const isHourError = !!(get(touched, hourName) && get(errors, hourName));
  //@ts-ignore
  const isMinuteError = !!(get(touched, minuteName) && get(errors, minuteName));

  return (
    <Grid item xs={6}>
      <Grid item xs={12}>
        <SubHeader
          title={intl.formatMessage({
            id: 'form.event.appointments.subheader.course_time',
            defaultMessage: 'Course time:',
          })}
        />
      </Grid>

      <Grid container item spacing={3}>
        <Grid item xs={6}>
          <Field
            disableClearable
            freeSolo
            disabled={!values.appointments.startDate}
            name={hourName}
            component={FormikAutocomplete}
            options={eventsService.getHours().map(option => option.value)}
            textFieldProps={{
              name: hourName,
              type: 'number',
              inputProps: { max: 24, min: 0 },
              margin: 'normal',
              variant: 'outlined',
              label: intl.formatMessage({
                id: 'form.event.appointments.subheader.course_hour',
                defaultMessage: 'Hour*',
              }),
              error: isHourError,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Field
            disableClearable
            freeSolo
            name={minuteName}
            disabled={!values.appointments.startDate}
            component={FormikAutocomplete}
            options={eventsService.getMinutes().map(option => option.value)}
            textFieldProps={{
              name: minuteName,
              type: 'number',
              inputProps: { max: 59, min: 0 },
              label: intl.formatMessage({
                id: 'form.event.appointments.subheader.course_minutes',
                defaultMessage: 'Minutes*',
              }),
              margin: 'normal',
              variant: 'outlined',
              error: isMinuteError,
            }}
          />
        </Grid>
      </Grid>

      <div className={classes.errorContainer}>
        {(isHourError || isMinuteError) && (
          <FormHelperText error={isHourError || isMinuteError} className={classes.helperText}>
            {isMinuteError ||
            errors.appointments?.hour === 'form.event.error.appointments.time.required'
              ? intl.formatMessage({
                  id: 'form.event.error.appointments.time.required',
                  defaultMessage: 'Please provide a course clock time.',
                })
              : intl.formatMessage({
                  id: 'form.event.error.appointments.first.time.passed',
                  defaultMessage: 'Time of the first appointment has already passed',
                })}
          </FormHelperText>
        )}
      </div>
    </Grid>
  );
};
