import { useMemo } from 'react';

import {
  getDashboardPathname,
  getInviteUsersPathname,
  getUsersManagementPathname,
} from '@/Mappers';
import { BreadcrumbProps } from '@/Types';

import useCurrentLanguage from '../useCurrentLanguage';
import useMemoedIntl from '../useMemoedIntl';

const useUserManagementBreadcrumbs = (currentTab: number): BreadcrumbProps[] => {
  const { formatMessage } = useMemoedIntl();
  const currentLanguage = useCurrentLanguage();

  const dynamicBreadcrumbs = useMemo(() => {
    const staticBreadcrumbs = [
      {
        title: formatMessage({ id: 'view.dashboard', defaultMessage: 'Dashboard' }),
        href: getDashboardPathname(currentLanguage),
      },
      {
        title: formatMessage({
          id: 'breadcrumbs.users',
          defaultMessage: 'User',
        }),
        href: getUsersManagementPathname(currentLanguage),
      },
    ];

    if (currentTab === 1) {
      staticBreadcrumbs.push({
        title: formatMessage({
          id: 'breadcrumbs.invite_users',
          defaultMessage: 'Invite users',
        }),
        href: getInviteUsersPathname(currentLanguage),
      });
    } else {
      staticBreadcrumbs.push({
        title: formatMessage({
          id: 'breadcrumbs.user_management',
          defaultMessage: 'User management',
        }),
        href: getUsersManagementPathname(currentLanguage),
      });
    }
    return staticBreadcrumbs;
  }, [formatMessage, currentTab, currentLanguage]);

  return dynamicBreadcrumbs;
};

export default useUserManagementBreadcrumbs;
