import { Container, Header } from './PlaceholderLogo.styles';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button } from '@/Components';
import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';

export const PlaceholderLogo = () => {
  const { formatMessage } = useIntl();
  const currentLanguage = useCurrentLanguage();
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(`/${currentLanguage}/foto-und-video/logo`);
  };

  return (
    <Container>
      <Header variant='h5'>
        {formatMessage({
          id: 'view.dashboard.profile_info.header.no_logo_available',
          defaultMessage: 'No logo available',
        })}
      </Header>
      <Button variant='outlined' onClick={handleRedirect}>
        {formatMessage({
          id: 'cta.upload_now',
          defaultMessage: 'Upload now',
        })}
      </Button>
    </Container>
  );
};
