import { Backdrop, CircularProgress } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import { useCurrentUserRoleQuery } from '@/Queries';
import { Role } from '@/Types';

const RequireAdmin = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const { data: userRole, isLoading } = useCurrentUserRoleQuery();
  const language = useCurrentLanguage();

  if (isLoading) {
    return (
      <Backdrop open>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  if (userRole !== Role.ADMIN && !isLoading) {
    return <Navigate to={`/${language}/dashboard`} state={{ from: location }} replace />;
  }

  return children as ReactElement;
};

export default RequireAdmin;
