import { makeStyles } from 'tss-react/mui';

export const useDashHeaderStyles = makeStyles()(theme => ({
  stickyBackground: {
    position: 'fixed',
    width: '100%',
    height: 100,
    left: 0,
    top: 0,
    backgroundColor: theme.palette.background.default,
    boxShadow: '0 5px 5px 0 rgba(52, 54, 61, 0.05)',
    zIndex: 10,
  },
  headerContainer: {
    paddingTop: 42,
    transition: 'padding-top 0.20s ease-out',
    marginBottom: 40,
  },
  smallPaddingToBody: {
    paddingTop: 12,
  },
  stickyInner: {
    zIndex: 11,
  },
}));

export const useBarStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },
  leftContainer: {
    width: 'auto',
    marginLeft: 34,
  },
  profileDropdownContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  breadcrumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    height: 24,
  },
  headerContainer: {
    height: 55,
  },
}));
