import { styled } from '@mui/material';
import { DatePicker } from 'formik-mui-x-date-pickers';

import FigmaDesignTokens from '../../../../../../design/design-tokens.json';

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  border-radius: 4px;
  & label.MuiInputLabel-root {
    margin-top: 1px;
  },
  & input {
    font-size: 14px;
    height: 24px;
    :before {
      background-color: red!important;
      color: red!important;
    }
  },
  & .Mui-error input {
    color: ${props => props.theme.palette.error.main};
  }
    & .MuiSelect-select {
      &:focus {
        border-radius: 14px
      },
    },
  & .MuiSelect-outlined: {
    font-size: 14px;
  },
  & .MuiInputBase-root {
    &.Mui-focused {
      color: ${props => props.theme.palette.primary.main},
    },
  },
  & .MuiOutlinedInput-root {
    border-radius: 4px;
    &.Mui-error {
      & .MuiOutlinedInput-input::placeholder {
        color: ${props => props.theme.palette.error.main};
      },
    },
    & .MuiOutlinedInput-input::placeholder {
      color: ${props => props.theme.palette.text.primary};
      opacity: 1;
    },
    & .MuiInputBase-inputMultiline::placeholder {
      color: ${props => props.theme.palette.text.primary}
    },
  },
  & label.Mui-disabled {
    color: ${props => props.theme.palette.text.disabled},
  },
  & label.Mui-disabled.MuiInputLabel-root:not(.Mui-focused):not(.Mui-error):not(.MuiFormLabel-filled)
  {
    color: ${props => props.theme.palette.text.disabled},
  },
  & .MuiFormLabel-root {
    &.MuiFormLabel-filled {
      font-size: 14px;
      &.Mui-disabled {
        color: ${FigmaDesignTokens.Grey[400]};
      },
    &.Mui-error {
      color: ${props => props.theme.palette.error.main};
      font-size: 14px;
    },
    color: theme.palette.primary.main,
    },
  &.Mui-focused {
    &.Mui-error {
      color: ${props => props.theme.palette.error.main};
      font-size: 14px
    }
    color: ${props => props.theme.palette.primary.main},
  },
  },
  & .MuiSelect-iconOutlined {
    fill: ${props => props.theme.palette.text.primary};
    position: absolute;
    right: 4px;
  },
  & .MuiFormLabel-root.Mui-error {
    color: ${props => props.theme.palette.error.main};
    font-size: 14px;
  },
  & .MuiFormLabel-root.Mui-disabled {
    color: ${FigmaDesignTokens.Grey[400]};
  }
    & .MuiInputLabel-outlined:not(.Mui-focused):not(.Mui-error):not(.MuiFormLabel-filled) {
      color: ${props => props.theme.palette.text.primary};
      font-size: 14px;
    },
  & .MuiInputLabel-outlined.Mui-focused {
    font-size: 14px;
  },
  & .MuiOutlinedInput-notchedOutline {
    font-size: 14px;
  },
  & .MuiPickersDay-root.Mui-selected {
    background-color: ${FigmaDesignTokens.Blue[600]}!important
  },
`;
