import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import ReactCrop, { Crop } from 'react-image-crop';
import { useFormikContext } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { AssetService } from '@/Services';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { ConfirmationDialogTypes, useAppContext } from '@/Context';
import { Button } from '@/Components';
import { CropMap, getDefaultFilename } from '@/Mappers';
import { GalleryAsset, Partner } from '@/Types';
import CropIcon from '@/Static/Icons/atoms-symbols-icons-media-crop.svg';
import CloseIcon from '@/Static/Icons/atoms-symbols-category-close-big.svg';
import 'react-image-crop/dist/ReactCrop.css';
import { useCropGalleryImageStyles } from './Styles/CropGalleryImage.modal';

type CropGalleryImageModalProps = {
  resource: string;
  casPublicId: Partner['casPublicId'];
  assetService: AssetService;
  assetId: number;
  originalFilename: string | null;
  handlePrimaryCtaDisabled: (value: boolean) => void;
};

export const CropGalleryImageModal = ({
  resource,
  casPublicId,
  assetService,
  assetId,
  originalFilename,
  handlePrimaryCtaDisabled,
}: CropGalleryImageModalProps) => {
  const { classes } = useCropGalleryImageStyles();
  const intl = useIntl();
  const cropService = useMemo(() => new CropMap(), []);
  const [open, setOpen] = useState(false);
  const [signedResource, setSignedResource] = useState<string>();
  const [resourceData, setResourceData] = useState<Record<string, string>>();
  const [crop, setCrop] = useState<Partial<Crop>>({ aspect: 16 / 9 });

  const [croppedImageElement, setCroppedImageElement] = useState<HTMLImageElement>();
  const formik = useFormikContext<{ gallery: GalleryAsset[] }>();
  const { dispatch } = useAppContext();
  const [disabled, setDisabled] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const handleSave = useCallback(async () => {
    if (resourceData && cropService) {
      setDisabled(true);
      const croppedImageAsBlob = await cropService.getCroppedImgAsBlob(croppedImageElement, crop);

      if (croppedImageAsBlob) {
        let newAssetId;

        const filename = getDefaultFilename(resource);
        await assetService.S3Upload(
          casPublicId,
          filename,
          'Gallery',
          new File([croppedImageAsBlob], filename),
          () => {},
          err => {
            if (err) {
              console.error(err);
              return;
            }

            assetService
              .assignGalleryImage(casPublicId, {
                filename,
                originalFilename,
              })
              .then(response => {
                newAssetId = response.data.assetId;
                handlePrimaryCtaDisabled(false);

                const gallery = formik.values.gallery;
                const galleryIndex = gallery.findIndex(asset => asset.assetId === assetId);

                if (galleryIndex !== -1 && newAssetId) {
                  gallery[galleryIndex] = {
                    ...gallery[galleryIndex],
                    assetId: newAssetId,
                    uri: filename,
                  };

                  formik.setValues({ gallery: [...gallery] });
                  formik.setFieldTouched('gallery', true);
                  setOpen(false);
                  dispatch({
                    type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
                    payload: { show: true },
                  });
                  handlePrimaryCtaDisabled(false);
                }
              });
          },
        );
      }
      setDisabled(false);
    }
  }, [
    assetId,
    assetService,
    casPublicId,
    crop,
    cropService,
    croppedImageElement,
    dispatch,
    formik,
    handlePrimaryCtaDisabled,
    originalFilename,
    resource,
    resourceData,
  ]);

  const getImageDetails = useCallback(async () => {
    const signedResource = await assetService.S3GetSignedResource(casPublicId, resource, 'Gallery');

    const s3Object = await assetService.S3GetObject(casPublicId, resource, 'Gallery');

    if (signedResource) {
      setSignedResource(signedResource);
    }

    if (s3Object) {
      setResourceData(s3Object);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource, casPublicId]);

  useEffect(() => {
    getImageDetails();
  }, [getImageDetails]);

  return (
    <Box className={classes.cropWrapper}>
      <Button className={classes.btnModal} onClick={handleOpen} variant='text'>
        <img alt='' src={CropIcon} />
        <Typography variant='body2' className={classes.btnText}>
          <FormattedMessage
            id='form.photo_and_video.photo_upload.image.cta.edit_image_detail'
            defaultMessage='Edit image section'
          />
        </Typography>
      </Button>
      <Dialog
        maxWidth='sm'
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        PaperProps={{
          style: {
            padding: '50px 30px 30px 30px',
          },
        }}>
        <Box className={classes.dialogTitle}>
          <DialogTitle>
            <FormHeader
              title={intl.formatMessage({
                id: 'form.photo_and_video.photo_upload.image.cta.edit_image_detail',
                defaultMessage: 'Edit image section',
              })}
            />
          </DialogTitle>
          <IconButton aria-label='close' onClick={handleClose} className={classes.closeButton}>
            <img src={CloseIcon} alt='' />
          </IconButton>
        </Box>
        <DialogContent className={classes.dialogContent}>
          <Grid container>
            <Grid item xs={12}>
              {signedResource && (
                <ReactCrop
                  src={signedResource}
                  crop={crop}
                  onImageLoaded={setCroppedImageElement}
                  onChange={newCrop => setCrop(newCrop)}
                  crossorigin='anonymous'
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button disabled={disabled} autoFocus onClick={handleSave} className={classes.cta}>
            <FormattedMessage
              id='form.photo_and_video.photo_upload.image.cta.submit_edit_image_detail'
              defaultMessage='Apply changes'
            />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
