import { EventList, EventsData } from '@/Types';

export const getDefaultFiltersValues = (): EventList => ({
  updatedEvents: [],
  checkedEvents: [],
  canceled: [],
  courseName: [],
  courseType: [],
  dateRange: { from: null, to: null },
  durationRange: { from: null, to: null },
  bookings: [],
  published: [],
  page: '',
  stream: [],
  timeRange: { from: '', to: '' },
  limit: 0,
  eventsData: {} as EventsData,
});
