import { Description, Typography } from '@/Components/Modals/Styles/AdministrationModal.styles';
import { UserModalChildContentProps, UserModalProps } from '@/Types';

const ModalContent = ({ description, givenName, name }: UserModalChildContentProps) => (
  <>
    <Typography>
      {givenName} {name}
    </Typography>
    <Typography>
      <Description>{description}</Description>
    </Typography>
  </>
);

export const DeletedContentModal = ({ givenName, name, intl }: UserModalProps) => ({
  header: intl.formatMessage({
    id: 'view.user_management.user_management.modal.delete.header',
    defaultMessage: 'delete user',
  }),
  submitButtonTitle: intl.formatMessage({
    id: 'view.user_management.user_management.modal.delete.cta',
    defaultMessage: 'Delete user now',
  }),
  children: (
    <ModalContent
      givenName={givenName}
      name={name}
      description={intl.formatMessage({
        id: 'view.user_management.user_management.modal.delete.description',
        defaultMessage: 'Do you really want to delete this user?\nThe action cannot be undone.',
      })}
    />
  ),
});

export const ActiveContentModal = ({ givenName, name, intl }: UserModalProps) => ({
  header: intl.formatMessage({
    id: 'view.user_management.user_management.modal.unblock.header',
    defaultMessage: 'unlock user',
  }),
  submitButtonTitle: intl.formatMessage({
    id: 'view.user_management.user_management.modal.unblock.cta',
    defaultMessage: 'Unblock user now',
  }),
  children: (
    <ModalContent
      givenName={givenName}
      name={name}
      description={intl.formatMessage({
        id: 'view.user_management.user_management.modal.unblock.description',
        defaultMessage: 'Do you want to unblock this user?',
      })}
    />
  ),
});

export const BlockedContentModal = ({ givenName, name, intl }: UserModalProps) => ({
  header: intl.formatMessage({
    id: 'view.user_management.user_management.modal.block.header',
    defaultMessage: 'block user',
  }),
  submitButtonTitle: intl.formatMessage({
    id: 'view.user_management.user_management.modal.block.cta',
    defaultMessage: 'Block user now',
  }),
  children: (
    <ModalContent
      givenName={givenName}
      name={name}
      description={intl.formatMessage({
        id: 'view.user_management.user_management.modal.block.description',
        defaultMessage: 'Are you sure you want to ban this user?\nYou can unblock at any time.',
      })}
    />
  ),
});
