import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@/Context';
import { PartnerCoursesServiceFactory } from '@/Services';

type useGetPartnerCourseQueryProps = {
  casPublicId?: string;
  courseId: string;
};

const useGetPartnerCourseQuery = ({ casPublicId, courseId }: useGetPartnerCourseQueryProps) => {
  const { dispatch } = useAppContext();
  const partnerCoursesService = new PartnerCoursesServiceFactory().getInstance(dispatch);

  const queryResult = useQuery(
    ['getPartnerCourse', courseId],
    () => {
      if (!casPublicId) {
        return Promise.resolve(null);
      }
      return partnerCoursesService.getCourse(casPublicId, parseInt(courseId));
    },
    {
      cacheTime: 0,
      enabled: !!casPublicId && !!courseId,
    },
  );

  return queryResult;
};

export default useGetPartnerCourseQuery;
