import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';

import useModalStyles from '@/App/Shared/Modal/ModalStyles';
import theme from '@/App/Theme/Theme';
import { Button, LineDivider } from '@/Components';
import { ManualCheckinValidation } from '@/Utils';

type ActionModalProps = {
  title: string;
  content: JSX.Element;
  ctaDenyTitle: string;
  ctaConfirmTitle: string;
  initialState: any;
  onSubmit: (data: ActionModalProps['initialState']) => Promise<void>;
  onClose: () => void;
};

const ActionModal: React.FC<ActionModalProps> = ({
  title,
  content,
  ctaDenyTitle,
  ctaConfirmTitle,
  initialState,
  onSubmit,
  onClose,
}) => {
  const intl = useIntl();
  const { classes } = useModalStyles();

  const submitHandler = (values: any) => onSubmit(values);

  return (
    <Box>
      <Dialog open onClose={onClose}>
        <Formik
          onSubmit={submitHandler}
          validationSchema={() => ManualCheckinValidation(intl)}
          validateOnChange
          validateOnMount
          initialValues={{ ...initialState }}>
          {({ isValid }) => (
            <Form>
              <Box className={classes.headerWrapper}>
                <Typography variant='h2' color={theme.palette.primary.light}>
                  {title}
                </Typography>
                <CloseIcon className={classes.clickable} onClick={() => onClose()} />
              </Box>
              <LineDivider verticalSpace={16} />
              <DialogContent>{content}</DialogContent>
              <DialogActions className={classes.actionsWrapper}>
                <Button variant='outlined' size='md' onClick={() => onClose()}>
                  {ctaDenyTitle}
                </Button>

                <Button type='submit' size='md' disabled={!isValid}>
                  {ctaConfirmTitle}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Box>
  );
};

export default ActionModal;
