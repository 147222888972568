import { Box, styled } from '@mui/material';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const Container = styled(Box)`
  width: 100%;
  max-width: 1090px;
  display: flex;
  justify-content: center;
  background-color: ${FigmaDesignTokens.White};
  border-radius: 4px;
`;
