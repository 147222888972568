import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const usePreviewModalStyles = makeStyles()(() => ({
  container: {
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: FigmaDesignTokens.White,
    height: '60px',
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05), 0 2px 4px 0 rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    width: '100%',
    paddingLeft: '30px',
    paddingRight: '24px',
  },
  title: {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: '14px',
    color: FigmaDesignTokens.Grey[950],
    textTransform: 'uppercase',
    position: 'relative',
    bottom: '4px',
  },
  iframeContainer: {
    height: '100%',
    marginTop: '60px',
  },
  iframe: {
    border: 'none',
  },
  spacer: {
    flex: 1,
    backgroundColor: FigmaDesignTokens.Red[300],
  },
  closePreviewCta: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    color: FigmaDesignTokens.Grey[200],
    borderRadius: '20px',
    height: '36px',
  },
  errorDialog: {
    '& .MuiDialog-paper': {
      borderRadius: '10px',
    },
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '39px',
    paddingRight: '30px',
    paddingTop: '39px',
    paddingBottom: '59px',
  },
  errorTitle: {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: '20px',
    color: FigmaDesignTokens.Blue[950],
    textTransform: 'uppercase',
    lineHeight: '30px',
    marginRight: '169px',
  },
  errorHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '18px',
  },
  errorMessageContainer: {
    backgroundColor: FigmaDesignTokens.Orange[50],
    borderRadius: '11px',
    padding: '22px 21px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
  },
  errorMessage: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '20px',
    color: FigmaDesignTokens.Grey[950],
    fontWeight: 'bold',
  },
  lastUpdatedContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
    lineHeight: '18px',
    color: FigmaDesignTokens.Grey[500],
    marginRight: '20px',
  },
}));
