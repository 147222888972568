import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import { ArrayHelpers, Field, FieldArray, useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { CustomizedAutocompleteHours } from '@/App/Shared/Form/Components/Customized/CustomizedAutocompleteOhrs';
import { FormikSwitch } from '@/App/Shared/Form/Components/Formik/FormikSwitch';
import { SwitchLabel } from '@/App/Shared/Form/Components/Label/SwitchLabel';
import { BodyTextSmall } from '@/Components';
import { useConfirmDialog } from '@/Hooks/useConfirmDialog';
import { OpeningHourMap } from '@/Mappers';
import { useGetPartnerQuery } from '@/Queries';
import { OpeningHour } from '@/Types';

import { AddWithIconAndTitleHour } from './AddWithIconAndTitleHour';
import { useWeekdayRowStyles } from './OpeningHours.styles';
import { PlusConnector } from './PlusConnector';
import { RemoveOpeningHour } from './RemoveOpeningHour';

type WeekdayRowProps = {
  idx: number;
  openingHour: OpeningHour;
  handlePrimaryCtaDisabled: (value: boolean) => void;
  openingHourService: OpeningHourMap;
  setValidateAfterSubmit: Function;
};

export const WeekdayRow = (props: WeekdayRowProps): JSX.Element => {
  const { idx, openingHour, handlePrimaryCtaDisabled, openingHourService, setValidateAfterSubmit } =
    props;
  const { data: partner } = useGetPartnerQuery();
  const { classes } = useWeekdayRowStyles();
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<{ openingHours: OpeningHour[] }>();
  const { openingHours } = values;
  const handleConfirmDialog = useConfirmDialog();

  const handleFromChange = useCallback(
    (value: string, idx: number, openingHourIdx: number) => {
      const mutatedOpeningHours = [...openingHours];
      mutatedOpeningHours[idx].times[openingHourIdx].from = value;
      mutatedOpeningHours[idx].times[openingHourIdx].to = '';
      setFieldValue('openingHours', mutatedOpeningHours);
      handleConfirmDialog(true);
      setValidateAfterSubmit(true);
      handlePrimaryCtaDisabled(false);
    },
    [
      openingHours,
      setFieldValue,
      handleConfirmDialog,
      setValidateAfterSubmit,
      handlePrimaryCtaDisabled,
    ],
  );

  const handleToChange = useCallback(
    (value: string, idx: number, openingHourIdx: number) => {
      const mutatedOpeningHours = [...openingHours];
      mutatedOpeningHours[idx].times[openingHourIdx].to = value;

      if (openingHourIdx === 0 && mutatedOpeningHours[idx].times[1]) {
        const a = mutatedOpeningHours[idx].times[0].to;
        const b = mutatedOpeningHours[idx].times[1].from;
        if (a && a !== '') {
          const parsedTo = parseInt(a.replace(':', ''));
          const parsedFrom = parseInt(b.replace(':', ''));

          if (parsedTo > parsedFrom) {
            mutatedOpeningHours[idx].times[1].from = '';
            mutatedOpeningHours[idx].times[1].to = '';
          }
        } else {
          mutatedOpeningHours[idx].times[1].from = '';
          mutatedOpeningHours[idx].times[1].to = '';
        }
      }
      setFieldValue('openingHours', mutatedOpeningHours);

      handleConfirmDialog(true);
      handlePrimaryCtaDisabled(false);
      setValidateAfterSubmit(true);
    },
    [
      openingHours,
      setFieldValue,
      handleConfirmDialog,
      handlePrimaryCtaDisabled,
      setValidateAfterSubmit,
    ],
  );

  const handleInsertAdditionOpeningHours = useCallback(
    (arrayHelpers: ArrayHelpers) => {
      if (!partner?.readonly) {
        arrayHelpers.insert(1, { from: '', to: '' });
        handleConfirmDialog(true);
        handlePrimaryCtaDisabled(false);
      }
    },
    [handleConfirmDialog, handlePrimaryCtaDisabled, partner],
  );

  const handleRemoveOpeningHours = useCallback(
    (arrayHelpers: ArrayHelpers) => {
      if (!partner?.readonly) {
        arrayHelpers.remove(1);
        handleConfirmDialog(true);
        handlePrimaryCtaDisabled(false);
      }
    },
    [handleConfirmDialog, handlePrimaryCtaDisabled, partner],
  );

  const handleOpenedChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
      const isOpened = e.target.checked;
      setValidateAfterSubmit(false);
      if (isOpened) {
        setFieldValue(`openingHours[${idx}]opened`, true);
        setFieldValue(`openingHours[${idx}]times`, [{ from: '', to: '' }]);
      } else {
        setFieldValue(`openingHours[${idx}]opened`, false);
        setFieldValue(`openingHours[${idx}]times`, []);

        const currentTimes = values.openingHours[idx].times;
        const isAllClosed = currentTimes.every(time => time.from === '' && time.to === '');
        !isAllClosed && handlePrimaryCtaDisabled(false);
      }

      handleConfirmDialog(true);
    },

    [
      handleConfirmDialog,
      handlePrimaryCtaDisabled,
      setFieldValue,
      setValidateAfterSubmit,
      values.openingHours,
    ],
  );

  return (
    <Grid
      item
      xs={12}
      className={clsx(classes.row, {
        [classes.rowEven]: idx % 2 === 0,
        [classes.rowOdd]: idx % 2 !== 0,
      })}
      id={`openingHoursRow${idx}`}>
      <Box className={classes.weekday}>
        <BodyTextSmall
          textContent={formatMessage({
            id: `form.description_and_opening_hours.opening_hours.weekdays.${openingHour.weekday}`,
            defaultMessage: openingHour.weekday,
          })}
          fontWeight={600}
        />
      </Box>
      <Box className={classes.opened}>
        <Field
          disabled={partner?.readonly}
          color='primary'
          type='checkbox'
          component={FormikSwitch}
          name={`openingHours[${idx}]opened`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOpenedChange(e, idx)}
        />
        <SwitchLabel
          title={
            openingHour.opened
              ? formatMessage({
                  id: 'form.description_and_opening_hours.opening_hours.opened',
                  defaultMessage: 'Open',
                })
              : formatMessage({
                  id: 'form.description_and_opening_hours.opening_hours.closed',
                  defaultMessage: 'Closed',
                })
          }
        />
      </Box>

      <Box className={classes.hoursContainer}>
        <FieldArray
          name={`openingHours[${idx}].times`}
          render={arrayHelpers => (
            <>
              {openingHour.times.map((time, timeIdx) => {
                const valueToFirstEntry = values.openingHours[idx].times[0].to;
                const valueFrom = values.openingHours[idx].times[timeIdx].from;
                const valueTo = values.openingHours[idx].times[timeIdx].to;

                return (
                  <Box key={timeIdx} className={classes.hoursItemContainer}>
                    <CustomizedAutocompleteHours
                      disabled={!!partner?.readonly}
                      label={formatMessage({ id: 'common.date.from', defaultMessage: 'from' })}
                      threshold={timeIdx === 0 ? '' : valueToFirstEntry}
                      openingHourService={openingHourService}
                      handleRangeChange={handleFromChange}
                      timeIdx={timeIdx}
                      idx={idx}
                      value={valueFrom}
                      direction={timeIdx === 0 ? 'from' : 'to'}
                    />
                    <CustomizedAutocompleteHours
                      disabled={!!partner?.readonly}
                      threshold={time.from}
                      openingHourService={openingHourService}
                      label={formatMessage({ id: 'common.date.to', defaultMessage: 'to' })}
                      handleRangeChange={handleToChange}
                      timeIdx={timeIdx}
                      idx={idx}
                      value={valueTo}
                      direction='to'
                    />
                    {openingHour.times.length > 0 && openingHour.times.length - 1 > timeIdx && (
                      <PlusConnector />
                    )}
                  </Box>
                );
              })}
              {openingHour.times.length > 0 &&
                (openingHour.times.length === 1 ? (
                  <Box
                    onClick={() => handleInsertAdditionOpeningHours(arrayHelpers)}
                    className={classes.additionalTimes}>
                    <AddWithIconAndTitleHour
                      title={formatMessage({
                        id: 'form.description_and_opening_hours.button.additional_opening_hours',
                        defaultMessage: 'Add more times ',
                      })}
                    />
                  </Box>
                ) : (
                  <Box onClick={() => handleRemoveOpeningHours(arrayHelpers)}>
                    <RemoveOpeningHour />
                  </Box>
                ))}
            </>
          )}
        />
      </Box>
    </Grid>
  );
};
