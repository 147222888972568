import { Field, FieldArray, useFormikContext } from 'formik';
import { Box } from '@mui/material';
import { FormikTextField } from '../../../../../App/Shared/Form/Components/Formik/FormikTextField';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

export type VerificationCodeType = {
  autoTab: (
    event: React.KeyboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
  handleChange: (value: string, index: number) => void;
  inputsRef: React.RefObject<HTMLInputElement[]>;
  handlePaste: (e: ClipboardEvent, maxLength: number) => void;
};

type ValidationCodeType = {
  verificationCode: { partCode: string }[];
};

const useStyles = makeStyles()(() => ({
  multiInputsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputBox: {
    width: 60,
  },
}));

export const VerificationCode = ({
  autoTab,
  handleChange,
  inputsRef,
  handlePaste,
}: VerificationCodeType) => {
  const { classes } = useStyles();
  const { values } = useFormikContext<ValidationCodeType>();

  return (
    <FieldArray
      name='startKey'
      render={() => (
        <Box className={classes.multiInputsWrapper}>
          {values.verificationCode.map((_, idx: number) => (
            <Box key={idx}>
              <Box className={classes.inputBox}>
                <Field
                  key={`verificationCode.${idx}.partCode`}
                  name={`verificationCode.${idx}.partCode`}
                  component={FormikTextField}
                  variant='outlined'
                  onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
                    e.target.value ? e.target.select() : undefined
                  }
                  onPaste={(e: ClipboardEvent) => (idx === 0 ? handlePaste(e, 1) : undefined)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e.target.value, idx)
                  }
                  inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                  inputRef={(input: HTMLInputElement) =>
                    input && inputsRef.current && (inputsRef.current[idx] = input)
                  }
                  onKeyUp={(
                    e: React.KeyboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>,
                  ) => autoTab(e, idx)}
                />
              </Box>
            </Box>
          ))}
        </Box>
      )}
    />
  );
};
