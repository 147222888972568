import { Alert, Box, Grid, Link, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { ServerErrorTypes, useAppContext } from '@/Context';

import { NotificationIcon } from '../../Notification/Components/NotificationIcon';

const useStyles = makeStyles()(() => ({
  root: {
    flex: 1,
    '&.MuiAlert-root': {
      borderRadius: 11,
      paddingTop: 0,
      paddingBottom: 0,
      alignItems: 'flex-start',
    },
  },
  container: {
    marginBottom: 30,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: 12,
    fontStyle: 'italic',
  },
  details: {
    fontSize: 12,
    fontWeight: 500,
  },
  toggleShow: {
    marginLeft: 8,
    fontSize: 12,
  },
}));

const KNOWN_ERRORS = ['Course with the same name already exists'];

export const ServerErrorNotification = () => {
  const { classes } = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);
  const [showMore, setShowMore] = useState(false);
  const {
    state: {
      serverError: { show, message },
    },
    dispatch,
  } = useAppContext();
  const [disableDispatch, setDisableDispatch] = useState(false);
  const location = useLocation();
  const handleToggleShow = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      setShowMore(!showMore);
    },
    [showMore],
  );

  useEffect(() => {
    // TODO: Why is this here?
    if (ref.current && !disableDispatch) {
      dispatch({
        type: ServerErrorTypes.SET_SERVER_ERROR_HEIGHT,
        payload: { show, message, height: ref.current.clientHeight + 16 },
      });
    }
  }, [dispatch, ref, show, showMore, message, disableDispatch]);

  useEffect(() => {
    setDisableDispatch(true);
    setShowMore(false);
    dispatch({
      type: ServerErrorTypes.REMOVE_SERVER_ERROR,
    });
  }, [dispatch, location]);

  const errorWithoutMore = KNOWN_ERRORS.includes(message);

  if (!show) {
    return null;
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Alert
          ref={ref}
          className={classes.root}
          severity='error'
          icon={<NotificationIcon severity='error' />}>
          <Box className={classes.titleContainer}>
            <Typography className={classes.title}>
              {!errorWithoutMore ? (
                <FormattedMessage
                  id='common.server_notification.title'
                  defaultMessage='Unfortunately, an error has occurred. Your changes could not be saved. '
                />
              ) : (
                <FormattedMessage id={message} />
              )}
            </Typography>
            {!errorWithoutMore && (
              <Link href='#' onClick={handleToggleShow} className={classes.toggleShow}>
                {!showMore && (
                  <FormattedMessage id='common.server_notification.more' defaultMessage='More' />
                )}
                {showMore && (
                  <FormattedMessage id='common.server_notification.less' defaultMessage='Less' />
                )}
              </Link>
            )}
          </Box>

          {showMore && !errorWithoutMore && (
            <Typography className={classes.details}>
              <FormattedMessage id={message} />
            </Typography>
          )}
        </Alert>
      </Grid>
    </Grid>
  );
};
