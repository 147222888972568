import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { AuthStateTypes, DataFetchingStatus } from '@/Types';

import { Login } from './Login';

export const LoginExtraReducers = (builder: ActionReducerMapBuilder<AuthStateTypes>) => {
  builder.addCase(Login.pending, state => {
    state.fetchingStatus = DataFetchingStatus.Fetching;
  });
  builder.addCase(Login.fulfilled, (state, action) => {
    state.fetchingStatus = DataFetchingStatus.FulFilled;
    state.isAuthenticated = true;
    state.cognitoUser = action.payload;
  });
  builder.addCase(Login.rejected, (state, action) => {
    state.fetchingStatus = DataFetchingStatus.HasError;
    state.isAuthenticated = false;
    state.credentials = null;

    if (action.payload) {
      state.errorMessage = action.payload as string;
    }
  });
};
