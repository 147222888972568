import moment from 'moment';
import 'moment/locale/de';
import { DayOption } from '@/Types';

export class WeekdayService {
  public getDisabledWeekdaysByRange(start: Date, end: Date): number[] {
    let disabledIds = [7, 1, 2, 3, 4, 5, 6];
    const diff = moment(end).diff(start, 'days');
    if (diff >= 6) {
      return [];
    } else {
      for (let i = 0; i < 7; i++) {
        const day = moment(start).add(i, 'days');

        if (!day.isAfter(end)) {
          disabledIds = [...disabledIds.filter(id => id !== day.isoWeekday())];
        }
      }

      return disabledIds;
    }
  }

  public hasLessThanSevenDays(start: Date, end: Date): boolean {
    const days = moment(end).diff(start, 'days');

    return days < 7;
  }

  public getWeekdayOptions(locale: string): DayOption[] {
    return moment
      .localeData(locale)
      .weekdays()
      .map((day, i) => {
        switch (i) {
          case 0:
            return { id: '7', shortDescription: day };
          case 1:
            return { id: '1', shortDescription: day };
          case 2:
            return { id: '2', shortDescription: day };
          case 3:
            return { id: '3', shortDescription: day };
          case 4:
            return { id: '4', shortDescription: day };
          case 5:
            return { id: '5', shortDescription: day };
          case 6:
            return { id: '6', shortDescription: day };
          default:
            return { id: '7', shortDescription: day };
        }
      });
  }
}
