import { Box } from '@mui/material';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { BodyTextSmall, MainHeadline } from '@/Components';
import ErrorIcon from '@/Static/Icons/atoms-symbols-alert-error.svg';

import { LoginPhotos } from '../Login/LoginPhotos';
import { ConfirmationStep } from '../RegisterPartner/Steps/ConfirmationStep';
import { useConfirmationEmailStyles } from './EmailConfirmed.styles';

const EmailConfirmed = ({ responseType }: { responseType: 'success' | 'expired' | 'error' }) => {
  const { classes } = useConfirmationEmailStyles();
  const { formatMessage } = useIntl();

  return (
    <>
      {' '}
      {responseType === 'success' ? (
        <ConfirmationStep isEmailConfirmed showHeader={false} />
      ) : (
        <>
          <Box className={classes.mainContent}>
            {responseType === 'expired' && (
              <>
                <img alt='' src={ErrorIcon} className={classes.headerIcon} />
                <MainHeadline
                  className={clsx(classes.headerForm)}
                  textAlign='center'
                  textContent={formatMessage({
                    id: 'form.register.stepper.step.confirm_mail.header.expired',
                    defaultMessage: 'This link has expired',
                  })}
                />
                <BodyTextSmall
                  textAlign='center'
                  textContent={formatMessage({
                    id: 'form.register.stepper.step.subheader.expired',
                    defaultMessage: 'Your link has expired. Link is only valid for 24 hours.',
                  })}
                  className={classes.subHeader}
                />
              </>
            )}
            {responseType === 'error' && (
              <>
                <img alt='' src={ErrorIcon} className={classes.headerIcon} />
                <MainHeadline
                  className={clsx(classes.headerForm)}
                  textAlign='center'
                  textContent={formatMessage({
                    id: 'form.register.stepper.step.confirm_mail.header.error',
                    defaultMessage: 'An error has occured',
                  })}
                />
                <BodyTextSmall
                  textAlign='center'
                  textContent={formatMessage({
                    id: 'form.register.stepper.step.subheader.error',
                    defaultMessage: 'Please contact us for support',
                  })}
                  className={classes.subHeader}
                />
              </>
            )}

            <LoginPhotos />
          </Box>
        </>
      )}
    </>
  );
};

export default EmailConfirmed;
