import { Dialog, IconButton } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from '@/Context';
import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';
import { getCoursesPathname, getDashboardPathname } from '@/Mappers';
import { useCurrentUserRoleQuery, useGetPartnerQuery } from '@/Queries';
import { PartnerCoursesServiceFactory } from '@/Services';
import CloseIcon from '@/Static/Icons/atoms-symbols-category-close-big.svg';
import FirstStepImage from '@/Static/Img/enable-course-manager-step-1-image.png';
import SecondStepImage from '@/Static/Img/enable-course-manager-step-2-image.png';
import ThirdStepImage from '@/Static/Img/enable-course-manager-step-3-image.png';

import { useCourseAndEventsStyles } from '../CoursesAndEvents.styles';
import { EnableCourseManagerDialogNoAccess } from './EnableCourse/EnableCourseManagerDialogNoAccess';
import { EnableCourseManagerDialogStepPrimary } from './EnableCourse/EnableCourseManagerDialogStepPrimary';
import { EnableCourseMangerDialogStepFinal } from './EnableCourse/EnableCourseMangerDialogStepFinal';

type ErrorTypeProps = {
  title: string;
  detail: string;
  type: string;
};

type EnableCourseManagerDialogProps = {
  publicId: string;
  setCustomToastMessage: React.Dispatch<React.SetStateAction<string>>;
  setDefaultToast: React.Dispatch<React.SetStateAction<boolean>>;
  setIsErrorToastIcon: React.Dispatch<React.SetStateAction<boolean>>;
  handleToastMessage: (value: boolean) => void;
};

export const EnableCourseManagerDialog = ({
  publicId,
  handleToastMessage,
  setCustomToastMessage,
  setDefaultToast,
  setIsErrorToastIcon,
}: EnableCourseManagerDialogProps) => {
  const intl = useIntl();
  const { dispatch } = useAppContext();
  const currentLanguage = useCurrentLanguage();
  const partnerCoursesService = new PartnerCoursesServiceFactory().getInstance(dispatch);
  const { classes } = useCourseAndEventsStyles();
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { data: role } = useCurrentUserRoleQuery();
  const { refetch: refetchPartnerData } = useGetPartnerQuery();

  const handleStep = useCallback((value: number) => {
    setStep(value);
  }, []);

  const handleEnableCourseManager = useCallback(async () => {
    if (partnerCoursesService) {
      const res = await partnerCoursesService.activateServicePackage(publicId, 'Online-Kurse');

      try {
        if (res.isAxiosError) {
          const errorData = res.response?.data as ErrorTypeProps;
          if (typeof errorData === 'object') {
            setDefaultToast(true);
            setIsErrorToastIcon(true);
            setCustomToastMessage(errorData.title);
          } else {
            console.error('Unknown error data:', errorData);
          }
          handleToastMessage(true);
        } else {
          await refetchPartnerData();
          navigate(getCoursesPathname(currentLanguage));
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }, [
    currentLanguage,
    handleToastMessage,
    navigate,
    partnerCoursesService,
    publicId,
    setCustomToastMessage,
    setDefaultToast,
    setIsErrorToastIcon,
    refetchPartnerData,
  ]);

  const handleRedirectToDashboard = useCallback(() => {
    const dashboard = getDashboardPathname(currentLanguage);
    navigate(dashboard);
  }, [currentLanguage, navigate]);

  if (role === 'User') {
    return (
      <Dialog
        open
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        PaperProps={{
          style: {
            borderRadius: 10,
            overflow: 'visible',
          },
        }}>
        <IconButton
          aria-label='close'
          onClick={handleRedirectToDashboard}
          className={classes.closeButton}>
          <img alt='' src={CloseIcon} />
        </IconButton>
        <EnableCourseManagerDialogNoAccess
          header={intl.formatMessage({
            id: 'course_manager.dialog.header',
            defaultMessage: 'Hansefit Course Manager',
          })}
          description={intl.formatMessage({
            id: 'course_manager.dialog.no_access',
            defaultMessage: 'No access',
          })}
        />
      </Dialog>
    );
  }

  if (role === 'Admin') {
    return (
      <Dialog
        open
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        PaperProps={{
          style: {
            borderRadius: 10,
            overflow: 'visible',
          },
        }}>
        <IconButton
          aria-label='close'
          onClick={handleRedirectToDashboard}
          className={classes.closeButton}>
          <img alt='' src={CloseIcon} />
        </IconButton>
        {step === 0 && (
          <EnableCourseManagerDialogStepPrimary
            imageSrc={FirstStepImage}
            handleStep={handleStep}
            header={intl.formatMessage({
              id: 'course_manager.dialog.header',
              defaultMessage: 'Hansefit Course Manager',
            })}
            description={intl.formatMessage({
              id: 'course_manager.dialog.description',
              defaultMessage:
                'With the course manager of Hansefit you can enter your online courses and give direct access to Hansefit members.',
            })}
            contentHeader={intl.formatMessage({
              id: 'course_manager.dialog.step_1.content_header',
              defaultMessage: 'Transfer courses in real time',
            })}
            contentDescription={intl.formatMessage({
              id: 'course_manager.dialog.step_1.content_description',
              defaultMessage: `Your online courses are transferred directly to the Hansefit app and are visible to Hansefit members.
                                  Make sure that your courses can actually take place.`,
            })}
            currentStep={step}
          />
        )}

        {step === 1 && (
          <EnableCourseManagerDialogStepPrimary
            imageSrc={SecondStepImage}
            handleStep={handleStep}
            header={intl.formatMessage({
              id: 'course_manager.dialog.header',
              defaultMessage: 'Hansefit Course Manager',
            })}
            description={intl.formatMessage({
              id: 'course_manager.dialog.description',
              defaultMessage:
                'With the course manager of Hansefit you can enter your online courses and give direct access to Hansefit members.',
            })}
            contentHeader={intl.formatMessage({
              id: 'course_manager.dialog.step_2.content_header',
              defaultMessage: 'All applications visible',
            })}
            contentDescription={intl.formatMessage({
              id: 'course_manager.dialog.step_2.content_description',
              defaultMessage:
                'In the appointment list you have a quick overview of your registrations.\nYou can view the individual registrations\nfor each appointment and export them\nexport them as a list.',
            })}
            currentStep={step}
          />
        )}

        {step === 2 && (
          <EnableCourseManagerDialogStepPrimary
            imageSrc={ThirdStepImage}
            handleStep={handleStep}
            header={intl.formatMessage({
              id: 'course_manager.dialog.header',
              defaultMessage: 'Hansefit Course Manager',
            })}
            description={intl.formatMessage({
              id: 'course_manager.dialog.description',
              defaultMessage:
                'With the course manager of Hansefit you can enter your online courses and give direct access to Hansefit members.',
            })}
            contentHeader={intl.formatMessage({
              id: 'course_manager.dialog.step_3.content_header',
              defaultMessage: 'Check-in value for\nOnline courses',
            })}
            contentDescription={intl.formatMessage({
              id: 'course_manager.dialog.step_3.content_description',
              defaultMessage:
                'For your online courses\nthe check-ins with 50% of the\nof the agreed value.',
            })}
            currentStep={step}
          />
        )}

        {step === 3 && (
          <EnableCourseMangerDialogStepFinal
            handleEnableCourseManager={handleEnableCourseManager}
            header={intl.formatMessage({
              id: 'course_manager.dialog.header',
              defaultMessage: 'Hansefit Course Manager',
            })}
            description={intl.formatMessage({
              id: 'course_manager.dialog.description',
              defaultMessage:
                'With the course manager of Hansefit you can enter your online courses and give direct access to Hansefit members.',
            })}
            currentStep={step}
          />
        )}
      </Dialog>
    );
  }

  return null;
};
