import {
  Container,
  HeaderContent,
  SubContentWrapper,
  SubHeader,
  Wrapper,
} from './NoRowsOverlay.styles';

type EmptyTableProps = {
  header: string;
  subHeader: string;
};

export const NoRowsOverlay = ({ header, subHeader }: EmptyTableProps) => (
  <Container>
    <Wrapper>
      <HeaderContent variant='body2'>{header}</HeaderContent>
      <SubContentWrapper>
        <SubHeader variant='body2'>{subHeader}</SubHeader>
      </SubContentWrapper>
    </Wrapper>
  </Container>
);
