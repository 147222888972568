import { IntlShape } from 'react-intl';

export enum BtnLabelsTypes {
  SAVE_EVENT = 'view.courses_and_events.course.save_and_add_event.cta',
  BACK = 'view.courses_and_events.course.save_and_add_event.cta.back',
  PUBLISH = 'cta.save_and_publish',
  ARCHIVE = 'view.courses_and_events.course.archive.cta',
  DRAFT = 'view.courses_and_events.event.save_as_draft.cta',
  SAVE_CHANGES = 'cta.save_changes',
}

export const getButtonLabel = (intl: IntlShape, btnType: BtnLabelsTypes) => {
  switch (btnType) {
    case BtnLabelsTypes.SAVE_EVENT:
      return intl.formatMessage({
        id: BtnLabelsTypes.SAVE_EVENT,
        defaultMessage: 'Save & Add Appointment',
      });

    case BtnLabelsTypes.SAVE_CHANGES:
      return intl.formatMessage({
        id: BtnLabelsTypes.SAVE_CHANGES,
        defaultMessage: 'Save changes',
      });
    case BtnLabelsTypes.BACK:
      return intl.formatMessage({
        id: BtnLabelsTypes.BACK,
        defaultMessage: 'Back',
      });
    case BtnLabelsTypes.PUBLISH:
      return intl.formatMessage({
        id: BtnLabelsTypes.PUBLISH,
        defaultMessage: 'Save & Publish',
      });
    case BtnLabelsTypes.ARCHIVE:
      return intl.formatMessage({
        id: BtnLabelsTypes.ARCHIVE,
        defaultMessage: 'Archive course',
      });
    case BtnLabelsTypes.DRAFT:
      return intl.formatMessage({
        id: BtnLabelsTypes.DRAFT,
        defaultMessage: 'Save as draft',
      });

    default:
      return '';
  }
};
