import { Box, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { de, enGB } from 'date-fns/locale';
import moment from 'moment';
import { useMemo, useState } from 'react';

import { useHeaderTableStyles } from '@/App/Shared/DataGridTable/Table/Table.styles';
import { BodyTextSmall, Header, SubHeadlineDisplay } from '@/Components';
import useStaticStatisticBreadcrumbs from '@/Hooks/Breadcrumbs/useStaticStatisticBreadcrumbs';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { getStaticPathname } from '@/Mappers';
import { useCheckInStatsQuery, useGetPartnerQuery } from '@/Queries';
import {
  CheckedOnDayType,
  DateSelectionType,
  ExtendedCheckedOnDayType,
  GetOverviewStatsResponseType,
  StatisticsType,
} from '@/Types';
import { defaultStatisticsRange, formatDate, formatRowValue } from '@/Utils';

import { ContentContainer } from '../../App/Shared/ContentContainer/ContentContainer';
import CheckInTable from './Components/CheckInTable';

const CheckInAll = () => {
  const { classes } = useHeaderTableStyles();
  const currentLanguage = useCurrentLanguage();
  const { formatMessage } = useMemoedIntl();
  const { data: partnerData } = useGetPartnerQuery();

  const [dates, setDates] = useState<DateSelectionType>(defaultStatisticsRange);

  const { data, isLoading } = useCheckInStatsQuery<GetOverviewStatsResponseType>({
    partnerPublicId: partnerData?.publicId,
    statsType: StatisticsType.Overview,
    endDate: dates.endDate && formatDate(dates.endDate),
    startDate: dates.startDate && formatDate(dates.startDate),
  });

  const customData = useMemo(() => {
    if (!data?.data || !Object.keys(data?.data || {}).length) return { rows: [], totalCheckins: 0 };

    let totalCheckins = 0;

    const rows = Object.entries(data.data).reduce((acc, [key, checkinsByDayRows]) => {
      checkinsByDayRows.forEach((row: CheckedOnDayType) => {
        acc.push({
          checkedInDateTime: moment(
            `${key} ${formatRowValue(row.check_in_time, 'TIME')}`,
            'YYYYMMDD HH:mm',
          ).valueOf(),
          checkedOutDateTime: row.check_out_time
            ? moment(
                `${key} ${formatRowValue(row.check_out_time, 'TIME')}`,
                'YYYYMMDD HH:mm',
              ).valueOf()
            : null,
          ...row,
          id: `${key}-${row.check_in_time}-${row.check_out_time}-${row.checkin_type}-${row.company_name}-${row.eu_id}`,
        });
      });

      totalCheckins += checkinsByDayRows.length;

      return acc;
    }, [] as ExtendedCheckedOnDayType[]);

    return { rows, totalCheckins };
  }, [data?.data]);

  const staticStatisticBreadcrumbs = useStaticStatisticBreadcrumbs();
  const currentBreadcrumbs = useMemo(
    () => [
      ...staticStatisticBreadcrumbs,
      {
        title: formatMessage({
          id: 'statistic.title.all_check_in',
          defaultMessage: 'Alle Check-Ins',
        }),
        href: getStaticPathname(currentLanguage, 'check-ins-alle'),
      },
    ],
    [staticStatisticBreadcrumbs, currentLanguage, formatMessage],
  );

  return (
    <>
      <Header
        showLanguageSwitcher={false}
        header={formatMessage({
          id: 'statistic.title.all_check_in',
          defaultMessage: 'Alle Check-Ins',
        })}
        breadcrumbs={currentBreadcrumbs}
      />

      <Box mt={4}>
        <div
          style={{
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <Box
            className={classes.checkIn}
            mb={2}
            style={{
              width: 'fit-content',
            }}>
            <BodyTextSmall
              fontWeight={500}
              textContent={`${formatMessage({
                id: 'view.statistic.company.number_check_in',
                defaultMessage: 'Number of check-ins',
              })}: `}
            />
            <SubHeadlineDisplay textContent={customData.totalCheckins.toString()} />
          </Box>
        </div>
        <ContentContainer padding={0}>
          <Grid item xs={12}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={currentLanguage === 'en' ? enGB : de}>
              <CheckInTable
                data={customData.rows}
                setDateRange={setDates}
                dateRange={dates}
                isLoading={isLoading}
              />
            </LocalizationProvider>
          </Grid>
        </ContentContainer>
      </Box>
    </>
  );
};

export default CheckInAll;
