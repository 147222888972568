import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppContext } from '@/Context';
import { queryClient } from '@/Helpers';
import { AssetServiceFactory } from '@/Services';
import { GalleryAsset, Partner } from '@/Types';

type useAssignLogoProps = {
  casPublicId: Partner['casPublicId'];
  newGallery: {
    gallery: GalleryAsset[];
  };
};

const useUpdateGalleryAssetMutation = () => {
  const { dispatch } = useAppContext();
  const assetService = useMemo(() => new AssetServiceFactory().getInstance(dispatch), [dispatch]);

  return useMutation(
    ({ casPublicId, newGallery }: useAssignLogoProps) =>
      assetService.updateGalleryAsset(casPublicId, newGallery),
    {
      onSuccess(response) {
        queryClient.setQueryData(['getAssetsQuery'], response.data);
      },
    },
  );
};

export default useUpdateGalleryAssetMutation;
