import { Theme } from '@mui/material';

import { CheckInCheckoutEventType } from '@/Types';

import FigmaDesignTokens from '../design/design-tokens.json';

export const checkInColorHelper = (data: CheckInCheckoutEventType['data'], theme: Theme) => {
  if (data.checkInTimestamp && !data.checkOutTimestamp) {
    return theme.palette.success.light;
  }

  if (data.offline) {
    return FigmaDesignTokens.Orange[50];
  }

  return theme.palette.background.default;
};

export const checkInStatusHelper = (data: CheckInCheckoutEventType['data']) => {
  if (data.checkInTimestamp && !data.checkOutTimestamp) {
    return 'active';
  }

  if (data.offline) {
    return 'offline';
  }

  return 'default';
};
