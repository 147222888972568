import { Box, MenuItem, Popover } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useCurrentPartnerIdQuery } from '@/Queries';
import { EventsService } from '@/Services';
import Menu from '@/Static/Icons/menu.svg';
import { uiActions } from '@/Store';
import { EventListItem } from '@/Types';
import MobileAppPreview from '@/Views/MobileAppPreview';

import { AlertMenuItem, EditButton } from './ActionsCell.styles';

type ActionsCellProps = {
  isOnsiteCourseType: boolean;

  gridRenderCellParams: GridRenderCellParams<EventListItem, number>;

  handleEditEvent: (eventIds: string[]) => void;
  setViewRegistrationModalOpen: (open: boolean) => void;
  setActionRow: (event: EventListItem) => void;
  handlePublishEvents: (eventIds: string[]) => void;
  handleCancelEvents: (eventIds: string[]) => void;
  handleEditEventStreamData: (eventIds: string[]) => void;
  updateEvents: () => void;
};

export const ActionsCell = ({
  gridRenderCellParams,
  handleEditEvent,
  setViewRegistrationModalOpen,
  setActionRow,
  handlePublishEvents,
  handleCancelEvents,
  handleEditEventStreamData,
  updateEvents,
  isOnsiteCourseType,
}: ActionsCellProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [previewApp, setPreviewApp] = useState(false);
  const { data: casPublicId = '' } = useCurrentPartnerIdQuery();

  useEffect(() => {
    document.body.style.overflow = previewApp ? 'hidden' : 'unset';
  }, [previewApp]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setActionRow(gridRenderCellParams.row);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleEdit = (row: EventListItem) => {
    handleEditEvent([row.eventId?.toString()]);
    handleClose();
  };

  const handleEditStream = (row: EventListItem) => {
    handleEditEventStreamData([row.eventId?.toString()]);
    handleClose();
  };

  const handlePublish = (row: EventListItem) => {
    handlePublishEvents([row.eventId?.toString()]);
    handleClose();
  };

  const handleCancel = (row: EventListItem) => {
    handleCancelEvents([row.eventId?.toString()]);
    handleClose();
  };

  const showEditCourseAddressInfoModal = useCallback(() => {
    dispatch(
      uiActions.showModal('EDIT_ADDRESS_INFORMATION_COURSE', {
        values: gridRenderCellParams.row,
        casPublicId,
        updateEvents,
      }),
    );

    handleClose();
  }, [casPublicId, dispatch, gridRenderCellParams.row, updateEvents]);

  return (
    <Box>
      {previewApp && (
        <MobileAppPreview
          courseId={gridRenderCellParams.row.courseId.toString()}
          courseDate={gridRenderCellParams.row.date}
          courseTime={gridRenderCellParams.row.time}
          courseDuration={gridRenderCellParams.row.duration}
          streamLink={gridRenderCellParams.row.streamLink}
          streamPassword={gridRenderCellParams.row.streamPassword}
          streamingHost={gridRenderCellParams.row.streamingHost}
          additionaInfo={gridRenderCellParams.row.additionalInformation}
          meetingId={gridRenderCellParams.row.meetingId}
          onsiteAddress={{
            city: gridRenderCellParams.row.onsiteAddress?.city,
            street: gridRenderCellParams.row.onsiteAddress?.street,
            number: gridRenderCellParams.row.onsiteAddress?.streetNumber,
            postalCode: gridRenderCellParams.row.onsiteAddress?.postcode,
          }}
          handleClose={() => setPreviewApp(false)}
        />
      )}
      <EditButton onClick={handleClick}>
        <img alt='' src={Menu} />
      </EditButton>
      <Popover
        anchorEl={anchorEl}
        id={gridRenderCellParams.row.eventId.toString()}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        {gridRenderCellParams.row.bookings > 0 && (
          <MenuItem
            onClick={() => {
              setViewRegistrationModalOpen(true);
              handleClose();
            }}>
            {intl.formatMessage({
              id: 'view.course.table.action.view_registrations',
              defaultMessage: 'View registrations',
            })}
          </MenuItem>
        )}
        {!EventsService.isCanceled(gridRenderCellParams.row) && (
          <>
            <MenuItem onClick={() => handleEdit(gridRenderCellParams.row)}>
              <>
                {isOnsiteCourseType
                  ? intl.formatMessage({
                      id: 'view.courses_and_events.events.cta.edit_appointments_address',
                      defaultMessage: 'Edit appointments',
                    })
                  : intl.formatMessage({
                      id: 'view.courses_and_events.events.cta.edit_event',
                      defaultMessage: 'Edit event',
                    })}
              </>
            </MenuItem>

            <MenuItem
              onClick={() =>
                isOnsiteCourseType
                  ? showEditCourseAddressInfoModal()
                  : handleEditStream(gridRenderCellParams.row)
              }>
              {isOnsiteCourseType ? (
                <>
                  {isEmpty(gridRenderCellParams.row.courseType)
                    ? intl.formatMessage({
                        id: 'view.courses_and_events.events.cta.add_address_information',
                        defaultMessage: 'Add address information',
                      })
                    : intl.formatMessage({
                        id: 'view.courses_and_events.events.cta.edit_address_information',
                        defaultMessage: 'Edit address information',
                      })}
                </>
              ) : (
                <>
                  {gridRenderCellParams.row.streamLink
                    ? intl.formatMessage({
                        id: 'view.courses_and_events.events.cta.edit_streaming_data',
                        defaultMessage: 'Edit streaming data',
                      })
                    : intl.formatMessage({
                        id: 'view.courses_and_events.events.cta.add_streaming_data',
                        defaultMessage: 'Add streaming data',
                      })}
                </>
              )}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setPreviewApp(true);
              }}>
              {intl.formatMessage({
                id: 'view.courses_and_events.events.cta.mobile_app_preview',
                defaultMessage: 'Course preview display',
              })}
            </MenuItem>
          </>
        )}

        {EventsService.isDraft(gridRenderCellParams.row) && (
          <MenuItem onClick={() => handlePublish(gridRenderCellParams.row)}>
            {intl.formatMessage({
              id: 'view.course.table.action.publish_event',
              defaultMessage: 'Publish event',
            })}
          </MenuItem>
        )}

        {EventsService.isPublished(gridRenderCellParams.row) && (
          <AlertMenuItem onClick={() => handleCancel(gridRenderCellParams.row)}>
            {isOnsiteCourseType
              ? intl.formatMessage({
                  id: 'view.course.table.action.cancel_appointments_address',
                  defaultMessage: 'Cancel appointments',
                })
              : intl.formatMessage({
                  id: 'view.course.table.action.cancel_event',
                  defaultMessage: 'Cancel event',
                })}
          </AlertMenuItem>
        )}
      </Popover>
    </Box>
  );
};
