import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { EventListItem } from '@/Types';
import { InternalCourseName } from './InternalCourseNameCell.styles';

type InternalCourseNameCellProps = {
  gridRenderCellParams: GridRenderCellParams<EventListItem, number>;
};

export const InternalCourseNameCell = ({ gridRenderCellParams }: InternalCourseNameCellProps) => (
  <InternalCourseName>{gridRenderCellParams.value}</InternalCourseName>
);
