import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';
import { EnableCourseManagerDialogFooter } from './EnableCourseManagerDialogFooter';
import { EnableCourseManagerDialogStepper } from './EnableCourseManagerDialogStepper';

export interface EnableCourseManagerDialogBodyProps {
  children: React.ReactNode;
  header: string;
  description: string;
  currentStep: number;
}

const useStyles = makeStyles()(() => ({
  mainContent: {
    width: 624,
    borderRadius: 10,
    boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: FigmaDesignTokens.White,
    margin: '0 auto',
    padding: '20px 60px',
    paddingTop: 37,
    position: 'relative',
  },
  header: {
    textAlign: 'center',
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 25,
    fontWeight: 900,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: 0.5,
    color: FigmaDesignTokens.Blue[950],
  },
  subHeader: {
    marginTop: 4,
    fontSize: 14,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: 0.18,
    color: FigmaDesignTokens.Grey[950],
    textAlign: 'center',
  },
  stepperContainer: {
    width: 332,
    marginTop: 32,
  },
  content: {
    backgroundColor: FigmaDesignTokens.Grey[200],
    borderRadius: 9,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 30,
  },
  footerContainer: {
    paddingTop: 4,
  },
}));

export const EnableCourseManagerDialogBody = ({
  header,
  description,
  currentStep,
  children,
}: EnableCourseManagerDialogBodyProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.mainContent}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid>
            <Typography variant='body1' className={classes.header}>
              {header.toUpperCase()}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant='body2' className={classes.subHeader}>
              {description}
            </Typography>
          </Grid>
          <Grid alignItems='center' container direction='column'>
            <Box className={classes.stepperContainer}>
              <EnableCourseManagerDialogStepper activeStep={currentStep} />
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.content}>
            {children}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.footerContainer}>
          <EnableCourseManagerDialogFooter />
        </Grid>
      </Grid>
    </Box>
  );
};
