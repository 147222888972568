import { Box, styled, Typography } from '@mui/material';

import FigmaDesignTokens from '../../../../design/design-tokens.json';
import { EVENT_LIST_VIEW } from '../../../../Hooks/useEventsTableView';
import { withTransientProps } from '../../../Theme/withTransientProps';

export const Container = styled(Box, withTransientProps)<{ $view?: EVENT_LIST_VIEW }>`
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${FigmaDesignTokens.Grey[100]};
  margin: ${props => (props.$view === EVENT_LIST_VIEW.DASHBOARD ? '0' : '100px 110px 181px 110px')};
  border-radius: 4px;
  padding: 87px;
  text-align: center;
  position: relative;
  z-index: 10;
`;

export const SubHeader = styled(Typography)`
  margin-top: 2px;
`;

export const CtaContainer = styled(Box)`
  margin-top: 25px;
`;
