import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { Logout } from './Logout';
import { DataFetchingStatus, AuthStateTypes } from '@/Types';

export const LogoutExtraReducers = (builder: ActionReducerMapBuilder<AuthStateTypes>) => {
  builder.addCase(Logout.pending, state => {
    state.fetchingStatus = DataFetchingStatus.Fetching;
  });
  builder.addCase(Logout.fulfilled, state => {
    state.fetchingStatus = DataFetchingStatus.FulFilled;
    state.isAuthenticated = false;
    state.partnerRoles = [];
  });
  builder.addCase(Logout.rejected, state => {
    state.fetchingStatus = DataFetchingStatus.HasError;
    state.isAuthenticated = false;
    state.partnerRoles = [];
  });
};
