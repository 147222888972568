import fp from 'lodash/fp';
import * as modals from '../Modals';
import { modalNameToConstant } from '@/Utils';

const MODAL_LOOKUP = fp.flow(
  fp.entries,
  fp.map(webpackImport => {
    const [name, component] = webpackImport;
    return { [modalNameToConstant(name)]: component };
  }),
  fp.reduce(fp.merge, {}),
)(modals) as { [key: string]: React.FC };

export default MODAL_LOOKUP;
