import { useMemo } from 'react';

import { getCurrentCoursesPathname, getDashboardPathname } from '@/Mappers';
import { BreadcrumbProps } from '@/Types';

import useCurrentLanguage from '../useCurrentLanguage';
import useMemoedIntl from '../useMemoedIntl';

const useStaticCourseOverviewBreadcrumbs = (): BreadcrumbProps[] => {
  const { formatMessage } = useMemoedIntl();
  const currentLanguage = useCurrentLanguage();

  const staticBreadcrumbs = useMemo(
    () => [
      {
        title: formatMessage({ id: 'view.dashboard', defaultMessage: 'Dashboard' }),
        href: getDashboardPathname(currentLanguage),
      },
      {
        title: formatMessage({
          id: 'breadcrumbs.course_overview',
          defaultMessage: 'Course overview',
        }),
        href: getCurrentCoursesPathname(currentLanguage),
      },
    ],
    [formatMessage, currentLanguage],
  );

  return staticBreadcrumbs;
};

export default useStaticCourseOverviewBreadcrumbs;
