import { Box, Grid, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactNode, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { makeStyles, withStyles } from 'tss-react/mui';

import { InfoMessage } from '@/Components';
import { AssetService } from '@/Services';
import Drag from '@/Static/Icons/atoms-symbols-icons-media-drag.svg';
import Info from '@/Static/Icons/atoms-symbols-icons-media-info-i.svg';
import { GalleryAsset, Partner } from '@/Types';
import handleCatchError from '@/Utils/handleCatchError';

import FigmaDesignTokens from '../../../design/design-tokens.json';
import { useSortableItemStyles } from './Gallery.styles';
import { GalleryImageTile } from './GalleryImageTile';

type GalleryListProps = {
  gallery: GalleryAsset[];
  latestUploads: GalleryAsset[];
  handleGallery: (value: GalleryAsset[]) => void;
  casPublicId: Partner['casPublicId'];
  assetService: AssetService;
  handleReplaceImage: (
    idx: number,
    uri: string,
    assetId: number,
    newOriginalFilename: string | null,
  ) => void;
  handleAssetIdDeleteQueue: (assetId: number) => void;
  handlePrimaryCtaDisabled: (value: boolean) => void;
};

const useDragStyles = makeStyles()(() => ({
  grab: {
    '&:hover': {
      cursor: 'grab',
    },
  },
}));

const DragHandle = SortableHandle(() => {
  const { classes } = useDragStyles();

  return (
    <span>
      <img alt='' className={classes.grab} src={Drag} />
    </span>
  );
});

export interface SortableItemProps {
  casPublicId: Partner['casPublicId'];
  idx: number;
  resourceData: GalleryAsset;
  handleRemoveImage: (value: number) => void;
  dragComponent: ReactNode;
  assetService: AssetService;
  latestUploads: GalleryAsset[];
  handleReplaceImage: (
    idx: number,
    uri: string,
    assetId: number,
    newOriginalFilename: string | null,
  ) => void;
  handlePrimaryCtaDisabled: (value: boolean) => void;
}

const SortableItem = SortableElement(
  ({
    casPublicId,
    idx,
    resourceData,
    handleRemoveImage,
    dragComponent,
    assetService,
    latestUploads,
    handleReplaceImage,
    handlePrimaryCtaDisabled,
  }: SortableItemProps) => (
    <Grid item>
      <GalleryImageTile
        handleRemoveImage={handleRemoveImage}
        dragComponent={dragComponent}
        latestUploads={latestUploads}
        casPublicId={casPublicId}
        idx={idx}
        resourceData={resourceData}
        assetService={assetService}
        handleReplaceImage={handleReplaceImage}
        handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
      />
    </Grid>
  ),
);

export interface SortableListProps {
  gallery: GalleryAsset[];
  handleRemoveImage: (assetId: number) => void;
  casPublicId: Partner['casPublicId'];
  assetService: AssetService;
  latestUploads: GalleryAsset[];
  handleReplaceImage: (
    idx: number,
    uri: string,
    assetId: number,
    newOriginalFilename: string | null,
  ) => void;
  handlePrimaryCtaDisabled: (value: boolean) => void;
}

const MainImageTooltip = withStyles(Tooltip, () => ({
  tooltip: {
    backgroundColor: FigmaDesignTokens.White,
    boxShadow: '0 -10px 20px 0 rgba(0, 0, 0, 0.1)',
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.11,
    color: FigmaDesignTokens.Blue[950],
    maxWidth: 230,
    padding: 20,
    borderRadius: 12,
    marginLeft: 16,
  },
}));

const SortableList = SortableContainer(
  ({
    gallery,
    handleRemoveImage,
    casPublicId,
    assetService,
    latestUploads,
    handleReplaceImage,
    handlePrimaryCtaDisabled,
  }: SortableListProps) => {
    const { classes } = useSortableItemStyles();
    const { formatMessage } = useIntl();

    return (
      <Grid container spacing={2}>
        <Box className={classes.activeContainer}>
          <Typography className={classes.mainImageLabel}>
            <FormattedMessage
              id='form.photo_and_video.photo_upload.image.main'
              defaultMessage='Cover'
            />
          </Typography>
          <MainImageTooltip
            placement='right'
            title={formatMessage({
              id: 'form.photo_and_video.photo_upload.image.main.tooltip.title',
              defaultMessage:
                'The title image is displayed as the first image on your studio details page. You can change it by clicking "Replace image".',
            })}
            className={classes.tooltipRoot}>
            <img alt='' className={classes.tooltip} src={Info} />
          </MainImageTooltip>
        </Box>

        {gallery.map((value, index) => (
          <SortableItem
            handleReplaceImage={handleReplaceImage}
            latestUploads={latestUploads}
            assetService={assetService}
            idx={index}
            casPublicId={casPublicId}
            key={`item-${value.assetId}`}
            index={index}
            resourceData={value}
            handleRemoveImage={handleRemoveImage}
            dragComponent={<DragHandle />}
            handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
          />
        ))}

        <Grid item xs={3}>
          <Box
            className={clsx({
              [classes.hintBoxWrapper]: gallery.length < 2,
              [classes.adjustHintBox]:
                (gallery.length === 1 && gallery[0].assetId) || gallery.length > 1,
            })}>
            <InfoMessage
              title={formatMessage({
                id: 'form.photo_and_video.photo_upload.hint.number_of_images.header',
                defaultMessage: 'Max. 10 images ',
              })}
              description={formatMessage({
                id: 'form.photo_and_video.photo_upload.hint.number_of_images.description',
                defaultMessage:
                  'You can upload up to 10 images with a maximum size of 12 MB per image.',
              })}
              type='information'
            />
          </Box>
        </Grid>
      </Grid>
    );
  },
);

const galleryListStyles = makeStyles()(() => ({
  helper: {
    zIndex: 4,
  },
}));
export const GalleryList = ({
  gallery,
  handleGallery,
  casPublicId,
  assetService,
  latestUploads,
  handleReplaceImage,
  handleAssetIdDeleteQueue,
  handlePrimaryCtaDisabled,
}: GalleryListProps) => {
  const styles = galleryListStyles().classes;

  const handleRemoveImage = useCallback(
    (assetId: number) => {
      try {
        let galleryAssets;

        if (gallery.length === 1) {
          gallery[0].assetId = null;
          gallery[0].uri = null;
          gallery[0].originalFilename = null;
          gallery[0].title.de = null;
          gallery[0].title.en = null;
          galleryAssets = [...gallery];
        } else {
          galleryAssets = gallery.filter(galleryImage => galleryImage.assetId !== assetId);
        }

        if (galleryAssets) {
          handleGallery(galleryAssets);
          handleAssetIdDeleteQueue(assetId);
        }
      } catch (error: unknown) {
        handleCatchError(error);
      }
    },
    [gallery, handleAssetIdDeleteQueue, handleGallery],
  );

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (newIndex === 0 && !gallery[0].assetId) {
      const tmpArrayElement = { ...gallery[oldIndex] };
      gallery.splice(oldIndex, 1);
      gallery[newIndex] = tmpArrayElement;
      handleGallery([...gallery]);
    } else {
      handleGallery([...arrayMove(gallery, oldIndex, newIndex)]);
    }
  };

  return (
    <SortableList
      latestUploads={latestUploads}
      assetService={assetService}
      useDragHandle
      axis='xy'
      gallery={gallery}
      helperClass={styles.helper}
      handleRemoveImage={handleRemoveImage}
      onSortEnd={onSortEnd}
      casPublicId={casPublicId}
      handleReplaceImage={handleReplaceImage}
      handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
    />
  );
};
