import { IsFirstTranslationType } from '@/Types';

import useReadStorage from './useReadStorage';
import { useStorage } from './useStorage';

/***
 * just for showing a notification ... this...
 * {showLanguageSwitcher && isFirstTranslation && (
            <TranslateNotification handleClose={handleAccept} />
          )}

 */
const useIsFirstTranslation = () => {
  const firstTranslation = useReadStorage<IsFirstTranslationType>(
    'vpp_is_first_translation',
    'SESSION_STORAGE',
  );
  const [isFirstTranslation, setIsFirstTranslation] = useStorage(
    'vpp_is_first_translation',
    firstTranslation,
    'SESSION_STORAGE',
  );

  return { isFirstTranslation, setIsFirstTranslation };
};

export default useIsFirstTranslation;
