import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@/Context';
import { PartnerServiceFactory } from '@/Services';

const useGetPartnerNamesQuery = () => {
  const { dispatch } = useAppContext();

  const queryResult = useQuery(
    ['getPartnersNames'],
    async () => {
      const partnerServiceInstance = new PartnerServiceFactory().getInstance(dispatch);

      const response = await partnerServiceInstance.getPartnersNames();

      if (!response?.data?.length) {
        return [];
      }

      return response.data.sort((a, b) => a.studioName.localeCompare(b.studioName));
    },
    {
      cacheTime: 0,
      staleTime: 3 * 60 * 1000,
    },
  );

  return queryResult;
};

export default useGetPartnerNamesQuery;
