import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { Authenticate } from './Authenticate';
import { DataFetchingStatus, AuthStateTypes } from '@/Types';

export const AuthenticateExtraReducers = (builder: ActionReducerMapBuilder<AuthStateTypes>) => {
  builder.addCase(Authenticate.pending, state => {
    state.fetchingStatus = DataFetchingStatus.Fetching;
  });
  builder.addCase(Authenticate.fulfilled, (state, { payload }) => {
    state.fetchingStatus = DataFetchingStatus.FulFilled;
    state.isAuthenticated = true;
  });
  builder.addCase(Authenticate.rejected, state => {
    state.fetchingStatus = DataFetchingStatus.HasError;
    state.isAuthenticated = false;
  });
};
