import * as React from 'react';
import Popover from '@mui/material/Popover';
import Button, { ButtonProps } from '@mui/material/Button';

const BasicPopover = ({
  children,
  buttonVariant,
  buttonLabel,
  disabled,
}: {
  children: React.ReactNode;
  buttonVariant?: ButtonProps['variant'];
  buttonLabel: string | React.ReactNode;
  disabled?: boolean;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant={buttonVariant}
        onClick={handleClick}
        disabled={disabled}>
        {buttonLabel}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        {children}
      </Popover>
    </div>
  );
};

export default BasicPopover;
