import { Box } from '@mui/system';
import { Search } from './Search';
import {
  FilterButton,
  GridToolbarWrapper,
} from '@/App/Shared/DataGridTable/Toolbar/Toolbar.styles';
import { Button } from '@/Components';
import { ReactComponent as BluePlusIcon } from '@/Static/Icons/bluePlusIcon.svg';
import { useToolbarStyles } from './Search.styles';

type ToolbarProps = {
  showSearch?: boolean;
  searchFields?: string[];
  handleSubmit: () => void;
  submitText?: string;
};

export const Toolbar = ({
  showSearch = false,
  searchFields = [],
  handleSubmit,
  submitText,
}: ToolbarProps) => {
  const { classes } = useToolbarStyles();

  return (
    <GridToolbarWrapper>
      {showSearch && <Search searchFields={searchFields} />}

      <FilterButton />

      {handleSubmit && submitText && (
        <Box className={classes.ctaWrapper}>
          <Button
            onClick={handleSubmit}
            className={classes.inviteNewButton}
            icon={<BluePlusIcon />}
            textTransform>
            {submitText}
          </Button>
        </Box>
      )}
    </GridToolbarWrapper>
  );
};
