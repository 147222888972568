import { useEffect, useState } from 'react';
import moment from 'moment/moment';

import { EventListItem } from '@/Types';

export const useEventsModalHelper = (eventIds: string[], events: EventListItem[]) => {
  const [subHeader, setSubHeader] = useState<string>('');
  const [selectedEvents, setSelectedEvents] = useState<EventListItem[]>([]);
  const [hasPublishedEvents, setHasPublishedEvents] = useState<boolean>(false);
  const [hasEventsWithBookings, setHasEventsWithBookings] = useState<boolean>(false);

  useEffect(() => {
    const filteredEvents = events.filter(
      event => eventIds.indexOf(event.eventId.toString()) !== -1,
    );

    const eventsWithBookings = filteredEvents.filter(event => event.bookings > 0);
    const publishedEvents = filteredEvents.filter(event => event.published);

    setHasEventsWithBookings(eventsWithBookings.length > 0);
    setHasPublishedEvents(publishedEvents.length > 0);
    if (filteredEvents.length === 1) {
      const formattedSubHeader =
        moment(filteredEvents[0].date).format('dddd, DD.MM.YYYY') +
        ', ' +
        filteredEvents[0].time +
        ' Uhr';
      setSubHeader(formattedSubHeader);
    } else {
      const formattedSubheader = filteredEvents
        .map(event => moment(event.date).format(' DD.MM'))
        .join(', ');

      setSubHeader(formattedSubheader);
    }

    setSelectedEvents(filteredEvents);
  }, [eventIds, eventIds.length, events]);

  return { subHeader, selectedEvents, hasPublishedEvents, hasEventsWithBookings };
};
