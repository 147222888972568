import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogActions, DialogContent } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

import postHubspotSupportForm from '@/api/postHubspotSupportForm';
import useModalStyles from '@/App/Shared/Modal/ModalStyles';
import theme from '@/App/Theme/Theme';
import { useAppDispatch } from '@/Hooks/useAppDispatch';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import useGetHubspotDataQuery from '@/Queries/useGetHubspotDataQuery';
import { AuthActions } from '@/Store/Auth';
import {
  FieldFormType,
  FormFieldGroup,
  HubspotField,
  HubspotSupportForm as HubspotSupportFormType,
  ObjectTypeId,
  PropertyObjectType,
} from '@/Types';
import { formatBytes } from '@/Utils';
import { validateFileExtension, validateFileSize } from '@/Utils/validationHelpers';

import { Button } from '../../../Components/Button';
import HubspotEditForm from '../../../Components/Forms/HubspotForm';
import { LineDivider } from '../../../Components/LineDivider';
import { BodyTextRegular, DisplayTextAccent, PageHeadline } from '../../../Components/Typography';

export const ANCHOR_ID = 'hubspot_support_form';
// Proper type it with all possible fields instead of just string
type ExtractedFieldName = HubspotField['name'];
export type HubspotForm = Record<ExtractedFieldName, string | string[] | FileList>;
//types for dynamical depending fields
export type CustomDependingField = {
  field: string;
  dependsOn: {
    parentField: string;
    value: boolean | number | number[] | string | string[];
  };
};
export type ExtractedDependingValue = CustomDependingField['dependsOn']['value'];

export const MAX_FILE_SIZE = 4200000; // 4MB

export const VALID_FILE_EXTENSIONS = [
  'doc',
  'docx',
  'xml',
  'png',
  'jpg',
  'jpeg',
  'pdf',
  'xls',
  'xlsx',
  'txt',
  'csv',
];

const initialState = {
  email: '',
  firstname: '',
  lastname: '',
  company: '',
  art_des_anliegens___2024_: '',
  pid: '',
  hf_communication_language: '',
  hf_type_of_request: '',
  hf_bereich_der_anfrage_trainierenden_firmen_verbundpartner: '',
  hf_quelle_der_anfrage: '',
  art_des_anliegens_category__2024_: '',
  hs_file_upload: [],
  phoneNumber: '',
  dialCode: '',
};

const HubspotSupportForm = () => {
  const intl = useMemoedIntl();
  const dispatch = useAppDispatch();
  const { classes } = useModalStyles();

  const [submissionSuccessfull, setSubmissionSuccessfull] = useState<boolean | null>(null);
  const [supportFormStructure, setSupportFormStructure] = useState<HubspotSupportFormType>();
  const [hiddenValues, setHiddenValues] = useState<Record<string, string>>({});
  const [isShowLogoutAlert, setIsShowLogoutAlert] = useState<boolean>(false);
  const [customFieldDependsOn, setCustomFieldDependsOn] = useState<CustomDependingField[]>([]);
  const { data: hubspotFormRawStructure } = useGetHubspotDataQuery();

  const importFormStructure = useCallback(async (json: HubspotSupportFormType | undefined) => {
    if (!json) {
      return;
    }

    const hiddenFieldValues: Record<string, string> = {};
    const dependingFields: CustomDependingField[] = [];
    //hremove hidden elements from response
    const formFieldGroups: FormFieldGroup[] = json.formFieldGroups.reduce(
      (acc: FormFieldGroup[], fieldGroup: FormFieldGroup) => {
        const mainFields: HubspotField[] = [];
        const dependentFields: HubspotField[] = [];
        fieldGroup.fields.forEach(item => {
          if (!item.hidden) {
            // Hidden fields are not displayed in the form and added later from the state to the form
            mainFields.push(item);
          }
          if (
            item.hidden &&
            (item.defaultValue || (item.type === 'enumeration' && item.selectedOptions.length > 0))
          ) {
            const { propertyObjectType, name } = item || {};
            const fieldName =
              propertyObjectType !== 'CONTACT' ? `${propertyObjectType}.${name}` : name;
            hiddenFieldValues[fieldName] =
              item.type === 'enumeration'
                ? item.selectedOptions.join(';')
                : item.defaultValue.toString();
          }
          if (item.dependentFieldFilters.length > 0) {
            item.dependentFieldFilters.forEach(dependentField => {
              dependentFields.push(dependentField.dependentFormField);
              let parentVal: ExtractedDependingValue = '';
              if (dependentField.filters[0].boolValue) {
                parentVal = dependentField.filters[0].boolValue;
              } else if (dependentField.filters[0].numberValue) {
                parentVal = dependentField.filters[0].numberValue;
              } else if (dependentField.filters[0].numberValues.length > 0) {
                parentVal = dependentField.filters[0].numberValues;
              } else if (dependentField.filters[0].strValue) {
                parentVal = dependentField.filters[0].strValue;
              } else if (dependentField.filters[0].strValues.length > 0) {
                parentVal = dependentField.filters[0].strValues;
              }
              dependingFields.push({
                field: dependentField.dependentFormField.name,
                dependsOn: { parentField: item.name, value: parentVal },
              });
            });
          }
        });
        if (mainFields.length > 0) acc.push({ ...fieldGroup, fields: mainFields });
        if (dependentFields.length > 0) acc.push({ ...fieldGroup, fields: dependentFields });
        return acc;
      },
      [] as FormFieldGroup[],
    );

    setCustomFieldDependsOn(dependingFields);
    setHiddenValues(hiddenFieldValues);
    setSupportFormStructure({ ...json, formFieldGroups });
  }, []);

  useEffect(() => {
    importFormStructure(hubspotFormRawStructure);
  }, [hubspotFormRawStructure, importFormStructure]);

  // dynamically generate yup schema from hubspot form structure
  const generatedHubspotSchema = useMemo(() => {
    const requiredMessage = intl.formatMessage({
      id: 'form.generic.field_required',
      defaultMessage: 'Field is required',
    });
    const invalidFileSizeMessage = intl.formatMessage(
      {
        id: 'form.generic.invalid_file_size',
        defaultMessage: 'Max allowed size is {maxFileSize}',
      },
      { maxFileSize: formatBytes(MAX_FILE_SIZE) },
    );
    const invalidFileExtensionMessage = intl.formatMessage(
      {
        id: 'form.generic.invalid_file_extension',
        defaultMessage: 'Not a valid file extension (allowed: {validFileExtensions})',
      },
      { validFileExtensions: VALID_FILE_EXTENSIONS.join(', ') },
    );

    if (!supportFormStructure) {
      return yup.object().shape({});
    }

    const schema = supportFormStructure.formFieldGroups.reduce(
      (acc, fieldGroup) => {
        fieldGroup.fields.forEach(field => {
          const { name, required, fieldType } = field;

          if (fieldType === 'checkbox') {
            acc[name] = required
              ? yup.array().of(yup.string()).required(requiredMessage)
              : yup.array().of(yup.string());
          } else if (fieldType === 'file') {
            acc[name] = required
              ? yup
                  .mixed()
                  .required(requiredMessage)
                  .test('is-valid-type', invalidFileExtensionMessage, async files =>
                    validateFileExtension(files, VALID_FILE_EXTENSIONS),
                  )
                  .test('is-valid-size', invalidFileSizeMessage, async files =>
                    validateFileSize(files, MAX_FILE_SIZE),
                  )
              : yup
                  .mixed()
                  .test('is-valid-type', invalidFileExtensionMessage, async files =>
                    validateFileExtension(files, VALID_FILE_EXTENSIONS),
                  )
                  .test('is-valid-size', invalidFileSizeMessage, async files =>
                    validateFileSize(files, MAX_FILE_SIZE),
                  );
          } else if (fieldType === 'phonenumber') {
            acc[name] = required ? yup.mixed().required(requiredMessage) : yup.mixed().nullable();
          } else if (fieldType === 'booleancheckbox') {
            acc[name] = required ? yup.boolean().required(requiredMessage) : yup.boolean();
          } else {
            acc[name] = required ? yup.string().required(requiredMessage) : yup.string();
          }
        });

        // custom fields
        acc['dialCode'] = yup.string().max(4);
        acc['phoneNumber'] = yup.mixed().test('is-valid-number', 'Must be a number', value => {
          if (!value) {
            return true;
          }

          return !isNaN(value);
        });

        return acc;
      },
      {} as Record<ExtractedFieldName, yup.BaseSchema>,
    );

    return yup.object().shape(schema);
  }, [intl, supportFormStructure]);

  // React object type ids from hubspot form structure
  const objectTypes = useMemo(() => {
    if (!supportFormStructure) {
      return {};
    }

    return supportFormStructure.formFieldGroups.reduce(
      (acc, fieldGroup) =>
        fieldGroup.fields.reduce((accField, field) => {
          accField[field.name] = {
            objectTypeId: field.objectTypeId,
            fieldType: field.fieldType,
            propertyObjectType: field.propertyObjectType,
          };

          return accField;
        }, acc),
      {} as Record<
        ExtractedFieldName,
        {
          objectTypeId: ObjectTypeId;
          fieldType: FieldFormType;
          propertyObjectType: PropertyObjectType;
        }
      >,
    );
  }, [supportFormStructure]);

  const toggleLogoutModal = useCallback(() => {
    setIsShowLogoutAlert(prev => !prev);
  }, [setIsShowLogoutAlert]);

  const handleLogout = useCallback(() => {
    Sentry.setUser(null);
    dispatch(AuthActions.Logout());
    localStorage.removeItem('publicId');
  }, [dispatch]);

  const onSubmit = async (formData: HubspotForm) => {
    if (!supportFormStructure) {
      return null;
    }
    const formsApiPayload = new FormData();

    formsApiPayload.append(
      'hs_context',
      JSON.stringify({
        pageUri: window.location.href,
        pageName: document.title,
      }),
    );

    Object.entries(formData).forEach(([name, value]) => {
      if (value) {
        const { propertyObjectType, fieldType } = objectTypes?.[name] || {};
        const fieldName =
          propertyObjectType !== 'CONTACT' && propertyObjectType
            ? `${propertyObjectType}.${name}`
            : name;

        if (name === 'phoneNumber' || name === 'dialCode') {
          return;
        }

        if (fieldType === 'file' && value.length > 0) {
          // Handle file values
          formsApiPayload.append(fieldName, value[0]);
        } else if (Array.isArray(value)) {
          // Handle array values
          formsApiPayload.append(fieldName, value.join(';'));
        } else {
          // Handle other values
          formsApiPayload.append(fieldName, value.toString());
        }
      }
    });

    if (formData.dialCode && formData.phoneNumber) {
      // Handle phone number field
      formsApiPayload.append('phone', `${formData.dialCode} ${formData.phoneNumber}`);
    }

    Object.keys(hiddenValues).forEach(key => formsApiPayload.append(key, hiddenValues[key]));

    try {
      const response = await postHubspotSupportForm(
        formsApiPayload,
        supportFormStructure.guid,
        supportFormStructure.portalId,
      );
      if (response.status === 200 || response.status === 204) {
        setSubmissionSuccessfull(true);
      } else {
        setSubmissionSuccessfull(false);
      }
    } catch (error) {
      setSubmissionSuccessfull(false);
    }
  };

  if (submissionSuccessfull) {
    return (
      <Box className='questions__card' style={{ alignItems: 'center' }}>
        <DisplayTextAccent
          textContent={intl.formatMessage({
            id: 'support.contact.form.title',
            defaultMessage: 'Contact us',
          })}
        />
        <BodyTextRegular
          textContent={intl.formatMessage({
            id: 'support.contact.form.subtitle',
            defaultMessage: 'We are here to help you',
          })}
        />
        <BodyTextRegular
          textContent={intl.formatMessage({
            id: 'support.contact.form.submit_success',
            defaultMessage: 'Your request has been submitted successfully',
          })}
        />
      </Box>
    );
  }

  if (!supportFormStructure) {
    return null;
  }

  return (
    <Box
      sx={{
        justifyContent: 'start',
        display: 'flex',
        gap: '16px',
        maxWidth: '466px',
      }}>
      <Formik
        validationSchema={generatedHubspotSchema}
        validateOnChange
        validateOnBlur
        initialValues={initialState}
        onSubmit={onSubmit}>
        {() => (
          <HubspotEditForm
            supportFormStructure={supportFormStructure}
            submissionSuccessfull={submissionSuccessfull}
            toggleLogoutModal={toggleLogoutModal}
            customFieldDependsOn={customFieldDependsOn}
          />
        )}
      </Formik>
      {isShowLogoutAlert && (
        <Dialog open onClose={toggleLogoutModal}>
          <Box className={classes.headerWrapper}>
            <PageHeadline
              customColor={theme.palette.primary.light}
              textContent={intl.formatMessage({
                id: 'alert.modal.hubspot_logout.title',
                defaultMessage: 'Your current session will be ended',
              })}
            />
            <CloseIcon className={classes.clickable} onClick={toggleLogoutModal} />
          </Box>
          <LineDivider verticalSpace={16} />
          <DialogContent>
            <BodyTextRegular
              textContent={intl.formatMessage({
                id: 'alert.modal.hubspot_logout.description',
                defaultMessage: 'Are you sure you want to log out?',
              })}
            />
          </DialogContent>
          <DialogActions className={classes.actionsWrapper}>
            <Button variant='outlined' size='md' onClick={toggleLogoutModal}>
              {intl.formatMessage({
                id: 'alert.modal.button.cancel',
                defaultMessage: 'Cancel',
              })}
            </Button>

            <Button type='submit' size='md' onClick={handleLogout}>
              {intl.formatMessage({
                id: 'alert.modal.button.confirm',
                defaultMessage: 'Confirm',
              })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default HubspotSupportForm;
