import { LanguageType } from '@/Types';

export const languageName = {
  GERMANY: 'de',
  ENGLISH: 'en',
};

export function getLanguageName(language: LanguageType) {
  let name = '';
  switch (language) {
    case languageName.GERMANY:
      name = languageName.GERMANY;
      break;
    case languageName.ENGLISH:
      name = languageName.ENGLISH;
      break;

    default:
      break;
  }
  return name;
}
