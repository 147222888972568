import { Alert, AlertColor, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';
import { NotificationIcon } from './NotificationIcon';

export type NotificationProps = {
  header?: string;
  subHeader?: string;
  message: string;
  severity: AlertColor;
};

const getBackgroundColor = (severity: AlertColor) => {
  switch (severity) {
    case 'info':
      return FigmaDesignTokens.Grey[100];
    case 'warning':
      return FigmaDesignTokens.Orange[50];
    case 'error':
      return FigmaDesignTokens.Red[50];
    default:
      return '';
  }
};

const useStyles = ({ severity }: { severity: AlertColor }) =>
  makeStyles()(() => ({
    root: {
      flex: 1,
      '&.MuiAlert-root': {
        borderRadius: 11,
        paddingTop: 0,
        paddingBottom: 0,
        alignItems: 'center',
        backgroundColor: getBackgroundColor(severity),
      },
    },
    header: {
      fontSize: 12,
      color: FigmaDesignTokens.Grey[950],
      fontWeight: 500,
      marginBottom: 4,
      marginLeft: 8,
    },
    subheader: {
      color: FigmaDesignTokens.Grey[950],
      fontSize: 12,
      fontWeight: 500,
      marginBottom: 4,
      marginLeft: 8,
    },
    message: {
      fontSize: 12,
      marginLeft: 8,
    },
  }));

export const Notification = ({ message, severity, header, subHeader }: NotificationProps) => {
  const { classes } = useStyles({
    severity,
  })();

  return (
    <Alert
      className={classes.root}
      severity={severity}
      icon={<NotificationIcon severity={severity} />}>
      {header && (
        <Typography className={classes.header}>
          <FormattedMessage id={header} />
        </Typography>
      )}

      {subHeader && (
        <Typography className={classes.subheader}>
          <FormattedMessage id={subHeader} />
        </Typography>
      )}
      <Typography className={classes.message}>
        <FormattedMessage id={message} />
      </Typography>
    </Alert>
  );
};
