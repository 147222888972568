import { Box, Grid, Typography } from '@mui/material';
import { ManagedUpload } from 'aws-sdk/lib/s3/managed_upload';
import { Form, Formik, FormikProps } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { FormSnackbar } from '@/App/Shared/Notification/Components/FormSnackbar';
import { TranslationService } from '@/App/Shared/Translation/TranslationService';
import { BodyTextSmall, Dropzone, InfoMessage, UploadIndicator } from '@/Components';
import { ConfirmationDialogTypes, DelayModalTypes, useAppContext } from '@/Context';
import { useConfirmDialog } from '@/Hooks/useConfirmDialog';
import {
  getDefaultFilename,
  getDefaultGalleryAsset,
  isDisableUploadImage,
  isQualifiedForUpload,
} from '@/Mappers';
import { useDeleteAllAssetsMutation, useUpdateGalleryAssetMutation } from '@/Queries';
import { AssetServiceFactory } from '@/Services';
import ErrorIcon from '@/Static/Icons/atoms-symbols-alert-error.svg';
import Check from '@/Static/Icons/atoms-symbols-icons-check.svg';
import MediaImage from '@/Static/Icons/atoms-symbols-icons-media-image.svg';
import { GalleryAsset, Partner, Upload } from '@/Types';
import { CrazyUseRefCallbackStuffTypes, isGalleryFormRef } from '@/Utils/formRefTypeguards';
import handleCatchError from '@/Utils/handleCatchError';

import { useGalleryFormStyles } from './Gallery.styles';
import { GalleryList } from './GalleryList';

type GalleryFormProps = {
  casPublicId: Partner['casPublicId'];
  gallery: GalleryAsset[];
  partner?: Partner | null;
  refCallback: (ref: CrazyUseRefCallbackStuffTypes, tabNumber: number) => void;
  handlePrimaryCtaDisabled: (value: boolean) => void;
  setOpenToastMessage: (value: boolean) => void;
  refetchAssets: () => void;
};

export const GalleryForm = ({
  refCallback,
  casPublicId,
  gallery,
  partner,
  handlePrimaryCtaDisabled,
  setOpenToastMessage,
  refetchAssets,
}: GalleryFormProps) => {
  const formikRef = useRef<FormikProps<{ gallery: GalleryAsset[] }>>(null);
  const {
    dispatch,
    state: { delayModal },
  } = useAppContext();
  const { formatMessage } = useIntl();
  const { classes } = useGalleryFormStyles();
  const handleConfirmDialog = useConfirmDialog();

  const [initialState, setInitialState] = useState<GalleryAsset[]>(gallery);
  const [isDisabledDropzone, setIsDisabledDropzone] = useState(false);
  const [uploads, setUploads] = useState<Upload[]>([]);
  const [isWrongFormat, setIsWorngFormat] = useState(false);
  const [latestUploads, setLatestUploads] = useState<GalleryAsset[]>([]);
  const [deleteAssetIdQueue, setDeleteAssetIdQueue] = useState<number[]>([]);

  const assetService = useMemo(() => new AssetServiceFactory().getInstance(dispatch), [dispatch]);

  const { mutateAsync: deleteAllAssets } = useDeleteAllAssetsMutation();
  const { mutateAsync: updateGalleryAsset } = useUpdateGalleryAssetMutation();

  const handleGallery = useCallback(
    (gallery: GalleryAsset[]) => {
      formikRef.current?.setValues({ gallery });
      handlePrimaryCtaDisabled(false);
      handleConfirmDialog(true);
    },
    [formikRef, handlePrimaryCtaDisabled, handleConfirmDialog],
  );

  const handleReplaceImage = useCallback(
    (idx: number, uri: string, assetId: number, originalFilename: string | null) => {
      const gallery = formikRef.current?.values.gallery;
      if (!gallery) return;
      gallery[idx] = {
        ...gallery[idx],
        uri,
        assetId,
        originalFilename,
      };
      formikRef.current?.setValues({ gallery });
      handlePrimaryCtaDisabled(false);
    },
    [formikRef, handlePrimaryCtaDisabled],
  );

  const handleCancel = useCallback(
    (managedUpload: ManagedUpload, idx: number) => {
      managedUpload.abort.bind(managedUpload);
      managedUpload.abort();
      const mutatedUploads = [...uploads];
      mutatedUploads[idx].errorMessage = formatMessage({
        id: 'form.photo_and_video.photo_upload.upload.error.canceled',
        defaultMessage: 'Upload canceled',
      });
      setUploads(mutatedUploads);
    },
    [uploads, formatMessage],
  );

  const handleRemoveFromList = useCallback(
    (idx: number) => {
      uploads.splice(idx, 1);

      let enabledUpload = false;
      const updatedUploads = uploads.map(upload => {
        if (upload.errorMessage && !enabledUpload) {
          enabledUpload = true;
          return { ...upload, errorMessage: undefined };
        } else {
          return upload;
        }
      });

      setUploads([...updatedUploads]);
    },
    [uploads, setUploads],
  );

  const uploadFile = useCallback(
    async (
      file: File,
      idx: number,
      resolve: (value?: GalleryAsset | PromiseLike<GalleryAsset>) => void,
      preUploads: Upload[],
    ) => {
      const filename = getDefaultFilename(file.name);
      const managedUpload = await assetService.S3Upload(
        casPublicId,
        filename,
        'Gallery',
        file,
        (progressEvent: ManagedUpload.Progress) => {
          preUploads[idx] = {
            ...preUploads[idx],
            file,
            progress: progressEvent,
            managedUpload,
          };
          setUploads([...preUploads]);
        },
        err => {
          if (err) {
            resolve();
            return;
          }

          assetService
            .assignGalleryImage(casPublicId, {
              filename,
              originalFilename: file.name,
            })
            .then(response => {
              if (!response.isAxiosError) {
                resolve(getDefaultGalleryAsset(response.data.assetId, filename, file.name));
              } else {
                resolve(undefined);
              }
            })
            .catch(() => {
              resolve(undefined);
            });
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [casPublicId],
  );

  const uploadFiles = useCallback(
    async (preUploads: Upload[]) => {
      setIsDisabledDropzone(true);
      const galleryPreviewPromises: Promise<GalleryAsset | undefined>[] = [];

      preUploads.forEach((upload: Upload, idx) => {
        if (!upload.errorMessage) {
          galleryPreviewPromises.push(
            new Promise(resolve => {
              uploadFile(upload.file, idx, resolve, preUploads);
            }),
          );
        }
      });
      const newGalleryImages = await Promise.all(galleryPreviewPromises);

      setUploads([]);

      const gallery = formikRef.current?.values.gallery;
      const resolvedNewGalleryImages = newGalleryImages.filter(
        galleryImage => !!galleryImage,
      ) as GalleryAsset[];

      if (gallery && gallery.length > 0 && !!gallery[0].assetId) {
        formikRef.current?.setValues({
          gallery: [...gallery, ...resolvedNewGalleryImages],
        });

        if (gallery && gallery.length > 0 && !gallery[0].assetId) {
          formikRef.current?.setValues({
            gallery: [...resolvedNewGalleryImages],
          });
        }

        setLatestUploads(resolvedNewGalleryImages);
      } else {
        formikRef.current?.setValues({ gallery: [...resolvedNewGalleryImages] });
      }
      setIsDisabledDropzone(false);
    },
    [uploadFile],
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (formikRef.current?.values.gallery) {
        let existingImages = formikRef.current.values.gallery.length + uploads.length;
        const newUploads: Upload[] = acceptedFiles.map(fileData => {
          const upload: Upload = { file: fileData };

          if (
            formikRef.current?.values.gallery &&
            !isQualifiedForUpload({
              firstGalleryAsset: formikRef.current.values.gallery[0],
              existingImages,
              maxAllowedImages: 11,
            })
          ) {
            upload.errorMessage = formatMessage({
              id: 'form.photo_and_video.photo_upload.upload.error.max_limit',
              defaultMessage: 'A maximum of 11 images are allowed.',
            });
          }

          existingImages++;

          return upload;
        });
        const mergedUploads = [...uploads, ...newUploads];
        setLatestUploads([]);
        setUploads(mergedUploads);

        return uploadFiles(mergedUploads);
      }
    },
    [uploads, uploadFiles, formatMessage],
  );

  const handleAssetIdDeleteQueue = useCallback(
    (assetId: number) => {
      deleteAssetIdQueue.push(assetId);

      setDeleteAssetIdQueue([...deleteAssetIdQueue]);
      handlePrimaryCtaDisabled(false);
    },
    [deleteAssetIdQueue, setDeleteAssetIdQueue, handlePrimaryCtaDisabled],
  );

  useEffect(() => {
    if (isGalleryFormRef(formikRef)) {
      refCallback(formikRef, 1);
    }
  }, [refCallback, formikRef]);

  useEffect(() => {
    formikRef.current?.setValues({ gallery });
    setInitialState(gallery);
    dispatch({
      type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
      payload: { show: false },
    });
    handlePrimaryCtaDisabled(true);
  }, [dispatch, gallery, handlePrimaryCtaDisabled]);

  const handleSubmit = async ({ gallery }: { gallery: GalleryAsset[] }) => {
    if (!delayModal.notShowProfileModal) {
      dispatch({ type: DelayModalTypes.SET_OPEN, payload: { open: true } });
    }

    try {
      await deleteAllAssets({ casPublicId, deleteAssetIdQueue });
      const newGallery = { gallery };

      await updateGalleryAsset({ casPublicId, newGallery });

      setDeleteAssetIdQueue([]);
      setOpenToastMessage(true);
      handlePrimaryCtaDisabled(true);
      refetchAssets();
    } catch (error: unknown) {
      dispatch({
        type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
        payload: { show: true },
      });
      handleCatchError(error);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      validateOnBlur={false}
      validateOnChange
      validate={values => {
        const initialStateStringify = JSON.stringify(initialState);
        const currentStateStringify = JSON.stringify(values.gallery);

        if (initialStateStringify !== currentStateStringify) {
          dispatch({
            type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
            payload: { show: true },
          });
          setInitialState(values.gallery);
          handlePrimaryCtaDisabled(false);
        }
      }}
      initialValues={{ gallery }}
      onSubmit={handleSubmit}>
      {formik => (
        <ContentContainer>
          <Form>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormHeader
                      title={formatMessage({
                        id: 'view.photo_and_video.photo_upload',
                        defaultMessage: 'Photo upload',
                      })}
                    />
                  </Grid>

                  <Grid xs={9} item>
                    <FormSnackbar
                      openToastMessage={isWrongFormat}
                      handleCloseToastMessage={() => setIsWorngFormat(false)}
                      customMessage={formatMessage({
                        id: 'toast.upload.fail',
                        defaultMessage:
                          'File upload failed. Please verify the file type and size and try again.',
                      })}
                      customIcon={ErrorIcon}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Dropzone
                          disabled={isDisableUploadImage({
                            gallery: formik.values.gallery,
                            maxAllowedImages: 11,
                            isDisabledDropzone,
                            readonly: partner?.readonly,
                          })}
                          maxFileSize={15000000}
                          supportedFileNames='JPEG, PNG, TIFF'
                          onDropRejected={() => setIsWorngFormat(true)}
                          onDrop={onDrop}
                          accept='image/jpeg, image/png, image/tiff'
                        />
                      </Grid>

                      {uploads.length > 0 && (
                        <Grid item xs={12}>
                          <UploadIndicator
                            uploads={uploads}
                            handleRemoveFromList={handleRemoveFromList}
                            handleCancel={handleCancel}
                          />
                        </Grid>
                      )}

                      {latestUploads.length > 0 && (
                        <Grid container item xs={12} className={classes.tipWrapper}>
                          <img alt='' src={Check} />
                          <Typography variant='body2' className={classes.uploadSuccessMessage}>
                            {latestUploads.length === 1 &&
                              formatMessage({
                                id: 'form.photo_and_video.photo_upload.upload.success.one_image',
                                defaultMessage: 'You have uploaded 1 new image.',
                              })}
                            {latestUploads.length > 1 &&
                              TranslationService.replacePlaceholder(
                                formatMessage({
                                  id: 'form.photo_and_video.photo_upload.upload.success.multiple_images',
                                  defaultMessage: 'You have uploaded {{uploads}} new images.',
                                }),
                                '{{uploads}}',
                                latestUploads.length.toString(),
                              )}
                          </Typography>
                        </Grid>
                      )}
                      <Grid container item xs={12} className={classes.tipWrapper}>
                        <Grid item xs={1} className={classes.imageTip}>
                          <img alt='' src={MediaImage} />
                        </Grid>
                        <Grid item xs={11} className={classes.textWrapperTip}>
                          <BodyTextSmall
                            fontWeight={600}
                            textContent={formatMessage({
                              id: 'form.photo_and_video.photo_upload.hint.image_order.header',
                              defaultMessage: 'Change order',
                            })}
                          />
                          <BodyTextSmall
                            textContent={formatMessage({
                              id: 'form.photo_and_video.photo_upload.hint.image_order.description',
                              defaultMessage:
                                'By clicking on the dots in the upper right corner of an image you can change the order of the images via drag & drop.',
                            })}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid xs={3} item container>
                    <Grid item xs={12}>
                      <Box className={classes.hintWrapper}>
                        <InfoMessage
                          title={formatMessage({
                            id: 'form.photo_and_video.photo_upload.hint.image_size.header',
                            defaultMessage: 'Create an image gallery',
                          })}
                          description={formatMessage({
                            id: 'form.photo_and_video.photo_upload.hint.image_size.description',
                            defaultMessage:
                              'Upload photos of your facility to give Hansefit members a glimpse of what to expect at your facility.',
                          })}
                          type='information'
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <InfoMessage
                        title={formatMessage({
                          id: 'form.photo_and_video.photo_upload.hint.image_detail.header',
                          defaultMessage: 'Image rights',
                        })}
                        description={formatMessage({
                          id: 'form.photo_and_video.photo_upload.hint.image_detail.description',
                          defaultMessage:
                            'By uploading the images you confirm that you hold the rights to the images.',
                        })}
                        type='information'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} item>
                <Box>
                  <GalleryList
                    latestUploads={latestUploads}
                    gallery={formik.values.gallery}
                    handleGallery={handleGallery}
                    assetService={assetService}
                    casPublicId={casPublicId}
                    handleReplaceImage={handleReplaceImage}
                    handleAssetIdDeleteQueue={handleAssetIdDeleteQueue}
                    handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
                  />
                </Box>
              </Grid>
            </Grid>
          </Form>
        </ContentContainer>
      )}
    </Formik>
  );
};
