import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppContext } from '@/Context';
import { EventsServiceFactory } from '@/Services';

import useCurrentPartnerIdQuery from '../useCurrentPartnerIdQuery';

type useGetPublishEventsQueryProps = {
  courseId: string;
};

const useGetNumberOfPublishEventsQuery = ({ courseId }: useGetPublishEventsQueryProps) => {
  const { dispatch } = useAppContext();
  const partnerServiceInstance = useMemo(
    () => new EventsServiceFactory().getInstance(dispatch),
    [dispatch],
  );
  const { data: casPublicId = '' } = useCurrentPartnerIdQuery();

  const queryResult = useQuery(
    ['getNumberOfPublishEvents', casPublicId, courseId],
    async () => {
      const publishEvents = await partnerServiceInstance.getNumberOfPublishedEvents(
        casPublicId,
        courseId,
      );
      return publishEvents?.data?.pagination?.totalCount || 0;
    },
    {
      enabled: !!casPublicId && !!courseId,
      cacheTime: 0,
    },
  );

  return queryResult;
};

export default useGetNumberOfPublishEventsQuery;
