import { Grid, Link, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

const useStyles = makeStyles()(theme => ({
  helpText: {
    fontSize: 12,
    letterSpacing: 0.11,
    color: FigmaDesignTokens.Grey[950],
  },
  helpLink: {
    fontSize: 12,
    letterSpacing: 0.34,
    color: theme.palette.primary.dark,
    textTransform: 'uppercase',
    fontWeight: 500,
    marginTop: 4,
  },
}));

export const EnableCourseManagerDialogFooter = (): JSX.Element => {
  const { classes } = useStyles();
  const intl = useIntl();

  return (
    <Grid container alignItems='center' justifyContent='center' direction='column'>
      <Grid item xs={12}>
        <Typography variant='body1' className={classes.helpText}>
          <FormattedMessage
            id='course_manager.dialog.footer.help'
            defaultMessage='Questions about the Hansefit Course Manager? '
          />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Link
          variant='body1'
          className={classes.helpLink}
          target='_blank'
          href={intl.formatMessage({
            id: 'course_manager.dialog.footer.link.href',
            defaultMessage: 'https://hansefit.de/partner/support/',
          })}>
          <FormattedMessage id='course_manager.dialog.footer.link.text' defaultMessage='Contact' />
        </Link>
      </Grid>
    </Grid>
  );
};
