import { Button, Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import { useGetPartnerQuery } from '@/Queries';

import FigmaDesignTokens from '../../../../design/design-tokens.json';
import { EnableCourseManagerDialogBody } from './EnableCourseManagerDialogBody';

export interface EnableCourseManagerDialogStep1Props {
  handleStep: (value: number) => void;
  header: string;
  description: string;
  contentHeader: string;
  contentDescription: string;
  currentStep: number;
  imageSrc: string;
}

const useStyles = makeStyles()(theme => ({
  cta: {
    borderRadius: 18,
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: theme.palette.background.default,
    height: 36,
    marginTop: 18,
    padding: '7px 21px',
  },
  image: {
    width: 180,
    position: 'relative',
    right: 24,
    top: -20,
  },
  header: {
    fontSize: 14,
    letterSpacing: 0.13,
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    fontStretch: 'normal',
    textTransform: 'uppercase',
  },
  description: {
    fontSize: 12,
    letterSpacing: 0.11,
    color: FigmaDesignTokens.Grey[700],
    fontWeight: 'normal',
    fontStretch: 'normal',
    marginTop: 4,
  },
  ctaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
}));
export const EnableCourseManagerDialogStepPrimary = ({
  handleStep,
  header,
  description,
  currentStep,
  imageSrc,
  contentHeader,
  contentDescription,
}: EnableCourseManagerDialogStep1Props) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const { data: partner } = useGetPartnerQuery();

  return (
    <EnableCourseManagerDialogBody
      currentStep={currentStep}
      header={header}
      description={description}>
      <Grid container>
        <Grid item xs={5}>
          <img className={classes.image} alt='' src={imageSrc} />
        </Grid>
        <Grid item xs={7}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.header} variant='body1'>
                {contentHeader}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.description} variant='body1'>
                {contentDescription}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.ctaContainer}>
              <Button
                disabled={partner?.readonly}
                onClick={() => handleStep(currentStep + 1)}
                type='submit'
                variant='contained'
                color='primary'
                className={classes.cta}>
                {formatMessage({
                  id: 'course_manager.dialog.cta.continue',
                  defaultMessage: 'Confirm & continue ',
                }).toUpperCase()}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </EnableCourseManagerDialogBody>
  );
};
