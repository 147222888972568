import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../design/design-tokens.json';

export const useContactStyles = makeStyles()(theme => ({
  root: {
    padding: '28px 0 37px 50px',
    backgroundColor: theme.palette.primary.dark,
    color: FigmaDesignTokens.Grey[100],
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    marginBottom: 8,
    opacity: 0.5,

    svg: {
      color: theme.palette.background.paper,
    },
  },
  phoneIcon: {
    marginRight: 6,
  },
  contactContainer: {
    fontSize: 12,
    letterSpacing: 0.11,
    fontWeight: 400,
    lineHeight: 1.5,
  },
}));
