import { Box } from '@mui/material';
import { GridColDef, GridSortModel } from '@mui/x-data-grid-pro';
import { useCallback, useMemo, useState } from 'react';

import { CustomDataGrid } from '@/App/Shared/DataGridTable/Table/DataGridTable';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { TIME_AND_DATE_FORMAT } from '@/Models';
import { CheckedOnDayType, DateSelectionType, ExtendedCheckedOnDayType } from '@/Types';

import StatisticsTableToolbar from './StatisticsTableToolbar';

const CheckInTable = ({
  data = [],
  setDateRange,
  dateRange,
  isLoading,
}: {
  data?: ExtendedCheckedOnDayType[];
  setDateRange: (date: DateSelectionType) => void;
  dateRange: DateSelectionType;
  isLoading: boolean;
}) => {
  const { formatMessage, formatDate } = useMemoedIntl();
  const [sortedState, setSortedState] = useState<GridSortModel>([
    { field: 'checkedInDateTime', sort: 'asc' },
  ]);

  const checkInsOptions = useMemo(
    () => [
      formatMessage({
        id: `view.current_checkins.table.checked_in_via_app`,
        defaultMessage: `with App`,
      }),
      formatMessage({
        id: `view.current_checkins.table.checked_in_via_card`,
        defaultMessage: `with Card`,
      }),
      formatMessage({
        id: `view.current_checkins.table.checked_in_via_vpp`,
        defaultMessage: `with Vpp`,
      }),
      formatMessage({
        id: `view.current_checkins.table.checked_in_via_unknown`,
        defaultMessage: `with Unknown`,
      }),
    ],
    [formatMessage],
  );
  const courseTypeOptions = useMemo(
    () => [
      formatMessage({
        id: `view.current_checkins.table.course_type_onsite`,
        defaultMessage: 'Onsite',
      }),
      formatMessage({
        id: `view.current_checkins.table.course_type_online`,
        defaultMessage: 'Online',
      }),
      formatMessage({
        id: `view.current_checkins.table.course_type_unknown`,
        defaultMessage: 'Unknown',
      }),
    ],
    [formatMessage],
  );

  const checkInsTextHelper = useCallback(
    (checkInType: CheckedOnDayType['checkin_type']) => {
      switch (checkInType) {
        case 'hansefitapp':
          return formatMessage({
            id: `view.current_checkins.table.checked_in_via_app`,
            defaultMessage: `with App`,
          });
        case 'card':
        case 'hansefitcard':
          return formatMessage({
            id: `view.current_checkins.table.checked_in_via_card`,
            defaultMessage: `with Card`,
          });
        case 'vpp':
          return formatMessage({
            id: `view.current_checkins.table.checked_in_via_vpp`,
            defaultMessage: `with Vpp`,
          });
        default:
          return formatMessage({
            id: `view.current_checkins.table.checked_in_via_unknown`,
            defaultMessage: `with Unknown`,
          });
      }
    },
    [formatMessage],
  );
  const courseTypeTextHelper = useCallback(
    (checkInType: CheckedOnDayType['course_type']) => {
      switch (checkInType) {
        case 'onsite':
          return formatMessage({
            id: `view.current_checkins.table.course_type_onsite`,
            defaultMessage: 'Onsite',
          });
        case 'online':
          return formatMessage({
            id: `view.current_checkins.table.course_type_online`,
            defaultMessage: 'Online',
          });

        default:
          return formatMessage({
            id: `view.current_checkins.table.course_type_unknown`,
            defaultMessage: 'Unknown',
          });
      }
    },
    [formatMessage],
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
      },
      {
        field: 'eu_id',
        display: 'flex',
        headerName: formatMessage({
          id: 'view.statistic.check_in_all.table.header.eu_id',
          defaultMessage: 'HF user ID',
        }),
      },
      {
        field: 'company_id',
        minWidth: 100,
        headerName: formatMessage({
          id: 'view.statistic.check_in_all.table.header.company_id',
          defaultMessage: 'CAS company ID',
        }),
        display: 'flex',
      },
      {
        field: 'company_name',
        flex: 1,
        headerName: formatMessage({
          id: 'view.statistic.check_in_all.table.header.company_name',
          defaultMessage: 'Company name',
        }),
        hide: true,
        display: 'flex',
        disableExport: true,
      },
      {
        field: 'checkin_type',
        minWidth: 130,
        headerName: formatMessage({
          id: 'view.statistic.check_in_all.table.header.checkin_type',
          defaultMessage: 'Checkin type',
        }),
        valueGetter: value => (value ? checkInsTextHelper(value) : ''),
        type: 'singleSelect',
        valueOptions: checkInsOptions,
        display: 'flex',
      },
      {
        field: 'course_type',
        minWidth: 130,
        headerName: formatMessage({
          id: 'view.statistic.check_in_all.table.header.course_type',
          defaultMessage: 'Course type',
        }),
        valueGetter: value => (value ? courseTypeTextHelper(value) : ''),
        type: 'singleSelect',
        valueOptions: courseTypeOptions,
        display: 'flex',
        // renderCell: cell => (
        //   <div
        //     style={{
        //       display: 'flex',
        //       alignItems: 'flex-start',
        //       flexDirection: 'column',
        //       gap: 1,
        //     }}>
        //     <div>{cell.value}</div>
        //     <Chip label={cell.row.course_type} color='primary' size='small' />
        //   </div>
        // ),
      },
      {
        field: 'checkedInDateTime',
        minWidth: 130,
        type: 'dateTime',
        display: 'flex',
        headerName: formatMessage({
          id: 'view.statistic.check_in_all.table.header.check_in_time',
          defaultMessage: 'Check in time',
        }),
        valueFormatter: value => formatDate(value, { ...TIME_AND_DATE_FORMAT }),
        renderCell: cell => (
          <Box sx={{ display: 'flex', flexDirection: 'column', lineHeight: 1 }}>
            {formatDate(cell.value, { ...TIME_AND_DATE_FORMAT })
              .split(',')
              .map((v, i) => (
                <div key={i}>{v}</div>
              ))}
          </Box>
        ),
      },
      {
        field: 'checkedOutDateTime',
        minWidth: 130,
        display: 'flex',
        type: 'dateTime',
        headerName: formatMessage({
          id: 'view.statistic.check_in_all.table.header.check_out_time',
          defaultMessage: 'Check out time',
        }),
        valueFormatter: value => (value ? formatDate(value, { ...TIME_AND_DATE_FORMAT }) : null),
        renderCell: cell =>
          cell.value ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', lineHeight: 1 }}>
              {formatDate(cell.value, { ...TIME_AND_DATE_FORMAT })
                .split(',')
                .map((v, i) => (
                  <div key={i}>{v}</div>
                ))}
            </Box>
          ) : null,
      },
      {
        field: 'value',
        width: 90,
        display: 'flex',
        headerName: formatMessage({
          id: 'view.statistic.check_in_all.table.header.value',
          defaultMessage: 'The Count',
        }),
      },
    ],

    [
      formatMessage,
      checkInsOptions,
      courseTypeOptions,
      checkInsTextHelper,
      courseTypeTextHelper,
      formatDate,
    ],
  );

  const exportableColumns = columns.filter(column => !column.disableExport);

  return (
    <CustomDataGrid
      columns={columns}
      rows={data}
      columnHeaderHeight={48}
      loading={isLoading}
      autoHeight
      pagination
      rowHeight={64}
      hideFooter={!data.length}
      getRowId={row => row.id}
      sortModel={sortedState}
      onSortModelChange={model => setSortedState(model)}
      slots={{
        toolbar: StatisticsTableToolbar,
      }}
      slotProps={{
        toolbar: {
          handleSubmit: setDateRange,
          value: dateRange,
          fieldsToExport: exportableColumns,
        },
      }}
      isRowSelectable={() => false}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
            company_name: false,
          },
        },
      }}
    />
  );
};

export default CheckInTable;
