import { Box, styled, Typography } from '@mui/material';

import { withTransientProps } from '@/App/Theme/withTransientProps';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const CourseName = styled(Typography, withTransientProps)<{ $cancelled: boolean }>`
  font-weight: bold;
  display: flex;
  font-family: Roboto-Bold;
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
  color: ${props => (props.$cancelled ? FigmaDesignTokens.Grey[500] : 'inherit')};
`;

export const PremiumImage = styled('img')`
  position: absolute;
  top: 0;
`;

export const Container = styled(Box)`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
`;

export const Cancelled = styled(Typography)`
  position: absolute;
  top: 0;
  background-color: ${FigmaDesignTokens.Blue[400]};
  font-family: Roboto-Bold;
  font-size: 9px;
  padding: 2px 7px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  color: ${FigmaDesignTokens.White};
  line-height: 14px;
`;
