import { motion } from 'framer-motion';
import { Toast } from 'react-hot-toast';
import useStyles from './ToastItem.styles';
import { cloneElement, isValidElement } from 'react';

const ToastItem = ({
  message,
  duration,
  createdAt,
  id,
  style,
  index,
}: Toast & { index: number }) => {
  const { classes } = useStyles(style)();

  return (
    <motion.div
      layout
      initial={{ y: 0, opacity: 0 }}
      animate={{ y: 40 * index, opacity: 1 }}
      transition={{ duration: 0.2 }}
      className={classes.container}
      key={id}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}>
        {message ? (
          isValidElement(message) ? (
            cloneElement(message, {
              id,
              duration,
              createdAt,
            } as React.Attributes)
          ) : (
            <div>{message}</div>
          )
        ) : null}
      </div>
    </motion.div>
  );
};

export default ToastItem;
