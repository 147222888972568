import { Typography, Box } from '@mui/material';
import { sanitize } from 'dompurify';

type FaqCleanArticleProps = {
  answer: string;
};

const FaqCleanArticle: React.FC<FaqCleanArticleProps> = ({ answer }) => {
  const santinizedText = sanitize(answer, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  });
  return (
    <Box>
      <Typography
        variant='subtitle1'
        className='answer__item'
        dangerouslySetInnerHTML={{ __html: santinizedText }}
      />
      <br />
    </Box>
  );
};

export default FaqCleanArticle;
