import { useIntl } from 'react-intl';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { CustomTooltip } from '@/App/Shared/Tooltip/CustomTooltip';

import { EventsService } from '@/Services';
import { EventListItem } from '@/Types';
import { EVENT_LIST_VIEW } from '@/Hooks/useEventsTableView';

import DraftIcon from '@/Static/Icons/atoms-symbols-events-visibility-draft.svg';
import PublishedIcon from '@/Static/Icons/atoms-symbols-events-visibility-published.svg';
import CanceledIcon from '@/Static/Icons/atoms-symbols-events-visibility-canceled.svg';

import { Container } from './VisibilityCell.styles';

type VisibilityCellProps = {
  gridRenderCellParams: GridRenderCellParams<EventListItem, number>;
  view: EVENT_LIST_VIEW | undefined;
};

export const VisibilityCell = ({ gridRenderCellParams }: VisibilityCellProps) => {
  const intl = useIntl();

  return (
    <Container>
      {EventsService.isDraft(gridRenderCellParams.row) && (
        <CustomTooltip
          title={intl.formatMessage({
            id: 'view.courses_and_events.events.table.state.draft',
            defaultMessage: 'Draft',
          })}>
          <img alt='' src={DraftIcon} />
        </CustomTooltip>
      )}
      {EventsService.isCanceled(gridRenderCellParams.row) && (
        <CustomTooltip
          title={intl.formatMessage({
            id: 'view.courses_and_events.events.table.state.canceled',
            defaultMessage: 'cancelled',
          })}>
          <img alt='' src={CanceledIcon} />
        </CustomTooltip>
      )}
      {EventsService.isPublished(gridRenderCellParams.row) && (
        <CustomTooltip
          title={intl.formatMessage({
            id: 'view.courses_and_events.events.table.state.published',
            defaultMessage: 'published',
          })}>
          <img alt='' src={PublishedIcon} />
        </CustomTooltip>
      )}
    </Container>
  );
};
