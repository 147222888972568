export enum ServerErrorTypes {
  SET_SERVER_ERROR = 'SET_SERVER_ERROR',
  REMOVE_SERVER_ERROR = 'REMOVE_SERVER_ERROR',
  SET_SERVER_ERROR_HEIGHT = 'SET_SERVER_ERROR_HEIGHT',
}

export interface ServerErrorState {
  show: boolean;
  message: string;
  height: number;
}

export interface ServerErrorAction {
  type: ServerErrorTypes;
  payload?: ServerErrorState;
}

export const ServerErrorReducer = (state: ServerErrorState, action: ServerErrorAction) => {
  switch (action.type) {
    case ServerErrorTypes.SET_SERVER_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case ServerErrorTypes.SET_SERVER_ERROR_HEIGHT:
      return {
        ...state,
        ...action.payload,
      };
    case ServerErrorTypes.REMOVE_SERVER_ERROR:
      return {
        show: false,
        message: '',
        height: 0,
      };
    default:
      return state;
  }
};
