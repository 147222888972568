import { Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { CustomTable } from '@/App/Shared/Table/CustomTable';
import { EquipmentAndServiceTableHead } from './EquipmentAndServiceTableHead';
import { EquipmentAndServiceContractTableBody } from './TableBody/EquipmentAndServiceContractTableBody';

import { Partner } from '@/Types';
import { BodyTextSmall, SubHeadlineDisplay } from '@/Components';

type EquipmentAndServiceContractTableBoxProps = {
  partner: Partner;
  handleChange: (property: string, value: string, idx: number) => void;
};

export const EquipmentAndServiceContractTableBox = (
  props: EquipmentAndServiceContractTableBoxProps,
): JSX.Element => {
  const { partner, handleChange } = props;
  const { formatMessage } = useIntl();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item>
            <SubHeadlineDisplay
              textContent={formatMessage({
                id: 'form.equipment_and_services.contract.header',
                defaultMessage: 'Contractually agreed equipment and services',
              })}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid container item xs={8}>
            <Grid item xs={12}>
              <BodyTextSmall
                textContent={formatMessage({
                  id: 'form.equipment_and_services.contract.sub_header',
                  defaultMessage:
                    'The following equipment and services were contracted agreed with you. If you wish to change these details, please contact our Verbundpartner team.',
                })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          tableHead={<EquipmentAndServiceTableHead partner={partner} equipmentAndServiceContract />}
          tableBody={<EquipmentAndServiceContractTableBody handleChange={handleChange} />}
        />
      </Grid>
    </Grid>
  );
};
