import { useAppContext } from '@/Context';
import { PartnerServiceFactory } from '@/Services';
import { useCallback, useEffect, useState } from 'react';
import { CurrentUserInfo } from '@/Types';

const useCurrentUserAttr = () => {
  const [userAttr, setUserAttr] = useState<CurrentUserInfo['attributes']>();
  const { dispatch } = useAppContext();

  const getCurrentUser = useCallback(async () => {
    const partnerServiceInstance = new PartnerServiceFactory().getInstance(dispatch);
    const currentSession: CurrentUserInfo | null = await partnerServiceInstance.httpService
      .getAuthClass()
      .currentUserInfo();

    if (!currentSession) {
      return;
    }

    setUserAttr(currentSession.attributes);
  }, [dispatch]);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  return { data: userAttr, refetch: getCurrentUser };
};

export default useCurrentUserAttr;
