import { Box, Fade, Grid } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import Sticky from 'react-stickynode';

import { handleIsStickyBackgroundColor } from '@/Mappers';
import { Partner, UserAttr } from '@/Types';

import { useDashHeaderStyles } from './Header.styles';
import DashSiteAndProfileBar from './ProfileDropdown';

type DashHeaderProps = {
  userAttr: UserAttr;
  partner: Partner;
};

const DashHeader = ({ userAttr, partner }: DashHeaderProps): JSX.Element => {
  const { classes } = useDashHeaderStyles();
  const [isStickyBackgroundVisible, setStickyBackgroundVisible] = useState<boolean>(false);

  const handleStickyStateChange = useCallback(
    (state: { status: number }) => {
      const isStickyBackground = handleIsStickyBackgroundColor(state.status);
      setStickyBackgroundVisible(isStickyBackground);
      return;
    },
    [setStickyBackgroundVisible],
  );

  return (
    <>
      <Fade in={isStickyBackgroundVisible} timeout={{ enter: 250, exit: 50 }}>
        <Box className={classes.stickyBackground} />
      </Fade>
      <Sticky onStateChange={handleStickyStateChange} innerClass={classes.stickyInner}>
        <Grid
          container
          className={clsx(classes.headerContainer, {
            [classes.smallPaddingToBody]: isStickyBackgroundVisible,
          })}>
          <DashSiteAndProfileBar userAttr={userAttr} partner={partner} />
        </Grid>
      </Sticky>
    </>
  );
};

export default DashHeader;
