import { Box } from '@mui/material';

type SpacerProps = {
  verticalSpace?: number | 'inherit';
  horizontalSpace?: number | 'inherit';
};

export const LineDivider = ({ verticalSpace = 0, horizontalSpace = 0 }: SpacerProps) => (
  <Box height={verticalSpace} width={horizontalSpace} />
);
