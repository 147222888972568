import { Grid, InputAdornment, MenuItem } from '@mui/material';
import { Field, FormikProps, useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { InfoMessage } from '@/Components';
import { ReactComponent as Arrow } from '@/Static/Icons/atoms-symbols-arrow-light.svg';
import { CourseTypes, Event } from '@/Types';

export const StreamInfoForm = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const formik = useFormikContext<Event>();

  const streamingHosts = [
    {
      value: 'Zoom',
      displayValue: formatMessage({
        id: 'form.event.field.stream_settings.streamingHost.option.zoom',
        defaultMessage: 'Zoom',
      }),
    },
  ];

  const handleWebsiteChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, formik: FormikProps<Event>) => {
      const updatedString = e.currentTarget.value
        .split(' ')
        .join('')
        .replace('https://', '')
        .replace('http://', '');
      formik.setFieldValue('streamSettings.streamLink', updatedString);
    },
    [],
  );

  return (
    <Grid item xs={12}>
      <ContentContainer>
        <Grid container spacing={4}>
          <Grid item container spacing={4}>
            <Grid item xs={12}>
              <FormHeader
                title={formatMessage({
                  id: 'form.event.header.streaming_information',
                  defaultMessage: 'Streaming information',
                })}
              />
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Grid container spacing={4}>
              <Grid item container spacing={3}>
                <Grid item xs={6}>
                  <Field
                    name='streamSettings.streamingHost'
                    component={FormikTextField}
                    variant='outlined'
                    select
                    SelectProps={{
                      IconComponent: () => (
                        <Arrow className='MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined' />
                      ),
                    }}
                    label={
                      <FormattedMessage
                        id='form.event.field.stream_settings.streaming_host.label'
                        defaultMessage='Streaming provider'
                      />
                    }>
                    {streamingHosts.map((type, k) => (
                      <MenuItem
                        key={k}
                        value={type.value}
                        selected={
                          formik.values.eventType === CourseTypes.ONLINE &&
                          formik.values.streamSettings.streamingHost === type.value
                        }>
                        {type.displayValue}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>

              <Grid item container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    name='streamSettings.streamLink'
                    component={FormikTextField}
                    variant='outlined'
                    type='text'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleWebsiteChange(e, formik)
                    }
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleWebsiteChange(e, formik)
                    }
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>https://</InputAdornment>,
                    }}
                    label={formatMessage({
                      id: 'form.event.field.stream_settings.stream_link.label',
                      defaultMessage: 'Access link to the live stream*',
                    })}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    name='streamSettings.streamPassword'
                    component={FormikTextField}
                    variant='outlined'
                    type='text'
                    label={formatMessage({
                      id: 'form.event.field.stream_settings.stream_password.label',
                      defaultMessage: 'Password',
                    })}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    name='streamSettings.meetingId'
                    component={FormikTextField}
                    variant='outlined'
                    type='text'
                    label={formatMessage({
                      id: 'form.event.field.stream_settings.meeting_id.label',
                      defaultMessage: 'Meeting ID',
                    })}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={FormikTextField}
                    variant='outlined'
                    name='streamSettings.additionalInformation'
                    type='text'
                    placeholder={formatMessage({
                      id: 'form.event.field.stream_settings.additional_information.label',
                      defaultMessage: 'Additional information',
                    })}
                    multiline
                    rows={4}
                    label={formatMessage({
                      id: 'form.event.field.stream_settings.additional_information.label',
                      defaultMessage: 'Additional information',
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={4} item container>
            <Grid item xs={12}>
              <InfoMessage
                title={formatMessage({
                  id: 'form.event.stream_settings.hint.streaming_host.header',
                  defaultMessage: 'Find out streaming information',
                })}
                description={formatMessage({
                  id: 'form.event.stream_settings.hint.streaming_host.description',
                  defaultMessage: `Currently, we support online courses delivered through the streaming provider Zoom.\n\nTo find out the streaming dates for a scheduled
                                    Zoom meeting, click the Meetings tab in the
                                    Zoom desktop app. There you will find your scheduled meetings.
                                    Click View Meeting Invitation to view details such as the meeting ID, password, and invitation link.`,
                })}
                type='information'
              />
            </Grid>
          </Grid>
        </Grid>
      </ContentContainer>
    </Grid>
  );
};
