import { Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../design/design-tokens.json';
import { Login } from './Components/Login';
import { GuestSwitchBtn } from './Components/Shared/Btns/GuestSwitchBtn';

const useStyles = makeStyles()(theme => ({
  root: {
    overflow: 'scroll',
    background: `linear-gradient(165deg, ${theme.palette.primary.main} -8%, ${FigmaDesignTokens.Blue[200]} 80%)`,
    height: '100%',
  },
}));

export const LoginPage = () => {
  const { classes } = useStyles();
  const intl = useIntl();

  return (
    <Grid className={classes.root}>
      <GuestSwitchBtn
        header={intl.formatMessage({
          id: 'view.login.header',
          defaultMessage: "You don't have an account for the Hansefit partner portal yet?",
        })}
        href='/register'
        btnText={intl.formatMessage({
          id: 'view.login.cta',
          defaultMessage: 'Register now',
        })}
      />
      <Login />
    </Grid>
  );
};
