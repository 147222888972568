import { Button, createStyles } from '@mui/material';
import { withStyles } from 'tss-react/mui';

export const CustomizedButtonUpload = withStyles(Button, theme =>
  createStyles({
    root: {
      marginTop: 10,
      padding: '4px 11px',
      fontSize: 12,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.83,
      letterSpacing: 0.34,
      color: theme.palette.primary.dark,
      border: `solid 1px ${theme.palette.primary.dark}`,
      borderRadius: 18,
    },
  }),
);
