import { useMemo } from 'react';

import { getDashboardPathname, getDescriptionPathname, getOpeningHoursPathname } from '@/Mappers';
import { BreadcrumbProps } from '@/Types';

import useCurrentLanguage from '../useCurrentLanguage';
import useMemoedIntl from '../useMemoedIntl';

const useDescriptionAndOpeningHoursBreadcrumbs = (currentTab: number): BreadcrumbProps[] => {
  const { formatMessage } = useMemoedIntl();
  const currentLanguage = useCurrentLanguage();

  const dynamicBreadcrumbs = useMemo(() => {
    const staticBreadcrumbs = [
      {
        title: formatMessage({ id: 'view.dashboard', defaultMessage: 'Dashboard' }),
        href: getDashboardPathname(currentLanguage),
      },
      {
        title: formatMessage({
          id: 'view.description_and_opening_hours',
          defaultMessage: 'Description & opening hours',
        }),
        href: getDescriptionPathname(currentLanguage),
      },
    ];

    if (currentTab === 1) {
      staticBreadcrumbs.push({
        title: formatMessage({
          id: 'view.description_and_opening_hours.opening_hours',
          defaultMessage: 'Opening hours',
        }),
        href: getOpeningHoursPathname(currentLanguage),
      });
    } else {
      staticBreadcrumbs.push({
        title: formatMessage({
          id: 'view.description_and_opening_hours.description',
          defaultMessage: 'Description',
        }),
        href: getDescriptionPathname(currentLanguage),
      });
    }
    return staticBreadcrumbs;
  }, [formatMessage, currentTab, currentLanguage]);

  return dynamicBreadcrumbs;
};

export default useDescriptionAndOpeningHoursBreadcrumbs;
