import axios from 'axios';
import axiosRetry from 'axios-retry';

const axiosRetryClient = axios.create();

axiosRetry(axiosRetryClient, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: () => true, // retry no matter what
});

export default axiosRetryClient;
