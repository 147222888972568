import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppContext } from '@/Context';
import { queryClient } from '@/Helpers';
import { PartnerServiceFactory } from '@/Services';
import {
  AddressAndContactPartialType,
  CategoriesAndTagsPartial,
  CheckInOptionsPartial,
  DescriptionPartial,
  EquipmentsAndServicesUpdate,
  OpeningHoursPartial,
  Partner,
} from '@/Types';

type useUpdatePartnerDataMutationProps = {
  casPublicId: Partner['casPublicId'];
  updatedData:
    | AddressAndContactPartialType
    | CategoriesAndTagsPartial
    | CheckInOptionsPartial
    | OpeningHoursPartial
    | DescriptionPartial
    | EquipmentsAndServicesUpdate;
};

const useUpdatePartnerDataMutation = () => {
  const { dispatch } = useAppContext();
  const partnerService = useMemo(
    () => new PartnerServiceFactory().getInstance(dispatch),
    [dispatch],
  );

  return useMutation(
    ({ casPublicId, updatedData }: useUpdatePartnerDataMutationProps) =>
      partnerService.updateData(casPublicId, updatedData),
    {
      onSuccess(response) {
        queryClient.setQueryData(['useGetPartnerQuery'], response.data);
      },
    },
  );
};

export default useUpdatePartnerDataMutation;
