import { makeStyles } from 'tss-react/mui';

export const useAddressAndContactFormStyles = makeStyles()(() => ({
  phoneNumberContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  phoneNumber: {
    width: '100%',
  },
  countryPhoneNumberPrefix: {
    width: 128,
    marginRight: 24,
    '& .MuiOutlinedInput-root.MuiInputBase-formControl': {
      height: 53,
    },
  },
}));
