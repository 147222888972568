import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { useEventsModalHelper } from '@/Hooks/useEventsModalHelper';
import { useMemo } from 'react';
import { TranslationService } from '@/App/Shared/Translation/TranslationService';
import { EventsModal } from './EventsModal';
import { InfoMessage, LineDivider, Button } from '@/Components';
import { EventList } from '@/Types';

import { ButtonContainer } from './EventsModal.styles';

import { Information, HintContainer } from './CancelEvents.styles';

interface CancelEventsProps {
  eventIds: string[];
  handleClose: () => void;
  handleCancel: (eventIds: string[]) => void;
  open: boolean;
}

export const CancelEvents = ({ eventIds, handleClose, handleCancel, open }: CancelEventsProps) => {
  const { formatMessage } = useIntl();
  const { values } = useFormikContext<EventList>();
  const { subHeader } = useEventsModalHelper(eventIds, values.eventsData.result);

  const handleCancelEvents = () => {
    handleCancel(eventIds);
    handleClose();
  };

  const header = useMemo(() => {
    if (eventIds.length === 1) {
      return formatMessage({
        id: 'view.courses_and_events.events.dialog.edit_event.header.cancel_event',
        defaultMessage: 'Cancel appointment',
      });
    } else {
      return TranslationService.replacePlaceholder(
        formatMessage({
          id: 'view.courses_and_events.events.dialog.edit_event.header.cancel_events',
          defaultMessage: '{{checkedEvents}} Cancel dates',
        }),
        '{{checkedEvents}}',
        eventIds.length.toString(),
      );
    }
  }, [eventIds.length, formatMessage]);

  return (
    <EventsModal open={open} header={header} subHeader={subHeader} onClose={handleClose}>
      <>
        <Information variant='body2'>
          {eventIds.length === 1 ? (
            <FormattedMessage
              id='view.courses_and_events.events.dialog.edit_event.text.cancel'
              defaultMessage='Are you sure you want to cancel this appointment?'
            />
          ) : (
            <FormattedMessage
              id='view.courses_and_events.events.dialog.edit_events.text.cancel'
              defaultMessage='Are you sure you want to cancel these appointments?'
            />
          )}
        </Information>
        <HintContainer>
          <InfoMessage
            title={
              eventIds.length === 1
                ? formatMessage({
                    id: 'view.courses_and_events.events.dialog.edit_event.hint.cancel_event.header',
                    defaultMessage: 'Existing registrations will be canceled',
                  })
                : formatMessage({
                    id: 'view.courses_and_events.events.dialog.edit_events.hint.cancel_event.header',
                    defaultMessage: 'Existing registrations will be canceled',
                  })
            }
            description={
              eventIds.length === 1
                ? formatMessage({
                    id: 'view.courses_and_events.events.dialog.edit_event.hint.cancel_event.description',
                    defaultMessage:
                      'If registrations for this date have already been received, Hansefit members will be informed that the date will not take place.',
                  })
                : formatMessage({
                    id: 'view.courses_and_events.events.dialog.edit_events.hint.cancel_event.description',
                    defaultMessage:
                      'If registrations for these dates have already been received, Hansefit members will be informed that the dates will not take place.',
                  })
            }
          />
        </HintContainer>

        <ButtonContainer>
          <LineDivider horizontalSpace='inherit' />
          <Button onClick={handleCancelEvents}>
            {eventIds.length === 1
              ? formatMessage({
                  id: 'view.courses_and_events.events.dialog.cancel.cta.cancel_event',
                  defaultMessage: 'Cancel appointment',
                })
              : formatMessage({
                  id: 'view.courses_and_events.events.dialog.edit_events.cta.cancel',
                  defaultMessage: 'Cancel appointments',
                })}
          </Button>
        </ButtonContainer>
      </>
    </EventsModal>
  );
};
