import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@/Context';
import { PartnerCoursesServiceFactory } from '@/Services';

type useGetPartnerCourseCategoriesQueryProps = {
  currentLanguage: string;
};

const useGetPartnerCourseCategoriesQuery = ({
  currentLanguage,
}: useGetPartnerCourseCategoriesQueryProps) => {
  const { dispatch } = useAppContext();
  const partnerCoursesService = new PartnerCoursesServiceFactory().getInstance(dispatch);

  const queryResult = useQuery(
    ['getPartnerCourseCategories'],
    async () => await partnerCoursesService.getCourseCategories(currentLanguage),

    {
      cacheTime: 0,
    },
  );

  return queryResult;
};

export default useGetPartnerCourseCategoriesQuery;
