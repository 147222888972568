import { Box, Typography } from '@mui/material';
import qs from 'qs';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CourseManagerTypes, useAppContext } from '@/Context';
import { useGetPartnerNamesQuery } from '@/Queries';

import { useCurrentLanguage } from '../../Hooks/useCurrentLanguage';
import { useSwitchPartnerStyles } from './SwitchPartner.styles';

export const SwitchPartner = () => {
  const { classes } = useSwitchPartnerStyles();
  const { dispatch } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const currentLanguage = useCurrentLanguage();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { data: partnerNamesList = [] } = useGetPartnerNamesQuery();

  useEffect(() => {
    if (queryParams.publicId && typeof queryParams.publicId === 'string') {
      setTimeout(() => {
        navigate(`/${currentLanguage}/dashboard`);
      }, 3000);
      return () => {
        localStorage.setItem('publicId', queryParams.publicId as string);
        sessionStorage.removeItem('ResourceAuth');
        dispatch({ type: CourseManagerTypes.SET_COURSE, payload: { course: null } });
        dispatch({ type: CourseManagerTypes.SET_EVENTS, payload: { events: null } });
      };
    }
  }, [dispatch, navigate, currentLanguage, queryParams]);

  const partner = partnerNamesList.find(partner => partner.publicId === queryParams.publicId);

  return (
    <Box className={classes.background}>
      <Box className={classes.partnerNameWrapper}>
        <Typography variant='body1' className={classes.partnerName}>
          {partner?.studioName}
        </Typography>
      </Box>
    </Box>
  );
};
