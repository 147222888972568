import { IntlShape } from 'react-intl/src/types';
import * as Yup from 'yup';

import { DeeplService } from '@/Services';
import { LanguageType } from '@/Types';

export const CourseValidation = (
  language: LanguageType,
  intl: IntlShape,
  deeplService: DeeplService,
) =>
  Yup.object().shape({
    shortDescription: Yup.object()
      .shape(
        (function () {
          const t = deeplService.getLanguages().map(currentLanguage => ({
            [`${currentLanguage}`]: Yup.string()
              .nullable()
              .required(
                intl.formatMessage({
                  id: 'form.course.error.shortDescription.required',
                  defaultMessage: 'Please provide a brief description.',
                }),
              ),
          }));

          return t.reduce((a, v) => ({ ...a, ...v }), {});
        })(),
      )
      .test('filled-languages', '', (data, context) => {
        const { createError, path } = context;
        let otherLanguagesEmpty: boolean = false;
        deeplService.getLanguages().forEach(languageListItem => {
          if (languageListItem !== language && !otherLanguagesEmpty && !data[languageListItem]) {
            otherLanguagesEmpty = true;
          }
        });

        if (otherLanguagesEmpty) {
          return createError({
            path: path + `.${language}`,
            message: intl.formatMessage({
              id: 'form.course.error.shortDescription.check_other_languages',
              defaultMessage: 'Please check the automatic translation.',
            }),
          });
        } else {
          return true;
        }
      }),
    courseName: Yup.object()
      .shape(
        (function () {
          const t = deeplService.getLanguages().map(currentLanguage => ({
            [currentLanguage]: Yup.string()
              .nullable()
              .required(
                intl.formatMessage({
                  id: 'form.course.error.courseName.required',
                  defaultMessage: 'Please provide a course name.',
                }),
              ),
          }));

          return t.reduce((a, v) => ({ ...a, ...v }), {});
        })(),
      )
      .test('filled-languages', '', (data, context) => {
        const { createError, path } = context;
        let otherLanguagesEmpty = false;

        deeplService.getLanguages().forEach(languageListItem => {
          if (languageListItem !== language && !otherLanguagesEmpty && !data[languageListItem]) {
            otherLanguagesEmpty = true;
          }
        });

        if (otherLanguagesEmpty) {
          return createError({
            path: path + `.${language}`,
            message: intl.formatMessage({
              id: 'form.course.error.courseName.check_other_languages',
              defaultMessage: 'Please check the automatic translation.',
            }),
          });
        } else {
          return true;
        }
      }),
    internalName: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.course.error.internalName.required',
          defaultMessage: 'Please provide an internal course designation.',
        }),
      ),
    courseDuration: Yup.number()
      .nullable()
      .min(
        1,
        intl.formatMessage({
          id: 'form.course.error.courseDuration.required',
          defaultMessage: 'Please specify a course duration.',
        }),
      )
      .required(
        intl.formatMessage({
          id: 'form.course.error.courseDuration.required',
          defaultMessage: 'Please specify a course duration.',
        }),
      ),
    courseType: Yup.string().required(
      intl.formatMessage({
        id: 'form.course.error.courseType.required',
        defaultMessage: 'Please specify a course type.',
      }),
    ),
    capacity: Yup.number()
      .nullable()
      .min(
        1,
        intl.formatMessage({
          id: 'form.course.error.capacity.required',
          defaultMessage: 'Please specify a capacity (max. available seats).',
        }),
      )
      .required(
        intl.formatMessage({
          id: 'form.course.error.capacity.required',
          defaultMessage: 'Please specify a capacity (max. available seats).',
        }),
      ),
    level: Yup.string().required(
      intl.formatMessage({
        id: 'form.event.error.stream_settings.level.required',
        defaultMessage: 'Please select a level.',
      }),
    ),
    mainCategory: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.course.error.mainCategory.required',
          defaultMessage: 'Please specify a main category.',
        }),
      ),
  });
