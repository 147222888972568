import { Chip, createStyles } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../../design/design-tokens.json';

export const CustomizedChip = withStyles(Chip, theme =>
  createStyles({
    root: {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.primary.main,
      '& .MuiChip-deleteIconColorPrimary': {
        color: theme.palette.info.main,
      },
      '&.MuiChip-colorSecondary': {
        backgroundColor: FigmaDesignTokens.White,
        border: `1px solid ${FigmaDesignTokens.Grey[100]}`,
        color: theme.palette.text.primary,
        '&.Mui-disabled': {
          backgroundColor: theme.palette.background.default,
          border: 'none',
          opacity: 1,
          color: FigmaDesignTokens.Grey[400],
        },
      },
      '& .MuiChip-label': {
        fontSize: 16,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.43,
        letterSpacing: 0.18,
      },
    },
  }),
);
