import { CSSObject } from '@emotion/react';
import { Box } from '@mui/system';
import { ReactNode } from 'react';

import FigmaDesignTokens from '../../../design/design-tokens.json';

type CondensedAccountInfoProps = {
  firstName?: string;
  lastName?: string;
  studioName?: string;
  email: string;
  companyId: string;
  actionButton?: ReactNode;
  containerCss?: CSSObject;
};

const CondensedAccountInfo = ({
  firstName = '',
  lastName = '',
  studioName = '',
  email,
  companyId,
  actionButton,
  containerCss,
}: CondensedAccountInfoProps) => (
  <Box
    sx={{
      padding: '16px',
      background: FigmaDesignTokens.Grey[50],
      borderRadius: '4px',
      border: `0.5px ${FigmaDesignTokens.txt.Brand.brand} solid`,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      display: 'inline-flex',
      width: '100%',
      gap: '8px',
      ...containerCss,
    }}>
    <Box
      sx={{
        color: FigmaDesignTokens.Grey[950],
        fontSize: '18px',
        fontFamily: 'Roboto-Regular',
        fontWeight: '600',
        wordWrap: 'break-word',
      }}>
      {[firstName, lastName].filter(name => name).join(' ')}
    </Box>
    <Box
      sx={{
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '8px',
        display: 'inline-flex',
      }}>
      <Box
        sx={{
          flex: '1 1 0',
          color: FigmaDesignTokens.Grey[500],
          fontSize: '16px',
          fontFamily: 'Roboto-Regular',
          fontWeight: '400',
        }}>
        {[studioName, email, companyId ? `ID:\u00A0${companyId}` : null]
          .filter(name => name)
          .join(' | ')}
      </Box>
    </Box>

    {actionButton}
  </Box>
);

export default CondensedAccountInfo;
