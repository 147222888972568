import { Grid } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useAppDispatch } from '@/Hooks/useAppDispatch';
import { AuthActions } from '@/Store/Auth';

import FigmaDesignTokens from '../../design/design-tokens.json';

const useStyles = makeStyles()(theme => ({
  root: {
    overflow: 'scroll',
    background: `linear-gradient(165deg, ${theme.palette.primary.main} -8%, ${FigmaDesignTokens.Blue[200]} 80%)`,
    height: '100%',
  },
}));

const LogoutPage = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  useEffect(() => {
    Sentry.setUser(null);
    dispatch(AuthActions.Logout());
    localStorage.removeItem('publicId');
  }, [dispatch]);

  return <Grid className={classes.root}>Logout</Grid>;
};

export default LogoutPage;
