import { Box, Link } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BodyTextSmall, Button, PageHeadline, PreviewModal, RegularTextAccent } from '@/Components';
import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import {
  getCheckInOptionsPathname,
  getEquipmentPathname,
  getLogoPathname,
  getOpeningHoursPathname,
} from '@/Mappers';
import useGetPartnerAsset from '@/Queries/useGetPartnerAsset';
import CheckIcon from '@/Static/Icons/atoms-symbols-icons-check.svg';
import FailIcon from '@/Static/Icons/atoms-symbols-icons-fail.svg';
import { Category, Partner } from '@/Types';

import { PlaceholderLogo } from './ProfileInfo/PlaceholderLogo';
import { useProfileInfoStyles } from './StudioProfile.styles';

type ProfileInfoProps = {
  partnerCategories: Category[];
  partner?: Partner;
};

export const ProfileInfo = ({ partnerCategories, partner }: ProfileInfoProps) => {
  const { classes } = useProfileInfoStyles();
  const { formatMessage } = useMemoedIntl();
  const [resolvedCategory, setResolvedCategory] = useState<Category>();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const navigate = useNavigate();
  const currentLanguage = useCurrentLanguage();
  const { data: signedResource, isSuccess: fetchedSignedResource } = useGetPartnerAsset();

  const {
    sectionCompletion: {
      checkInOptions = false,
      logo = false,
      openingHours = false,
      services = false,
    },
  } = partner || {
    sectionCompletion: {
      checkInOptions: false,
      logo: false,
      openingHours: false,
      services: false,
    },
  };

  const isProfileComplete = checkInOptions && logo && openingHours && services;

  const profileStatus = useMemo(
    () => [
      {
        title: formatMessage({
          id: 'view.common_information.check_in_options',
          defaultMessage: 'Check-in options',
        }),
        icon: checkInOptions ? CheckIcon : FailIcon,
        completed: checkInOptions,
        path: getCheckInOptionsPathname(currentLanguage),
      },
      {
        title: formatMessage({
          id: 'view.description_and_opening_hours.opening_hours',
          defaultMessage: 'Opening hours',
        }),
        icon: openingHours ? CheckIcon : FailIcon,
        completed: openingHours,
        path: getOpeningHoursPathname(currentLanguage),
      },
      {
        title: formatMessage({
          id: 'view.equipment_and_services',
          defaultMessage: 'Equipment & Services',
        }),
        icon: services ? CheckIcon : FailIcon,
        completed: services,
        path: getEquipmentPathname(currentLanguage),
      },
      {
        title: formatMessage({ id: 'view.photo_and_video.logo', defaultMessage: 'Logo' }),
        icon: logo ? CheckIcon : FailIcon,
        completed: logo,
        path: getLogoPathname(currentLanguage),
      },
    ],
    [checkInOptions, currentLanguage, formatMessage, logo, openingHours, services],
  );

  useEffect(() => {
    if (!partner) {
      return;
    }
    const resolved = partnerCategories.find(
      (category: Category) => Number.parseInt(category.id, 10) === partner.categoryPrimary,
    );
    setResolvedCategory(resolved);
  }, [partner, partnerCategories]);

  const handleShowPreview = useCallback(() => {
    setShowPreview(true);
  }, []);

  const handleClosePreview = useCallback(() => {
    setShowPreview(false);
  }, []);

  const handleRedirect = () => {
    if (!checkInOptions) {
      const pathname = getCheckInOptionsPathname(currentLanguage);
      navigate(pathname);
      return;
    }
    if (!openingHours) {
      const pathname = getOpeningHoursPathname(currentLanguage);
      navigate(pathname);
      return;
    }
    if (!services) {
      const pathname = getEquipmentPathname(currentLanguage);
      navigate(pathname);
      return;
    }
    if (!logo) {
      const pathname = getLogoPathname(currentLanguage);
      navigate(pathname);
      return;
    }
  };

  const handleRedirectToPath = (e: React.MouseEvent<HTMLElement>, path: string) => {
    e.preventDefault();
    navigate(path);
  };

  if (!partner) {
    return null;
  }

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.infoWrapper}>
          {!fetchedSignedResource && (
            <Box className={clsx(classes.placeholder, classes.placeholderLogo)} />
          )}
          {fetchedSignedResource && signedResource && (
            <img src={signedResource} alt='logo' className={classes.logo} />
          )}
          {fetchedSignedResource && !signedResource && <PlaceholderLogo />}
          <PageHeadline textContent={partner.studioName} />
          {resolvedCategory && (
            <Box className={classes.categoryInfo}>
              {resolvedCategory.iconUrlContour && (
                <img
                  alt=''
                  src={resolvedCategory.iconUrlContour}
                  className={classes.categoryIcon}
                />
              )}
              <BodyTextSmall
                textContent={resolvedCategory?.shortDescription || ''}
                className={{
                  [classes.noCategoryInfoMargin]: !resolvedCategory.iconUrlContour,
                }}
              />
            </Box>
          )}
          <Box>
            <RegularTextAccent
              textContent={
                isProfileComplete
                  ? formatMessage({
                      id: 'view.dashboard.completed_partner',
                      defaultMessage: 'Studioprofil vollständig',
                    })
                  : formatMessage({
                      id: 'view.dashboard.complete_partner',
                      defaultMessage: 'Studioprofil vervollständigen',
                    })
              }
            />
            {profileStatus.map((item, index) => (
              <Box className={classes.completeWrapper} key={index}>
                <img src={item.icon} alt='' />
                {item.completed ? (
                  <BodyTextSmall textContent={item.title} />
                ) : (
                  <Link
                    href={item.path}
                    onClick={e => handleRedirectToPath(e, item.path)}
                    variant='body2'
                    className={classes.profileStatusLink}>
                    {item.title}
                  </Link>
                )}
              </Box>
            ))}
            <Box className={classes.btnContainer}>
              {!isProfileComplete && (
                <Button size='sm' width='max' onClick={handleRedirect}>
                  {formatMessage({
                    id: 'cta.complete_partner',
                    defaultMessage: 'Jetzt Profil fertig stellen',
                  })}
                </Button>
              )}
              <Button variant='outlined' width='max' onClick={handleShowPreview}>
                {formatMessage({
                  id: 'cta.preview_partner',
                  defaultMessage: ' Zur Studioprofil vorschau',
                })}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {showPreview && <PreviewModal onClose={handleClosePreview} />}
    </>
  );
};
