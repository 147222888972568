import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { MainHeadline } from '@/Components';
import { useAppContext } from '@/Context';
import { RegisterPartnerServiceFactory } from '@/Services';
import { InitialUserType, ValidatePartnerDataType } from '@/Types';

import { useRegisterFormStyles } from './RegisterPartner.styles';
import { RegisterStepper } from './Stepper/RegisterStepper';
import { ConfirmationStep } from './Steps/ConfirmationStep';
import { CreateUserStep } from './Steps/CreateUserStep';
import { VALIDATE_PARTNER_STEP_VIEW, ValidatePartnerStep } from './Steps/ValidatePartnerStep';

export const RegisterForm = () => {
  const { classes } = useRegisterFormStyles();
  const { formatMessage } = useIntl();
  const { dispatch } = useAppContext();
  const [step, setStep] = useState(0);
  const [validatePartnerData, setValidatePartnerData] = useState<ValidatePartnerDataType>();
  const [initialUser, setInitialUser] = useState<InitialUserType>();

  const registerPartnerService = new RegisterPartnerServiceFactory().getInstance(dispatch);

  const handleStep = useCallback((value: number) => {
    setStep(value);
  }, []);

  const handleInitialUser = useCallback((user: InitialUserType) => {
    setInitialUser(user);
  }, []);

  const handleValidatePartnerData = useCallback((params: ValidatePartnerDataType) => {
    setValidatePartnerData(params);
  }, []);

  if (!registerPartnerService) {
    return null;
  }

  return (
    <Grid container direction='column' alignItems='center'>
      <Box className={classes.headerWrapper}>
        <MainHeadline
          className={clsx(classes.mainHeader)}
          textContent={formatMessage({
            id: 'form.register.header',
            defaultMessage: 'Verbundpartner portal',
          })}
        />
        <RegisterStepper activeStep={step} />
      </Box>
      {step === 0 && (
        <ValidatePartnerStep
          addPartner={false}
          header={formatMessage({
            id: 'form.register.stepper.step.initial_startcode.header',
            defaultMessage: 'Partner registration',
          })}
          subHeader={formatMessage({
            id: 'form.register.stepper.step.initial_startcode.subheader',
            defaultMessage:
              'Please verify yourself as a Hansefit partner by entering your partner ID and your personal start code.',
          })}
          ctaTitle={formatMessage({
            id: 'form.register.stepper.step.initial_startcode.cta',
            defaultMessage: "Let's go",
          })}
          validatePartnerStepView={VALIDATE_PARTNER_STEP_VIEW.REGISTER}
          handleStep={handleStep}
          handleValidatePartnerData={handleValidatePartnerData}
        />
      )}
      {step === 1 && validatePartnerData && (
        <CreateUserStep
          handleStep={handleStep}
          handleInitialUser={handleInitialUser}
          validatePartnerData={validatePartnerData}
          registerPartnerService={registerPartnerService}
        />
      )}

      {step === 2 && (
        <ConfirmationStep
          email={initialUser?.email}
          password={initialUser?.password}
          showHeader={false}
          isEmailConfirmed={false}
        />
      )}
    </Grid>
  );
};
