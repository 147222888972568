import { Box, CircularProgress } from '@mui/material';
import { useLoaderStyles } from './Loader.styles';
import clsx from 'clsx';

type SpinnerLoaderProps = {
  styles?: string;
  isFullScreen?: boolean;
};

export const SpinnerLoader = ({ styles, isFullScreen = false }: SpinnerLoaderProps) => {
  const { classes } = useLoaderStyles();

  return (
    <Box
      className={clsx(styles, { [classes.container]: isFullScreen })}
      sx={{ margin: '0 auto', padding: '36px', width: 'fit-content' }}>
      <CircularProgress thickness={6} />
    </Box>
  );
};
