import axios, { AxiosInstance } from 'axios';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import { Dispatch } from 'react';
import { AnyStateAction, ServerErrorTypes } from '@/Context';

export class HttpService {
  public authClass: AuthClass;
  public dispatch: Dispatch<AnyStateAction>;

  constructor(authClass: AuthClass, dispatch: Dispatch<AnyStateAction>) {
    this.authClass = authClass;
    this.dispatch = dispatch;
  }

  public getAuthClass(): AuthClass {
    return this.authClass;
  }

  public async getAxios(): Promise<AxiosInstance> {
    const instance = axios.create({
      timeout: 30000,
    });
    instance.interceptors.response.use(
      response => {
        this.dispatch({
          type: ServerErrorTypes.REMOVE_SERVER_ERROR,
        });
        return response;
      },
      error => {
        setTimeout(() => {
          window.scrollTo(0, 0);
        });
        this.dispatch({
          type: ServerErrorTypes.SET_SERVER_ERROR,
          payload: {
            show: true,
            message: error.response?.data?.detail ? error.response.data.detail : error.message,
            height: 0,
          },
        });
        throw error;
      },
    );

    return instance;
  }

  public async getAuthenticatedAxios(): Promise<AxiosInstance> {
    const currentSession = await this.getAuthClass().currentSession();

    const customHeaders = {
      Authorization: 'Bearer ' + currentSession.getIdToken().getJwtToken(),
    };

    const instance = axios.create({
      timeout: 30000,
      headers: customHeaders,
    });

    instance.interceptors.response.use(
      response => {
        this.dispatch({
          type: ServerErrorTypes.REMOVE_SERVER_ERROR,
        });
        return response;
      },
      error => {
        if (
          error?.response?.config?.url?.includes('/addUser') ||
          (error?.response?.data?.message === 'JWT_USER_NOT_ADMIN' &&
            error?.response?.config?.method === 'get')
        ) {
        } else {
          setTimeout(() => {
            window.scrollTo(0, 0);
          });
          this.dispatch({
            type: ServerErrorTypes.SET_SERVER_ERROR,
            payload: {
              show: true,
              message: error.response?.data?.detail ? error.response.data.detail : error.message,
              height: 0,
            },
          });
        }

        return error;
      },
    );

    return instance;
  }

  public async getAuthenticatedAxiosForPatchRequest(): Promise<AxiosInstance> {
    const currentSession = await this.getAuthClass().currentSession();
    const customHeaders = {
      Authorization: 'Bearer ' + currentSession.getIdToken().getJwtToken(),
      'Content-Type': 'application/merge-patch+json',
    };
    const instance = axios.create({
      timeout: 30000,
      headers: customHeaders,
    });

    instance.interceptors.response.use(
      response => {
        this.dispatch({
          type: ServerErrorTypes.REMOVE_SERVER_ERROR,
        });
        return response;
      },
      error => {
        setTimeout(() => {
          window.scrollTo(0, 0);
        });
        this.dispatch({
          type: ServerErrorTypes.SET_SERVER_ERROR,
          payload: {
            show: true,
            message: error.response?.data?.detail ? error.response.data.detail : error.message,
            height: 0,
          },
        });
        throw error;
      },
    );

    return instance;
  }
}
