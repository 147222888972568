import { EnvironmentService, HttpService } from '@/Services';
import { Auth } from 'aws-amplify';
import { UsersMap } from '@/Mappers';
import { Dispatch } from 'react';
import { AnyStateAction } from '@/Context';

export class UsersServiceFactory {
  public getInstance(dispatch: Dispatch<AnyStateAction>): UsersMap {
    return new UsersMap(new HttpService(Auth, dispatch), new EnvironmentService());
  }
}
