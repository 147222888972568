import { useMemo } from 'react';

import { getDashboardPathname, getScheduledEventsPathname } from '@/Mappers';
import { BreadcrumbProps } from '@/Types';

import useCurrentLanguage from '../useCurrentLanguage';
import useMemoedIntl from '../useMemoedIntl';

const useScheduleBreadcrumbs = (): BreadcrumbProps[] => {
  const { formatMessage } = useMemoedIntl();
  const currentLanguage = useCurrentLanguage();

  const staticBreadcrumbs = useMemo(
    () => [
      {
        title: formatMessage({ id: 'view.dashboard', defaultMessage: 'Dashboard' }),
        href: getDashboardPathname(currentLanguage),
      },
      {
        title: formatMessage({
          id: 'breadcrumbs.schedule',
          defaultMessage: 'Schedule',
        }),
        href: getScheduledEventsPathname(currentLanguage),
      },
    ],
    [formatMessage, currentLanguage],
  );

  return staticBreadcrumbs;
};

export default useScheduleBreadcrumbs;
