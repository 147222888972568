import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppContext } from '@/Context';
import { AssetServiceFactory } from '@/Services';
import { Partner } from '@/Types';

type useAssignLogoProps = {
  casPublicId: Partner['casPublicId'];
  filename: string;
  originalFilename: string;
};

const useUploadLogoMutation = () => {
  const { dispatch } = useAppContext();
  const assetService = useMemo(() => new AssetServiceFactory().getInstance(dispatch), [dispatch]);

  return useMutation(({ casPublicId, filename, originalFilename }: useAssignLogoProps) =>
    assetService.assignLogo(casPublicId, {
      filename,
      originalFilename,
    }),
  );
};

export default useUploadLogoMutation;
