import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useIntl } from 'react-intl';
import { EventListItem } from '@/Types';
import { Duration } from './Duration.styles';

export interface DurationCellProps {
  gridRenderCellParams: GridRenderCellParams<EventListItem>;
}

export const DurationCell = ({ gridRenderCellParams }: DurationCellProps) => {
  const intl = useIntl();

  return (
    <Duration $cancelled={gridRenderCellParams.row.cancelled}>
      <>
        {gridRenderCellParams.value} {intl.formatMessage({ id: 'min', defaultMessage: 'min' })}
      </>
    </Duration>
  );
};
