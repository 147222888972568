import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@/Context';
import { PartnerCoursesServiceFactory } from '@/Services';
import { Partner } from '@/Types';

type useGetPartnerCoursesQueryProps = {
  casPublicId: Partner['casPublicId'];
  isActiveCourse: boolean;
  page: number;
};

const useGetPartnerCoursesQuery = ({
  casPublicId,
  isActiveCourse,
  page,
}: useGetPartnerCoursesQueryProps) => {
  const { dispatch } = useAppContext();
  const partnerCoursesService = new PartnerCoursesServiceFactory().getInstance(dispatch);

  const queryResult = useQuery(
    ['getPartnerCourses', 'getInactivePartnerCourses', isActiveCourse, casPublicId, page],
    async () => await partnerCoursesService.getCourses(casPublicId, isActiveCourse, page),
    {
      cacheTime: 300000,
      staleTime: 300000,
      keepPreviousData: true,
    },
  );

  return { ...queryResult, data: queryResult.data };
};

export default useGetPartnerCoursesQuery;
