import { Course, CourseTypes } from '@/Types';

export const initialCourseData: Partial<Course> = {
  courseName: { de: '', en: '' },
  internalName: '',
  courseType: CourseTypes.ONLINE,
  shortDescription: { de: '', en: '' },
  courseInstructor: '',
  capacity: 0,
  level: '',
  isNeededAccessoires: false,
  neededAccessoires: { de: '', en: '' },
  mainCategory: null,
  secondaryCategories: [],
  courseDuration: 0,
};
