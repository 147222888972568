import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const ChangePasswordSchema = (formatMessage: IntlShape['formatMessage']) =>
  Yup.object().shape({
    oldPassword: Yup.string().required(
      formatMessage({
        id: 'form.change_password.field_required',
        defaultMessage: 'This field is required',
      }),
    ),
    password: Yup.string()
      .notOneOf(
        [Yup.ref('oldPassword'), null],
        formatMessage({
          id: 'form.change_password.password_is_old',
          defaultMessage: 'New password cannot be the same as an old password',
        }),
      )
      .required(
        formatMessage({
          id: 'form.change_password.field_required',
          defaultMessage: 'This field is required',
        }),
      ),
    passwordConfirmation: Yup.string()
      .oneOf(
        [Yup.ref('password'), null],
        formatMessage({
          id: 'form.change_password.password_repeat',
          defaultMessage: 'Passwords do not match',
        }),
      )
      .required(
        formatMessage({
          id: 'form.change_password.field_required',
          defaultMessage: 'This field is required',
        }),
      ),
  });

export const getPasswordErrors = (errorType: string, formatMessage: IntlShape['formatMessage']) => {
  switch (errorType) {
    case 'LimitExceededException':
      return formatMessage({
        id: 'change_password.form.error.LimitExceededException',
        defaultMessage: 'Password was changed too many time, try again later',
      });
    case 'NotAuthorizedException':
      return formatMessage({
        id: 'change_password.form.old_password.incorrect',
        defaultMessage: 'Old password incorrect',
      });
    default:
      return formatMessage({
        id: 'change_password.form.old_password.unexpected_erorr',
        defaultMessage: 'Unexpected error occured. Try again later',
      });
  }
};
