import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  photosWrapper: {},
  img: {
    top: -8,
    left: 529,
    width: 408,
    position: 'absolute',
  },
}));

export const RegisterPhotos = () => {
  const { classes } = useStyles();

  return (
    <Box>
      <img alt='' src='/Images/einzel-motiv-a-er-sport-rgb-2.png' className={classes.img} />
    </Box>
  );
};
