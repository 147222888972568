import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    width: 24,
    height: 24,
  },
}));

type FlagProps = {
  src: string;
  width?: number;
  height?: number;
};

export const Flag = (props: FlagProps): JSX.Element => {
  const { src, width, height } = props;
  const { classes } = useStyles();
  return <img style={{ width, height }} className={classes.root} alt='' src={src} />;
};
