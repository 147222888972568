import * as Yup from 'yup';
import { IntlShape } from 'react-intl/src/types';

export const PartnerCategoriesValidation = (intl: IntlShape) =>
  Yup.object().shape({
    categoryPrimary: Yup.string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'form.partner_categories.error.category_primary',
          defaultMessage: 'Please select a main category.',
        }),
      ),
  });
