import * as Yup from 'yup';

export const CreateUserValidation = Yup.object().shape({
  name: Yup.string().required('form.register.stepper.step.registration.error.name.required'),
  surname: Yup.string().required('form.register.stepper.step.registration.error.surname.required'),
  email: Yup.string()
    .required('form.register.stepper.step.registration.error.email.required')
    .email('form.register.stepper.step.registration.error.email.invalid'),
  password: Yup.string()
    .required('form.register.stepper.step.registration.error.password.required')
    .min(3),
  passwordConfirmation: Yup.string()
    .oneOf(
      [Yup.ref('password')],
      'form.register.stepper.step.registration.error.password_confirmation.match',
    )
    .required('form.register.stepper.step.registration.error.password_confirmation.required'),
});
