import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { AppContext } from '@/Context';
import { AssetServiceFactory } from '@/Services';

type useGetAssetsQueryProps = {
  casPublicId?: string;
};

const useGetAssetsQuery = ({ casPublicId }: useGetAssetsQueryProps) => {
  const { dispatch } = useContext(AppContext);
  const assetService = new AssetServiceFactory().getInstance(dispatch);

  const queryResult = useQuery(
    ['getAssetsQuery', casPublicId],
    async () => {
      const response = await assetService.getAssetsByPartnerId(casPublicId);
      return response.data;
    },
    {
      cacheTime: 0,
      enabled: !!casPublicId,
    },
  );

  return queryResult;
};

export default useGetAssetsQuery;
