import { TableCell, TableFooter, TableRow } from '@mui/material';
import { Pagination } from '@mui/material';
import { ListTableFooterStyles } from './Styles/ListTableFooterStyles';

interface ListTableFooterProps {
  pages: number;
  currentPage: number;
  handlePage: (value: number) => void;
}

export const ListTableFooter = ({ pages, currentPage, handlePage }: ListTableFooterProps) => {
  const { classes } = ListTableFooterStyles();

  return (
    <TableFooter className={classes.root}>
      <TableRow>
        <TableCell colSpan={8}>
          <Pagination count={pages} page={currentPage} onChange={(_, page) => handlePage(page)} />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};
