import { Grid } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { FormSnackbar } from '@/App/Shared/Notification/Components/FormSnackbar';
import { Dropzone, InfoMessage, UploadIndicator } from '@/Components';
import { ConfirmationDialogTypes, DelayModalTypes, useAppContext } from '@/Context';
import { useUploadCancel } from '@/Hooks/useUploadCancel';
import {
  useDeleteAllAssetsMutation,
  useUpdateLogoAssetMutation,
  useUploadLogoMutation,
} from '@/Queries';
import { AssetServiceFactory } from '@/Services';
import ErrorIcon from '@/Static/Icons/atoms-symbols-alert-error.svg';
import { LogoAssetProps, Partner } from '@/Types';
import { handleFileUpload } from '@/Utils/fileUpload';
import { CrazyUseRefCallbackStuffTypes, isLogoAssetRef } from '@/Utils/formRefTypeguards';

import { AssetPreview } from './Assets/AssetPreview';

export type LogoFormProps = {
  casPublicId: Partner['casPublicId'];
  refCallback: (ref: CrazyUseRefCallbackStuffTypes, tabNumber: number) => void;
  partner?: Partner | null;
  logoAsset: LogoAssetProps;
  handlePrimaryCtaDisabled: (value: boolean) => void;
  setOpenToastMessage: (value: boolean) => void;
  refetchAssets: () => void;
};

export const LogoForm = ({
  refCallback,
  casPublicId,
  logoAsset,
  handlePrimaryCtaDisabled,
  setOpenToastMessage,
  partner,
  refetchAssets,
}: LogoFormProps) => {
  const formikRef = useRef<FormikProps<LogoAssetProps>>(null);
  const intl = useIntl();
  const { formatMessage } = intl;
  const {
    dispatch,
    state: { delayModal },
  } = useAppContext();
  const [initialState, setInitialState] = useState<LogoAssetProps>(logoAsset);
  const [deleteAssetIdQueue, setDeleteAssetIdQueue] = useState<number[]>([]);
  const [isUploadFail, setIsUploadFail] = useState(false);
  const [uploadError, setUploadError] = useState('');

  const assetService = useMemo(() => new AssetServiceFactory().getInstance(dispatch), [dispatch]);
  const getErrorMessage = (errorType: string) => {
    switch (errorType) {
      case 'file-too-large': {
        return formatMessage({
          id: 'toast.upload.fail.file_too_large',
          defaultMessage: 'File upload failed. File size must be less than 15Mb',
        });
      }

      case 'No current user': {
        return formatMessage({
          id: 'toast.upload.fail.no_user_found',
          defaultMessage: 'Please log in again',
        });
      }

      case 'file-invalid-type': {
        return formatMessage({
          id: 'toast.upload.fail',
          defaultMessage: 'File upload failed. Please verify the file type and size and try again.',
        });
      }
    }
  };

  const { mutateAsync: uploadLogo } = useUploadLogoMutation();

  const { mutateAsync: deleteAllAssets } = useDeleteAllAssetsMutation();
  const { mutateAsync: updateLogoAsset } = useUpdateLogoAssetMutation();

  const { uploads, handleCancel, setUploads } = useUploadCancel();

  const handleDrop = useCallback(
    async (files: File[]) => {
      try {
        if (files.length > 0) {
          const file = files[0];

          const onSuccess = async (filename: string, file: File) => {
            try {
              const response = await uploadLogo({
                casPublicId,
                filename,
                originalFilename: file.name,
              });
              formikRef.current?.setFieldValue('assetId', response.data.assetId);
              formikRef.current?.setFieldValue('uri', filename);
              formikRef.current?.setFieldValue('originalFilename', file.name);
              setUploads([]);
              return response;
            } catch (error) {
              setIsUploadFail(true);
              const errMessage = error ? error.toString() : '';
              setUploadError(errMessage);
            }
          };

          await handleFileUpload(
            assetService,
            casPublicId,
            file,
            'Logo',
            setUploads,
            onSuccess,
            intl.formatMessage({
              id: 'form.photo_and_video.logo.upload.error.failed',
              defaultMessage: 'Upload failed',
            }),
          );
          handlePrimaryCtaDisabled(false);
        }
      } catch (error) {
        console.error('Error while uploading the file', error);
      }
    },
    [assetService, casPublicId, handlePrimaryCtaDisabled, intl, uploadLogo, setUploads],
  );

  const handleDeleteAsset = useCallback(
    (assetId: number) => {
      deleteAssetIdQueue.push(assetId);

      setDeleteAssetIdQueue([...deleteAssetIdQueue]);

      formikRef.current?.setFieldValue('assetId', 0);
      formikRef.current?.setFieldValue('uri', '');
      formikRef.current?.setFieldValue('originalFilename', '');
      handlePrimaryCtaDisabled(false);
    },
    [formikRef, deleteAssetIdQueue, setDeleteAssetIdQueue, handlePrimaryCtaDisabled],
  );

  useEffect(() => {
    if (isLogoAssetRef(formikRef)) {
      refCallback(formikRef, 0);
    }
  }, [refCallback, formikRef]);

  useEffect(() => {
    formikRef.current?.setValues(logoAsset);
    setInitialState(logoAsset);
    dispatch({
      type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
      payload: { show: false },
    });
    handlePrimaryCtaDisabled(true);
  }, [logoAsset, handlePrimaryCtaDisabled, dispatch]);

  const handleSubmit = async (values: LogoAssetProps) => {
    if (!delayModal.notShowProfileModal) {
      dispatch({ type: DelayModalTypes.SET_OPEN, payload: { open: true } });
    }

    await deleteAllAssets({ casPublicId, deleteAssetIdQueue });

    const logoAsset: LogoAssetProps = {
      assetId: values.assetId === 0 ? null : values.assetId,
      uri: values.uri === '' ? null : values.uri,
      originalFilename: values.originalFilename,
    };

    try {
      await updateLogoAsset({ casPublicId, logoAsset });
      refetchAssets();

      setUploads([]);
      setDeleteAssetIdQueue([]);
      handlePrimaryCtaDisabled(true);
      setOpenToastMessage(true);
    } catch (error) {
      dispatch({
        type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
        payload: { show: true },
      });

      console.error('An error occurred:', error);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      validateOnBlur={false}
      validateOnChange
      validate={values => {
        const initialStateStringify = JSON.stringify(initialState);
        const currentStateStringify = JSON.stringify(values);

        if (initialStateStringify !== currentStateStringify) {
          dispatch({
            type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
            payload: { show: true },
          });
          setInitialState(values);
          handlePrimaryCtaDisabled(false);
        }
      }}
      initialValues={logoAsset}
      onSubmit={handleSubmit}>
      {({ values }) => (
        <ContentContainer>
          <Form>
            <Grid container spacing={2}>
              <Grid item container spacing={4}>
                <Grid item xs={12}>
                  <FormHeader
                    title={intl.formatMessage({
                      id: 'view.photo_and_video.header.logo',
                      defaultMessage: 'Logo',
                    })}
                  />
                </Grid>
              </Grid>

              <Grid item xs={8}>
                <Grid container spacing={8}>
                  {!!values.assetId && values.uri && (
                    <Grid item xs={12}>
                      <AssetPreview
                        originalFilename={values.originalFilename}
                        assetId={values.assetId}
                        assetService={assetService}
                        casPublicId={casPublicId}
                        type='image'
                        handleDelete={handleDeleteAsset}
                        resource={values.uri}
                      />
                    </Grid>
                  )}
                  {!values.uri && uploads.length === 0 && (
                    <Grid item xs={12}>
                      <Dropzone
                        maxFileSize={15000000}
                        disabled={!!partner?.readonly}
                        supportedFileNames='JPEG, PNG, TIFF'
                        onDrop={handleDrop}
                        onDropRejected={err => {
                          setIsUploadFail(true);
                          setUploadError(err[0].errors[0].code);
                        }}
                        accept='image/jpeg, image/png, image/tiff'
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <UploadIndicator uploads={uploads} handleCancel={handleCancel} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={4} item>
                <Grid item xs={12}>
                  <FormSnackbar
                    openToastMessage={isUploadFail}
                    handleCloseToastMessage={() => setIsUploadFail(false)}
                    customMessage={getErrorMessage(uploadError)}
                    customIcon={ErrorIcon}
                  />
                  <InfoMessage
                    title={formatMessage({
                      id: 'form.photo_and_video.logo.hint.fileformats.header',
                      defaultMessage: 'Upload a logo',
                    })}
                    description={formatMessage({
                      id: 'form.photo_and_video.logo.hint.fileformats.description',
                      defaultMessage:
                        'Upload the logo of your institution here. The logo looks best when it has a transparent background.',
                    })}
                    type='information'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </ContentContainer>
      )}
    </Formik>
  );
};
