import { AxiosError, AxiosResponse } from 'axios';

import { EnvironmentService, HttpService } from '@/Services';
import { AdministrationFormik, InvitationFormik, InvitedUser, User, UsersResponse } from '@/Types';

export enum UserStatus {
  DELETED = 'Deleted',
  BLOCKED = 'Blocked',
  ACTIVE = 'Active',
  PENDING = 'Pending',
}

export class UsersMap {
  public httpService: HttpService;
  public environmentService: EnvironmentService;

  constructor(httpService: HttpService, environmentService: EnvironmentService) {
    this.httpService = httpService;
    this.environmentService = environmentService;
  }

  public async getUsers(id: string): Promise<AxiosResponse<UsersResponse> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();
    return await axiosInstance.get(
      this.environmentService.getAwsRegisterUserBasePath() + 'getUsers/' + id,
    );
  }

  public getInviteUserTranslateMessageKey(code: string): string {
    return 'form.invite_user.error.' + code;
  }

  public getInvitedUser(invitation: InvitationFormik, publicId: string) {
    return {
      ...invitation,
      publicId,
      name: invitation.name,
      givenName: invitation.surname,
    };
  }

  public sendInvitedUsers(invitedUsers: InvitedUser[]): Promise<AxiosResponse<InvitedUser>>[] {
    return invitedUsers.map(invitedUser => this.sendInvitedUser(invitedUser));
  }

  public async sendInvitedUser(invitedUser: InvitedUser): Promise<AxiosResponse<InvitedUser>> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();
    return await axiosInstance.post(
      this.environmentService.getAwsRegisterUserBasePath() + 'addUser',
      invitedUser,
    );
  }

  public async updateUser(user: User): Promise<AxiosResponse<User> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();
    return axiosInstance.put(
      this.environmentService.getAwsRegisterUserBasePath() + 'changeUser',
      user,
    );
  }

  /*
   * In summary, this function is designed to update a specific user within a list of users maintained in a form or state object. It ensures immutability by creating a new array with the updated user, leaving the original array unchanged.
   */
  public getNewUsers(usersListFormikVal: AdministrationFormik['users'], updatedUser: User) {
    const newUpdatedUserIdx = usersListFormikVal.findIndex(
      user => user.cognitoId === updatedUser.cognitoId,
    );
    const newUsers = [...usersListFormikVal];
    newUsers[newUpdatedUserIdx] = updatedUser;

    return newUsers;
  }
}
