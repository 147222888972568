import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Button } from '@/Components';
import { useGetPartnerQuery } from '@/Queries';
import Warning from '@/Static/Icons/atoms-symbols-alert-warning.svg';
import CloseIcon from '@/Static/Icons/atoms-symbols-category-close-big.svg';
import { LanguageType } from '@/Types';

import { usePreviewModalStyles } from './Styles/PreviewModal.styles';

type PreviewModalProps = {
  onClose: () => void;
};

export const PreviewModal = ({ onClose }: PreviewModalProps) => {
  const { language: currentLanguage = 'de' } = useParams<{ language: LanguageType }>();
  const { classes } = usePreviewModalStyles();
  const { data: partner } = useGetPartnerQuery();

  const [token, setToken] = useState('');
  const [hasError, setHasError] = useState(false);
  const iFrameRef = useRef<HTMLIFrameElement>(null);

  const handleCloseError = useCallback(() => {
    setHasError(false);
    onClose();
  }, [onClose]);

  const getInitialData = useCallback(async () => {
    const session = await Auth.currentSession();
    setToken(session.getIdToken().getJwtToken());
  }, []);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  if (!partner) {
    return null;
  }

  if (hasError) {
    return (
      <Dialog open className={classes.errorDialog}>
        <Box className={classes.errorContainer}>
          <Box className={classes.errorHeaderContainer}>
            <Typography className={classes.errorTitle}>
              <FormattedMessage id='preview.modal.title' defaultMessage='Studioprofil Vorschau' />
            </Typography>
            <IconButton aria-label='close' onClick={handleCloseError}>
              <img alt='' src={CloseIcon} />
            </IconButton>
          </Box>
          <Box className={classes.errorMessageContainer}>
            <img alt='' src={Warning} />
            <Typography className={classes.errorMessage}>
              <FormattedMessage
                id='preview.modal.error_message'
                defaultMessage='Die Vorschau des Studioprofils kann gerade nicht geladen werden. Versuche es später erneut.'
              />
            </Typography>
          </Box>
        </Box>
      </Dialog>
    );
  }

  return (
    <Dialog open fullScreen>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography className={classes.title}>
            <FormattedMessage id='preview.modal.title' defaultMessage='Studioprofil Vorschau' />
          </Typography>

          <Box className={classes.spacer} />
          <Box className={classes.lastUpdatedContainer}>
            <FormattedMessage
              id='preview.modal.last_updated'
              defaultMessage='Zuletzt aktualisiert am'
            />
            {moment.unix(partner.modificationDate).format('DD.MM.YYYY')}{' '}
            <FormattedMessage id='preview.modal.at' defaultMessage='um' />{' '}
            {moment.unix(partner.modificationDate).format('HH:mm')}{' '}
            <FormattedMessage id='preview.modal.clock' defaultMessage='Uhr' />
          </Box>
          <Button onClick={onClose} className={classes.closePreviewCta}>
            <FormattedMessage
              id='preview.modal.close_preview'
              defaultMessage='Vorschau schließen'
            />
          </Button>
        </Box>
        <Box className={classes.iframeContainer}>
          {token && (
            <iframe
              title='partner-preview'
              ref={iFrameRef}
              className={classes.iframe}
              width='100%'
              height='100%'
              src={`${process.env.REACT_APP_DETAIL_SITE}${currentLanguage}/preview/?id=${partner.publicId}&token=${token}`}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
