import { makeStyles } from 'tss-react/mui';

const drawerOpenWidth = 300;
const drawerCloseWidth = 80;

export const useSidebarNavGuestStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      marginRight: 66,
    },
  },
  backdrop: {
    zIndex: 1000,
  },
  brandContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 24,
    paddingTop: 50,
    paddingBottom: 50,
    backgroundColor: theme.palette.primary.main,
    height: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },

    transition: theme.transitions.create(['padding', 'margin-bottom', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('lg')]: {
      paddingTop: 0,
      paddingBottom: 0,

      height: 100,
    },
  },
  brandContainerShow: {
    height: 'auto',
    [theme.breakpoints.down('lg')]: {
      paddingTop: 50,
      paddingBottom: 50,
      height: 'auto',
    },
  },
  brandContainerHide: {},
  logo: {
    backgroundImage: 'url("/logo.svg")',
    width: 54,
    minHeight: 54,
    backgroundRepeat: 'inherit',
    [theme.breakpoints.down('lg')]: {
      width: 36,
      minHeight: 36,
      backgroundRepeat: 'no-repeat',
    },
    transition: theme.transitions.create(['width', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logoFullSize: {
    width: 54,
    minHeight: 54,
    [theme.breakpoints.down('lg')]: {
      width: 54,
      minHeight: 54,
    },
    backgroundRepeat: 'inherit',
  },
  brandSubHeader: {
    lineHeight: 1.5,
    letterSpacing: 0.4,
    color: theme.palette.background.default,
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 14,
    fontWeight: 900,
  },
  drawer: {
    width: drawerOpenWidth,
    flexShrink: 0,
    [theme.breakpoints.down('md')]: {
      width: drawerCloseWidth,
      position: 'absolute',
    },
  },
  drawerOpen: {
    width: drawerOpenWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: drawerCloseWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('lg')]: {
      width: drawerOpenWidth,
    },
  },
  paper: {
    width: drawerCloseWidth,
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRight: 'none',

    [theme.breakpoints.down('lg')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('lg')]: {
      width: drawerOpenWidth,
    },
  },
  paperOpen: {
    width: drawerOpenWidth,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  paperClose: {
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  linksWrapper: {
    marginBottom: 40,
  },
  hidden: {
    display: 'none',
  },
  show: {
    display: 'inherit',
  },
  menuIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuIcon: {
    width: 25,
    marginTop: 14,
  },
}));
