import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Grid, Typography } from '@mui/material';
import { sanitize } from 'dompurify';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';
import { EnableCourseManagerDialogBody } from './EnableCourseManagerDialogBody';

export interface EnableCourseMangerDialogStepFinalProps {
  handleEnableCourseManager: () => void;
  header: string;
  description: string;
  currentStep: number;
}

const useStyles = makeStyles()(theme => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    height: 68,
    justifyContent: 'space-between',
    marginTop: 16,
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
  },
  listItemIcon: {
    backgroundColor: FigmaDesignTokens.Green[500],
    height: 16,
    width: 16,
    borderRadius: 8,
    color: FigmaDesignTokens.White,
    padding: 2,
    marginRight: 10,
  },
  listItemText: {
    color: FigmaDesignTokens.Grey[700],
    letterSpacing: 0.11,
    fontSize: 12,
    fontWeight: 500,
  },
  cta: {
    borderRadius: 18,
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: theme.palette.background.default,
    height: 36,
    marginTop: 18,
    padding: '7px 21px',
  },
  header: {
    fontWeight: 'bold',
    letterSpacing: 0.13,
    fontSize: 14,
    color: FigmaDesignTokens.Blue[950],
    position: 'relative',
    right: 14,
  },
  legalHint: {
    fontSize: 10,
    textAlign: 'center',
    lineHeight: 1.5,
    marginTop: 24,
    '& a': {
      textStyle: 'underline',
      fontWeight: 'bold',
      color: FigmaDesignTokens.Grey[700],
    },
  },
}));

export const EnableCourseMangerDialogStepFinal = ({
  header,
  description,
  currentStep,
  handleEnableCourseManager,
}: EnableCourseMangerDialogStepFinalProps) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  const sanitizedLegalHint = useMemo(
    () =>
      sanitize(
        formatMessage({
          id: 'course_manager.dialog.step_4.legal_hint',
          defaultMessage: 'By activating I agree that I have read and accept the conditions',
        }),
      ),
    [formatMessage],
  );

  return (
    <EnableCourseManagerDialogBody
      currentStep={currentStep}
      header={header}
      description={description}>
      <Grid container>
        <Grid item xs={12} className={classes.contentContainer}>
          <Typography variant='body1' className={classes.header}>
            {formatMessage({
              id: 'course_manager.dialog.step_4.header',
              defaultMessage: 'You can start right away!',
            })}
          </Typography>
          <Box className={classes.list}>
            <Box className={classes.listItem}>
              <CheckIcon className={classes.listItemIcon} />
              <Typography variant='body1' className={classes.listItemText}>
                {formatMessage({
                  id: 'course_manager.dialog.step_4.list.realtime_course_transfer',
                  defaultMessage: 'Transfer courses in real time',
                })}
              </Typography>
            </Box>

            <Box className={classes.listItem}>
              <CheckIcon className={classes.listItemIcon} />
              <Typography variant='body1' className={classes.listItemText}>
                {formatMessage({
                  id: 'course_manager.dialog.step_4.list.subscriptions_visible',
                  defaultMessage: 'All applications visible',
                })}
              </Typography>
            </Box>

            <Box className={classes.listItem}>
              <CheckIcon className={classes.listItemIcon} />
              <Typography variant='body1' className={classes.listItemText}>
                {formatMessage({
                  id: 'course_manager.dialog.step_4.list.online_course_check_in',
                  defaultMessage: 'Check-in value for online courses',
                })}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography
              className={classes.legalHint}
              dangerouslySetInnerHTML={{
                __html: sanitizedLegalHint,
              }}
            />
          </Box>
          <Button
            onClick={handleEnableCourseManager}
            type='submit'
            variant='contained'
            color='primary'
            className={classes.cta}>
            {formatMessage({
              id: 'course_manager.dialog.cta.enable_course_manager',
              defaultMessage: 'Start course manager now',
            }).toUpperCase()}
          </Button>
        </Grid>
      </Grid>
    </EnableCourseManagerDialogBody>
  );
};
