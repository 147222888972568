import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Container, CourseName, PremiumImage } from './CourseNameCell.styles';
import { CustomTooltip } from '@/App/Shared/Tooltip/CustomTooltip';
import { Course } from '@/Types';
import PremiumIcon from '@/Static/Icons/modules-list-exclusive.svg';

type CourseNameCellProps = {
  gridRenderCellParams: GridRenderCellParams<Course, Course['courseName']>;
};

export const CourseNameCell = ({ gridRenderCellParams }: CourseNameCellProps) => (
  <Container>
    {gridRenderCellParams.row.exclusiveCourse ? <PremiumImage alt='' src={PremiumIcon} /> : null}
    <CustomTooltip title={gridRenderCellParams.value || ''} placement='right-end'>
      <CourseName>{gridRenderCellParams.value}</CourseName>
    </CustomTooltip>
  </Container>
);
