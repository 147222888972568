import { makeStyles } from 'tss-react/mui';

export const useCourseCategoriesStyles = makeStyles()(() => ({
  divider: {
    marginTop: 50,
    marginBottom: 50,
  },
  lastElementContainer: {
    marginBottom: 32,
  },
}));

export const useCourseDataStyles = makeStyles()(() => ({
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  switchFieldAndLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  lastElementContainer: {
    marginBottom: 14,
  },
}));
