export const stickyHeaderStatuses = {
  STATUS_ORIGINAL: 0,
  STATUS_RELEASED: 1,
  STATUS_FIXED: 2,
};

export const handleIsStickyBackgroundColor = (status: number) => {
  if (status === stickyHeaderStatuses.STATUS_FIXED) {
    return true;
  }
  if (status === stickyHeaderStatuses.STATUS_ORIGINAL) {
    return false;
  }
  return false;
};
