import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import { EventsModal } from './EventsModal';
import { TranslationService } from '@/App/Shared/Translation/TranslationService';
import { LineDivider, Button } from '@/Components';
import { useEventsModalHelper } from '@/Hooks/useEventsModalHelper';

import { EventList } from '@/Types';

import { ButtonContainer } from './EventsModal.styles';
import { Information } from './PublishEvents.styles';

interface PublishEventsModalProps {
  eventIds: string[];
  handleClose: () => void;
  handlePublish: (eventIds: string[]) => void;
  open: boolean;
}
export const PublishEvents = ({
  eventIds,
  handleClose,
  handlePublish,
  open,
}: PublishEventsModalProps) => {
  const { formatMessage } = useIntl();
  const { values } = useFormikContext<EventList>();
  const { subHeader } = useEventsModalHelper(eventIds, values.eventsData.result);

  const handlePublishEvents = () => {
    handlePublish(eventIds);
    handleClose();
  };

  const header = useMemo(() => {
    if (eventIds.length === 1) {
      return formatMessage({
        id: 'view.courses_and_events.events.dialog.edit_event.header.publish_event',
        defaultMessage: 'Publish appointment',
      });
    } else {
      return TranslationService.replacePlaceholder(
        formatMessage({
          id: 'view.courses_and_events.events.dialog.edit_events.header.publish_events',
          defaultMessage: '{{checkedEvents}} Publish dates',
        }),
        '{{checkedEvents}}',
        eventIds.length.toString(),
      );
    }
  }, [eventIds.length, formatMessage]);

  return (
    <EventsModal open={open} header={header} subHeader={subHeader} onClose={handleClose}>
      <>
        <Information variant='body2'>
          {eventIds.length > 1
            ? formatMessage({
                id: 'view.courses_and_events.events.dialog.edit_events.text.publish',
                defaultMessage:
                  'Are you sure you want these dates to be published? They will be displayed directly in the course schedule in the Hansefit app and can be booked by Hansefit members.\n\nTherefore, please check if all dates are stored correctly and the streaming information is complete.',
              })
            : formatMessage({
                id: 'view.courses_and_events.events.dialog.edit_event.text.publish',
                defaultMessage:
                  'Are you sure that this appointment should be published? The appointment will be displayed directly in the course schedule in the Hansefit app and can be booked by Hansefit members.\n\nTherefore, please check if all data is stored correctly and the streaming information is complete.',
              })}
        </Information>

        <ButtonContainer>
          <LineDivider horizontalSpace='inherit' />
          <Button onClick={handlePublishEvents}>
            {eventIds.length > 1
              ? formatMessage({
                  id: 'view.courses_and_events.events.dialog.edit_events.cta.publish',
                  defaultMessage: 'Publish dates',
                })
              : formatMessage({
                  id: 'view.courses_and_events.events.dialog.edit_event.cta.publish',
                  defaultMessage: 'Publish appointment',
                })}
          </Button>
        </ButtonContainer>
      </>
    </EventsModal>
  );
};
