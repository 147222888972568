import { OpeningHour } from '@/Types';

export class OpeningHourMap {
  public weekdays: string[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  public getHours(
    threshold: string,
    direction: 'from' | 'to',
  ): { value: string; displayValue: string }[] {
    const openingHours = [];

    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        let minutes = 0;

        if (j === 1) {
          minutes = 15;
        }

        if (j === 2) {
          minutes = 30;
        }

        if (j === 3) {
          minutes = 45;
        }

        openingHours.push({
          value: i.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0'),
          displayValue: i.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0'),
        });
      }
    }

    openingHours.push({
      value: '24:00',
      displayValue: '24:00',
    });

    if (threshold !== '' && direction === 'to') {
      const idx = openingHours.findIndex(hour => hour.value === threshold);
      openingHours.splice(1, idx);
    }

    return openingHours;
  }

  public getClosedOpeningHoursState(): [] {
    return [];
  }

  public getInitialOpenedOpeningHoursState(): OpeningHour[] {
    return [
      {
        weekday: 'monday',
        opened: false,
        times: [],
      },
      {
        weekday: 'tuesday',
        opened: false,
        times: [],
      },
      {
        weekday: 'wednesday',
        opened: false,
        times: [],
      },
      {
        weekday: 'thursday',
        opened: false,
        times: [],
      },
      {
        weekday: 'friday',
        opened: false,
        times: [],
      },
      {
        weekday: 'saturday',
        opened: false,
        times: [],
      },
      {
        weekday: 'sunday',
        opened: false,
        times: [],
      },
    ];
  }
}
