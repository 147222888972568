import { GetStatisticsResponseType, GetStatsRequestParamsProps } from '@/Types';
import { Auth } from 'aws-amplify';
import axios from 'axios';

export const getStatistics = async <T extends GetStatisticsResponseType>({
  partnerPublicId,
  statsType,
  startDate,
  endDate,
}: GetStatsRequestParamsProps) => {
  const currentSession = await Auth.currentSession();
  const customHeaders = {
    Authorization: 'Bearer ' + currentSession.getIdToken().getJwtToken(),
  };

  const instance = axios.create({
    timeout: 30000,
    headers: customHeaders,
  });

  const endpoint = `${process.env.REACT_APP_STATISTICS_ENDPOINT}checkin?partnerId=${partnerPublicId}&format=${statsType}&startDay=${startDate}&endDay=${endDate}&explain=true`;

  const query = await instance.get<T>(endpoint);

  // This is a workaround to add an id to each item in the array, because the backend does not provide an id
  if (query && query.data && Array.isArray(query.data.data)) {
    query.data.data.forEach((item, index) => {
      item.id = index.toString();
    });
  }

  return query.data;
};
