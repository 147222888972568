import { Box, Button, Grid, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { ConnectedFocusError } from 'focus-formik-error';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { BodyTextSmall, MainHeadline } from '@/Components';
import { RegisterPartnerService } from '@/Services';
import { InitialUserType, ValidatePartnerDataType } from '@/Types';
import { SdkErrorType } from '@/Types/SdkError';
import { CreateUserValidation } from '@/Utils';

import { FormikErrorMessage } from '../../../../../App/Shared/Form/Components/Formik/FormikErrorMessage';
import { FormikTextField } from '../../../../../App/Shared/Form/Components/Formik/FormikTextField';
import { RegisterPhotos } from '../../Shared/Photos/RegisterPhotos';
import { useUserFormStyles } from './Steps.styles';

export interface CreateUserStepProps {
  handleStep: (value: number) => void;
  handleInitialUser: (params: InitialUserType) => void;
  validatePartnerData: ValidatePartnerDataType;
  registerPartnerService: RegisterPartnerService;
}

export const CreateUserStep = ({
  handleStep,
  validatePartnerData,
  handleInitialUser,
  registerPartnerService,
}: CreateUserStepProps) => {
  const { classes } = useUserFormStyles();
  const { formatMessage } = useIntl();
  const [asyncErrorMessage, setAsyncErrorMessage] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [validateOnChange, setValidateOnChange] = useState(false);

  return (
    <Formik
      validationSchema={CreateUserValidation}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
      validate={() => {
        if (!validateOnChange) {
          setValidateOnChange(true);
        }

        setAsyncErrorMessage('');

        setSubmitDisabled(false);
      }}
      initialValues={{ name: '', surname: '', email: '', password: '', passwordConfirmation: '' }}
      onSubmit={async (values, formik) => {
        setAsyncErrorMessage('');
        try {
          await registerPartnerService.registerPartner({
            startCode: validatePartnerData.startCode.map(value => value.partKey).join(''),
            partnerId: validatePartnerData.partnerId,
            email: values.email,
            password: values.password,
            name: values.name,
            surname: values.surname,
          });
          handleInitialUser({ email: values.email, password: values.password });
          handleStep(2);
        } catch (e: unknown) {
          setSubmitDisabled(true);

          if (e instanceof AxiosError && e.response?.data) {
            const errorMessage = e.response.data.message as SdkErrorType['code'];

            setAsyncErrorMessage(registerPartnerService.getTranslateMessageKey(errorMessage));

            if (errorMessage === 'USER_ALREADY_REGISTERED') {
              formik.setFieldError('email', ' ');
            }

            if (errorMessage === 'PASSWORD_INVALID') {
              formik.setFieldError('password', ' ');
            }
          }
        }
      }}>
      {formik => (
        <Form>
          <ConnectedFocusError />
          <Box className={classes.mainContent}>
            <Grid container spacing={4}>
              <Grid item>
                <Grid>
                  <MainHeadline textAlign='center' textContent={validatePartnerData.name} />
                </Grid>
                <Grid>
                  <BodyTextSmall
                    className={classes.subHeader}
                    textAlign='center'
                    textContent={formatMessage({
                      id: 'form.register.stepper.step.registration.subheader',
                      defaultMessage:
                        'Please enter your contact details to create your user account. You can later invite other collaborators for editing.',
                    })}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2} justifyContent='center'>
                <Grid container item spacing={2} justifyContent='center'>
                  <Grid item xs={12}>
                    <Field
                      className={classes.textField}
                      component={FormikTextField}
                      variant='outlined'
                      name='name'
                      type='text'
                      label={formatMessage({
                        id: 'form.register.stepper.step.registration.field.name.label',
                        defaultMessage: 'First name',
                      })}
                    />
                    <FormikErrorMessage name='name' />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      className={classes.textField}
                      component={FormikTextField}
                      variant='outlined'
                      name='surname'
                      type='text'
                      label={formatMessage({
                        id: 'form.register.stepper.step.registration.field.surname.label',
                        defaultMessage: 'Last name',
                      })}
                    />
                    <FormikErrorMessage name='surname' />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      className={classes.textField}
                      component={FormikTextField}
                      variant='outlined'
                      name='email'
                      type='email'
                      label={formatMessage({
                        id: 'form.register.stepper.step.registration.field.email.label',
                        defaultMessage: 'E-mail address',
                      })}
                    />
                    <FormikErrorMessage name='email' />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      className={classes.textField}
                      component={FormikTextField}
                      variant='outlined'
                      name='password'
                      type='password'
                      label={formatMessage({
                        id: 'form.register.stepper.step.registration.field.password.label',
                        defaultMessage: 'Password',
                      })}
                    />
                    <FormikErrorMessage name='password' />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      className={classes.textField}
                      component={FormikTextField}
                      variant='outlined'
                      name='passwordConfirmation'
                      type='password'
                      label={formatMessage({
                        id: 'form.register.stepper.step.registration.field.password_confirmation.label',
                        defaultMessage: 'Repeat password',
                      })}
                    />
                    <FormikErrorMessage name='passwordConfirmation' />
                  </Grid>
                </Grid>

                {asyncErrorMessage.length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant='body2' className={classes.error}>
                      {formatMessage({
                        id: asyncErrorMessage,
                      })}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Grid item container xs={12} alignItems='center' direction='column'>
                <Grid>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={submitDisabled || !formik.isValid}
                    className={clsx(classes.mainActionBtn, classes.userBtn)}>
                    {formatMessage({
                      id: 'form.register.stepper.step.registration.cta',
                      defaultMessage: 'Verify email',
                    }).toUpperCase()}
                  </Button>
                </Grid>
              </Grid>
              <RegisterPhotos />
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
