import { styled, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const useBookingsStyles = makeStyles()(() => ({
  bookingsContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    gap: '2px',
    maxHeight: '70vh',
  },
}));

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 2px;
  max-height: 70vh;
`;

export const Item = styled('div')`
  background-color: ${FigmaDesignTokens.Blue[50]};
  display: flex;
  flex-direction: row;
  align-items: baseline;

  padding: 12px 16px;
`;

export const Name = styled(Typography)`
  min-width: 240px;
  width: 240px;
  overflow: hidden;
  font-family: Roboto-Bold;
`;

export const Company = styled(Typography)`
  flex: 1;
  margin-left: 16px;
`;
