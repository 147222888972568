import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../design/design-tokens.json';

export const useInvitationStyles = makeStyles()(theme => ({
  invitationWrapper: {
    padding: 30,
    borderRadius: 14,
    backgroundColor: theme.palette.background.default,
    position: 'relative',
  },
  invitationWrapperOdd: {
    backgroundColor: FigmaDesignTokens.Grey[50],
  },
  deleteBtn: {
    padding: 0,
    minWidth: 'unset',
    position: 'absolute',
    height: 30,
    width: 30,
    right: 10,
    top: 10,
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  switchFieldAndLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputContainer: {
    marginBottom: 14,
  },
  spacer: {
    width: 38,
  },
  nameContainer: {
    display: 'flex',
    width: '100%',
  },
}));

export const useInvitationsFormStyles = makeStyles()(() => ({
  contentContainerWrapper: {
    width: 1090,
    display: 'block',
  },
  addNewUserBtn: {
    marginLeft: 28,
    padding: '2px 18px 2px 2px',
    borderRadius: 15,
    height: 30,
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.83,
    letterSpacing: 0.34,
    color: FigmaDesignTokens.Blue[950],
    boxShadow: 'none',
    '& .MuiButton-startIcon': {
      marginRight: 11,
      marginLeft: 0,
    },
  },
}));
