import { StepConnector } from '@mui/material';
import { makeStyles, withStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../../design/design-tokens.json';

export const RegisterStepConnector = withStyles(StepConnector, {
  alternativeLabel: {
    zIndex: 1,
  },
  active: {
    '& $line': {
      backgroundColor: FigmaDesignTokens.White,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: FigmaDesignTokens.White,
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: FigmaDesignTokens.White,
  },
});

export const useRegisterStepIconStyles = makeStyles()(theme => ({
  root: {
    fontSize: 12,
    backgroundColor: FigmaDesignTokens.White,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2,
    letterSpacing: 0.11,
    padding: '0 10px',
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'row',
    zIndex: 2,
    border: `1px solid ${FigmaDesignTokens.White}`,
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: FigmaDesignTokens.White,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  completed: {
    backgroundColor: FigmaDesignTokens.White,
    color: FigmaDesignTokens.Green[500],
    border: `1px solid ${FigmaDesignTokens.Green[500]}`,
  },
  stepNumber: {
    fontWeight: 900,
    paddingRight: 8,
  },
}));

export const useRegisterStepperStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 2,
  },
  stepper: {
    backgroundColor: 'transparent',
    paddingLeft: 0,
    paddingRight: 0,
    display: 'table-row',
    paddingTop: 0,
    paddingBottom: 20,
  },
  step: {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'table-cell',
    '& .MuiStepLabel-iconContainer': {
      display: 'initial',
      width: '100%',
      paddingRight: 5,
      paddingLeft: 5,
      zIndex: 2,
    },
    '& .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel': {
      paddingRight: 5,
    },
  },
}));
