import { createTheme, Theme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';

import FigmaDesignTokens from '../../design/design-tokens.json';

declare module '@mui/material/styles' {
  interface Palette {
    white: string;
  }
  interface PaletteOptions {
    white: string;
  }
}

const defaultFontFamilies = [
  FigmaDesignTokens.fontFamilies.roboto,
  'Helvetica',
  'Arial',
  'sans-serif',
].join(',');

const fontFamilyNameHelper = (fontFamily: string, fontWeight: string) =>
  fontFamily.replace(' ', '') + '-' + fontWeight.replace(' ', '');

type CSSTransformType = 'uppercase' | 'lowercase' | 'capitalize' | 'none';

// A custom theme for this app
const HansefitTheme: Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          fontSize: FigmaDesignTokens.Button['L16'].fontSize,
          fontFamily: fontFamilyNameHelper(
            FigmaDesignTokens.Button['L16'].fontFamily,
            FigmaDesignTokens.Button['L16'].fontWeight,
          ),
          lineHeight: FigmaDesignTokens.Button['L16'].lineHeight + 'px',
          letterSpacing: FigmaDesignTokens.Button['L16'].letterSpacing,
          textTransform: FigmaDesignTokens.Button['L16'].textCase as CSSTransformType,

          borderRadius: 32,
          display: 'inline-flex',
          alignItems: 'center',
          padding: '8px 24px',
          minWidth: 124,
          boxShadow:
            'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
        },
        outlined: {
          fontSize: FigmaDesignTokens.Button['L16'].fontSize,
          fontFamily: fontFamilyNameHelper(
            FigmaDesignTokens.Button['L16'].fontFamily,
            FigmaDesignTokens.Button['L16'].fontWeight,
          ),
          lineHeight: FigmaDesignTokens.Button['L16'].lineHeight + 'px',
          letterSpacing: FigmaDesignTokens.Button['L16'].letterSpacing,
          textTransform: FigmaDesignTokens.Button['L16'].textCase as CSSTransformType,

          borderRadius: 32,
          alignItems: 'center',
          padding: '8px 24px',
          fontWeight: 500,
          minWidth: 124,
          borderColor: FigmaDesignTokens.border.borderFocus,
        },
      },
    },
  },
  typography: {
    h1: {
      color: FigmaDesignTokens.txt.Brand.brandActive,
      fontSize: FigmaDesignTokens.Heading['2XL24'].fontSize,
      fontFamily: fontFamilyNameHelper(
        FigmaDesignTokens.Heading['2XL24'].fontFamily,
        FigmaDesignTokens.Heading['2XL24'].fontWeight,
      ),
      // fontWeight: 900,
      lineHeight: FigmaDesignTokens.Heading['2XL24'].lineHeight + 'px',
      textTransform: FigmaDesignTokens.Heading['2XL24'].textCase as CSSTransformType,
    },
    h2: {
      color: FigmaDesignTokens.txt.Brand.brandActive,
      fontSize: FigmaDesignTokens.Heading['XL20'].fontSize,
      fontFamily: fontFamilyNameHelper(
        FigmaDesignTokens.Heading['XL20'].fontFamily,
        FigmaDesignTokens.Heading['XL20'].fontWeight,
      ),
      // fontWeight: 900,
      lineHeight: FigmaDesignTokens.Heading['XL20'].lineHeight + 'px',
      letterSpacing: FigmaDesignTokens.Heading['XL20'].letterSpacing,
      textTransform: FigmaDesignTokens.Heading['XL20'].textCase as CSSTransformType,
    },
    h3: {
      fontSize: FigmaDesignTokens.Heading['L16'].fontSize,
      fontFamily: fontFamilyNameHelper(
        FigmaDesignTokens.Heading['L16'].fontFamily,
        FigmaDesignTokens.Heading['L16'].fontWeight,
      ),
      lineHeight: FigmaDesignTokens.Heading['L16'].lineHeight + 'px',
      letterSpacing: FigmaDesignTokens.Heading['L16'].letterSpacing,
      textTransform: FigmaDesignTokens.Heading['L16'].textCase as CSSTransformType,
    },
    h4: {
      color: FigmaDesignTokens.txt.Brand.brandActive,
      fontSize: FigmaDesignTokens.Heading['M']['Medium'].fontSize,
      fontFamily: fontFamilyNameHelper(
        FigmaDesignTokens.Heading['M']['Medium'].fontFamily,
        FigmaDesignTokens.Heading['M']['Medium'].fontWeight,
      ),
      lineHeight: FigmaDesignTokens.Heading['M']['Medium'].lineHeight + 'px',
      letterSpacing: FigmaDesignTokens.Heading['M']['Medium'].letterSpacing,
      textTransform: FigmaDesignTokens.Heading['M']['Medium'].textCase as CSSTransformType,
    },
    h5: {
      color: FigmaDesignTokens.txt.Brand.brandActive,
      fontSize: FigmaDesignTokens.Heading['M']['Normal'].fontSize,
      fontFamily: fontFamilyNameHelper(
        FigmaDesignTokens.Heading['M']['Normal'].fontFamily,
        FigmaDesignTokens.Heading['M']['Normal'].fontWeight,
      ),
      lineHeight: FigmaDesignTokens.Heading['M']['Normal'].lineHeight + 'px',
      letterSpacing: FigmaDesignTokens.Heading['M']['Normal'].letterSpacing,
      textTransform: FigmaDesignTokens.Heading['M']['Normal'].textCase as CSSTransformType,
    },
    h6: {
      color: FigmaDesignTokens.txt.Brand.brandActive,
      fontSize: FigmaDesignTokens.Heading['S12'].fontSize,
      fontFamily: fontFamilyNameHelper(
        FigmaDesignTokens.Heading['S12'].fontFamily,
        FigmaDesignTokens.Heading['S12'].fontWeight,
      ),
      lineHeight: FigmaDesignTokens.Heading['S12'].lineHeight + 'px',
      letterSpacing: FigmaDesignTokens.Heading['S12'].letterSpacing,
      textTransform: FigmaDesignTokens.Heading['S12'].textCase as CSSTransformType,
    },
    body1: {
      color: FigmaDesignTokens.txt.Brand.brandActive,
      fontSize: FigmaDesignTokens.Body['L']['normal'].fontSize,
      fontFamily: fontFamilyNameHelper(
        FigmaDesignTokens.Body['L']['normal'].fontFamily,
        FigmaDesignTokens.Body['L']['normal'].fontWeight,
      ),
      lineHeight: FigmaDesignTokens.Body['L']['normal'].lineHeight + 'px',
      letterSpacing: FigmaDesignTokens.Body['L']['normal'].letterSpacing,
      textTransform: FigmaDesignTokens.Body['L']['normal'].textCase as CSSTransformType,
    },
    body2: {
      color: FigmaDesignTokens.txt.Brand.brandActive,
      fontSize: FigmaDesignTokens.Body['M']['normal'].fontSize,
      fontFamily: fontFamilyNameHelper(
        FigmaDesignTokens.Body['M']['normal'].fontFamily,
        FigmaDesignTokens.Body['M']['normal'].fontWeight,
      ),
      lineHeight: FigmaDesignTokens.Body['M']['normal'].lineHeight + 'px',
      letterSpacing: FigmaDesignTokens.Body['M']['normal'].letterSpacing,
      textTransform: FigmaDesignTokens.Body['M']['normal'].textCase as CSSTransformType,
    },
    overline: {
      color: FigmaDesignTokens.txt.Brand.brandActive,
      fontSize: FigmaDesignTokens.Body['S']['normal'].fontSize,
      fontFamily: fontFamilyNameHelper(
        FigmaDesignTokens.Body['S']['normal'].fontFamily,
        FigmaDesignTokens.Body['S']['normal'].fontWeight,
      ),
      lineHeight: FigmaDesignTokens.Body['S']['normal'].lineHeight + 'px',
      letterSpacing: FigmaDesignTokens.Body['S']['normal'].letterSpacing,
      textTransform: FigmaDesignTokens.Body['S']['normal'].textCase as CSSTransformType,
    },

    caption: {
      color: FigmaDesignTokens.txt.Brand.brandActive,
      fontSize: 10,
      fontFamily: fontFamilyNameHelper(
        FigmaDesignTokens.Body['S']['normal'].fontFamily,
        FigmaDesignTokens.Body['S']['normal'].fontWeight,
      ),
      lineHeight: 1.4,
      letterSpacing: FigmaDesignTokens.Body['S']['normal'].letterSpacing,
      textTransform: FigmaDesignTokens.Body['S']['normal'].textCase as CSSTransformType,
    },
    fontFamily: defaultFontFamilies,
  },
  palette: {
    primary: {
      main: FigmaDesignTokens.txt.Brand.brand,
      light: FigmaDesignTokens.bg.info.light.lightHover,
      dark: FigmaDesignTokens.txt.Brand.brandHover,
    },
    secondary: {
      main: FigmaDesignTokens.txt.secondary,
      light: FigmaDesignTokens.bg.info.light.light,
    },
    error: {
      main: FigmaDesignTokens.bg.critical.bold.bold,
      light: FigmaDesignTokens.bg.critical.light.light,
    },
    warning: {
      main: FigmaDesignTokens.bg.warning.bold.bold,
      light: FigmaDesignTokens.bg.warning.light.light,
    },
    background: {
      default: FigmaDesignTokens.bg.bgPage,
      paper: FigmaDesignTokens.bg.surface,
    },
    success: {
      main: FigmaDesignTokens.bg.success.bold.bold,
      light: FigmaDesignTokens.bg.success.light.light,
    },
    text: {
      primary: FigmaDesignTokens.txt.primary,
      secondary: FigmaDesignTokens.txt.disabled,
    },
    info: {
      main: FigmaDesignTokens.bg.info.bold.bold,
      light: FigmaDesignTokens.bg.info.light.light,
      dark: FigmaDesignTokens.Grey[400],
    },
    white: FigmaDesignTokens.White,
    grey: FigmaDesignTokens.Grey,
  },
});

export default HansefitTheme;
