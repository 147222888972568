import { Box, Grid } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { FormikErrorMessage } from '@/App/Shared/Form/Components/Formik/FormikErrorMessage';
import { FormikSwitch } from '@/App/Shared/Form/Components/Formik/FormikSwitch';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { SwitchLabel } from '@/App/Shared/Form/Components/Label/SwitchLabel';
import { useGetPartnerQuery } from '@/Queries';
import { OnsiteEvent } from '@/Types';

import { useAppointmentsFormStyles } from './Event.styles';

type LocationInfoFormProps = {
  isShowTitle?: boolean;
  xsContainer?: number;
  contentContainerPadding?: number;
  isEditable?: boolean;
};

export const LocationInfoForm = ({
  xsContainer = 8,
  isShowTitle = true,
  contentContainerPadding,
  isEditable = true,
}: LocationInfoFormProps) => {
  const { formatMessage } = useIntl();
  const { data: partner } = useGetPartnerQuery();
  const { classes } = useAppointmentsFormStyles();
  const { setFieldValue, values } = useFormikContext<OnsiteEvent>();

  const setPartnerAddress = useCallback(
    (isPartnerAddress: boolean) => {
      if (partner) {
        setFieldValue(
          'address.street',
          isPartnerAddress ? partner?.street : values.address?.street,
        );
        setFieldValue(
          'address.streetNumber',
          isPartnerAddress ? partner?.streetNumber : values.address?.streetNumber,
        );
        setFieldValue('address.city', isPartnerAddress ? partner?.city : values.address?.city);
        setFieldValue(
          'address.postcode',
          isPartnerAddress ? partner?.zip : values.address?.postcode,
        );
        setFieldValue('additionalInformation', values.additionalInformation);
      }
    },
    [
      partner,
      setFieldValue,
      values.address?.street,
      values.address?.streetNumber,
      values.address?.city,
      values.address?.postcode,
      values.additionalInformation,
    ],
  );

  useEffect(() => {
    setPartnerAddress(values.address.isPartnerAddress || false);
  }, [values.address.isPartnerAddress, setPartnerAddress]);

  const handleWebsiteChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentVal = e.target.value === 'true';
      setPartnerAddress(currentVal);
      setFieldValue('address.isPartnerAddress', !currentVal);
    },
    [setFieldValue, setPartnerAddress],
  );
  return (
    <Grid item xs={12}>
      <Grid container spacing={4}>
        {isShowTitle && (
          <Grid item container spacing={4}>
            <Grid item xs={12}>
              <FormHeader
                title={formatMessage({
                  id: 'form.event.header.venue',
                  defaultMessage: 'Venue',
                })}
              />
            </Grid>
          </Grid>
        )}

        <Grid xs={xsContainer} item container>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box className={classes.switchWrapper}>
                <Box className={classes.switchFieldAndLabel}>
                  <Field
                    color='primary'
                    type='checkbox'
                    component={FormikSwitch}
                    onChange={handleWebsiteChange}
                    name='address.isPartnerAddress'
                    disabled={!isEditable}
                  />
                  <SwitchLabel
                    title={formatMessage({
                      id: 'form.event.subheader.venue.apply_partner_address',
                      defaultMessage: 'Adopt partner address as event location',
                    })}
                  />
                </Box>
                <FormikErrorMessage name='address.isPartnerAddress' />
              </Box>
              {!isEditable &&
                formatMessage({
                  id: 'form.address_and_contact.cant_edit',
                  defaultMessage:
                    'You cannot edit the address later than 24 hours before the event',
                })}
            </Grid>
            <Grid item xs={8}>
              <Field
                component={FormikTextField}
                variant='outlined'
                name='address.street'
                type='text'
                label={formatMessage({
                  id: 'form.address_and_contact.street',
                  defaultMessage: 'Street',
                })}
                disabled={values.address.isPartnerAddress || !isEditable}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component={FormikTextField}
                variant='outlined'
                name='address.streetNumber'
                type='text'
                label={formatMessage({
                  id: 'form.address_and_contact.house_number',
                  defaultMessage: 'House number',
                })}
                disabled={values.address.isPartnerAddress || !isEditable}
              />
            </Grid>

            <Grid item xs={5}>
              <Field
                component={FormikTextField}
                variant='outlined'
                name='address.postcode'
                type='text'
                label={formatMessage({
                  id: 'form.address_and_contact.zipcode',
                  defaultMessage: 'Postal code',
                })}
                disabled={values.address.isPartnerAddress || !isEditable}
              />
            </Grid>
            <Grid item xs={7}>
              <Field
                component={FormikTextField}
                variant='outlined'
                name='address.city'
                type='text'
                label={formatMessage({
                  id: 'form.address_and_contact.city',
                  defaultMessage: 'Location',
                })}
                disabled={values.address.isPartnerAddress || !isEditable}
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                component={FormikTextField}
                variant='outlined'
                name='additionalInformation'
                type='text'
                placeholder={formatMessage({
                  id: 'form.event.field.location_settings.additional_information.placeholder',
                  defaultMessage: 'Free text (max. character limit)',
                })}
                multiline
                rows={4}
                label={formatMessage({
                  id: 'form.event.field.location_settings.additional_information.label',
                  defaultMessage: 'Further information about the venue',
                })}
                disabled={!isEditable}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
