import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { useIntl } from 'react-intl';

import { RegisterStepIcon } from './RegisterStepIcon';
import { RegisterStepConnector, useRegisterStepperStyles } from './Stepper.styles';

export interface RegisterStepperProps {
  activeStep: number;
}

export const RegisterStepper = (props: RegisterStepperProps): JSX.Element => {
  const { activeStep } = props;
  const { classes } = useRegisterStepperStyles();
  const { formatMessage } = useIntl();

  return (
    <Box className={classes.root}>
      <Stepper
        alternativeLabel
        connector={<RegisterStepConnector />}
        activeStep={activeStep}
        className={classes.stepper}>
        <Step className={classes.step}>
          <StepLabel
            StepIconComponent={props => (
              <RegisterStepIcon
                {...props}
                stepNumber={1}
                label={formatMessage({
                  id: 'form.register.stepper.step.initial_startcode',
                  defaultMessage: 'Initial start code',
                })}
              />
            )}
          />
        </Step>
        <Step className={classes.step}>
          <StepLabel
            StepIconComponent={props => (
              <RegisterStepIcon
                {...props}
                stepNumber={2}
                label={formatMessage({
                  id: 'form.register.stepper.step.registration',
                  defaultMessage: 'Registration',
                })}
              />
            )}
          />
        </Step>
        <Step className={classes.step}>
          <StepLabel
            StepIconComponent={props => (
              <RegisterStepIcon
                {...props}
                stepNumber={3}
                label={formatMessage({
                  id: 'form.register.stepper.step.finished',
                  defaultMessage: 'Done',
                })}
              />
            )}
          />
        </Step>
      </Stepper>
    </Box>
  );
};
