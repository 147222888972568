import { styled, Typography } from '@mui/material';

import { withTransientProps } from '@/App/Theme/withTransientProps';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const StyledTime = styled(Typography, withTransientProps)<{ $cancelled: boolean }>`
  font-family: Roboto-Bold;
  font-size: 14px;
  color: ${props => (props.$cancelled ? FigmaDesignTokens.Grey[500] : 'inherit')};
`;
