import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { useGetPartnerQuery } from '@/Queries';
import { AssetService } from '@/Services';
import Trash from '@/Static/Icons/bin.svg';
import { Partner } from '@/Types';

import { useAssetPreviewStyles } from './Assets.styles';

type AssetPreviewProps = {
  type: 'image' | 'video';
  assetService: AssetService;
  assetId: number;
  handleDelete: (assetId: number) => void;
  resource: string;
  casPublicId: Partner['casPublicId'];
  originalFilename?: string | null;
};

export const AssetPreview = ({
  type,
  resource,
  handleDelete,
  casPublicId,
  assetService,
  assetId,
  originalFilename,
}: AssetPreviewProps) => {
  const [signedResource, setSignedResource] = useState<string>();
  const { classes } = useAssetPreviewStyles();
  const { data: partner } = useGetPartnerQuery();

  const getSignedResource = useCallback(async () => {
    const signedResource = await assetService.S3GetSignedResource(
      casPublicId,
      resource,
      type === 'video' ? 'Video' : 'Logo',
    );

    if (signedResource) {
      setSignedResource(signedResource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [casPublicId, resource, type]);

  useEffect(() => {
    getSignedResource();
  }, [getSignedResource]);

  return (
    <Grid container>
      <Grid item xs={12}>
        {type === 'video' && signedResource && (
          <video className={classes.video} src={signedResource} controls />
        )}
        {type === 'image' && signedResource && (
          <Box className={classes.logoWrapper}>
            <img className={classes.logoImg} alt='logo' src={signedResource} />
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.fileInformationWrapper}>
          <Typography className={classes.videoTitle}>{originalFilename}</Typography>
          <IconButton onClick={() => handleDelete(assetId)} disabled={partner?.readonly}>
            <img alt='' className={classes.trash} src={Trash} />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};
