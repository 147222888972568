import { HttpService } from '@/Services';
import { Auth } from 'aws-amplify';
import { AssetService } from '../Asset/Asset.service';
import { Dispatch } from 'react';
import { AnyStateAction } from '@/Context';

export class AssetServiceFactory {
  public getInstance(dispatch: Dispatch<AnyStateAction>): AssetService {
    return new AssetService(new HttpService(Auth, dispatch));
  }
}
