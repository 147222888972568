import { LineDivider } from '@/Components';
import AdvertismentHeader from '@/Components/AdvertismentHeader';
import EventsDashboardWidget from '@/Components/Forms/Events/DashboardWidget';
import { Category, Partner, StatsData } from '@/Types';

import CurrentCheckinsWidget from './CurrentCheckinsWidget';
import { Container, EventsTableContainer, FirstRowContainer } from './DashContent.styles';
import { ProfileInfo } from './StudioProfil/ProfileInfo';
import { TopAndStats } from './TopAndStats/TopAndStats';

type DashContentProps = {
  partnerCategories: Category[];
  partner: Partner;

  stats: StatsData;
};

export const DashContent = ({
  partnerCategories,
  partner,

  stats,
}: DashContentProps) => (
  <Container>
    <AdvertismentHeader />
    <FirstRowContainer>
      <ProfileInfo partnerCategories={partnerCategories} partner={partner} />
      <CurrentCheckinsWidget />
    </FirstRowContainer>
    <LineDivider verticalSpace={30} />
    <EventsTableContainer>
      <EventsDashboardWidget />
    </EventsTableContainer>
    <TopAndStats stats={stats} />
  </Container>
);
