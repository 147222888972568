import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppContext } from '@/Context';
import { queryClient } from '@/Helpers';
import { AssetServiceFactory } from '@/Services';
import { LogoAssetProps, Partner } from '@/Types';

const useUpdateLogoAssetMutation = () => {
  const { dispatch } = useAppContext();
  const assetService = useMemo(() => new AssetServiceFactory().getInstance(dispatch), [dispatch]);

  return useMutation(
    ({
      casPublicId,
      logoAsset,
    }: {
      casPublicId: Partner['casPublicId'];
      logoAsset: LogoAssetProps;
    }) => assetService.updateLogoAsset(casPublicId, logoAsset),
    {
      onSuccess(response) {
        queryClient.setQueryData(['getAssetsQuery'], response.data);
      },
    },
  );
};

export default useUpdateLogoAssetMutation;
