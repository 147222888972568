import { Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../design/design-tokens.json';
import { RegisterForm } from './Components/RegisterPartner/RegisterForm';
import { GuestSwitchBtn } from './Components/Shared/Btns/GuestSwitchBtn';

const useStyles = makeStyles()(theme => ({
  root: {
    overflow: 'scroll',
    backgroundImage: `linear-gradient(170deg, ${FigmaDesignTokens.Blue[200]} 30%, ${theme.palette.primary.main} 108%)`,
    height: '100%',
  },
}));

export const RegisterPartnerPage = () => {
  const { classes } = useStyles();
  const intl = useIntl();

  return (
    <Grid className={classes.root}>
      <GuestSwitchBtn
        header={intl.formatMessage({
          id: 'view.register.header',
          defaultMessage: "You don't have an account for the Hansefit partner portal yet?",
        })}
        href='/login'
        btnText={intl.formatMessage({
          id: 'view.register.cta',
          defaultMessage: 'Register now',
        })}
      />
      <RegisterForm />
    </Grid>
  );
};
