import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const CellContainer = styled(Typography)`
  width: 100%;
`;

export const StyleName = styled(Typography)`
  fontsize: 16px;
  font-family: Roboto-Bold;
`;

export const StyleDescription = styled(Typography)`
  fontsize: 16px;
  color: ${FigmaDesignTokens.Grey[500]};
  font-family: Roboto-Regular;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 4px;
`;

export const StyleFileType = styled(Typography)`
  fontsize: 16px;
  color: ${FigmaDesignTokens.Grey[950]};
  font-family: Roboto-Regular;
`;

export const StyleCategory = styled(Box)<{ $cancelled?: boolean }>`
  padding: 1px 8px;
  color: ${props => (props.$cancelled ? FigmaDesignTokens.Grey[500] : 'inherit')};
  font-family: Roboto-Regular;
  fontsize: 16px;
  line-height: 1.38;
  border: 1px solid ${FigmaDesignTokens.Grey[300]};
  border-radius: 11px;
  text-transform: unset;
  width: fit-content;
`;
