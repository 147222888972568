import { Typography } from '@mui/material';

import { Button } from '@/Components';
import { useEventsTableView } from '@/Hooks/useEventsTableView';
import { useGetPartnerQuery } from '@/Queries';
import { ReactComponent as AddIcon } from '@/Static/Icons/bluePlusIcon.svg';

import { Container, CtaContainer, SubHeader } from './NoRowsOverlay.styles';

type EmptyTableProps = {
  readOnlyPartner?: boolean;
  header: string;
  subHeader: string;
  ctaTitle?: string;
  ctaHandler?: () => void;
  hasIcon?: boolean;
};

export const NoRowsOverlay = ({
  header,
  subHeader,
  ctaTitle,
  ctaHandler,
  readOnlyPartner,
  hasIcon = true,
}: EmptyTableProps) => {
  const { data: partner } = useGetPartnerQuery();

  const view = useEventsTableView();

  const isDisabled = Boolean(partner?.readonly) ? partner?.readonly : readOnlyPartner;

  return (
    <Container $view={view}>
      <Typography variant='h2'>{header}</Typography>
      <SubHeader variant='body1'>{subHeader}</SubHeader>
      {ctaTitle && (
        <CtaContainer>
          <Button disabled={isDisabled} onClick={ctaHandler} icon={hasIcon ? <AddIcon /> : ''}>
            {ctaTitle}
          </Button>
        </CtaContainer>
      )}
    </Container>
  );
};
