import { makeStyles } from 'tss-react/mui';

import theme from '@/App/Theme/Theme';
import { heightButtonStyles, paddingButtonStyles, sizeButtonStyles } from '@/Models';
import { ButtonSize, ButtonVariant, ValuePosition } from '@/Types';

import FigmaDesignTokens from '../../design/design-tokens.json';

const backgroundMapping = {
  contained: theme.palette.primary.main,
  outlined: FigmaDesignTokens.Grey[100],
  text: 'inherit',
};

const colorMapping = {
  contained: FigmaDesignTokens.White,
  outlined: theme.palette.primary.main,
  text: theme.palette.primary.main,
};

const backgroundColorMapping = {
  contained: theme.palette.primary.dark,
  outlined: FigmaDesignTokens.Blue[100],
  text: 'unset',
};

const disabledBackgroundColorMapping = {
  contained: theme.palette.info.dark,
  outlined: FigmaDesignTokens.Grey[100],
  text: 'inherit',
};

const disabledColorMapping = {
  contained: FigmaDesignTokens.White,
  outlined: theme.palette.info.dark,
  text: theme.palette.info.dark,
};

const valuePositionMapper = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

export const useButtonStyles = ({
  withShadow,
  textTransform,
  size,
  width,
  variant,
  hasIcon,
  link,
  valuePosition,
}: {
  withShadow?: boolean;
  textTransform?: boolean;
  link?: boolean;
  hasIcon?: boolean;
  padding?: string;
  valuePosition: ValuePosition;
  width?: string;
  size: ButtonSize;
  variant: ButtonVariant;
}) =>
  makeStyles()(() => ({
    button: {
      display: 'flex',
      justifyContent: valuePositionMapper[valuePosition],
      borderRadius: '20px',
      fontWeight: 500,
      fontFamily: 'Roboto',
      width: `${width === 'max' ? '100%' : 'auto'}`,
      backgroundColor: backgroundMapping[variant],
      color: colorMapping[variant],
      fontSize: sizeButtonStyles[size] || '14px',
      height: heightButtonStyles[size] || '24px',
      padding: paddingButtonStyles[size],
      paddingLeft: `${hasIcon && '5'}px`,

      boxShadow: `${withShadow ? '0 10px 10px 0 rgba(0, 0, 0, 0.1)' : 'none'}`,
      textTransform: `${textTransform ? 'uppercase' : 'none'}`,
      border: ` ${variant === 'outlined' ? `2px solid ${theme.palette.primary.main}` : 'none'}`,

      '&:hover': {
        backgroundColor: backgroundColorMapping[variant],
        border: `${variant === 'outlined' ? `2px solid ${theme.palette.primary.main}` : 'none'}`,
        textDecoration: `${link ? 'underline' : 'none'}`,
        textDecorationColor: colorMapping[variant],
      },
      '&:disabled': {
        backgroundColor: disabledBackgroundColorMapping[variant],
        border: `${variant === 'outlined' ? `2px solid ${FigmaDesignTokens.Grey[300]}` : 'none'}`,
        color: disabledColorMapping[variant],
      },
      img: {
        marginRight: '8px',
        marginLeft: '2px',
      },
    },
    iconLeft: {
      display: 'flex',
      marginRight: '10px',
    },
    iconRight: {
      display: 'flex',
      marginLeft: '10px',
    },
  }));
