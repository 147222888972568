import { useCallback } from 'react';
import { ConfirmationDialogTypes, useAppContext } from '@/Context';

export function useConfirmDialog(): (value: boolean) => void {
  const { dispatch } = useAppContext();
  const handleConfirmDialog = useCallback(
    (value: boolean) => {
      dispatch({
        type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
        payload: { show: value },
      });
    },
    [dispatch],
  );

  return handleConfirmDialog;
}
