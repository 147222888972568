/* eslint-disable import/prefer-default-export */
import { Backdrop, Box, Drawer, Fade, Typography, useMediaQuery, useTheme } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { Contact, LineDivider } from '@/Components';
import MenuIcon from '@/Static/Icons/atoms-symbols-icons-menu.svg';

import { useSidebarNavGuestStyles } from '../Styles/SidebarNavGuest.styles';
import { SidebarGuestFooter } from './SidebarGuestFooter';
import SidebarLogo from './SidebarLogo';

export const SidebarNavGuest = (): JSX.Element => {
  const { classes } = useSidebarNavGuestStyles();
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const handleToggleSidebar = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <div className={classes.root}>
      <Backdrop
        open={open && !matches}
        className={classes.backdrop}
        onClick={handleToggleSidebar}
      />
      <Drawer
        classes={{
          paper: clsx(classes.paper, {
            [classes.paperOpen]: open,
            [classes.paperClose]: !open,
          }),
        }}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        variant='permanent'>
        <Box
          className={clsx(classes.brandContainer, {
            [classes.brandContainerShow]: open,
            [classes.brandContainerHide]: !open,
          })}>
          <Box className={clsx(classes.logo, { [classes.logoFullSize]: open })} />
          <Fade in={open || matches} timeout={800}>
            <Box
              className={clsx({
                [classes.hidden]: !open || !matches,
                [classes.show]: open || matches,
              })}>
              <SidebarLogo />
            </Box>
          </Fade>
          <Fade in={open || matches} timeout={800}>
            <Typography
              variant='body2'
              className={clsx(classes.brandSubHeader, {
                [classes.hidden]: !open || !matches,
                [classes.show]: open || matches,
              })}>
              {intl
                .formatMessage({
                  id: 'app.alternative_name',
                  defaultMessage: 'Verbundpartner - Portal',
                })
                .toUpperCase()}
            </Typography>
          </Fade>
        </Box>
        <Fade in={!open && !matches}>
          <Box
            className={clsx(classes.menuIconWrapper, {
              [classes.show]: open || !matches,
            })}
            onClick={handleToggleSidebar}>
            <img alt='' src={MenuIcon} className={clsx(classes.menuIcon)} />
          </Box>
        </Fade>
        <LineDivider horizontalSpace='inherit' />
        <Fade in={open || matches} timeout={800}>
          <Box className={classes.linksWrapper}>
            <Contact />
            <SidebarGuestFooter />
          </Box>
        </Fade>
      </Drawer>
    </div>
  );
};
