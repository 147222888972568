import { LabelDisplayedRowsArgs } from '@mui/material';
import { useIntl } from 'react-intl';

const LabelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => {
  const intl = useIntl();
  return (
    <>{`${from}–${to} ${intl.formatMessage({
      id: 'table.footer.from',
      defaultMessage: 'from',
    })} ${Intl.NumberFormat('de-DE').format(count)} ${intl.formatMessage({
      id: 'table.footer.results',
      defaultMessage: 'Results',
    })}`}</>
  );
};

export default LabelDisplayedRows;
