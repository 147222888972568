import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { IntlShape } from 'react-intl';

import { CourseTypeCell } from '@/App/Shared/Table/TableCell/CourseTypeCell';
import { ActionsCell } from '@/Components/Forms/Events/TableCell/ActionsCell';
import { BookingsCell } from '@/Components/Forms/Events/TableCell/BookingsCell';
import { CourseNameCell } from '@/Components/Forms/Events/TableCell/CourseNameCell';
import { DateCell } from '@/Components/Forms/Events/TableCell/DateCell';
import { DurationCell } from '@/Components/Forms/Events/TableCell/DurationCell';
import { InternalCourseNameCell } from '@/Components/Forms/Events/TableCell/InternalCourseNameCell';
import { StreamLinkCell } from '@/Components/Forms/Events/TableCell/StreamLinkCell';
import { TimeCell } from '@/Components/Forms/Events/TableCell/TimeCell';
import { VisibilityCell } from '@/Components/Forms/Events/TableCell/VisibilityCell';
import { EVENT_LIST_VIEW } from '@/Hooks/useEventsTableView';
import { getCourseTypes, getCourseTypeTranslation, isOnsiteCourseType } from '@/Mappers';
import { EventListItem } from '@/Types';
import { TableColumnView } from '@/Types/TableColumnVeew';

type HandleModals = {
  handleEditEventsModal: (eventIds: string[]) => void;
  handlePublishEventsModal: (eventIds: string[]) => void;
  handleCancelEventsModal: (eventIds: string[]) => void;
  handleEditEventsStreamDataModal: (eventIds: string[]) => void;
  setBookingListModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setActionRow: React.Dispatch<React.SetStateAction<EventListItem | null>>;
  handleUpdateEvents: () => void;
};

export const getEventTableColumns = (
  columnValue: Partial<TableColumnView>,

  intl: IntlShape,
  view?: EVENT_LIST_VIEW | undefined,
  handleModals?: HandleModals,
  disableMenu = true,
) => {
  const courseTypes = getCourseTypes();
  const columnCollection: GridColDef[] = [];

  if (columnValue['cancelled']) {
    columnCollection.push({
      field: 'cancelled',
      headerName: intl.formatMessage({
        id: 'view.courses_and_events.events.table.header.visible',
        defaultMessage: 'Visibility',
      }),
      display: 'flex',
      disableColumnMenu: disableMenu,
      minWidth: columnValue['cancelled'].minWidth,
      renderCell: (params: GridRenderCellParams) => (
        <VisibilityCell gridRenderCellParams={params} view={view} />
      ),
    });
  }

  if (columnValue['courseName'])
    columnCollection.push({
      field: 'courseName',
      display: 'flex',
      headerName: intl.formatMessage({
        id: 'view.courses_and_events.events.table.header.course_name',
        defaultMessage: 'Course name',
      }),
      disableColumnMenu: disableMenu,
      minWidth: columnValue['courseName'].minWidth,
      renderCell: (params: GridRenderCellParams) => (
        <CourseNameCell gridRenderCellParams={params} />
      ),
    });

  if (columnValue['internalCourseName'])
    columnCollection.push({
      field: 'internalCourseName',
      display: 'flex',
      headerName: intl.formatMessage({
        id: 'view.courses_and_events.events.table.header.internal_course_name',
        defaultMessage: 'Internal course name',
      }),
      disableColumnMenu: disableMenu,
      minWidth: columnValue['internalCourseName'].minWidth,
      renderCell: (params: GridRenderCellParams) => (
        <InternalCourseNameCell gridRenderCellParams={params} />
      ),
    });

  if (columnValue['courseType'])
    columnCollection.push({
      field: 'courseType',
      headerName: intl.formatMessage({
        id: 'view.courses_and_events.events.table.header.course_type',
        defaultMessage: 'Type',
      }),
      display: 'flex',
      width: columnValue['courseType'].minWidth,
      disableColumnMenu: disableMenu,
      // TODO: Oh manomanoman
      valueGetter: (value: EventListItem['courseType']) => getCourseTypeTranslation(value, intl),
      valueOptions: courseTypes.map(type => getCourseTypeTranslation(type.value, intl)),
      renderCell: (params: GridRenderCellParams<EventListItem, EventListItem['courseType']>) => (
        <CourseTypeCell value={params.value} isCancelled={params.row.cancelled} />
      ),
    });

  if (columnValue['date'])
    columnCollection.push({
      field: 'date',
      display: 'flex',
      headerName: intl.formatMessage({
        id: 'view.courses_and_events.events.table.header.date_range',
        defaultMessage: 'Date',
      }),
      disableColumnMenu: disableMenu,
      minWidth: columnValue['date'].minWidth,
      renderCell: (params: GridRenderCellParams) => <DateCell gridRenderCellParams={params} />,
    });

  if (columnValue['time'])
    columnCollection.push({
      field: 'time',
      display: 'flex',
      headerName: intl.formatMessage({
        id: 'view.courses_and_events.events.table.header.time_range',
        defaultMessage: 'Time',
      }),
      disableColumnMenu: disableMenu,
      minWidth: columnValue['time'].minWidth,
      renderCell: (params: GridRenderCellParams) => <TimeCell gridRenderCellParams={params} />,
    });

  if (columnValue['duration'])
    columnCollection.push({
      field: 'duration',
      display: 'flex',
      headerName: intl.formatMessage({
        id: 'view.courses_and_events.events.table.header.duration_range',
        defaultMessage: 'Duration',
      }),
      disableColumnMenu: disableMenu,
      minWidth: columnValue['duration'].minWidth,
      renderCell: (params: GridRenderCellParams) => <DurationCell gridRenderCellParams={params} />,
    });

  if (columnValue['bookings'])
    columnCollection.push({
      field: 'bookings',
      display: 'flex',
      headerName: intl.formatMessage({
        id: 'view.courses_and_events.events.table.header.bookings',
        defaultMessage: 'Registrations',
      }),
      disableColumnMenu: disableMenu,
      minWidth: columnValue['bookings'].minWidth,
      renderCell: (params: GridRenderCellParams) => <BookingsCell gridRenderCellParams={params} />,
    });

  if (columnValue['streamLink'])
    columnCollection.push({
      field: 'streamLink',
      display: 'flex',
      headerName: intl.formatMessage({
        id: 'view.courses_and_events.events.table.header.streaming_data',
        defaultMessage: 'Streaming data',
      }),
      disableColumnMenu: disableMenu,
      minWidth: columnValue['streamLink'].minWidth,
      renderCell: (params: GridRenderCellParams) => (
        <StreamLinkCell
          gridRenderCellParams={params}
          isOnsiteCourseType={isOnsiteCourseType(params?.row?.courseType)}
          view={view}
        />
      ),
    });

  if (columnValue['tableActions'])
    columnCollection.push({
      field: 'tableActions',
      headerName: '',
      display: 'flex',
      width: columnValue['tableActions'].minWidth,
      filterable: false,
      sortable: false,
      resizable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) =>
        !params.row.cancelled && handleModals ? (
          <ActionsCell
            gridRenderCellParams={params}
            handleEditEvent={handleModals.handleEditEventsModal}
            handlePublishEvents={handleModals.handlePublishEventsModal}
            handleCancelEvents={handleModals.handleCancelEventsModal}
            handleEditEventStreamData={handleModals.handleEditEventsStreamDataModal}
            setViewRegistrationModalOpen={handleModals.setBookingListModalOpen}
            setActionRow={handleModals.setActionRow}
            isOnsiteCourseType={isOnsiteCourseType(params?.row?.courseType)}
            updateEvents={handleModals.handleUpdateEvents}
          />
        ) : null,
    });

  return columnCollection;
};
