import moment from 'moment';
import { IntlShape } from 'react-intl/src/types';
import * as Yup from 'yup';

import { CourseTypes, GeneralEvent } from '@/Types';

export const EventValidation = (intl: IntlShape, courseType: CourseTypes | undefined) => {
  const now = new Date();
  const minDate = new Date(now.setHours(0, 0, 0, 0));
  const maxDate = moment(minDate).add(1, 'years').toDate();
  let resultObject = Yup.object().shape({
    appointments: Yup.object().shape({
      startDate: Yup.date()
        .nullable()
        .typeError(
          intl.formatMessage({
            id: 'form.event.error.appointments.start_date.required',
            defaultMessage: 'Please enter a start date.',
          }),
        )
        .required(
          intl.formatMessage({
            id: 'form.event.error.appointments.start_date.required',
            defaultMessage: 'Please enter a start date.',
          }),
        )
        .min(
          minDate,
          intl.formatMessage({
            id: 'form.event.error.appointments.start_date.past_date_error',
            defaultMessage: 'Please select current date or a date in the future.',
          }),
        )
        .max(
          maxDate,
          intl.formatMessage({
            id: 'form.event.field.appointments.max_date.error',
            defaultMessage: 'Date must be no more than 12 months in the future',
          }),
        )
        .when('$isSeries', (isSeries, schema) => {
          if (isSeries) {
            return schema.test(
              'start_date_after_end_course_date',
              intl.formatMessage({
                id: 'form.event.error.appointments.series_settings.end_date_before_start_course_date',
                defaultMessage: 'Start date after end date.',
              }),
              function (value: any, context: any) {
                const values = context as unknown as {
                  from: Array<{ value: GeneralEvent['appointments'] }>;
                };
                const endDate = values.from[0].value.seriesSettings?.endDate;
                if (endDate && value) return value.getTime() < new Date(endDate).getTime();
                return true;
              },
            );
          }
          return schema;
        }),
      hour: Yup.string()
        .test(
          'time_first_appointment_fast',
          'form.event.error.appointments.first.time.passed',
          function (startHour, context) {
            const values = context as unknown as {
              from: Array<{ value: GeneralEvent['appointments'] }>;
            };

            if (!values.from[0]) return true;
            const startDate = values.from[0].value.startDate;
            const startMins = values.from[0].value.minute;

            const today = new Date();
            const currentHour = today.getHours();

            if (!startDate) return true;
            if (!startHour) return true;
            if (!startMins) return true;

            if (startDate && new Date(startDate) > today) return true;
            else if (parseInt(startHour) > currentHour) return true;
            else if (
              parseInt(startHour) === currentHour &&
              startMins &&
              parseInt(startMins) > today.getMinutes()
            )
              return true;
            else return false;
          },
        )
        .required('form.event.error.appointments.time.required')
        .matches(/^\d{1,2}$/),
      minute: Yup.string()
        .required(
          intl.formatMessage({
            id: 'form.event.error.appointments.time.required',
            defaultMessage: 'Please provide a course clock time.',
          }),
        )
        .matches(/^\d{1,2}$/),
      timeZone: Yup.string().required(
        intl.formatMessage({
          id: 'form.event.error.appointments.time_zone.required',
          defaultMessage: 'Please specify a time zone.',
        }),
      ),
      capacity: Yup.number()
        .nullable()
        .required(
          intl.formatMessage({
            id: 'form.event.error.appointments.capacity.required',
            defaultMessage: 'Please indicate the available seats.',
          }),
        ),
      seriesSettings: Yup.object().when('$isSeries', {
        is: true,
        then: Yup.object().shape({
          endDate: Yup.date()
            .nullable()
            .required(
              intl.formatMessage({
                id: 'form.event.error.appointments.series_settings.end_date.required',
                defaultMessage: 'Please specify an end date.',
              }),
            )
            .test(
              'end_date_before_start_course_date',
              intl.formatMessage({
                id: 'form.event.error.appointments.series_settings.end_date_before_start_course_date',
                defaultMessage: 'End date before start date.',
              }),
              function (value, context) {
                const values = context as unknown as {
                  from: Array<{ value: GeneralEvent['appointments'] }>;
                };
                const startDate = values.from[1].value.startDate;
                if (startDate && value) return value.getTime() > new Date(startDate).getTime();
                return true;
              },
            )
            .test(
              'end_date_max',
              intl.formatMessage({
                id: 'form.event.error.appointments.series_settings.end_date_before_start_course_date',
                defaultMessage: 'Date must be no more than 12 months after start date',
              }),
              function (value, context) {
                const values = context as unknown as {
                  from: Array<{ value: GeneralEvent['appointments'] }>;
                };
                const startDate = values.from[1].value.startDate;
                if (startDate && value) {
                  return (
                    moment(value).valueOf() <
                    moment(startDate).add(1, 'day').add(1, 'years').valueOf()
                  );
                }
                return true;
              },
            ),
          weekdays: Yup.array()
            .required()
            .min(
              1,
              intl.formatMessage({
                id: 'form.event.error.appointments.series_settings.weekdays.min_1',
                defaultMessage: 'Please select at least 1 day of the week.',
              }),
            ),
          repetitions: Yup.string().required(
            intl.formatMessage({
              id: 'form.event.error.appointments.series_settings.repetitions.required',
              defaultMessage: 'Please specify repetition.',
            }),
          ),
        }),
      }),
    }),
  });

  if (courseType === CourseTypes.ONLINE) {
    resultObject = resultObject.shape({
      streamSettings: Yup.object().shape({
        streamLink: Yup.string().matches(
          /(https?:\/\/)?([\w-])+\.{1}([a-zA-Z]{2,63})([/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/,
          intl.formatMessage({
            id: 'form.event.error.stream_settings.stream_link.matches',
            defaultMessage: 'Link has a wrong format',
          }),
        ),
      }),
    });
  }

  if (courseType === CourseTypes.ONSITE) {
    resultObject = resultObject.shape({
      address: Yup.object().shape({
        isPartnerAddress: Yup.boolean(),
        street: Yup.string().when('isPartnerAddress', {
          is: false,
          then: Yup.string().required(
            intl.formatMessage({
              id: 'form.event.error.appointments.location_settings.street.required',
              defaultMessage: 'Please enter a street name.',
            }),
          ),
        }),
        streetNumber: Yup.string().when('isPartnerAddress', {
          is: false,
          then: Yup.string().required(
            intl.formatMessage({
              id: 'form.event.error.appointments.location_settings.streetNumber.required',
              defaultMessage: 'Please enter a house number.',
            }),
          ),
        }),
        city: Yup.string().when('isPartnerAddress', {
          is: false,
          then: Yup.string().required(
            intl.formatMessage({
              id: 'form.event.error.appointments.location_settings.city.required',
              defaultMessage: 'Please enter a city name.',
            }),
          ),
        }),
        postcode: Yup.string().when('isPartnerAddress', {
          is: false,
          then: Yup.string().required(
            intl.formatMessage({
              id: 'form.event.error.appointments.location_settings.zip.required',
              defaultMessage: 'Please enter a postal code.',
            }),
          ),
        }),
        additionalInformation: Yup.string().max(
          255,
          intl.formatMessage({
            id: 'form.event.error.appointments.location_settings.additional_info.length',
            defaultMessage: 'This field must be less than 255 characters long.',
          }),
        ),
      }),
    });
  }

  return resultObject;
};
