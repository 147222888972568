import { Box, MenuItem, Select, styled } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import FigmaDesignTokens from '../../../../design/design-tokens.json';

export const AddNewContainer = styled(Box)`
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export const Selector = styled(Select)(() => ({
  width: 200,
  '.MuiSelect-outlined': {
    padding: '8px 14px',
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: 1.5,
    letterSpacing: 0.15,
  },
}));

export const SelectorItem = styled(MenuItem)(() => ({
  fontFamily: 'Roboto-Regular',
  fontSize: 14,
  lineHeight: 1.38,
  color: FigmaDesignTokens.Grey[950],
  padding: '8px 16px',
}));

export const useButtonStyles = makeStyles()(theme => ({
  filtersApplyBtn: {
    padding: '7px 16px',
    fontFamily: 'Roboto-Medium',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.main,
    borderRadius: '4px',
    boxShadow: 'none',
    minWidth: 'auto',
    height: '40px',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.38,
    textTransform: 'unset',

    '&.MuiButton-outlined': {
      fontFamily: 'Roboto-Bold',
      borderColor: 'currentColor',
    },
    '&.MuiButton-info': {
      fontFamily: 'Roboto-Bold',
      borderColor: 'currentColor',
    },

    '&:disabled': {
      opacity: 0.5,
    },
    '&:hover': {
      backgroundColor: FigmaDesignTokens.Grey[200],
      color: theme.palette.primary.dark,
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: FigmaDesignTokens.Grey[300],
      color: FigmaDesignTokens.Blue[950],
      boxShadow: 'none',
    },
  },
}));
