import ArticleIcon from '@mui/icons-material/Article';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableViewIcon from '@mui/icons-material/TableView';

import FigmaDesignTokens from '../design/design-tokens.json';

const contentTypeToIcon = (contentType: string) => {
  const commonStyle = {
    width: 24,
    height: 24,
    color: FigmaDesignTokens.Grey[500],
  };
  if (contentType.includes('image')) {
    return <ImageIcon sx={commonStyle} />;
  }
  if (contentType.includes('pdf')) {
    return <PictureAsPdfIcon sx={commonStyle} />;
  }
  if (
    contentType.includes('word') ||
    contentType.includes('document') ||
    contentType.includes('text')
  ) {
    return <ArticleIcon sx={commonStyle} />;
  }
  if (contentType.includes('sheet')) {
    return <TableViewIcon sx={commonStyle} />;
  }
  return null;
};

export default contentTypeToIcon;
