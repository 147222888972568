import { Modal } from '@mui/material';

import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { Button } from '@/Components';
import { UserStatus } from '@/Mappers';
import CloseIcon from '@/Static/Icons/atoms-symbols-category-close-big.svg';

import {
  ButtonContainer,
  Container,
  Content,
  CloseButton,
} from './Styles/AdministrationModal.styles';

type ActionModalProps = {
  show: boolean;
  submitButtonTitle: string;
  header: string;
  userStatus: UserStatus;
  onHide: () => void;
  children: JSX.Element;
  handleSubmit: (userStatus: UserStatus) => void;
};

export const AdministrationModal = ({
  show,
  onHide,
  children,
  header,
  handleSubmit,
  submitButtonTitle,
  userStatus,
}: ActionModalProps) => {
  const handleModalSubmit = () => {
    handleSubmit(userStatus);
    onHide();
  };
  return (
    <Modal open={show}>
      <Container>
        <CloseButton aria-label='close' onClick={onHide} variant='text'>
          <img alt='' src={CloseIcon} />
        </CloseButton>
        <FormHeader title={header} />
        <Content>{children}</Content>
        <ButtonContainer>
          <Button onClick={handleModalSubmit}>{submitButtonTitle}</Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};
