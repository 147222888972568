import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';

import { EVENT_LIST_VIEW } from '@/Hooks/useEventsTableView';
import { EventListItem } from '@/Types';

import { Container, StreamingLinkDataContainer, StreamLinkData } from './StreamLinkCell.styles';

type StreamLinkCellProps = {
  gridRenderCellParams: GridRenderCellParams<EventListItem, EventListItem>;

  view?: EVENT_LIST_VIEW;
  isOnsiteCourseType: boolean;
};

export const StreamLinkCell = ({
  gridRenderCellParams,
  isOnsiteCourseType,
}: StreamLinkCellProps) => {
  const intl = useIntl();

  return (
    <Container>
      <StreamingLinkDataContainer>
        <StreamLinkData
          $streamLink={
            (isOnsiteCourseType && !isEmpty(gridRenderCellParams.row.onsiteAddress)) ||
            gridRenderCellParams.row.streamLink
          }
          $published={gridRenderCellParams.row.published}
          $cancelled={gridRenderCellParams.row.cancelled}>
          {isOnsiteCourseType ? (
            <>
              {isEmpty(gridRenderCellParams.row.onsiteAddress)
                ? intl.formatMessage({
                    id: 'course_address_data_missing',
                    defaultMessage: 'Missing',
                  })
                : intl.formatMessage({
                    id: 'course_address_data_added',
                    defaultMessage: 'Complete',
                  })}
            </>
          ) : (
            <>
              {!!gridRenderCellParams.value
                ? intl.formatMessage({
                    id: 'uploaded_streaming_data',
                    defaultMessage: 'Uploaded',
                  })
                : intl.formatMessage({
                    id: 'missing_streaming_data',
                    defaultMessage: 'Missing',
                  })}
            </>
          )}
        </StreamLinkData>
      </StreamingLinkDataContainer>
    </Container>
  );
};
