import { Box, Button, styled } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const ActionButton = styled(Button)`
  padding: 0;
  min-width: 30px;
`;
