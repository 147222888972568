import 'moment/locale/de';

import { Icon } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Mui5DatePickerField } from '@/App/Shared/Form/Components/Formik/mui5/Mui5DatePickerField';
import { Mui5TextField } from '@/App/Shared/Form/Components/Formik/mui5/Mui5TextField';
import { TranslationService } from '@/App/Shared/Translation/TranslationService';
import { Button, InfoMessage, LineDivider } from '@/Components';
import { useEventsModalHelper } from '@/Hooks/useEventsModalHelper';
import DatePickerIcon from '@/Static/Icons/atoms-icon-calendar.svg';
import { EditEvent, EventList, UpdateEventTypes } from '@/Types';
import { SingleEditEventValidation } from '@/Utils';

import { ColumnWrapper, HintWrapper, Information, RowWrapper } from './EditEvents.styles';
import { EventsModal } from './EventsModal';
import { ButtonContainer } from './EventsModal.styles';

type EditEventsProps = {
  eventIds: string[];
  handleSubmit: (value: UpdateEventTypes) => void;
  handleCancel: (eventIds: string[]) => void;
  isOpen: boolean;
  handleClose: () => void;
};

export enum EDITABLE_EVENT_FIELD {
  CANCEL = 'CANCEL',
  PUBLISH = 'PUBLISH',
  STREAM = 'STREAM',
  EDIT = 'EDIT',
}

export const EditEvents = (props: EditEventsProps) => {
  const { eventIds, handleSubmit, handleClose, isOpen, handleCancel } = props;
  const intl = useIntl();
  const { formatMessage } = intl;
  const { values } = useFormikContext<EventList>();
  const [initialFormData, setInitialFormData] = useState<EditEvent>();
  const { selectedEvents, subHeader, hasEventsWithBookings, hasPublishedEvents } =
    useEventsModalHelper(eventIds, values.eventsData.result);

  const handleCancelEvent = useCallback(() => {
    handleCancel(eventIds);
    handleClose();
  }, [handleClose, eventIds, handleCancel]);

  useEffect(() => {
    if (!initialFormData) {
      if (selectedEvents.length === 1) {
        setInitialFormData({
          capacity: selectedEvents[0].capacity ? selectedEvents[0].capacity : 0,
          date: selectedEvents[0].date ? moment(selectedEvents[0].date).toDate() : '',
          duration: selectedEvents[0].duration ? selectedEvents[0].duration : 0,
          time: selectedEvents[0].time,
        });
      } else if (selectedEvents.length > 1) {
        setInitialFormData({
          capacity: '',
          date: null,
          duration: '',
          time: '',
        });
      }
    }

    moment.locale('de');
  }, [eventIds, initialFormData, selectedEvents]);

  const header = useMemo(() => {
    if (eventIds.length === 1) {
      return formatMessage({
        id: 'view.courses_and_events.events.dialog.edit_event.header.edit_event',
        defaultMessage: 'Edit appointment:',
      });
    } else {
      return TranslationService.replacePlaceholder(
        formatMessage({
          id: 'view.courses_and_events.events.dialog.edit_events.header.edit_events',
          defaultMessage: '{{checkedEvents}} Edit events:',
        }),
        '{{checkedEvents}}',
        eventIds.length.toString(),
      );
    }
  }, [eventIds.length, formatMessage]);

  const editValues = (values: EditEvent) => {
    const typedValues = {
      ...values,
      capacity: values.capacity ? parseInt(values.capacity as string) : null,
      duration: values.duration ? parseInt(values.duration as string) : null,
      date: values.date ? moment(values.date).format('YYYY-MM-DD') : null,
      time: values.time ? values.time : null,
    };
    handleSubmit(typedValues);
    handleClose();
  };

  return (
    <EventsModal open={isOpen} onClose={handleClose} header={header} subHeader={subHeader}>
      <>
        {initialFormData && (
          <Formik
            validationSchema={() => SingleEditEventValidation(intl)}
            initialValues={initialFormData}
            onSubmit={values => editValues(values)}>
            {formik => {
              const { setFieldValue, errors, values } = formik;
              const areAllFieldsEmpty = Object.values(values).every(
                value => value === '' || value === null || value === undefined,
              );

              return (
                <Form>
                  {!hasEventsWithBookings ? (
                    <>
                      <RowWrapper>
                        <ColumnWrapper>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Field
                              component={Mui5DatePickerField}
                              floatingLabelText='DP_Text Start Date'
                              variant='inline'
                              inputVariant='outlined'
                              name='date'
                              label={formatMessage({
                                id: 'form.event.field.appointments.start_date.label',
                                defaultMessage: 'Course date*',
                              })}
                              disablePast
                              onChange={(date: Date) => {
                                setFieldValue('date', date);
                              }}
                              format='dd/MM/yyyy'
                              inputProps={{ placeholder: 'DD/MM/YYYY' }}
                              placeholder='Date'
                              autoComplete='off'
                              autoOk
                              views={['year', 'month', 'day']}
                              components={{
                                OpenPickerIcon: () => (
                                  <Icon>
                                    <img src={DatePickerIcon} alt='' />
                                  </Icon>
                                ),
                              }}
                            />
                          </LocalizationProvider>
                          <Field
                            component={Mui5TextField}
                            variant='outlined'
                            name='time'
                            placeholder='--:--'
                            label={formatMessage({
                              id: 'form.event.field.appointments.time.label',
                              defaultMessage: 'Course starting time',
                            })}
                          />
                        </ColumnWrapper>

                        <ColumnWrapper>
                          <Field
                            component={Mui5TextField}
                            variant='outlined'
                            name='duration'
                            label={formatMessage({
                              id: 'form.event.field.appointments.duration.label',
                              defaultMessage: 'Course duration (in minutes)',
                            })}
                            type='number'
                          />

                          <Field
                            component={Mui5TextField}
                            variant='outlined'
                            name='capacity'
                            label={formatMessage({
                              id: 'form.event.field.appointments.capacity.label',
                              defaultMessage: 'Available seats',
                            })}
                            type='number'
                          />
                        </ColumnWrapper>
                      </RowWrapper>
                      {hasPublishedEvents && (
                        <HintWrapper>
                          <InfoMessage
                            title={formatMessage({
                              id: 'view.courses_and_events.events.dialog.edit_event.hint.cancel_event.header',
                              defaultMessage: 'Existing registrations will be canceled',
                            })}
                            description={formatMessage({
                              id: 'view.courses_and_events.events.dialog.edit_event.hint.cancel_event.description',
                              defaultMessage:
                                'If registrations for this date have already been received, Hansefit members will be informed that the date will not take place.',
                            })}
                            type='information'
                          />
                        </HintWrapper>
                      )}
                      <LineDivider verticalSpace={hasPublishedEvents ? 14 : 58} />

                      <ButtonContainer>
                        {hasPublishedEvents ? (
                          <Button
                            variant='outlined'
                            disabled={!formik.values.published}
                            onClick={handleCancelEvent}>
                            {formatMessage({
                              id: 'view.courses_and_events.events.dialog.edit_event.cta.cancel',
                              defaultMessage: 'Cancel appointment',
                            })}
                          </Button>
                        ) : (
                          <LineDivider horizontalSpace='inherit' />
                        )}
                        <Button
                          withShadow
                          type='submit'
                          disabled={!isEmpty(errors) || areAllFieldsEmpty}>
                          {hasPublishedEvents
                            ? formatMessage({
                                id: 'cta.save_and_publish',
                                defaultMessage: 'Save & Publish',
                              })
                            : formatMessage({
                                id: 'cta.save_changes',
                                defaultMessage: 'Save changes',
                              })}
                        </Button>
                      </ButtonContainer>
                    </>
                  ) : (
                    <>
                      <InfoMessage
                        title={formatMessage({
                          id: 'view.courses_and_events.events.dialog.cancel.notification',
                          defaultMessage:
                            'The date cannot be changed, since already registrations have been received. If not by this date can take place, you have to cancel it.',
                        })}
                        type='warning'
                        iconWidth='55'
                      />
                      <Information variant='body2'>
                        <FormattedMessage
                          id='view.courses_and_events.events.dialog.cancel.description'
                          defaultMessage='The participants will receive a notification via the Hansefit app that this appointment cannot take place.'
                        />
                      </Information>
                      <ButtonContainer>
                        <LineDivider horizontalSpace='inherit' />
                        <Button withShadow onClick={handleCancelEvent}>
                          {eventIds.length === 1
                            ? formatMessage({
                                id: 'view.courses_and_events.events.dialog.edit_event.cta.cancel',
                                defaultMessage: 'Cancel',
                              })
                            : formatMessage({
                                id: 'view.courses_and_events.events.dialog.edit_events.cta.cancel',
                                defaultMessage: 'Cancel',
                              })}
                        </Button>
                      </ButtonContainer>
                    </>
                  )}
                </Form>
              );
            }}
          </Formik>
        )}
      </>
    </EventsModal>
  );
};
