import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Box, Link } from '@mui/material';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import Theme from '@/App/Theme/Theme';
import { BodyTextRegular } from '@/Components';

import { useContactStyles } from './Contact.styles';

export const Contact = (): JSX.Element => {
  const { classes } = useContactStyles();
  const intl = useIntl();

  return (
    <Box className={clsx(classes.root, 'contactContainer')}>
      <Link href='https://hansefit.de/kontakt' target='_blank'>
        <Box className={classes.headerContainer}>
          <EmailOutlinedIcon fontSize='small' />
          <BodyTextRegular
            customColor={Theme.palette.secondary.light}
            textContent={intl.formatMessage({
              id: 'common.contact.header',
              defaultMessage: 'Contact',
            })}
          />
        </Box>
      </Link>
    </Box>
  );
};
