import { last } from 'lodash';
import { nanoid } from 'nanoid';

import { GalleryAsset } from '@/Types';

export const getDefaultFilename = (name: string): string => {
  const splitFileName = name.split('.');

  return splitFileName ? `${nanoid(10)}.${last(splitFileName)}` : nanoid(10);
};

export const getDefaultGalleryAsset = (
  assetId: number,
  key: string,
  originalFilename: string | null,
): GalleryAsset => ({
  uri: key,
  assetId,
  title: {
    de: '',
    en: '',
  },
  originalFilename,
});

export const isQualifiedForUpload = (params: {
  firstGalleryAsset?: GalleryAsset;
  existingImages: number;
  maxAllowedImages: number;
}) => {
  const { existingImages, maxAllowedImages, firstGalleryAsset } = params;

  return firstGalleryAsset?.assetId
    ? existingImages < maxAllowedImages
    : existingImages <= maxAllowedImages;
};

export const isDisableUploadImage = (params: {
  gallery: GalleryAsset[];
  maxAllowedImages: number;
  isDisabledDropzone: boolean;
  readonly: boolean | undefined;
}): boolean => {
  const { gallery, maxAllowedImages, isDisabledDropzone, readonly } = params;

  if (!!readonly) return true;

  if (gallery.length > 0 && !gallery[0].assetId) {
    return gallery.length >= maxAllowedImages + 1 || isDisabledDropzone;
  } else {
    return gallery.length >= maxAllowedImages || isDisabledDropzone;
  }
};
