import { useCallback } from 'react';

import { CustomTooltip } from '@/App/Shared/Tooltip/CustomTooltip';
import { useAppDispatch } from '@/Hooks/useAppDispatch';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import {
  ActiveContentModal,
  BlockedContentModal,
  DeletedContentModal,
  UserStatus,
} from '@/Mappers';
import Delete from '@/Static/Icons/atoms-symbols-events-delete.svg';
import DeleteDisabled from '@/Static/Icons/atoms-symbols-events-delete-disabled.svg';
import LockUser from '@/Static/Icons/atoms-symbols-user-lock.svg';
import UserLockDisabled from '@/Static/Icons/atoms-symbols-user-lock-disabled.svg';
import UnLockUser from '@/Static/Icons/atoms-symbols-user-unlock.svg';
import { uiActions } from '@/Store';
import { User } from '@/Types';

import { ActionButton, Container } from './ActionsCell.styles';

export type AdminIconCellProps = {
  currentUserId: string;
  data: User;
  updateUser: (user: User) => void;
};

export const ActionsCell = ({ data: row, currentUserId, updateUser }: AdminIconCellProps) => {
  const intl = useMemoedIntl();

  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(
    (userStatus: UserStatus) => {
      switch (userStatus) {
        case UserStatus.DELETED:
          updateUser({ ...row, status: UserStatus.DELETED });

          break;
        case UserStatus.ACTIVE:
          updateUser({ ...row, status: UserStatus.ACTIVE });

          break;
        case UserStatus.BLOCKED:
          updateUser({ ...row, status: UserStatus.BLOCKED });

          break;
      }
    },
    [row, updateUser],
  );

  const handleOpen = useCallback(
    (status: UserStatus) => {
      switch (status) {
        case UserStatus.DELETED:
          dispatch(
            uiActions.showModal('ADMINISTRATION', {
              handleSubmit,
              userStatus: status,
              ...DeletedContentModal({
                givenName: row.givenName,
                name: row.name,
                intl,
              }),
            }),
          );
          break;
        case UserStatus.ACTIVE:
          dispatch(
            uiActions.showModal('ADMINISTRATION', {
              handleSubmit,
              userStatus: status,
              ...ActiveContentModal({
                givenName: row.givenName,
                name: row.name,
                intl,
              }),
            }),
          );
          break;
        case UserStatus.BLOCKED:
          dispatch(
            uiActions.showModal('ADMINISTRATION', {
              handleSubmit,
              ...BlockedContentModal({
                givenName: row.givenName,
                name: row.name,
                intl,
              }),
              userStatus: status,
            }),
          );
          break;
      }
    },
    [dispatch, row.givenName, row.name, handleSubmit, intl],
  );

  return (
    <Container>
      {row.status === UserStatus.PENDING && (
        <ActionButton disabled>
          <img alt='' src={UserLockDisabled} />
        </ActionButton>
      )}
      {row.status === UserStatus.ACTIVE && (
        <CustomTooltip
          title={intl.formatMessage({
            id: 'view.user_management.user_management.table.tooltip.block_user',
            defaultMessage: 'Lock user',
          })}>
          <ActionButton onClick={() => handleOpen(UserStatus.BLOCKED)}>
            <img alt='' src={LockUser} />
          </ActionButton>
        </CustomTooltip>
      )}
      {row.status === UserStatus.ACTIVE && UserStatus.PENDING && (
        <CustomTooltip
          title={intl.formatMessage({
            id: 'view.user_management.user_management.table.tooltip.delete_user',
            defaultMessage: 'Delete user',
          })}>
          <ActionButton onClick={() => handleOpen(UserStatus.DELETED)}>
            <img alt='' src={Delete} />
          </ActionButton>
        </CustomTooltip>
      )}
      {row.status === UserStatus.BLOCKED && currentUserId !== row.cognitoId && (
        <CustomTooltip
          title={intl.formatMessage({
            id: 'view.user_management.user_management.table.tooltip.unblock_user',
            defaultMessage: 'Unlock user',
          })}>
          <ActionButton onClick={() => handleOpen(UserStatus.ACTIVE)}>
            <img alt='' src={UnLockUser} />
          </ActionButton>
        </CustomTooltip>
      )}
      {row.status === UserStatus.DELETED && UserStatus.BLOCKED && (
        <ActionButton disabled>
          <img alt='' src={DeleteDisabled} />
        </ActionButton>
      )}
      {row.status === UserStatus.PENDING && (
        <CustomTooltip
          title={intl.formatMessage({
            id: 'view.user_management.user_management.table.tooltip.delete_user',
            defaultMessage: 'Delete user',
          })}>
          <ActionButton onClick={() => handleOpen(UserStatus.DELETED)}>
            <img alt='' src={Delete} />
          </ActionButton>
        </CustomTooltip>
      )}
      {row.status === UserStatus.BLOCKED && (
        <ActionButton disabled>
          <img alt='' src={DeleteDisabled} />
        </ActionButton>
      )}
    </Container>
  );
};
