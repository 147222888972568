import { useQuery } from '@tanstack/react-query';
import { getStatistics } from '@/Services';
import { GetStatisticsResponseType, GetStatsRequestParamsProps } from '@/Types';

const useCheckInStatsQuery = <T extends GetStatisticsResponseType>({
  partnerPublicId,
  statsType,
  startDate,
  endDate,
}: GetStatsRequestParamsProps) => {
  const query = useQuery({
    queryKey: ['partnerCheckInStats', partnerPublicId, statsType, startDate, endDate],
    queryFn: () => getStatistics<T>({ partnerPublicId, statsType, startDate, endDate }),
    staleTime: 1000 * 60 * 5,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!partnerPublicId,
    cacheTime: 0,
  });

  return query;
};

export default useCheckInStatsQuery;
