import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppContext } from '@/Context';
import { RegisterPartnerServiceFactory } from '@/Services';

type useResetPasswordMutationProps = {
  publicId: string;
  email: string;
};

const useResetPasswordMutation = () => {
  const { dispatch } = useAppContext();
  const registerPartnerService = useMemo(
    () => new RegisterPartnerServiceFactory().getInstance(dispatch),
    [dispatch],
  );

  return useMutation(({ publicId, email }: useResetPasswordMutationProps) =>
    registerPartnerService.resendTempPassword({
      publicId,
      email,
    }),
  );
};

export default useResetPasswordMutation;
