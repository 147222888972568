import { makeStyles } from 'tss-react/mui';

export const useLoaderStyles = makeStyles()(() => ({
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
}));
